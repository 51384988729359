/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Progress from "components/Progress";

import CommonFun from "services/common";
import ERPAPI from "services/erp";

import OpenItems from "./components/openItem";
import OpenProcess from "./components/openProcess";
import UnPostedInvoices from "./components/unPostedInvoices";

function CreditInfo({ customerID }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [basicCreditInfo, setBasicCreditInfo] = useState({});
  const [creditFrame, setCreditFrame] = useState(0);
  const [openInvoices, setOpenInvoices] = useState([]);
  const [openProcess, setOpenProcess] = useState([]);
  const [unPostedInvoices, setUnPostedInvoices] = useState([]);

  const getCreditInfo = async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerCreditInformation(customerID.replace("erp-", ""));
      if (ERPResponse && ERPResponse.data && ERPResponse.data) {
        setBasicCreditInfo({ ...ERPResponse.data.basicCreditInfo });
        setCreditFrame(
          ERPResponse.data.openInvoices.reduce(
            (accumulator, current) => accumulator + current.openAmount,
            0
          )
        );
        setOpenInvoices([...ERPResponse.data.openInvoices]);
        setOpenProcess([...ERPResponse.data.openProcess]);
        setUnPostedInvoices([...ERPResponse.data.notBookedInvoices]);
      } else {
        setBasicCreditInfo({});
        setOpenInvoices([]);
        setOpenProcess([]);
        setUnPostedInvoices([]);
      }
      setLoading(false);
    } else {
      setBasicCreditInfo({});
      setOpenInvoices([]);
      setOpenProcess([]);
      setUnPostedInvoices([]);
    }
  };

  useEffect(() => {
    getCreditInfo();
  }, [customerID]);

  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "Credit Info");

  return (
    <Grid container spacing={1}>
      <Progress loading={loading} isFull />
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={7} lg={6} xl={5}>
            <OpenItems invoices={openInvoices} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={7} lg={6} xl={5}>
            <OpenProcess process={openProcess} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={7} lg={6} xl={5}>
            <UnPostedInvoices invoices={unPostedInvoices} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={7} lg={6} xl={5}>
            <Card sx={{ overflow: "hidden" }}>
              <MDBox>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
                          <MDTypography
                            variant="caption"
                            color="black"
                            fontWeight="bold"
                            textTransform="uppercase"
                            sx={({ typography: { size } }) => ({
                              fontSize: size.xs,
                            })}
                          >
                            {t("Credit Limit")}
                          </MDTypography>
                        </TableCell>
                        <TableCell align="right">
                          <MDTypography variant="caption" color="black" fontWeight="medium">
                            {CommonFun.displayCurrency(
                              basicCreditInfo.creditLimit,
                              basicCreditInfo.currency
                                ? basicCreditInfo.currency
                                : basicCreditInfo.currency
                            )}
                          </MDTypography>
                        </TableCell>
                        <TableCell sx={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
                          <MDTypography
                            variant="caption"
                            color="black"
                            fontWeight="bold"
                            textTransform="uppercase"
                            sx={({ typography: { size } }) => ({
                              fontSize: size.xs,
                            })}
                          >
                            {t("Credit Frame")}
                          </MDTypography>
                        </TableCell>
                        <TableCell align="right">
                          <MDTypography variant="caption" color="black" fontWeight="medium">
                            {CommonFun.displayCurrency(
                              creditFrame,
                              basicCreditInfo.currency
                                ? basicCreditInfo.currency
                                : basicCreditInfo.currency
                            )}
                          </MDTypography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(CreditInfo);
