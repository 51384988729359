/* eslint-disable react/prop-types */
import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

import ServerSideTable from "components/Tables/ServerSideTable";
import CommonFun from "services/common";
import ERPAPI from "services/erp";

function Items({ customerID }) {
  const { t } = useTranslation("common");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [orderBy, setOrderBy] = useState("articleNumber");
  const [order, setOrder] = useState("asc");

  const getItems = async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerItems(
        customerID.replace("erp-", ""),
        page,
        pageSize,
        orderBy,
        order
      );
      if (
        ERPResponse &&
        ERPResponse.data &&
        ERPResponse.data.items &&
        ERPResponse.data.items.length
      ) {
        setOrders(ERPResponse.data.items);
        setCount(ERPResponse.data.totalCount);
      } else {
        setOrders([]);
      }
      setLoading(false);
    } else {
      setOrders([]);
    }
  };

  useEffect(async () => {
    getItems();
  }, [customerID, page, pageSize, orderBy, order]);

  const columns = [
    {
      header: t("Article No."),
      accessor: "articleNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("H-Article No."),
      accessor: "manufacturerArticleNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Name"),
      accessor: "matchcode",
      align: "left",
      sort: true,
    },
    {
      header: t("Manufacturer"),
      accessor: "manufacturer",
      align: "left",
      sort: true,
    },
    {
      header: t("Article Group"),
      accessor: "articleGroupName",
      align: "left",
      sort: true,
    },
    {
      header: t("Stock"),
      accessor: "stock",
      align: "right",
      sort: false,
    },
    {
      header: t("Reserved"),
      accessor: "reserved",
      align: "right",
      sort: false,
    },
    {
      header: t("Ordered"),
      accessor: "ordered",
      align: "right",
      sort: false,
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "Items");

  return (
    <MDBox>
      <Progress loading={loading} isFull />
      <ServerSideTable
        fixedTable
        rowsPerPage={pageSize}
        page={page}
        columns={columns}
        data={orders}
        count={count}
        order={order}
        orderBy={orderBy}
        onRequestSort={(key) => {
          const isAsc = orderBy === key && order === "asc";
          setOrder(isAsc ? "desc" : "asc");
          setOrderBy(key);
        }}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        getRowProps={(row) => ({
          onClick: () => {
            CommonFun.onClickItem(row.articleNumber);
          },
          style: {
            cursor: "pointer",
          },
        })}
      />
    </MDBox>
  );
}

export default memo(Items);
