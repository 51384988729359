import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function TicketCard({ color, title, count, active, ...rest }) {
  return (
    <Card style={{ overflow: "hidden" }} {...rest}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={2}
        bgColor={active ? "dark" : "white"}
        lineHeight={1.25}
      >
        <MDTypography variant="h5" fontWeight="light" color={active ? "white" : "black"}>
          {title}
        </MDTypography>
        <MDTypography variant="h4" color={active ? "white" : "inherit"}>
          {count}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

TicketCard.defaultProps = {
  color: "info",
  active: false,
  count: "",
};

TicketCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  active: PropTypes.bool,
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TicketCard;
