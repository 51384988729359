/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, memo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Progress from "components/Progress";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Stats({ customerID }) {
  const { t } = useTranslation("common");
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerStats(customerID.replace("erp-", ""));
      if (ERPResponse && ERPResponse.data) {
        setStats(ERPResponse.data);
      } else {
        setStats({});
      }
      setLoading(false);
    } else {
      setStats({});
    }
  }, [customerID]);

  return (
    <MDBox position="relative">
      <Progress loading={loading} isFull />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <MDBox mb={2}>
            <MDInput
              label={t("Credit Limit")}
              fullWidth
              value={stats.creditLimit != null ? stats.creditLimit : ""}
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Open Credit")}
              fullWidth
              value={stats.openCredit != null ? stats.openCredit : ""}
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Payment Morale")}
              fullWidth
              value={stats.paymentMorale != null ? stats.paymentMorale : ""}
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Revenue (Total)")}
              fullWidth
              value={stats.salesNativeCurrency != null ? stats.salesNativeCurrency : ""}
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Revenue (YTD)")}
              fullWidth
              value={
                stats.salesNativeCurrencyCurrentYear != null
                  ? stats.salesNativeCurrencyCurrentYear
                  : ""
              }
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Revenue (Last Year)")}
              fullWidth
              value={
                stats.salesNativeCurrencyPreviousYear != null
                  ? stats.salesNativeCurrencyPreviousYear
                  : ""
              }
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Orders (Total)")}
              fullWidth
              value={stats.ordersTotal != null ? stats.ordersTotal : ""}
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Orders (YTD)")}
              fullWidth
              value={stats.ordersTotalCurrentYear != null ? stats.ordersTotalCurrentYear : ""}
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("Orders (Last Year)")}
              fullWidth
              value={stats.ordersTotalPreviousYear != null ? stats.ordersTotalPreviousYear : ""}
              disabled
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              label={t("First Contact")}
              fullWidth
              value={
                stats.firstContact != null
                  ? moment.utc(stats.firstContact).format(CommonFun.getDateFormat())
                  : ""
              }
              disabled
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(Stats);
