/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import CommonFun from "services/common";

import Users from "./components/users";
import Templates from "./components/templates";
import Signatures from "./components/signatures";
import Profile from "./components/profile";
import MySignatures from "./components/mySignatures";

function Settings({ type }) {
  const { t } = useTranslation("common");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  if (type === "SETTINGS") {
    // eslint-disable-next-line no-console
    console.log("render settings");
    return (
      <MDBox p={1} bgColor="light" position="relative">
        <AppBar position="static">
          <Tabs
            color="info"
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
          >
            <Tab label={t("Profile")} />
            <Tab label={t("Templates")} />
            <Tab label={t("My Signature")} sx={CommonFun.isAdmin() ? null : { display: "none" }} />
            <Tab label={t("Users")} sx={CommonFun.isAdmin() ? null : { display: "none" }} />
            <Tab label={t("Signature")} sx={CommonFun.isAdmin() ? null : { display: "none" }} />
          </Tabs>
        </AppBar>
        <MDBox>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={tabValue === 0 ? null : { display: "none" }}>
              <Profile />
            </Grid>
            <Grid item xs={12} sx={tabValue === 1 ? null : { display: "none" }}>
              <Templates />
            </Grid>
            <Grid item xs={12} sx={tabValue === 2 ? null : { display: "none" }}>
              <MySignatures />
            </Grid>
            <Grid item xs={12} sx={tabValue === 3 ? null : { display: "none" }}>
              {CommonFun.isAdmin() ? <Users /> : null}
            </Grid>
            <Grid item xs={12} sx={tabValue === 4 ? null : { display: "none" }}>
              {CommonFun.isAdmin() ? <Signatures /> : null}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    );
  }
  return false;
}

export default memo(Settings);
