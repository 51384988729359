/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { InputText, InputCheckbox, InputDropdown } from "components/MDInputs";
import StatisticsTable from "components/Tables/StatisticsTable";
import ERPAPI from "services/erp";

function Basics({ item, articleNumber }) {
  const { t } = useTranslation("common");
  const [currentItem, setCurrentItem] = useState(item);
  const [warehouseInformation, setWarehouseInformation] = useState([]);

  // MASTER DATA
  const [countries, setCountries] = useState([]);

  const getMasterData = async () => {
    const ERPResponse = await ERPAPI.getCountries();
    if (ERPResponse) {
      setCountries(ERPResponse);
    }
  };

  const getShippingClass = (shippingClass) => {
    switch (shippingClass) {
      case 496:
        return "Standard";
      case 490:
        return "Freight";
      default:
        return shippingClass ? `Other ${shippingClass}` : "";
    }
  };

  const getWarehouseInformation = async () => {
    const ERPResponse = await ERPAPI.getWarehouseInformation(articleNumber);
    if (ERPResponse && ERPResponse.data) {
      const array = ERPResponse.data.sort((a, b) => b.quantity - a.quantity);
      setWarehouseInformation([...array]);
    } else {
      setWarehouseInformation([]);
    }
  };

  useEffect(() => {
    setCurrentItem({ ...item });
  }, [item]);

  useEffect(() => {
    getWarehouseInformation();
  }, [articleNumber]);

  useEffect(() => {
    getMasterData();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleChange = (name, value) => {
    setCurrentItem({
      ...currentItem,
      // [name]: value,
    });
  };

  const columns = [
    {
      header: t("Price list"),
      accessor: "description",
      align: "left",
      sort: false,
    },
    {
      header: t("Gross"),
      accessor: "gross",
      align: "center",
      sort: false,
      render: (row) => (
        <MDBox>
          <Checkbox
            disabled
            color="success"
            checked={!!row.gross}
            inputProps={{ "aria-label": "controlled" }}
          />
        </MDBox>
      ),
    },
    {
      header: t("Unit Price"),
      accessor: "priceLocal",
      align: "right",
      currency: "currencyIso",
      sort: false,
    },
  ];

  const warehouseInformationColumns = [
    {
      header: t("Quantity"),
      accessor: "quantity",
      align: "left",
      sort: false,
    },
    {
      header: t("Unit"),
      accessor: "unit",
      align: "left",
      sort: false,
    },
    {
      header: t("Stock Location (Long)"),
      accessor: "stockLocationLong",
      align: "left",
      sort: false,
    },
  ];

  return (
    <MDBox position="relative">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <Card sx={{ boxShadow: "none" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Master Data")}
              </MDTypography>
            </MDBox>
            <InputText
              label={t("Description 1")}
              name="description1"
              value={currentItem.description1}
              onChange={handleChange}
            />
            <InputText
              label={t("Description 2")}
              name="description2"
              value={currentItem.description2}
              onChange={handleChange}
            />

            <InputCheckbox
              valueInt
              label={t("Sellable")}
              name="sales"
              onChange={handleChange}
              value={currentItem.sales}
            />
            <InputCheckbox
              valueInt
              label={t("Only on order")}
              name="bestellartikel"
              onChange={handleChange}
              value={currentItem.bestellartikel}
            />
            <InputText
              label={t("Manufacturer Number")}
              name="manufacturerArticleNumber"
              value={currentItem.manufacturerArticleNumber}
              onChange={handleChange}
            />
            <InputText
              label={t("Manufacturer")}
              name="manufacturer"
              value={currentItem.manufacturer}
              onChange={handleChange}
            />
            <InputText
              label={t("Item Group")}
              name="articleGroupName"
              value={currentItem.articleGroupName}
              onChange={handleChange}
            />
            <InputText
              label={t("Weight with packaging (kg)")}
              name="weight"
              value={currentItem.weight}
              onChange={handleChange}
            />
            <InputText
              label={t("Shipping Class")}
              name="shippingClass"
              value={getShippingClass(currentItem.shippingClass)}
              onChange={handleChange}
            />
            <InputText
              label={t("EAN")}
              name="eanNumber"
              value={currentItem.eanNumber}
              onChange={handleChange}
            />
            <InputText
              label={t("Custom Tariffs Number")}
              name="customTariffsNumber"
              value={currentItem.customTariffsNumber}
              onChange={handleChange}
            />
            <InputDropdown
              label={t("Country Of Origin")}
              options={countries}
              name="countryOfOrigin"
              value={currentItem.countryOfOrigin}
              onChange={handleChange}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} xl={4} style={{ display: "flex", position: "relative" }}>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: "4px",
              m: 0,
              display: { xs: "none", md: "block" },
            }}
          />
          <Card sx={{ boxShadow: "none", width: "100%" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Information")}
              </MDTypography>
            </MDBox>
            <InputText
              label={t("Total Stock")}
              name="stock"
              value={currentItem.stock}
              onChange={handleChange}
            />
            <InputText
              label={t("Available Stock")}
              name="availableStock"
              value={currentItem.availableStock}
              onChange={handleChange}
            />
            <InputText
              label={t("Purchased Stock")}
              name="ordered"
              value={currentItem.ordered}
              onChange={handleChange}
            />
            <MDBox display="flex" px={2} mb={2}>
              <StatisticsTable
                stickyHeader={300}
                tableHighLight
                columns={warehouseInformationColumns}
                rows={warehouseInformation}
                getRowProps={() => ({})}
              />
            </MDBox>
            <InputText
              label={t("Base Unit")}
              name="bme"
              value={currentItem.bme}
              onChange={handleChange}
            />
            <InputText
              label={t("Selling Unit")}
              name="vkme"
              value={currentItem.vkme}
              onChange={handleChange}
            />
            <InputText
              label={t("Selling Unit Contains Base Unit")}
              name="BmeInVkme"
              value={currentItem.BmeInVkme}
              onChange={handleChange}
            />
            <InputCheckbox
              valueInt
              label={t("Active")}
              name="active"
              onChange={handleChange}
              value={currentItem.active}
            />
            <InputCheckbox
              valueInt
              label={t("Possible To Purchase")}
              name="possibleToPurchase"
              onChange={handleChange}
              value={currentItem.possibleToPurchase}
            />
            <InputCheckbox
              valueInt
              label={t("Not Stocked")}
              name="notStocked"
              onChange={handleChange}
              value={currentItem.notStocked}
            />
          </Card>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              m: 0,
              right: "-4px",
              display: { xs: "none", xl: "block" },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4} style={{ display: "flex", position: "relative" }}>
          <Card sx={{ boxShadow: "none", width: "100%" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Memo")}
              </MDTypography>
            </MDBox>
            <MDBox
              mx={2}
              p={2}
              sx={{
                border: "1px solid #d2d6da",
                borderRadius: "0.375rem",
                minHeight: "200px",
                maxHeight: "200px",
                overflow: "auto",
              }}
            >
              <MDTypography variant="body2" color="black" sx={{ whiteSpace: " pre-line" }}>
                {currentItem.memo}
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Prices")}
              </MDTypography>
            </MDBox>
            <MDBox px={2}>
              <StatisticsTable
                stickyHeader={400}
                tableHighLight
                columns={columns}
                rows={
                  currentItem && currentItem.articlePriceList ? currentItem.articlePriceList : []
                }
                getRowProps={() => ({})}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(Basics);
