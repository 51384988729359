/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import ServerSideTable from "components/Tables/ServerSideTable";
import TableSearch from "view/components/TableSearch";

import CommonFun from "services/common";
import ERPAPI from "services/erp";

function Items({ type }) {
  const { t } = useTranslation("common");

  const [searchData, setSearchData] = useState(false);
  const [submittedFilter, setSubmittedFilter] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("articleNumber");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const searchableColumns = [
    { name: "ArticleNumber", type: "text", multiple: true, select: true },
    { name: "Matchcode", type: "text", multiple: true, select: true },
    { name: "Manufacturer", type: "text", multiple: true, select: true },
    { name: "ManufacturerArticleNumber", type: "text", multiple: true, select: true },
    { name: "ArticleGroup", type: "text", multiple: true },
    { name: "ArticleGroupName", type: "text", multiple: true },
  ];

  const getItems = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.searchItems(searchData, page, pageSize, orderBy, order);
    if (
      ERPResponse &&
      ERPResponse.data &&
      ERPResponse.data.items &&
      ERPResponse.data.items.length
    ) {
      setItems(ERPResponse.data.items);
      setCount(ERPResponse.data.totalCount);
    } else {
      setItems([]);
    }
    setLoading(false);
    setSubmittedFilter(false);
  };

  useEffect(async () => {
    if (submittedFilter) {
      getItems();
    }
  }, [submittedFilter]);

  const handleAdvancedSearch = (data) => {
    if (page !== 0) {
      setPage(0);
    }
    setSearchData({ ...data });
    setSubmittedFilter(true);
  };

  const columns = [
    {
      header: t("Article No."),
      accessor: "articleNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("H-Article No."),
      accessor: "manufacturerArticleNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Name"),
      accessor: "matchcode",
      align: "left",
      sort: true,
    },
    {
      header: t("Manufacturer"),
      accessor: "manufacturer",
      align: "left",
      sort: true,
    },
    {
      header: t("Article Group"),
      accessor: "articleGroupName",
      align: "left",
      sort: true,
    },
    {
      header: t("Stock"),
      accessor: "stock",
      align: "right",
      sort: false,
    },
    {
      header: t("Reserved"),
      accessor: "reserved",
      align: "right",
      sort: false,
    },
    {
      header: t("Ordered"),
      accessor: "ordered",
      align: "right",
      sort: false,
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    setSubmittedFilter(true);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    setSubmittedFilter(true);
  };

  if (type === "ITEMS") {
    // eslint-disable-next-line no-console
    console.log("render items");

    return (
      <MDBox p={1} bgColor="light">
        <Progress loading={loading} isFull />
        <Card>
          <Grid container spacing={1}>
            <TableSearch columns={searchableColumns} onSearch={handleAdvancedSearch} page="ITEMS" />
            <Grid item xs={12}>
              <MDBox pt={0}>
                <ServerSideTable
                  fixedTable
                  rowsPerPage={pageSize}
                  page={page}
                  columns={columns}
                  data={items}
                  count={count}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(key) => {
                    const isAsc = orderBy === key && order === "asc";
                    setOrder(isAsc ? "desc" : "asc");
                    setOrderBy(key);
                    setPage(0);
                    setSubmittedFilter(true);
                  }}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  getRowProps={(row) => ({
                    onClick: () => {
                      CommonFun.onClickItem(row.articleNumber);
                    },
                    style: {
                      cursor: "pointer",
                    },
                  })}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    );
  }
  return false;
}

export default memo(Items);
