/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CommonFun from "services/common";

function OpenItems({ invoices }) {
  const { t } = useTranslation("common");
  const total = invoices.reduce((accumulator, current) => accumulator + current.openAmount, 0);
  return (
    <Card sx={{ overflow: "hidden" }}>
      <MDBox px={3} py={1} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h5" fontWeight="medium">
          {t("Open Items")}
        </MDTypography>
      </MDBox>
      <MDBox>
        <TableContainer>
          <Table>
            <TableHead sx={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
              <TableRow>
                <TableCell>
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="bold"
                    textTransform="uppercase"
                    sx={({ typography: { size } }) => ({
                      fontSize: size.xs,
                    })}
                  >
                    {t("OI Number")}
                  </MDTypography>
                </TableCell>
                <TableCell>
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="bold"
                    textTransform="uppercase"
                    sx={({ typography: { size } }) => ({
                      fontSize: size.xs,
                    })}
                  >
                    {t("Date")}
                  </MDTypography>
                </TableCell>
                <TableCell>
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="bold"
                    textTransform="uppercase"
                    sx={({ typography: { size } }) => ({
                      fontSize: size.xs,
                    })}
                  >
                    {t("Doc. Number")}
                  </MDTypography>
                </TableCell>
                <TableCell>
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="bold"
                    textTransform="uppercase"
                    sx={({ typography: { size } }) => ({
                      fontSize: size.xs,
                    })}
                  >
                    {t("Due Date")}
                  </MDTypography>
                </TableCell>
                <TableCell align="right">
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="bold"
                    textTransform="uppercase"
                    sx={({ typography: { size } }) => ({
                      fontSize: size.xs,
                    })}
                  >
                    {t("Open Amount")}
                  </MDTypography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {row.openInvoiceNumber}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {moment.utc(row.documentDate).format(CommonFun.getDateFormat())}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {row.documentNumber}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {moment.utc(row.dueDate).format(CommonFun.getDateFormat())}
                    </MDTypography>
                  </TableCell>
                  <TableCell align="right">
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {CommonFun.displayCurrency(
                        row.openAmount,
                        row.currency ? row.currency : row.currency
                      )}
                    </MDTypography>
                  </TableCell>
                </TableRow>
              ))}
              {!invoices.length && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {t("No data to show")}
                    </MDTypography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {invoices.length ? (
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {t("Total")}
                    </MDTypography>
                  </TableCell>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <MDTypography variant="caption" color="black" fontWeight="medium">
                      {CommonFun.displayCurrency(
                        total,
                        invoices[0].currency ? invoices[0].currency : invoices[0].currency
                      )}
                    </MDTypography>
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}
          </Table>
        </TableContainer>
      </MDBox>
    </Card>
  );
}

export default memo(OpenItems);
