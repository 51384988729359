/* eslint-disable react/no-array-index-key */
import { useState, useEffect, memo } from "react";
import moment from "moment";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDStatus from "components/MDStatus";
import Progress from "components/Progress";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

function recentOrders({ customerID, setRecentOrdersCount }) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerRecentOrders(customerID.replace("erp-", ""));
      if (ERPResponse && ERPResponse.data) {
        setOrders(ERPResponse.data);
        setRecentOrdersCount(ERPResponse.data.length);
      } else {
        setOrders([]);
        setRecentOrdersCount(0);
      }
      setLoading(false);
    } else {
      setOrders([]);
    }
  }, [customerID]);

  return (
    <MDBox position="relative">
      <Progress loading={loading} isFull />
      {orders.map((item, index) => (
        <MDBox key={index} sx={{ position: "relative", display: "flex", flexDirection: "column" }}>
          <MDBox
            mt={1}
            onClick={() => {
              CommonFun.onClickOrder(item.documentIdentifier);
            }}
            sx={{ display: "flex", flexDirection: "column", cursor: "pointer" }}
          >
            <MDTypography variant="button" fontWeight="medium" color="dark">
              #{item.documentIdentifier}
            </MDTypography>
            <MDTypography variant="button" color="black">
              {moment.utc(item.documentDate).format(CommonFun.getDateFormat())}
            </MDTypography>
          </MDBox>
          <MDBox mt={1}>
            <MDStatus
              value={{
                documentIdentifier: item.documentIdentifier,
                payment: item.paidStatus,
                packing: item.fulfillmentStatus,
                invoicing: item.invoicingStatus,
                shipping: item.shipmentStatus,
                shipments: item.shipments,
              }}
            />
          </MDBox>
          {orders.length - 1 !== index ? <Divider /> : null}
        </MDBox>
      ))}
    </MDBox>
  );
}

export default memo(recentOrders);
