/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */

import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Progress from "components/Progress";
import ReportTable from "components/Tables/ReportTable";
import ReportSearch from "view/components/ReportSearch";

import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Delivery({ type }) {
  const { t } = useTranslation("common");
  const [searchData, setSearchData] = useState(false);
  const [submittedFilter, setSubmittedFilter] = useState(false);
  const [delivery, setDelivery] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("documentNumber");
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const searchableColumns = [
    { name: "DateFrom", type: "date", multiple: false, select: true },
    { name: "DateTo", type: "date", multiple: false, select: true },
    { name: "CountryCodes", type: "text", multiple: true, select: true },
    { name: "DocumentNumbers", type: "text", multiple: true, select: true },
    { name: "CustomerNumbers", type: "text", multiple: true, select: true },
    { name: "ArticleNumbers", type: "text", multiple: true, select: true },
  ];

  const getDelivery = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.searchDeliveryInventory(
      searchData,
      page,
      pageSize,
      orderBy,
      order
    );
    if (
      ERPResponse &&
      ERPResponse.data &&
      ERPResponse.data.items &&
      ERPResponse.data.items.length
    ) {
      setDelivery(ERPResponse.data.items);
      setCount(ERPResponse.data.totalCount);
    } else {
      setDelivery([]);
    }
    setLoading(false);
    setSubmittedFilter(false);
  };

  useEffect(async () => {
    if (submittedFilter) {
      getDelivery();
    }
  }, [submittedFilter]);

  const handleAdvancedSearch = (data) => {
    if (page !== 0) {
      setPage(0);
    }
    setSearchData({ ...data });
    setSubmittedFilter(true);
  };

  const getOrderConditionLabel = (id) => {
    switch (id) {
      case 1:
        return t("Deliverable");
      case 2:
        return t("Partial");
      case 3:
        return t("Out of Stock");
      default:
        return ";";
    }
  };

  const columns = [
    {
      header: t("Document No."),
      accessor: "documentNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Year"),
      accessor: "documentYear",
      align: "left",
      sort: true,
    },
    {
      header: t("Customer No."),
      accessor: "receiver",
      align: "left",
      sort: true,
    },
    {
      header: t("Match Code"),
      accessor: "a0Matchcode",
      align: "left",
      sort: true,
    },
    {
      header: t("Country"),
      accessor: "land",
      align: "left",
      sort: false,
    },
    {
      header: t("Payment Condition"),
      accessor: "paymentCondition",
      align: "left",
      sort: true,
    },
    {
      header: t("Carrier"),
      accessor: "shipment",
      align: "left",
      sort: true,
    },
    {
      header: t("Shipping Method"),
      accessor: "shipmentMethod",
      align: "left",
      sort: true,
    },
    {
      header: t("Article No."),
      accessor: "articleNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Article Desc."),
      accessor: "matchcode",
      align: "left",
      sort: true,
    },
    {
      header: t("Date"),
      accessor: "deliveryDate",
      align: "left",
      sort: true,
      dateFormat: CommonFun.getDateFormat(),
    },
    {
      header: t("Qty"),
      accessor: "baseQuantity",
      align: "left",
      sort: true,
    },
    {
      header: t("Stock"),
      accessor: "available",
      align: "left",
      sort: true,
    },
    {
      header: t("Stock LPL."),
      accessor: "stockAvailable",
      align: "left",
      sort: true,
    },
    {
      header: t("Location"),
      accessor: "storageLocation",
      align: "left",
      sort: true,
    },
    {
      header: t("Order Condition"),
      accessor: "orderCondition",
      align: "left",
      sort: true,
      render: (row) => (
        <MDTypography
          variant="caption"
          color={row.status === "failure" ? "error" : "black"}
          fontWeight="medium"
        >
          {getOrderConditionLabel(row.orderCondition)}
        </MDTypography>
      ),
    },
    {
      header: t("Order Item Condition"),
      accessor: "orderItemCondition",
      align: "left",
      sort: true,
      render: (row) => (
        <MDTypography
          variant="caption"
          color={row.status === "failure" ? "error" : "black"}
          fontWeight="medium"
        >
          {getOrderConditionLabel(row.orderItemCondition)}
        </MDTypography>
      ),
    },
    {
      header: t("Blocked Status"),
      accessor: "isBlocked",
      align: "left",
      sort: true,
      render: (row) => (
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={
              row.isBlocked && row.deliveryIsBlocked === -1
                ? t("Delivery block is set for customer")
                : null
            }
            placement="bottom"
          >
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
              }}
              variant="gradient"
              bgColor={row.isBlocked && row.deliveryIsBlocked === -1 ? "error" : "secondary"}
            >
              <Icon sx={{ fontFamily: "inherit" }}>D</Icon>
            </MDBox>
          </Tooltip>
          <Tooltip
            title={row.isBlocked && row.isVatChecked === 0 ? t("VAT ID needs to be checked") : null}
            placement="bottom"
          >
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
              }}
              variant="gradient"
              bgColor={row.isBlocked && row.isVatChecked === 0 ? "error" : "secondary"}
            >
              <Icon sx={{ fontFamily: "inherit" }}>V</Icon>
            </MDBox>
          </Tooltip>
          <Tooltip
            title={
              row.isBlocked && row.paymentMethod === "Bonitat"
                ? t("Overextended credit limit")
                : null
            }
            placement="bottom"
          >
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
              }}
              variant="gradient"
              bgColor={row.isBlocked && row.paymentMethod === "Bonitat" ? "error" : "secondary"}
            >
              <Icon sx={{ fontFamily: "inherit" }}>C</Icon>
            </MDBox>
          </Tooltip>
          <Tooltip
            title={
              row.isBlocked && row.belIdBlockedByCountry === -1
                ? t("UK taxation needs to be checked")
                : null
            }
            placement="bottom"
          >
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
              }}
              variant="gradient"
              bgColor={row.isBlocked && row.belIdBlockedByCountry === -1 ? "error" : "secondary"}
            >
              <Icon sx={{ fontFamily: "inherit" }}>U</Icon>
            </MDBox>
          </Tooltip>
        </MDBox>
      ),
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    setSubmittedFilter(true);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    setSubmittedFilter(true);
  };

  if (type === "DELIVERY") {
    // eslint-disable-next-line no-console
    console.log("render delivery");

    return (
      <MDBox p={1} bgColor="light">
        <Progress loading={loading} isFull />
        <Card>
          <Grid container spacing={1}>
            <ReportSearch columns={searchableColumns} onSearch={handleAdvancedSearch} />
            <Grid item xs={12}>
              <MDBox pt={0}>
                <ReportTable
                  fixedTable
                  rowsPerPage={pageSize}
                  page={page}
                  columns={columns}
                  data={delivery}
                  count={count}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(key) => {
                    const isAsc = orderBy === key && order === "asc";
                    setOrder(isAsc ? "desc" : "asc");
                    setOrderBy(key);
                    setPage(0);
                    setSubmittedFilter(true);
                  }}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  getRowProps={() => ({
                    onClick: () => {
                      // CommonFun.onClickOrder(row.documentIdentifier);
                    },
                    style: {
                      // cursor: "pointer",
                    },
                  })}
                  getRowColor={(row) => (row.status === "failure" ? "error" : "black")}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    );
  }
  return false;
}

export default memo(Delivery);
