/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Progress from "components/Progress";
import breakpoints from "assets/theme/base/breakpoints";
import ERPAPI from "services/erp";

import Basics from "./components/basics";
import Shops from "./components/shops";
import StockInformation from "./components/stockInformation";

function Item({ articleNumber, type, tab = 0 }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(tab);
  const [currentItem, setCurrentItem] = useState({});
  const [url, setURL] = useState("");
  const isNew = articleNumber.includes("new-");

  const priceListSorting = (data) => {
    const B2BNetto = "B2B (netto)";
    const UVPNetto = "UVP (netto)";
    const B2CBrutto = "B2C (brutto)";
    const UVPBrutto = "UVP (brutto)";

    if (data.articlePriceList && data.articlePriceList.length) {
      const priceList = [];
      data.articlePriceList
        .filter((x) => x.description.toLowerCase() === B2BNetto.toLowerCase())
        .forEach((item) => {
          priceList.push(item);
        });
      data.articlePriceList
        .filter((x) => x.description.toLowerCase() === UVPNetto.toLowerCase())
        .forEach((item) => {
          priceList.push(item);
        });
      data.articlePriceList
        .filter((x) => x.description.toLowerCase() === B2CBrutto.toLowerCase())
        .forEach((item) => {
          priceList.push(item);
        });
      data.articlePriceList
        .filter((x) => x.description.toLowerCase() === UVPBrutto.toLowerCase())
        .forEach((item) => {
          priceList.push(item);
        });
      data.articlePriceList
        .filter(
          (x) =>
            x.description.toLowerCase() !== B2BNetto.toLowerCase() &&
            x.description.toLowerCase().includes(B2BNetto.toLowerCase())
        )
        .forEach((item) => {
          priceList.push(item);
        });
      data.articlePriceList
        .filter(
          (x) =>
            x.description.toLowerCase() !== B2CBrutto.toLowerCase() &&
            x.description.toLowerCase().includes(B2CBrutto.toLowerCase())
        )
        .forEach((item) => {
          priceList.push(item);
        });
      data.articlePriceList
        .filter(
          (x) =>
            x.description.toLowerCase() !== UVPNetto.toLowerCase() &&
            x.description.toLowerCase() !== UVPBrutto.toLowerCase() &&
            !x.description.toLowerCase().includes(B2BNetto.toLowerCase()) &&
            !x.description.toLowerCase().includes(B2CBrutto.toLowerCase())
        )
        .forEach((item) => {
          priceList.push(item);
        });
      // eslint-disable-next-line no-param-reassign
      data.articlePriceList = priceList;
    }
    return data;
  };
  const getItem = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getItem(articleNumber);
    if (ERPResponse && ERPResponse.data) {
      setCurrentItem(priceListSorting(ERPResponse.data));
    } else {
      setCurrentItem({});
    }
    setLoading(false);
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(async () => {
    if (type === "ITEM" && articleNumber) {
      getItem();
    }
  }, [articleNumber]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    if (!isNew) {
      switch (tabValue) {
        case 0:
          setURL(`${window.location.origin}/item/${articleNumber}/basics`);
          break;
        case 1:
          setURL(`${window.location.origin}/item/${articleNumber}/shops`);
          break;
        default:
          setURL(`${window.location.origin}/item/${articleNumber}/basics`);
      }
    }
  }, [tabValue]);

  const handelShare = () => {
    const copyText = document.getElementById(`urlInput_${articleNumber}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  };

  if (type === "ITEM") {
    // eslint-disable-next-line no-console
    console.log("render item", articleNumber);
    return (
      <MDBox p={1} bgColor="light" position="relative">
        <Progress loading={loading} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {!isNew ? (
              <MDBox
                sx={{
                  position: "absolute",
                  top: "-40px",
                  right: "8px",
                }}
              >
                <input
                  type="text"
                  value={url}
                  id={`urlInput_${articleNumber}`}
                  style={{ display: "none" }}
                  readOnly
                />
                <Tooltip title={`Copy to clipboard: ${url}`}>
                  <Fab
                    size="small"
                    color="secondary"
                    aria-label="share"
                    onClick={() => {
                      handelShare();
                    }}
                    sx={{
                      width: 24,
                      height: 24,
                      minHeight: 24,
                    }}
                  >
                    <Icon>share</Icon>
                  </Fab>
                </Tooltip>
              </MDBox>
            ) : null}
            <Card>
              <MDBox position="relative">
                <MDBox pt={1} px={2}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <MDBox height="100%" mt={0.5} lineHeight={1}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {articleNumber
                            ? `${t("Article Number")}: ${articleNumber}`
                            : t("New Item")}
                        </MDTypography>
                        <MDTypography variant="button" color="black" fontWeight="regular">
                          {currentItem?.matchcode || ""}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} sx={{ ml: "auto" }}>
                      <AppBar position="static">
                        <Tabs
                          color="info"
                          orientation={tabsOrientation}
                          value={tabValue}
                          onChange={handleSetTabValue}
                        >
                          <Tab label={t("Basics")} />
                          <Tab label={t("Incoming / Outgoing")} />
                          <Tab label={t("Accessory / Shops")} />
                        </Tabs>
                      </AppBar>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sx={tabValue === 0 ? null : { display: "none" }}>
                      <Basics item={currentItem} articleNumber={articleNumber} />
                    </Grid>
                    <Grid item xs={12} sx={tabValue === 1 ? null : { display: "none" }}>
                      <StockInformation article={currentItem} articleNumber={articleNumber} />
                    </Grid>
                    <Grid item xs={12} sx={tabValue === 2 ? null : { display: "none" }}>
                      <Shops articleNumber={articleNumber} />
                    </Grid>
                    {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <MDBox p={2}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="medium"
                          onClick={() => {
                            window.dispatchEvent(
                              new CustomEvent("snackbar", {
                                detail: {
                                  type: "OK",
                                  title: "Save",
                                  content: "Saved successfully",
                                },
                              })
                            );
                          }}
                        >
                          {t("Save")}
                        </MDButton>
                      </MDBox>
                      <MDBox p={2}>
                        <MDButton variant="gradient" color="error" size="medium">
                          {t("Cancel")}
                        </MDButton>
                      </MDBox>
                    </Grid> */}
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    );
  }
  return false;
}

export default memo(Item);
