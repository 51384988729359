/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import ServerSideTable from "components/Tables/ServerSideTable";
import TableSearch from "view/components/TableSearch";

import CommonFun from "services/common";
import ERPAPI from "services/erp";

function Customers({ type }) {
  const { t } = useTranslation("common");

  const [searchData, setSearchData] = useState(false);
  const [submittedFilter, setSubmittedFilter] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("customerNumber");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const searchableColumns = [
    { name: "CustomerNumber", type: "text", multiple: true, select: true },
    { name: "Name1", type: "text", multiple: true, select: true },
    { name: "Name2", type: "text", multiple: true, select: true },
    { name: "Email", type: "text", multiple: true, select: true },
    { name: "ContactEmail", type: "text", multiple: true },
    { name: "ContactPhone", type: "text", multiple: true },
    { name: "Matchcode", type: "text", multiple: true },
    { name: "Street", type: "text", multiple: true },
    { name: "Postcode", type: "text", multiple: true },
    { name: "City", type: "text", multiple: true },
    { name: "Country", type: "text", multiple: true },
    { name: "CustomerGroup", type: "text", multiple: true },
    { name: "Active", type: "checkbox", multiple: false },
    { name: "Note", type: "text", multiple: true },
    { name: "Phone", type: "text", multiple: true },
    { name: "Mobile", type: "text", multiple: true },
    { name: "CountryCode", type: "text", multiple: false },
    { name: "VATId", type: "text", multiple: false },
    { name: "Homepage", type: "text", multiple: true },
    { name: "Memo", type: "text", multiple: true },
    { name: "LocalTaxNumber", type: "text", multiple: true },
  ];

  const getCustomers = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.searchCustomer(searchData, page, pageSize, orderBy, order);
    if (
      ERPResponse &&
      ERPResponse.data &&
      ERPResponse.data.items &&
      ERPResponse.data.items.length
    ) {
      setCustomers(ERPResponse.data.items);
      setCount(ERPResponse.data.totalCount);
    } else {
      setCustomers([]);
    }
    setLoading(false);
    setSubmittedFilter(false);
  };

  useEffect(async () => {
    if (submittedFilter) {
      getCustomers();
    }
  }, [submittedFilter]);

  const handleAdvancedSearch = (data) => {
    if (page !== 0) {
      setPage(0);
    }
    setSearchData({ ...data });
    setSubmittedFilter(true);
  };

  const columns = [
    {
      header: t("Customer Number"),
      accessor: "customerNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Match Code"),
      accessor: "matchcode",
      align: "left",
      sort: true,
    },
    {
      header: t("Customer Name"),
      accessor: "shippingAddress.name2",
      align: "left",
      sort: true,
    },
    {
      header: t("Company Name"),
      accessor: "shippingAddress.name1",
      align: "left",
      sort: true,
    },
    {
      header: t("Email"),
      accessor: "email",
      align: "left",
      sort: true,
    },
    {
      header: t("Mobile"),
      accessor: "mobile",
      align: "left",
      sort: true,
    },
    {
      header: t("Phone"),
      accessor: "phone",
      align: "left",
      sort: true,
    },
    {
      header: t("Customer Group"),
      accessor: "customerGroup",
      align: "left",
      sort: true,
    },
    {
      header: t("Zipcode"),
      accessor: "shippingAddress.postcode",
      align: "left",
      sort: true,
    },
    {
      header: t("Country"),
      accessor: "shippingAddress.country",
      align: "left",
      sort: true,
    },
    {
      header: t("City"),
      accessor: "shippingAddress.city",
      align: "left",
      sort: true,
    },
    {
      header: t("Currency"),
      accessor: "currencyIso",
      align: "left",
      sort: true,
    },
    {
      header: t("VAT ID"),
      accessor: "vatId",
      align: "left",
      sort: true,
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    setSubmittedFilter(true);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    setSubmittedFilter(true);
  };

  if (type === "CUSTOMERS") {
    // eslint-disable-next-line no-console
    console.log("render customers");

    return (
      <MDBox p={1} bgColor="light">
        <Progress loading={loading} isFull />
        <Card>
          <Grid container spacing={1}>
            <TableSearch
              columns={searchableColumns}
              onSearch={handleAdvancedSearch}
              page="CUSTOMERS"
            />
            <Grid item xs={12}>
              <MDBox pt={0}>
                <ServerSideTable
                  fixedTable
                  rowsPerPage={pageSize}
                  page={page}
                  columns={columns}
                  data={customers}
                  count={count}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(key) => {
                    const isAsc = orderBy === key && order === "asc";
                    setOrder(isAsc ? "desc" : "asc");
                    setOrderBy(key);
                    setPage(0);
                    setSubmittedFilter(true);
                  }}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  getRowProps={(row) => ({
                    onClick: () => {
                      CommonFun.onClickCustomer(
                        `erp-${row.customerNumber}`,
                        row.shippingAddress && row.shippingAddress.name2
                          ? row.shippingAddress.name2
                          : row.customerNumber
                      );
                    },
                    style: {
                      cursor: "pointer",
                    },
                  })}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    );
  }
  return false;
}

export default memo(Customers);
