import Fetch from "./fetch";

const SignaturesAPI = {
  async getAll() {
    const response = await Fetch.get(`api/signatures`);
    return response?.data;
  },
  async get(signatureId) {
    const response = await Fetch.get(`api/signature/${signatureId}`);
    return response?.data;
  },
  async delete(signatureId) {
    const response = await Fetch.delete(`api/signature/${signatureId}`);
    return response;
  },
  async create(data) {
    const response = await Fetch.post(`api/signature`, data);
    return response;
  },
  async getAllByUser() {
    const response = await Fetch.get(`api/user/signatures`);
    return response?.data;
  },
  async addUserSignatureMap(data) {
    const response = await Fetch.post(`api/usersignature`, data);
    return response;
  },
  async setDefaultSignature(signatureId) {
    const response = await Fetch.post(`api/signature/defaultsignature/${signatureId}`);
    return response;
  },
};
export default SignaturesAPI;
