/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Progress from "components/Progress";

import breakpoints from "assets/theme/base/breakpoints";

import CommonFun from "services/common";
import Items from "./components/items";
import Customer from "./components/customer";
import Information from "./components/information";
import Log from "./components/log";

function Order({ documentIdentifier, type, tab = 0 }) {
  const { t } = useTranslation("common");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(tab);
  const [orderHeaderCustomer, setOrderHeaderCustomer] = useState(null);
  const [orderHeaderOrder, setOrderHeaderOrder] = useState(null);
  const [url, setURL] = useState("");
  const isNew = documentIdentifier.includes("new-");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    if (!isNew) {
      switch (tabValue) {
        case 0:
          setURL(`${window.location.origin}/order/${documentIdentifier}/items`);
          break;
        case 1:
          setURL(`${window.location.origin}/order/${documentIdentifier}/customer`);
          break;
        case 2:
          setURL(`${window.location.origin}/order/${documentIdentifier}/information`);
          break;
        case 3:
          setURL(`${window.location.origin}/order/${documentIdentifier}/log`);
          break;
        default:
          setURL(`${window.location.origin}/order/${documentIdentifier}/items`);
      }
    }
  }, [tabValue]);

  const handelShare = () => {
    const copyText = document.getElementById(`urlInput_${documentIdentifier}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  };

  if (type === "ORDER") {
    // eslint-disable-next-line no-console
    console.log("render order", documentIdentifier);
    return (
      <MDBox p={1} bgColor="light" position="relative">
        {!isNew ? (
          <MDBox
            sx={{
              position: "absolute",
              top: "-40px",
              right: "8px",
            }}
          >
            <input
              type="text"
              value={url}
              id={`urlInput_${documentIdentifier}`}
              style={{ display: "none" }}
              readOnly
            />
            <Tooltip title={`Copy to clipboard: ${url}`}>
              <Fab
                size="small"
                color="secondary"
                aria-label="share"
                onClick={() => {
                  handelShare();
                }}
                sx={{
                  width: 24,
                  height: 24,
                  minHeight: 24,
                }}
              >
                <Icon>share</Icon>
              </Fab>
            </Tooltip>
          </MDBox>
        ) : null}
        <Card>
          <MDBox position="relative">
            <MDBox pt={1} px={2}>
              <Grid container spacing={1} alignItems="center">
                {documentIdentifier ? (
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <MDTypography variant="h5" fontWeight="medium">
                          #{documentIdentifier}
                          <span style={{ paddingLeft: 10, paddingRight: 10 }}>|</span>
                          {orderHeaderOrder && orderHeaderOrder?.documentDate
                            ? moment
                                .utc(orderHeaderOrder.documentDate)
                                .format(CommonFun.getDateFormat())
                            : ""}
                        </MDTypography>
                      </Grid>
                      {orderHeaderOrder ? (
                        <Grid item xs={12} md="auto" sx={{ ml: "auto" }}>
                          <MDTypography variant="h5" fontWeight="medium">
                            {CommonFun.displayCurrency(
                              orderHeaderOrder.nativeTotalValue,
                              orderHeaderOrder.nativeCurrencyIso
                            )}
                            <span style={{ paddingLeft: 10, paddingRight: 10 }}>|</span>
                            {orderHeaderOrder?.paymentCondition}
                          </MDTypography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h6" fontWeight="medium">
                      {orderHeaderCustomer && orderHeaderCustomer.customerNumber ? (
                        <>
                          <span>#{orderHeaderCustomer.customerNumber}</span>
                          <span style={{ paddingLeft: 10, paddingRight: 10 }}>|</span>
                        </>
                      ) : null}
                      {documentIdentifier
                        ? `${orderHeaderCustomer?.name1 || ""} ${orderHeaderCustomer?.name2 || ""}`
                        : "New Order"}
                    </MDTypography>
                    <MDTypography variant="button" color="black" fontWeight="regular">
                      {orderHeaderCustomer?.address || ""}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={8} lg={8} sx={{ ml: "auto" }}>
                  <AppBar position="static">
                    <Tabs
                      color="info"
                      orientation={tabsOrientation}
                      value={tabValue}
                      onChange={handleSetTabValue}
                    >
                      <Tab label={t("Items")} />
                      <Tab label={t("Customer")} />
                      <Tab label={t("Information")} />
                      <Tab label={t("Log")} />
                    </Tabs>
                  </AppBar>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={tabValue === 0 ? null : { display: "none" }}>
                  <Items
                    documentIdentifier={documentIdentifier}
                    nativeCurrencyIso={orderHeaderOrder?.nativeCurrencyIso}
                  />
                </Grid>
                <Grid item xs={12} sx={tabValue === 1 ? null : { display: "none" }}>
                  <Customer
                    documentIdentifier={documentIdentifier}
                    setHeader={setOrderHeaderCustomer}
                  />
                </Grid>
                <Grid item xs={12} sx={tabValue === 2 ? null : { display: "none" }}>
                  <Information
                    documentIdentifier={documentIdentifier}
                    setHeader={setOrderHeaderOrder}
                  />
                </Grid>
                <Grid item xs={12} sx={tabValue === 3 ? null : { display: "none" }}>
                  <Log documentIdentifier={documentIdentifier} />
                </Grid>
                {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <MDBox p={2}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="medium"
                      onClick={() => {
                        window.dispatchEvent(
                          new CustomEvent("snackbar", {
                            detail: {
                              type: "OK",
                              title: "Save",
                              content: "Saved successfully",
                            },
                          })
                        );
                      }}
                    >
                      {t("save")}
                    </MDButton>
                  </MDBox>
                  <MDBox p={2}>
                    <MDButton variant="gradient" color="error" size="medium">
                      {t("Cancel")}
                    </MDButton>
                  </MDBox>
                </Grid> */}
              </Grid>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    );
  }
  return false;
}

export default memo(Order);
