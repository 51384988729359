import Fetch from "../fetch";

const dsmAPI = {
  async getDSM(documentIdentifier, dmsId) {
    const response = await Fetch.get(`api/erp/dms/erp/${documentIdentifier}/dms/${dmsId}
    `);
    return response?.data;
  },
};
export default dsmAPI;
