import { forwardRef } from "react";

import PropTypes from "prop-types";

import MDAvatarRoot from "components/MDAvatar/MDAvatarRoot";
import MDTypography from "components/MDTypography";

const MDAvatar = forwardRef(({ bgColor, size, shadow, name, ...rest }, ref) => (
  <MDAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest}>
    {name && (
      <MDTypography fontWeight="bold" color={bgColor === "light" ? "black" : "white"}>
        {name && name.toUpperCase().substring(0, 1)}
      </MDTypography>
    )}
    {rest.children && (
      <MDTypography
        style={{
          fontSize: 24,
          lineHeight: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        color={bgColor === "light" ? "black" : "white"}
      >
        {rest.children}
      </MDTypography>
    )}
  </MDAvatarRoot>
));

MDAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
  src: null,
  name: null,
};

MDAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
  src: PropTypes.string,
  name: PropTypes.string,
};

export default MDAvatar;
