/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { useState, memo } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function FilterInput({ col, disabled, labels, addFilter }) {
  const [inputValue, setInputValue] = useState(null);

  const onHandleAdd = () => {
    if (!disabled && (col.type === "checkbox" || inputValue)) {
      if (col.type === "checkbox") {
        addFilter(col.name, inputValue || 0);
      } else {
        addFilter(col.name, inputValue);
      }
      setInputValue(null);
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <MDBox display="flex" alignItems="center">
        {col.type === "checkbox" ? (
          <MDBox display="flex" alignItems="center" sx={{ flex: 1 }}>
            <MDBox width="80%" mr="auto" ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="black">
                {labels[col.name]}
              </MDTypography>
            </MDBox>
            <MDBox mt={0.5}>
              <Checkbox
                disabled={disabled}
                color="success"
                checked={!!inputValue}
                onChange={(event) => {
                  setInputValue(event.target.checked ? -1 : 0);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </MDBox>
          </MDBox>
        ) : col.type === "date" ? (
          <MDBox sx={{ flex: 1 }}>
            <DatePicker
              label={labels[col.name]}
              value={inputValue ? moment(inputValue) : null}
              disableFuture
              onChange={(value) => {
                setInputValue(value ? value.startOf("day").format("YYYY-MM-DD HH:mm:ss") : null);
              }}
              slotProps={{
                field: {
                  fullWidth: true,
                  clearable: true,
                  onClear: () => {
                    setInputValue(null);
                  },
                  onKeyPress: (e) => {
                    if (e.key === "Enter") {
                      onHandleAdd();
                    }
                  },
                },
              }}
            />
          </MDBox>
        ) : (
          <MDBox sx={{ flex: 1 }}>
            <MDInput
              type={col.type}
              label={labels[col.name]}
              name={col.name}
              fullWidth
              disabled={disabled}
              value={inputValue || ""}
              onChange={(event) => {
                setInputValue(event.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onHandleAdd();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setInputValue(null);
                      }}
                      sx={{
                        marginRight: "-12px",
                      }}
                    >
                      {inputValue ? <Icon>close</Icon> : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
        )}

        <IconButton
          aria-label="add"
          variant="outlined"
          disabled={disabled || (col.type !== "checkbox" && !inputValue)}
          onClick={() => {
            onHandleAdd();
          }}
          sx={{
            marginLeft: 1,
            borderRadius: "0.375rem",
            border: "1px solid #d2d6da",
          }}
        >
          <Icon>add</Icon>
        </IconButton>
      </MDBox>
    </Grid>
  );
}

export default memo(FilterInput);
