/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Ticket from "view/components/Ticket";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";

import {
  TICKET_STATUS_CLOSE_ID,
  TICKET_INTERACTION_TYPE_EMAIL,
  TICKET_INTERACTION_TYPE_PHONE,
  TICKET_INTERACTION_TYPE_CHAT,
  TICKET_STATUS_OPEN_ID,
} from "environment";
import TicketsAPI from "services/tickets";
import CommonFun from "services/common";

function Tickets({
  customerID,
  customer,
  statusCode = null,
  openTicket,
  setOpenTicket,
  setTicketsCount,
  setParentLoading,
}) {
  const { t } = useTranslation("common");
  const [tickets, setTickets] = useState([]);

  const getTickets = async () => {
    if (
      customer &&
      (customer.customerNumber ||
        (customer.emailList && customer.emailList.length) ||
        (customer.phoneList && customer.phoneList.length))
    ) {
      const data = {
        status_code: statusCode,
      };
      if (customer.customerNumber) {
        data.erp_customer_id = customer.customerNumber;
      }
      if (customer.emailList.length) {
        data.email_list = customer.emailList;
      }
      if (customer.phoneList.length) {
        data.phone = customer.phoneList;
      }
      const response = await TicketsAPI.getTicketsByCustomerFilter(1000, 0, data);
      if (response && response.data && response.data.length) {
        const results = response.data.filter((e) => !e.assigned_to);
        const assignedTickets = response.data.filter((e) => e.assigned_to);
        assignedTickets.forEach((e) => {
          results.push(e);
        });
        setTickets([...results]);
        setTicketsCount(results.length);
        if (openTicket && !results.filter((x) => x.id === openTicket).length) {
          setParentLoading(false);
        }
      } else {
        setTickets([]);
        setTicketsCount(0);
        setParentLoading(false);
      }
    }
    if (!openTicket) {
      setParentLoading(false);
    }
  };

  useEffect(() => {
    setParentLoading(true);
    getTickets();
    window.addEventListener("tickets_status_change", getTickets);
    return () => window.removeEventListener("tickets_status_change", getTickets);
  }, [customer]);

  useEffect(() => {
    const onTabChange = (event) => {
      if (event.detail.type === "CUSTOMER" && event.detail.tab.id === customerID) {
        const isDirty = localStorage.getItem(`CK_EDITOR_DIRTY_${customerID}`);
        if (statusCode === TICKET_STATUS_OPEN_ID && isDirty && isDirty !== "") {
          window.dispatchEvent(
            new CustomEvent("saveDraft", {
              detail: {
                id: customerID,
              },
            })
          );
        } else {
          setParentLoading(true);
          getTickets();
        }
      }
    };
    window.addEventListener("tabChange", onTabChange);
    return () => window.removeEventListener("tabChange", onTabChange);
  }, [getTickets, customerID]);

  useEffect(() => {
    const onUpdateTickets = (event) => {
      if (event.detail.id === customerID) {
        setParentLoading(true);
        getTickets();
      }
    };
    window.addEventListener("updatesTickets", onUpdateTickets);
    return () => window.removeEventListener("updatesTickets", onUpdateTickets);
  }, [getTickets, customerID]);

  const rows = tickets;

  function Row({ row }) {
    const [open, setOpen] = useState(false);
    const [isDirty, setIsDirty] = useState(null);
    const [popupOpened, setPopupOpened] = useState(false);
    const Ref = useRef(null);
    const openNewWindow = (ticketID) => {
      const popupWindow = window.open(
        `${window.location.origin}/ticket/${ticketID}`,
        "_blank",
        "popup"
      );
      setPopupOpened(true);
      localStorage.setItem(`A24_POPUP_${row.id}`, true);
      popupWindow.onload = () => {
        popupWindow.onbeforeunload = () => {
          setPopupOpened(false);
          localStorage.removeItem(`A24_POPUP_${row.id}`);
        };
        popupWindow.addEventListener("tickets_status_change", () => {
          window.dispatchEvent(new Event("tickets_status_change"));
        });
      };
    };

    const handleOpen = (ticketId) => {
      const isDirtyLocal = parseInt(localStorage.getItem(`CK_EDITOR_DIRTY_${customerID}`), 10);
      if (isDirtyLocal === openTicket) {
        setOpen(ticketId);
      } else {
        if (openTicket !== ticketId) {
          setOpenTicket(ticketId);
        } else {
          setOpenTicket(null);
        }
        localStorage.removeItem(`CK_EDITOR_DIRTY_${customerID}`);
      }
    };

    const discardChanges = () => {
      if (openTicket !== open) {
        setOpenTicket(open);
      } else {
        setOpenTicket(null);
      }
      localStorage.removeItem(`CK_EDITOR_DIRTY_${customerID}`);
      setOpen(null);
    };

    useEffect(() => {
      if (localStorage.getItem(`A24_POPUP_${row.id}`)) {
        if (Ref.current) clearInterval(Ref.current);
        Ref.current = setInterval(() => {
          if (localStorage.getItem(`A24_POPUP_${row.id}`)) {
            setPopupOpened(true);
          } else {
            setPopupOpened(false);
            clearInterval(Ref.current);
          }
        }, 1000);
      }
    }, []);

    return (
      <>
        <TableRow
          sx={{
            "& > *": { borderBottom: "unset" },
            cursor: "pointer",
            backgroundColor: openTicket === row.id ? "#c3c3c3" : "",
          }}
          onClick={() => handleOpen(row.id)}
        >
          <TableCell>
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDTypography style={{ lineHeight: "20px" }}>
                <Icon>{openTicket === row.id ? "expand_less" : "expand_more"}</Icon>
                {isDirty === row.id ? <Icon color="warning">warning</Icon> : null}
              </MDTypography>
            </MDBox>
          </TableCell>
          <TableCell>
            <MDBox sx={{ position: "relative" }}>
              {!popupOpened ? (
                <Tooltip title={t("In new window")}>
                  <MDTypography
                    color="black"
                    onClick={(event) => {
                      event.stopPropagation();
                      openNewWindow(row.id);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Icon>open_in_new</Icon>
                  </MDTypography>
                </Tooltip>
              ) : null}
            </MDBox>
          </TableCell>
          <TableCell>
            <MDTypography variant="caption" color="black" fontWeight="medium">
              #{row.id}
            </MDTypography>
          </TableCell>
          <TableCell>
            <MDBox>
              {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
                <MDBox display="flex" alignItems="center" lineHeight={1}>
                  {row.customer_name ? (
                    <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
                  ) : (
                    <MDAvatar size="sm" bgColor="info">
                      <Icon>person</Icon>
                    </MDAvatar>
                  )}
                  <MDBox ml={2} lineHeight={1}>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                      {row.customer_name
                        ? CommonFun.truncateString(row.customer_name)
                        : row.customer_email}
                    </MDTypography>
                    {row.customer_name ? (
                      <MDTypography variant="caption" fontWeight="regular">
                        {row.customer_email}
                      </MDTypography>
                    ) : null}
                  </MDBox>
                </MDBox>
              ) : null}
              {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
                <MDBox display="flex" alignItems="center" lineHeight={1}>
                  {row.customer_name ? (
                    <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
                  ) : (
                    <MDAvatar size="sm" bgColor="info">
                      <Icon>person</Icon>
                    </MDAvatar>
                  )}
                  <MDBox ml={2} lineHeight={1}>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                      {row.customer_name
                        ? CommonFun.truncateString(row.customer_name)
                        : CommonFun.displayPhone(row.from_phone)}
                    </MDTypography>
                    <MDTypography variant="caption">
                      {row.customer_name ? CommonFun.displayPhone(row.from_phone) : ""}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : null}
              {row.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
                <MDBox display="flex" alignItems="center" lineHeight={1}>
                  {row.customer_name ? (
                    <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
                  ) : (
                    <MDAvatar size="sm" bgColor="info">
                      <Icon>person</Icon>
                    </MDAvatar>
                  )}
                  <MDBox ml={2} lineHeight={1}>
                    <MDTypography display="block" variant="button" fontWeight="medium">
                      {row.customer_name
                        ? CommonFun.truncateString(row.customer_name)
                        : "New Customer"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : null}
            </MDBox>
          </TableCell>
          <TableCell>
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
                <MDTypography color="success" style={{ lineHeight: "20px" }}>
                  <Icon>mail</Icon>
                </MDTypography>
              ) : null}
              {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE && !row.missed_call_reason ? (
                <MDTypography color="success" style={{ lineHeight: "20px" }}>
                  <Icon>phone_in_talk</Icon>
                </MDTypography>
              ) : null}
              {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE && row.missed_call_reason ? (
                <MDTypography color="error" style={{ lineHeight: "20px" }}>
                  <Icon>phone_missed</Icon>
                </MDTypography>
              ) : null}
              {row.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
                <MDTypography color="primary" style={{ lineHeight: "20px" }}>
                  <Icon>chat</Icon>
                </MDTypography>
              ) : null}
              {row.attachment ? (
                <MDTypography color="black" style={{ lineHeight: "20px" }}>
                  <Icon>attach_file</Icon>
                </MDTypography>
              ) : null}
            </MDBox>
          </TableCell>
          <TableCell>
            <MDBox lineHeight={0}>
              <MDBox lineHeight={1} textAlign="left">
                {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
                  <MDTypography
                    display="block"
                    variant="caption"
                    color="black"
                    fontWeight="medium"
                    mb={0.5}
                  >
                    {CommonFun.truncateString(row.subject, 70)}
                  </MDTypography>
                ) : null}
                {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
                  <MDBox
                    display="flex"
                    alignItems="center"
                    borderRadius="sm"
                    lineHeight={1}
                    mb={0.5}
                    sx={{ height: "15px" }}
                  >
                    <MDBox lineHeight={1} display="flex" alignItems="center">
                      <MDTypography
                        mr={1}
                        sx={{ fontSize: "16px" }}
                        variant="caption"
                        color="black"
                        fontWeight="medium"
                      >
                        <Icon>phone_forwarded</Icon>
                      </MDTypography>
                      <MDTypography variant="caption" color="black" fontWeight="medium">
                        {row.created_by ? row.created_by : null}{" "}
                        {row.to_phone ? `(${CommonFun.displayPhone(row.to_phone)})` : null}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ) : null}
                {row.assigned_to ? (
                  <>
                    {row.status_code !== TICKET_STATUS_CLOSE_ID ? (
                      <MDBadge
                        badgeContent={t("Assigned")}
                        color="success"
                        variant="gradient"
                        size="sm"
                        sx={{ marginLeft: -1, marginRight: 1 }}
                      />
                    ) : null}
                    <MDBadge
                      className="assigned_to_name_badge"
                      badgeContent={row.assigned_to_name}
                      color="light"
                      variant="gradient"
                      size="sm"
                      sx={{ marginLeft: -1 }}
                    />
                  </>
                ) : (
                  <MDBadge
                    badgeContent={t("Unassigned")}
                    color="primary"
                    variant="gradient"
                    size="sm"
                    sx={{ marginLeft: -1 }}
                  />
                )}
                {row.missed_call_reason ? (
                  <MDBadge
                    className="missed_call_reason_badge"
                    badgeContent={row.missed_call_reason.replace(/_/g, " ")}
                    color="light"
                    variant="contained"
                    border
                    size="sm"
                  />
                ) : null}
              </MDBox>
            </MDBox>
          </TableCell>
          <TableCell>
            {row.updated_at ? (
              <MDTypography variant="caption" color="black" fontWeight="medium">
                {moment.utc(row.updated_at).local().format(CommonFun.getDateTimeFormat())}
              </MDTypography>
            ) : null}
          </TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: "#f7f7f7" }}>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Ticket
              customerID={customerID}
              ticket={row}
              openTicketID={openTicket}
              popupOpened={popupOpened}
              setPopupOpened={setPopupOpened}
              setIsDirty={setIsDirty}
              setOpenTicket={setOpenTicket}
              setParentLoading={setParentLoading}
            />
          </TableCell>
        </TableRow>
        <Dialog
          open={Boolean(open)}
          onClose={() => {
            setOpen(null);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t("Discard Changes")}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
              {t(
                "You have made changes that haven't been saved yet. Are you sure to discard changes?"
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => {
                setOpen(null);
              }}
            >
              {t("Cancel")}
            </MDButton>
            <MDButton
              onClick={() => {
                discardChanges();
              }}
              autoFocus
            >
              {t("Yes")}
            </MDButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <MDBox>
      <TableContainer sx={{ boxShadow: "none" }} id="Tickets_Collapse_Wrap">
        <Table aria-label="collapsible table">
          <TableHead sx={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>
                <MDBox
                  sx={({ typography: { size, fontWeightBold } }) => ({
                    fontSize: size.xs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                  })}
                >
                  {t("Number")}
                </MDBox>
              </TableCell>
              <TableCell>
                <MDBox
                  sx={({ typography: { size, fontWeightBold } }) => ({
                    fontSize: size.xs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                  })}
                >
                  {t("Customer")}
                </MDBox>
              </TableCell>
              <TableCell />
              <TableCell style={{ width: "70%" }}>
                <MDBox
                  sx={({ typography: { size, fontWeightBold } }) => ({
                    fontSize: size.xs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                  })}
                >
                  {t("Conversation")}
                </MDBox>
              </TableCell>
              <TableCell>
                <MDBox
                  sx={({ typography: { size, fontWeightBold } }) => ({
                    fontSize: size.xs,
                    fontWeight: fontWeightBold,
                    textTransform: "uppercase",
                  })}
                >
                  {t("Updated At")}
                </MDBox>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <Row key={index} row={row} openTicketID={openTicket} />
            ))}
            {!rows.length ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <MDTypography variant="caption" color="black" fontWeight="medium">
                    {t("No data to show")}
                  </MDTypography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );
}

export default memo(Tickets);
