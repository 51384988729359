/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import { navbar, navbarContainer, navbarRow } from "components/Navbars/DashboardNavbar/styles";

import useLocalStorage from "hooks/useLocalStorage";
import { useMaterialUIController, setTransparentNavbar } from "context";
import Grid from "@mui/material/Grid";

import AuthAPI from "services/auth";

import { BRAND_LOGO, REGION_US } from "environment";

function DashboardNavbar({ absolute, light, isMini }) {
  const i18nLocal = useTranslation();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [OpenStatusMenu, setOpenStatusMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openMarketMenu, setOpenMarketMenu] = useState(false);
  const [market, setMarket] = useLocalStorage("A24_LOCATION", []);
  const [region] = useLocalStorage("A24_REGION", 0);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const markets = [
    {
      code: "at",
      text: "Austria",
    },
    {
      code: "be",
      text: "Belgium",
    },
    {
      code: "fr",
      text: "France",
    },
    {
      code: "de",
      text: "Germany",
    },
    {
      code: "it",
      text: "Italy",
    },
    // {
    //   code: "nl",
    //   text: "Netherlands",
    // },
    {
      code: "pl",
      text: "Poland",
    },
    {
      code: "se",
      text: "Sweden",
    },
    {
      code: "es",
      text: "Spain",
    },
    {
      code: "uk",
      text: "United Kingdom",
    },
    // {
    //   code: "us",
    //   text: "United States",
    // },
    {
      code: "int",
      text: "International",
    },
  ];

  const handleOpenProfileMenu = (event) => setOpenProfileMenu(event.currentTarget);
  const handleCloseProfileMenu = () => setOpenProfileMenu(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenStatusMenu = (event) => setOpenStatusMenu(event.currentTarget);
  const handleCloseStatusMenu = () => setOpenStatusMenu(false);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenMarketMenu = (event) => setOpenMarketMenu(event.currentTarget);
  const handleCloseMarketMenu = () => setOpenMarketMenu(false);
  const handleChangeLanguage = (lan) => {
    i18n.changeLanguage(lan);
    setOpenMenu(false);
  };

  /**
   * This function is used to change the status of the user
   */
  const handleStatusChange = (status) => {
    setOpenStatusMenu(false);
    const statusElement = document.getElementById("status");
    const statusColors = {
      available: "green",
      busy: "#efcd00e8",
      away: "red",
    };
    if (status) {
      const color = statusColors[status] ? statusColors[status] : "green";
      statusElement.style.backgroundColor = color;
      statusElement.style.borderColor = color;
    }
  };

  const isSelected = (item) => market.findIndex((i) => i.text === item.text);

  const handleChangeMarket = (item) => {
    if (item) {
      const index = isSelected(item);
      if (index === -1) {
        market.push(item);
      } else {
        market.splice(index, 1);
      }
      setMarket([...market]);
    } else {
      setMarket([]);
    }
    window.dispatchEvent(new Event("location_change"));
  };

  const handleLogout = async () => {
    setOpenProfileMenu(false);
    await AuthAPI.logout();
    navigate("/");
  };

  const handleClickSettings = async () => {
    setOpenProfileMenu(false);
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }
    const newTab = {
      id: "settings",
      type: "SETTINGS",
      label: "Settings",
    };
    const index = tabList.findIndex((e) => e.id === "settings" && !e.deleted);
    if (index === -1) {
      tabList.push(newTab);
      await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
    }
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    window.dispatchEvent(new Event("storage"));
  };

  const handleClickTrash = async () => {
    setOpenProfileMenu(false);
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }
    const newTab = {
      id: "trash",
      type: "TRASH",
      label: "Trash",
    };
    const index = tabList.findIndex((e) => e.id === "trash" && !e.deleted);
    if (index === -1) {
      tabList.push(newTab);
      await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
    }
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox display="flex">
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox component={NavLink} to="/home/dashboard" display="flex" alignItems="center">
              <MDBox
                component="img"
                src={BRAND_LOGO}
                alt="Brand"
                width="100%"
                sx={{ height: 40 }}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox
              color={light ? "white" : "inherit"}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {region === REGION_US ? (
                <MDTypography
                  variant="button"
                  color="black"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginLeft: 1,
                    marginRight: 2,
                  }}
                >
                  {market.length ? (
                    <img
                      src={`/countries/${market[0].code}.svg`}
                      alt={t(market[0].text)}
                      style={{ margin: "0px 10px 0 0" }}
                    />
                  ) : null}
                  {market.length ? t(market[0].text) : t("All")}
                </MDTypography>
              ) : (
                <MDTypography
                  variant="button"
                  color="black"
                  onClick={handleOpenMarketMenu}
                  sx={{
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginLeft: 1,
                    marginRight: 2,
                  }}
                >
                  {market.length ? (
                    <img
                      src={`/countries/${market[0].code}.svg`}
                      alt={t(market[0].text)}
                      style={{ margin: "0px 10px 0 0" }}
                    />
                  ) : null}
                  {market.length ? t(market[0].text) : t("All")}
                  {market.length > 1 ? (
                    <MDBadge
                      badgeContent={`+${market.length - 1}`}
                      size="md"
                      circular
                      sx={{ marginLeft: 1 }}
                    />
                  ) : null}
                  <Icon xs={{ marginLeft: 0.5 }}>expand_more</Icon>
                </MDTypography>
              )}
              <Menu
                anchorEl={openMarketMenu}
                anchorReference={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(openMarketMenu)}
                onClose={handleCloseMarketMenu}
                sx={{ mt: 2 }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-end",
                    height: "30px",
                    fontSize: "20px",
                    minWidth: "210px",
                  }}
                >
                  <Icon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleCloseMarketMenu}
                  >
                    close
                  </Icon>
                </MDBox>
                <MenuItem
                  onClick={() => {
                    handleChangeMarket(null);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  selected={!market.length}
                >
                  <span
                    style={{
                      width: 20,
                      height: 12,
                      display: " inline-block",
                      margin: "0px 10px 0 0",
                    }}
                  />
                  {t("All")}
                  {!market.length ? (
                    <>
                      <MDBox sx={{ flex: 1, minWidth: 10 }} />
                      <Icon color="success">check_circle</Icon>
                    </>
                  ) : null}
                </MenuItem>
                {markets.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleChangeMarket(item);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    selected={isSelected(item) !== -1}
                  >
                    <img
                      src={`/countries/${item.code}.svg`}
                      alt={t(item.text)}
                      style={{ margin: "0px 10px 0 0" }}
                    />
                    {t(item.text)}
                    {isSelected(item) !== -1 ? (
                      <>
                        <MDBox sx={{ flex: 1, minWidth: 10 }} />
                        <Icon color="success">check_circle</Icon>
                      </>
                    ) : null}
                  </MenuItem>
                ))}
              </Menu>
              <MDTypography
                variant="button"
                color="black"
                onClick={handleOpenStatusMenu}
                sx={{
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: 1,
                }}
              >
                <Grid
                  item
                  id="status"
                  sx={{
                    height: "15px",
                    width: "15px",
                    border: "1px solid",
                    borderRadius: "50%",
                    backgroundColor: "green",
                    borderColor: "green",
                  }}
                >
                  &nbsp;
                </Grid>
                <Icon xs={{ marginLeft: 0.5 }}>expand_more</Icon>
              </MDTypography>
              <Menu
                anchorEl={OpenStatusMenu}
                anchorReference={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(OpenStatusMenu)}
                onClose={handleCloseStatusMenu}
                sx={{ mt: 2 }}
              >
                <MenuItem
                  onClick={() => {
                    handleStatusChange("available");
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: "1px solid",
                        borderRadius: "50%",
                        backgroundColor: "green",
                        borderColor: "green",
                      }}
                    >
                      &nbsp;
                    </Grid>
                    <Grid item>
                      <span style={{ marginLeft: "8px" }}>{t("Available")}</span>
                    </Grid>
                  </Grid>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleStatusChange("away");
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: "1px solid",
                        borderRadius: "50%",
                        backgroundColor: "red",
                        borderColor: "red",
                      }}
                    >
                      &nbsp;
                    </Grid>
                    <Grid item>
                      <span style={{ marginLeft: "8px" }}>{t("Away")}</span>
                    </Grid>
                  </Grid>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleStatusChange("busy");
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sx={{
                        height: "12px",
                        width: "12px",
                        border: "1px solid",
                        borderRadius: "50%",
                        backgroundColor: "#efcd00e8",
                        borderColor: "#efcd00e8",
                      }}
                    >
                      &nbsp;
                    </Grid>
                    <Grid item>
                      <span style={{ marginLeft: "8px" }}>{t("Busy")}</span>
                    </Grid>
                  </Grid>
                </MenuItem>
              </Menu>
              <MDTypography
                variant="button"
                color="black"
                onClick={handleOpenMenu}
                sx={{
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: 1,
                }}
              >
                <Icon xs={{ marginRight: 0.5 }}>translate</Icon> |{" "}
                {i18nLocal.i18n.language.substring(0, 2)}{" "}
                <Icon xs={{ marginLeft: 0.5 }}>expand_more</Icon>
              </MDTypography>
              <Menu
                anchorEl={openMenu}
                anchorReference={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                sx={{ mt: 2 }}
              >
                <MenuItem
                  onClick={() => {
                    handleChangeLanguage("en");
                  }}
                >
                  En
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleChangeLanguage("de");
                  }}
                >
                  De
                </MenuItem>
              </Menu>
              <MDTypography
                variant="button"
                color="black"
                onClick={handleOpenProfileMenu}
                sx={{
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: 1,
                }}
              >
                <Icon xs={{ marginRight: 0.5 }} sx={{ fontSize: "24px !important" }}>
                  account_circle
                </Icon>
                <Icon xs={{ marginLeft: 0.5 }}>expand_more</Icon>
              </MDTypography>
              <Menu
                anchorEl={openProfileMenu}
                anchorReference={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(openProfileMenu)}
                onClose={handleCloseProfileMenu}
                sx={{ mt: 2 }}
              >
                <MenuItem onClick={handleClickSettings}>{t("Settings")}</MenuItem>
                <MenuItem onClick={handleClickTrash}>{t("Trash")}</MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  {t("Logout")}
                </MenuItem>
              </Menu>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
