/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
import { useState, useEffect, memo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDStatus from "components/MDStatus";
import Progress from "components/Progress";
import ServerSideTable from "components/Tables/ServerSideTable";
import TableSearch from "view/components/TableSearch";
import { useMaterialUIController, setPreviewDocument } from "context";

import CommonFun from "services/common";
import ERPAPI from "services/erp";

function Orders({ type }) {
  const { t } = useTranslation("common");

  const [searchData, setSearchData] = useState(false);
  const [documentsMenu, setDocumentsMenu] = useState(null);
  const [submittedFilter, setSubmittedFilter] = useState(false);
  const [, dispatch] = useMaterialUIController();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("documentId");
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const searchableColumns = [
    { name: "ProcessId", type: "number", multiple: true, select: true },
    { name: "DocumentId", type: "number", multiple: true, select: true },
    { name: "DocumentYear", type: "number", multiple: true, select: true },
    { name: "DocumentNumber", type: "number", multiple: true, select: true },
    { name: "DocumentTypeId", type: "text", multiple: true },
    { name: "DocumentType", type: "text", multiple: true },
    { name: "NativeCurrencyIso", type: "text", multiple: true },
    { name: "CustomerNumber", type: "text", multiple: true },
    { name: "CustomerName", type: "text", multiple: true },
    { name: "NativeNetFrom", type: "number", multiple: false },
    { name: "NativeNetTo", type: "number", multiple: false },
    { name: "NativeNetEqualTo", type: "number", multiple: true },
    { name: "NativeTotalFrom", type: "number", multiple: false },
    { name: "NativeTotalTo", type: "number", multiple: false },
    { name: "NativeTotalEqualTo", type: "number", multiple: true },
    { name: "ConvertedNetFrom", type: "number", multiple: false },
    { name: "ConvertedNetTo", type: "number", multiple: false },
    { name: "ConvertedNetEqualTo", type: "number", multiple: true },
    { name: "ConvertedTotalFrom", type: "number", multiple: false },
    { name: "ConvertedTotalTo", type: "number", multiple: false },
    { name: "ConvertedTotalEqualTo", type: "number", multiple: true },
    { name: "DocumentDateFrom", type: "date", multiple: false },
    { name: "DocumentDateTo", type: "date", multiple: false },
    { name: "DocumentDateEqualTo", type: "date", multiple: true },
    { name: "PaymentCondition", type: "text", multiple: true },
    { name: "CustomerEmail", type: "text", multiple: true },
    { name: "DocumentYearDocumentNumber", type: "text", multiple: true },
  ];

  const getOrders = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.searchOrders(searchData, page, pageSize, orderBy, order);
    if (
      ERPResponse &&
      ERPResponse.data &&
      ERPResponse.data.items &&
      ERPResponse.data.items.length
    ) {
      setOrders(ERPResponse.data.items);
      setCount(ERPResponse.data.totalCount);
    } else {
      setOrders([]);
    }
    setLoading(false);
    setSubmittedFilter(false);
  };

  useEffect(async () => {
    if (submittedFilter) {
      getOrders();
    }
  }, [submittedFilter]);

  const openDocumentsMenu = (event, row) => {
    setDocumentsMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      row,
    });
  };

  const closeDocumentsMenu = () => setDocumentsMenu(null);

  const handleAdvancedSearch = (data) => {
    if (page !== 0) {
      setPage(0);
    }
    setSearchData({ ...data });
    setSubmittedFilter(true);
  };

  const columns = [
    {
      header: t("Order Number"),
      accessor: "documentIdentifier",
      align: "left",
      sort: true,
    },
    {
      header: t("Order Date"),
      accessor: "documentDate",
      align: "left",
      sort: true,
      dateFormat: CommonFun.getDateFormat(),
    },
    {
      header: t("Customer"),
      accessor: "customerNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Line Items"),
      accessor: "numberOfLineItems",
      align: "right",
      sort: false,
    },
    {
      header: t("Net Total"),
      accessor: "nativeNetValue",
      align: "right",
      sort: true,
      currency: "nativeCurrencyIso",
    },
    {
      header: t("Total"),
      accessor: "nativeTotalValue",
      align: "right",
      sort: true,
      currency: "nativeCurrencyIso",
    },
    {
      header: t("Payment Condition"),
      accessor: "documentPaymentCondition.paymentCondition",
      align: "left",
      sort: true,
    },
    {
      header: t("Delivery Condition"),
      accessor: "deliveryCondition",
      align: "left",
      sort: true,
    },
    {
      header: t("Status"),
      accessor: "status",
      align: "left",
      sort: false,
      render: (row) => (
        <MDStatus
          value={{
            documentIdentifier: row.documentIdentifier,
            payment: row.paidStatus,
            packing: row.fulfillmentStatus,
            invoicing: row.invoicingStatus,
            shipping: row.shipmentStatus,
            shipments: row.shipments,
          }}
        />
      ),
      getCellProps: () => ({
        style: {
          cursor: "default",
        },
      }),
    },
    {
      header: t("Documents"),
      accessor: "documents",
      align: "center",
      sort: false,
      render: (row) => (
        <MDBox sx={{ position: "relative" }}>
          <IconButton
            color="black"
            size="small"
            aria-describedby={`menu_${row.documentId}`}
            onClick={(event) => {
              openDocumentsMenu(event, row);
            }}
            disabled={!row.orderDocuments || !row.orderDocuments.length}
          >
            <Icon>picture_as_pdf</Icon>
          </IconButton>
          <Menu
            id={`menu_documents_${row.documentId}`}
            anchorEl={documentsMenu !== null ? documentsMenu.anchorEl : undefined}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            open={!!(documentsMenu && row.documentId === documentsMenu.row.documentId)}
            onClose={closeDocumentsMenu}
          >
            {row.orderDocuments &&
              row.orderDocuments.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setPreviewDocument(dispatch, {
                      isImage: false,
                      documentIdentifier: item.documentIdentifier,
                      name: item.documentType,
                      dmsDocumentId: item.dmsDocumentId,
                    });
                  }}
                  sx={{
                    padding: 1,
                    marginBottom: 0.5,
                    border:
                      item.documentIdentifier && item.dmsDocumentId
                        ? "1px solid #1A73E8"
                        : "1px solid #000000",
                  }}
                  disabled={!item.documentIdentifier || !item.dmsDocumentId}
                >
                  <MDBox sx={{ display: "flex", width: "100%" }}>
                    <MDBox
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <MDTypography
                        variant="button"
                        color={item.documentIdentifier && item.dmsDocumentId ? "info" : "black"}
                      >
                        {item.documentType}
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        color={item.documentIdentifier && item.dmsDocumentId ? "info" : "black"}
                      >
                        {item.documentIdentifier}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        paddingLeft: 1,
                        paddingRight: 1,
                      }}
                    >
                      <MDTypography variant="caption" color="black">
                        {moment.utc(item.documentDate).format(CommonFun.getDateFormat())}
                      </MDTypography>
                      <MDTypography variant="caption" color="black">
                        {CommonFun.displayCurrency(item.nativeTotalValue, item.nativeCurrencyIso)}
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ display: "flex", alignItems: "center", padding: 1 }}>
                      <MDTypography color="black">
                        <Icon>picture_as_pdf</Icon>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MenuItem>
              ))}
          </Menu>
        </MDBox>
      ),
      getCellProps: () => ({
        style: {
          cursor: "default",
        },
      }),
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    setSubmittedFilter(true);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    setSubmittedFilter(true);
  };

  if (type === "ORDERS") {
    // eslint-disable-next-line no-console
    console.log("render orders");

    return (
      <MDBox p={1} bgColor="light">
        <Progress loading={loading} isFull />
        <Card>
          <Grid container spacing={1}>
            <TableSearch
              columns={searchableColumns}
              onSearch={handleAdvancedSearch}
              page="ORDERS"
            />
            <Grid item xs={12}>
              <MDBox pt={0}>
                <ServerSideTable
                  fixedTable
                  rowsPerPage={pageSize}
                  page={page}
                  columns={columns}
                  data={orders}
                  count={count}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(key) => {
                    const isAsc = orderBy === key && order === "asc";
                    setOrder(isAsc ? "desc" : "asc");
                    setOrderBy(key);
                    setPage(0);
                    setSubmittedFilter(true);
                  }}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  getRowProps={(row) => ({
                    onClick: () => {
                      CommonFun.onClickOrder(row.documentIdentifier);
                    },
                    style: {
                      cursor: "pointer",
                    },
                  })}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    );
  }
  return false;
}

export default memo(Orders);
