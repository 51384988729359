/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { InputText } from "components/MDInputs";
import StatisticsTable from "components/Tables/StatisticsTable";
import Progress from "components/Progress";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Information({ documentIdentifier, setHeader }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});

  const getOrder = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getOrder(documentIdentifier);
    if (ERPResponse && ERPResponse.data) {
      setCurrentOrder({ ...ERPResponse.data });
      setHeader({
        documentDate: ERPResponse.data.documentDate,
        nativeTotalValue: ERPResponse.data.nativeTotalValue,
        nativeCurrencyIso: ERPResponse.data.nativeCurrencyIso,
        paymentCondition: ERPResponse.data.paymentCondition,
      });
    } else {
      setCurrentOrder({});
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (documentIdentifier) {
      getOrder();
    }
  }, [documentIdentifier]);

  // eslint-disable-next-line no-unused-vars
  const handleChange = (name, value) => {
    setCurrentOrder({
      ...currentOrder,
      // [name]: value,
    });
  };

  const discountColumns = [
    {
      header: t("Name"),
      accessor: "description",
      align: "left",
      sort: false,
    },
    {
      header: t("Base"),
      accessor: "nativeAmountBase",
      align: "right",
      sort: false,
      currency: currentOrder?.nativeCurrencyIso,
    },
    {
      header: t("In %"),
      accessor: "percent",
      align: "right",
      sort: false,
    },
    {
      header: t("Discount"),
      accessor: "nativeDiscountAmount",
      align: "right",
      sort: false,
      currency: currentOrder?.nativeCurrencyIso,
    },
  ];

  const surchargeColumns = [
    {
      header: t("Name"),
      accessor: "type",
      align: "left",
      sort: false,
    },
    {
      header: t("Tax Amount"),
      accessor: "nativeAmount",
      align: "right",
      sort: false,
      currency: currentOrder?.nativeCurrencyIso,
    },
    {
      header: t("Surcharge"),
      accessor: "nativeNetValue",
      align: "right",
      sort: false,
      currency: currentOrder?.nativeCurrencyIso,
    },
  ];

  const taxColumns = [
    {
      header: t("Tax Codes"),
      accessor: "taxCode",
      align: "left",
      sort: false,
    },
    {
      header: t("Net Amount"),
      accessor: "netAmount",
      align: "right",
      sort: false,
      currency: currentOrder?.nativeCurrencyIso,
    },
    {
      header: t("Tax Amount"),
      accessor: "taxAmount",
      align: "right",
      sort: false,
      currency: currentOrder?.nativeCurrencyIso,
    },
  ];
  const discounts = [];
  if (currentOrder.discount1) {
    discounts.push(currentOrder.discount1);
  }
  if (currentOrder.discount2) {
    discounts.push(currentOrder.discount2);
  }
  if (currentOrder.discount3) {
    discounts.push(currentOrder.discount3);
  }

  return (
    <MDBox position="relative">
      <Progress loading={loading} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <Card sx={{ boxShadow: "none" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Document")}
              </MDTypography>
            </MDBox>
            <InputText
              label={t("Document Type")}
              name="documentType"
              value={currentOrder.documentType}
              onChange={handleChange}
            />
            <InputText
              label={t("Document Year")}
              name="documentYear"
              value={currentOrder.documentYear}
              onChange={handleChange}
            />
            <InputText
              label={t("Document Number")}
              name="documentNumber"
              value={currentOrder.documentNumber}
              onChange={handleChange}
            />
            <InputText
              label={t("Payment Condition")}
              name="paymentCondition"
              value={currentOrder.paymentCondition}
              onChange={handleChange}
            />
            <InputText
              label={t("Type Of Dispatch")}
              name="typeOfDispatch"
              value={currentOrder.typeOfDispatch}
              onChange={handleChange}
            />
            <InputText
              label={t("Delivery Condition")}
              name="deliveryCondition"
              value={currentOrder.deliveryCondition}
              onChange={handleChange}
            />
            <InputText
              label={t("Payment Status")}
              name="paymentStatus"
              value={currentOrder.paymentStatus}
              onChange={handleChange}
            />
            <InputText
              label={t("Payment Reference")}
              name="paymentReference"
              value={currentOrder.paymentReference}
              onChange={handleChange}
            />
            <InputText
              label={t("Your Document")}
              name="customerDocumentReference"
              value={currentOrder.customerDocumentReference}
              onChange={handleChange}
            />
            <InputText
              label={t("Shop Reference Note")}
              name="customerDocumentReferenceShopNote"
              value={currentOrder.customerDocumentReferenceShopNote}
              onChange={handleChange}
            />
            <InputText
              label={t("Shop Reference Number")}
              name="customerDocumentReferenceShopNumber"
              value={currentOrder.customerDocumentReferenceShopNumber}
              onChange={handleChange}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} xl={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={6} style={{ display: "flex", position: "relative" }}>
              <Card sx={{ boxShadow: "none", width: "100%" }}>
                <MDBox p={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("Discount")}
                  </MDTypography>
                </MDBox>
                <MDBox px={2}>
                  <StatisticsTable
                    tableHighLight
                    columns={discountColumns}
                    rows={discounts}
                    getRowProps={() => ({})}
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} xl={6} style={{ display: "flex", position: "relative" }}>
              <Card sx={{ boxShadow: "none", width: "100%" }}>
                <MDBox p={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("Taxes")}
                  </MDTypography>
                </MDBox>
                <MDBox px={2}>
                  <StatisticsTable
                    tableHighLight
                    columns={taxColumns}
                    rows={currentOrder.taxes ? currentOrder.taxes : []}
                    getRowProps={() => ({})}
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} xl={6} style={{ display: "flex", position: "relative" }}>
              <Card sx={{ boxShadow: "none", width: "100%" }}>
                <MDBox p={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("Surcharges")}
                  </MDTypography>
                </MDBox>
                <MDBox px={2}>
                  <StatisticsTable
                    tableHighLight
                    columns={surchargeColumns}
                    rows={currentOrder.surcharge || []}
                    getRowProps={() => ({})}
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={12} xl={6} style={{ display: "flex", position: "relative" }}>
              <Card sx={{ boxShadow: "none", width: "100%" }}>
                <MDBox p={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    {t("Prices Composition")}
                  </MDTypography>
                </MDBox>
                <MDBox px={2}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {t("Total")}
                            </MDTypography>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {CommonFun.displayCurrency(
                                currentOrder.nativeNetValue,
                                currentOrder.nativeCurrencyIso
                              )}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {t("Discount")}
                            </MDTypography>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {CommonFun.displayCurrency(
                                currentOrder.nativeTotalDiscountNetValue,
                                currentOrder.nativeCurrencyIso
                              )}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {t("Surcharge")}
                            </MDTypography>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {CommonFun.displayCurrency(
                                currentOrder.nativeTotalSurchargeNetValue,
                                currentOrder.nativeCurrencyIso
                              )}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {t("Plus VAT")}
                            </MDTypography>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {CommonFun.displayCurrency(
                                currentOrder.nativeTaxAmount,
                                currentOrder.nativeCurrencyIso
                              )}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {t("Total Price")}
                            </MDTypography>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              paddingLeft: "24px",
                              paddingRight: "24px",
                            }}
                          >
                            <MDTypography variant="caption" color="black" fontWeight="medium">
                              {CommonFun.displayCurrency(
                                currentOrder.nativeTotalValue,
                                currentOrder.nativeCurrencyIso
                              )}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(Information);
