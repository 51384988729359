import Fetch from "../fetch";

const deliveryAPI = {
  async searchDeliveryInventory(filter, page = 0, pageSize = 10, orderBy, order) {
    const params = [];
    if (filter) {
      Object.keys(filter).forEach((key) => {
        if (filter[key].length === 1) {
          params.push(`${key}=${filter[key][0]}`);
        } else {
          filter[key].forEach((value) => {
            params.push(`${key}[]=${value}`);
          });
        }
      });
    }
    params.push(`page=${page + 1}`);
    params.push(`pageSize=${pageSize}`);
    params.push(`order=${orderBy} ${order}`);
    const response = await Fetch.get(`api/erp/deliveryinventory?${params.join("&")}`);
    return response?.data;
  },
};
export default deliveryAPI;
