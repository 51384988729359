/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Scrollbar } from "react-scrollbars-custom";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import { TICKET_STATUS_OPEN_ID, TICKET_STATUS_CLOSE_ID } from "environment";
import Tickets from "./components/tickets";
import BusinessCard from "./components/businessCard";
import Stats from "./components/stats";
import RecentOrders from "./components/recentOrders";
import MemoField from "./components/memoField";
import Reminders from "./components/reminders";
import NewTicket from "./components/newTicket";

function OverView({ customerID, customer = {}, setLeadSaved, ticketID = null, setParentLoading }) {
  const { t } = useTranslation("common");
  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "OverView");

  const [recentOrdersCount, setRecentOrdersCount] = useState(0);
  const [remindersCount, setRemindersCount] = useState(0);
  const [openTicketsCount, setOpenTicketsCount] = useState(0);
  const [closedTicketsCount, setClosedTicketsCount] = useState(0);
  const [openNewTicket, setOpenNewTicket] = useState(false);
  const [openTicket, setOpenTicket] = useState(ticketID);

  useEffect(() => {
    setOpenTicket(ticketID);
  }, [ticketID]);

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={3}>
          <Scrollbar style={{ minHeight: "calc(100vh - 169px)", maxHeight: "calc(100vh - 169px)" }}>
            <Card>
              <Accordion
                defaultExpanded
                sx={{ margin: "0px !important" }}
                className="overview_accordion"
              >
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("Business Card")}
                    {customer?.id ? (
                      <MDBadge
                        badgeContent={t("Lead")}
                        color="success"
                        variant="gradient"
                        size="sm"
                        sx={{
                          marginLeft: 1,
                        }}
                      />
                    ) : null}
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <BusinessCard
                    customerID={customerID}
                    customer={customer}
                    setLeadSaved={setLeadSaved}
                    openNewTicket={openNewTicket}
                    setOpenNewTicket={setOpenNewTicket}
                    setParentLoading={setParentLoading}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ margin: "0px !important" }}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("Stats")}
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stats customerID={customerID} />
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ margin: "0px !important" }}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("Recent Orders")} ({recentOrdersCount})
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <RecentOrders
                    customerID={customerID}
                    setRecentOrdersCount={setRecentOrdersCount}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ margin: "0px !important" }}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("Memo Field")}
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MemoField customer={customer} />
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ margin: "0px !important" }}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("Reminders")} ({remindersCount})
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <Reminders customerID={customerID} setRemindersCount={setRemindersCount} />
                </AccordionDetails>
              </Accordion>
            </Card>
          </Scrollbar>
        </Grid>
        <Grid item xs={12} md={12} lg={9}>
          <Scrollbar style={{ minHeight: "calc(100vh - 169px)", maxHeight: "calc(100vh - 169px)" }}>
            <Card sx={{ width: "100%" }}>
              {openNewTicket ? (
                <Accordion
                  defaultExpanded
                  sx={{ boxShadow: "none", margin: "0px !important" }}
                  id="create_ticket_wrap"
                >
                  <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {t("Create Ticket")}
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <NewTicket
                      open={openNewTicket}
                      customer={customer}
                      setOpenNewTicket={setOpenNewTicket}
                      setOpenTicket={setOpenTicket}
                      setParentLoading={setParentLoading}
                    />
                  </AccordionDetails>
                </Accordion>
              ) : null}
              <Accordion defaultExpanded sx={{ boxShadow: "none", margin: "0px !important" }}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("Open Tickets")} ({openTicketsCount})
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Tickets
                    customerID={customerID}
                    customer={customer}
                    openTicket={openTicket}
                    setOpenTicket={setOpenTicket}
                    statusCode={TICKET_STATUS_OPEN_ID}
                    setTicketsCount={setOpenTicketsCount}
                    setParentLoading={setParentLoading}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded sx={{ boxShadow: "none", margin: "0px !important" }}>
                <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("Closed Tickets")} ({closedTicketsCount})
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Tickets
                    customerID={customerID}
                    customer={customer}
                    openTicket={openTicket}
                    setOpenTicket={setOpenTicket}
                    statusCode={TICKET_STATUS_CLOSE_ID}
                    setTicketsCount={setClosedTicketsCount}
                    setParentLoading={setParentLoading}
                  />
                </AccordionDetails>
              </Accordion>
            </Card>
          </Scrollbar>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(OverView);
