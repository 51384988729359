/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import { useMaterialUIController, setPreviewDocument } from "context";

import StatisticsTable from "components/Tables/StatisticsTable";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

function DMSDocuments({ customerID }) {
  const { t } = useTranslation("common");
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useMaterialUIController();

  const getCustomerDocumentsExternal = async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerDocumentsExternal(customerID.replace("erp-", ""));
      if (ERPResponse && ERPResponse.data && ERPResponse.data.length) {
        setDocuments(ERPResponse.data);
      } else {
        setDocuments([]);
      }
      setLoading(false);
    } else {
      setDocuments([]);
    }
  };

  useEffect(async () => {
    getCustomerDocumentsExternal();
  }, [customerID]);

  const columns = [
    {
      header: t("Document Type"),
      accessor: "documentType",
      align: "left",
      sort: false,
    },
    {
      header: t("File Name"),
      accessor: "fileName",
      align: "left",
      sort: false,
    },
    {
      header: t("File Type"),
      accessor: "fileType",
      align: "left",
      sort: false,
    },
    {
      header: t("Archived On"),
      accessor: "archivedOn",
      align: "left",
      sort: false,
      dateFormat: CommonFun.getDateFormat(),
    },
    {
      header: t("Archived By"),
      accessor: "archivedBy",
      align: "left",
      sort: false,
    },
    {
      header: t("Documents"),
      accessor: "documents",
      align: "center",
      sort: false,
      render: (row) => (
        <MDBox sx={{ position: "relative" }}>
          <IconButton
            color="black"
            size="small"
            onClick={() => {
              setPreviewDocument(dispatch, {
                isImage: false,
                documentIdentifier: row.documentIdentifier,
                name: row.documentType,
                dmsDocumentId: row.dmsDocumentId,
              });
            }}
            disabled={!row.documentIdentifier || !row.dmsDocumentId}
          >
            <Icon>picture_as_pdf</Icon>
          </IconButton>
        </MDBox>
      ),
      getCellProps: () => ({
        // onClick: () => {}
        style: {
          cursor: "default",
        },
      }),
    },
  ];

  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "DMS documents");

  return (
    <MDBox bgColor="light">
      <Progress loading={loading} isFull />
      <StatisticsTable
        columns={columns}
        rows={documents}
        getRowProps={() => ({
          // onClick: () => {
          // },
          // style: {
          //   cursor: "pointer",
          // },
        })}
      />
    </MDBox>
  );
}

export default memo(DMSDocuments);
