/* eslint-disable react/prop-types */
import { memo } from "react";
import Grid from "@mui/material/Grid";
import ContactPersons from "./components/contactPersons";
import Address from "./components/address";

function Contact({ customerID }) {
  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "Contact");

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ContactPersons customerID={customerID} />
      </Grid>
      <Grid item xs={12}>
        <Address customerID={customerID} />
      </Grid>
    </Grid>
  );
}

export default memo(Contact);
