import Fetch from "../fetch";

const item = {
  async searchItems(filter, page = 0, pageSize = 10, orderBy, order) {
    const params = [];
    if (filter) {
      Object.keys(filter).forEach((key) => {
        if (filter[key].length === 1) {
          params.push(`${key}=${filter[key][0]}`);
        } else {
          filter[key].forEach((value) => {
            params.push(`${key}[]=${value}`);
          });
        }
      });
    }
    params.push(`page=${page + 1}`);
    params.push(`pageSize=${pageSize}`);
    params.push(`order=${orderBy} ${order}`);
    const response = await Fetch.get(`api/erp/article?${params.join("&")}`);
    return response?.data;
  },
  async getItem(articleNumber) {
    const response = await Fetch.get(`api/erp/article/${articleNumber}`);
    return response?.data;
  },
  async getItemStatus(articleNumber) {
    const response = await Fetch.get(`api/erp/article/${articleNumber}/status`);
    return response?.data;
  },
  async getItemAccessories(articleNumber) {
    const response = await Fetch.get(`api/erp/article/${articleNumber}/accessories`);
    return response?.data;
  },
  async getStockInformation(articleNumber) {
    const response = await Fetch.get(`api/erp/article/${articleNumber}/stockinformation`);
    return response?.data;
  },
  async getWarehouseInformation(articleNumber) {
    const response = await Fetch.get(`api/erp/article/${articleNumber}/warehouseinformation`);
    return response?.data;
  },
};
export default item;
