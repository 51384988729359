/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */

import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import breakpoints from "assets/theme/base/breakpoints";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

import CustomerAPI from "services/customers";
import TicketsAPI from "services/tickets";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

import Items from "./components/items";
import Orders from "./components/orders";
import OverView from "./components/overView";
import Basic from "./components/basic";
import Quotations from "./components/quotations";
import Contact from "./components/contact";
import CollectiveFolder from "./components/collectiveFolder";
import DMSDocuments from "./components/dMSDocuments";
import CreditInfo from "./components/creditInfo";
import History from "./components/history";

function Customer({ type, customerID, ticketID = null }) {
  const { t } = useTranslation("common");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [customer, setCustomer] = useState(null);
  const [leadSaved, setLeadSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [parentLoading, setParentLoading] = useState(true);
  const [url, setURL] = useState("");
  const isNew = customerID.includes("new-");
  const isERP = customerID.includes("erp-");
  const isCustomer = customerID.includes("new-customer");

  const tabStr = localStorage.getItem("A24_CUSTOMER_TAB_STATE");
  let tabState = {};
  if (tabStr && tabStr !== "") {
    tabState = JSON.parse(tabStr);
  }
  const [tabValue, setTabValue] = useState(
    tabState[customerID] ? parseInt(tabState[customerID], 10) : 0
  );

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    if (!isNew) {
      switch (tabValue) {
        case 0:
          setURL(`${window.location.origin}/customer/${customerID}/overview`);
          break;
        case 1:
          setURL(`${window.location.origin}/customer/${customerID}/basic`);
          break;
        case 2:
          setURL(`${window.location.origin}/customer/${customerID}/contact`);
          break;
        case 3:
          setURL(`${window.location.origin}/customer/${customerID}/orders`);
          break;
        case 4:
          setURL(`${window.location.origin}/customer/${customerID}/items`);
          break;
        case 5:
          setURL(`${window.location.origin}/customer/${customerID}/quotations`);
          break;
        case 6:
          setURL(`${window.location.origin}/customer/${customerID}/collective-folder`);
          break;
        case 7:
          setURL(`${window.location.origin}/customer/${customerID}/dms-documents`);
          break;
        case 8:
          setURL(`${window.location.origin}/customer/${customerID}/history`);
          break;
        default:
          setURL("");
      }
    }
  }, [tabValue]);

  const getCustomer = async (customerId) => {
    setLoading(true);
    const emailList = [];
    const phoneList = [];
    const addEmail = (value) => {
      const index = emailList.findIndex((x) => x === value);
      if (index === -1 && value) {
        emailList.push(value);
      }
    };
    const addPhone = (value) => {
      const clearValue = CommonFun.serverPhone(value);
      const index = phoneList.findIndex((x) => x === clearValue);
      if (index === -1 && clearValue) {
        phoneList.push(clearValue);
      }
    };
    if (isNew) {
      if (customerID.includes("new-ticket-") && ticketID) {
        const response = await TicketsAPI.getTicketDetails(ticketID);
        if (response) {
          addEmail(response.customer_email);
          addPhone(response.from_phone);
          setCustomer({
            email: response.customer_email,
            name2: response.customer_name,
            phone: response.from_phone,
            customerNumber: response.erp_customer_id,
            emailList,
            phoneList,
          });
        }
      } else {
        setCustomer({});
      }
    } else if (customerID.includes("erp-")) {
      const ERPResponse = await ERPAPI.getCustomerDetails(customerId.replace("erp-", ""));
      if (ERPResponse && ERPResponse.data) {
        addEmail(ERPResponse.data.email);
        addPhone(ERPResponse.data.mobile);
        addPhone(ERPResponse.data.phone);
        const ERPContactsResponse = await ERPAPI.getCustomerContacts(
          customerID.replace("erp-", ""),
          0,
          1000,
          "id",
          "asc"
        );
        if (
          ERPContactsResponse &&
          ERPContactsResponse.data &&
          ERPContactsResponse.data.items &&
          ERPContactsResponse.data.items.length
        ) {
          ERPContactsResponse.data.items.forEach((item) => {
            addEmail(item.email);
            addPhone(item.phone);
            addPhone(item.mobile);
          });
        }
        setCustomer({
          ...ERPResponse.data,
          erpEmail: ERPResponse.data.email,
          name1: ERPResponse.data.shippingAddress?.name1,
          name2: ERPResponse.data.shippingAddress?.name2,
          street: ERPResponse.data.shippingAddress?.street,
          postcode: ERPResponse.data.shippingAddress?.postcode,
          city: ERPResponse.data.shippingAddress?.city,
          country: ERPResponse.data.shippingAddress?.country,
          emailList,
          phoneList,
        });
      } else {
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "ERR",
              title: "Customer",
              content: "No data received. Please check again.",
            },
          })
        );
        CommonFun.deleteCurrentTab();
      }
    } else if (customerID.includes("lead-")) {
      const response = await CustomerAPI.getCustomerDetails(customerId.replace("lead-", ""));
      if (response) {
        addEmail(response.erp_email);
        addEmail(response.email);
        addPhone(response.phone);
        addPhone(response.mobile);
        setCustomer({
          ...response,
          customerNumber: response.erp_customer_id,
          erpEmail: response.erp_email,
          name2: response.first_name,
          name1: response.last_name,
          street: response.address_line_1,
          postcode: response.zip,
          emailList,
          phoneList,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (type === "CUSTOMER") {
      getCustomer(customerID);
      const tabStateStr = localStorage.getItem("A24_CUSTOMER_TAB_STATE");
      let tabStateJson = {};
      if (tabStateStr && tabStateStr !== "") {
        tabStateJson = JSON.parse(tabStr);
      }
      setTabValue(tabStateJson[customerID] ? parseInt(tabStateJson[customerID], 10) : 0);
      const customersStr = localStorage.getItem("A24_RECENTLY_VIEW");
      let customers = [];
      if (customersStr && customersStr !== "") {
        customers = JSON.parse(customersStr);
      }
      const index = customers.findIndex((e) => e === customerID);
      if (index !== -1) {
        customers.splice(index, 1);
      }
      customers.unshift(customerID);
      localStorage.setItem("A24_RECENTLY_VIEW", JSON.stringify(customers.slice(0, 5)));
    }
  }, [customerID, ticketID]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(parseInt(newValue, 10));
    const tabStateStr = localStorage.getItem("A24_CUSTOMER_TAB_STATE");
    let tabStateJson = {};
    if (tabStateStr && tabStateStr !== "") {
      tabStateJson = JSON.parse(tabStateStr);
    }
    tabStateJson[customerID] = newValue;
    localStorage.setItem("A24_CUSTOMER_TAB_STATE", JSON.stringify(tabStateJson));
  };

  const handelShare = () => {
    const copyText = document.getElementById(`urlInput_${customerID}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  };

  if (type === "CUSTOMER") {
    // eslint-disable-next-line no-console
    console.log("render customer", customerID);

    return (
      <MDBox p={1} bgColor="light" position="relative">
        {!isNew ? (
          <MDBox
            sx={{
              position: "absolute",
              top: "-40px",
              right: "8px",
            }}
          >
            <input
              type="text"
              value={url}
              id={`urlInput_${customerID}`}
              style={{ display: "none" }}
              readOnly
            />
            <Tooltip title={`${t("Copy to clipboard")}: ${url}`}>
              <Fab
                size="small"
                color="secondary"
                aria-label="share"
                onClick={() => {
                  handelShare();
                }}
                sx={{
                  width: 24,
                  height: 24,
                  minHeight: 24,
                }}
              >
                <Icon>share</Icon>
              </Fab>
            </Tooltip>
          </MDBox>
        ) : null}
        <Progress loading={loading} isFull />
        <Progress loading={parentLoading} isFull />
        <AppBar position="static">
          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
            <Tab label={t("Overview")} />
            <Tab label={t("Basic")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("Contact")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("Orders")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("Items")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("Quotations")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("Collective Folder")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("DMS Documents")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("Credit Info")} disabled={(isNew && !leadSaved) || !isERP} />
            <Tab label={t("History")} disabled={isCustomer} />
          </Tabs>
        </AppBar>
        {customer ? (
          <MDBox pt={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={tabValue === 0 ? null : { display: "none" }}>
                <OverView
                  customer={customer}
                  customerID={customerID}
                  setLeadSaved={setLeadSaved}
                  ticketID={ticketID}
                  setParentLoading={setParentLoading}
                />
              </Grid>
              {!isNew || leadSaved ? (
                <>
                  <Grid item xs={12} sx={tabValue === 1 ? null : { display: "none" }}>
                    <Card sx={{ pb: 3 }}>
                      <Basic customerID={customerID} customer={customer} />
                    </Card>
                  </Grid>
                  <Grid item xs={12} sx={tabValue === 2 ? null : { display: "none" }}>
                    <Contact customerID={customerID} />
                  </Grid>
                  <Grid item xs={12} sx={tabValue === 3 ? null : { display: "none" }}>
                    <Orders customerID={customerID} />
                  </Grid>
                  <Grid item xs={12} sx={tabValue === 4 ? null : { display: "none" }}>
                    <Items customerID={customerID} customer={customer} />
                  </Grid>
                  <Grid item xs={12} sx={tabValue === 5 ? null : { display: "none" }}>
                    <Quotations customerID={customerID} customer={customer} />
                  </Grid>
                  <Grid item xs={12} sx={tabValue === 6 ? null : { display: "none" }}>
                    <CollectiveFolder customerID={customerID} />
                  </Grid>
                  <Grid item xs={12} sx={tabValue === 7 ? null : { display: "none" }}>
                    <DMSDocuments customerID={customerID} />
                  </Grid>
                  <Grid item xs={12} sx={tabValue === 8 ? null : { display: "none" }}>
                    <CreditInfo customerID={customerID} />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sx={tabValue === 9 ? null : { display: "none" }}>
                <History customerID={customerID} customer={customer} />
              </Grid>
            </Grid>
          </MDBox>
        ) : null}
      </MDBox>
    );
  }
  return false;
}

export default memo(Customer);
