import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { I18nextProvider, initReactI18next } from "react-i18next";

import { MaterialUIControllerProvider } from "context";

import i18next from "i18next";
import "moment/locale/de";

import LanguageDetector from "i18next-browser-languagedetector";
import commonDe from "./translations/de/common.json";
import commonEn from "./translations/en/common.json";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: "en",
    returnEmptyString: false,
    resources: {
      en: {
        common: commonEn,
      },
      de: {
        common: commonDe,
      },
    },
  });

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
