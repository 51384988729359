/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import StatisticsTable from "components/Tables/StatisticsTable";
import Progress from "components/Progress";
import { useMaterialUIController, setPreviewDocument } from "context";

import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Log({ documentIdentifier }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [, dispatch] = useMaterialUIController();
  const [log, setLogs] = useState([]);

  const getOrderLogs = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getOrderLogs(documentIdentifier);
    if (ERPResponse && ERPResponse.data) {
      setLogs([...ERPResponse.data.operations]);
      setDocuments([...ERPResponse.data.documents]);
    } else {
      setLogs([]);
      setDocuments([]);
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (documentIdentifier) {
      getOrderLogs();
    }
  }, [documentIdentifier]);

  const logColumns = [
    {
      accessor: "type",
      header: t("Type"),
      align: "left",
      sort: false,
    },
    {
      accessor: "createdDateTime",
      header: t("Date"),
      align: "left",
      sort: false,
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.createdDateTime).format(CommonFun.getDateFormat())}
        </MDTypography>
      ),
    },
    {
      accessor: "log",
      header: t("Log"),
      align: "left",
      sort: false,
    },
  ];

  return (
    <MDBox position="relative">
      <Progress loading={loading} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: "none" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Operation log")}
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={1} px={2} lineHeight={1.25}>
              <StatisticsTable
                tableHighLight
                columns={logColumns}
                rows={log}
                getRowProps={() => ({})}
              />
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: "flex", position: "relative" }}>
          <Divider
            orientation="vertical"
            sx={{ ml: -1, mr: 0, display: { xs: "none", md: "block" } }}
          />
          <Card sx={{ boxShadow: "none", width: "100%" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Documents")}
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={1} px={2} lineHeight={1.25} display="flex" flexDirection="column">
              {documents.map((item, index) => (
                <MDButton
                  key={index}
                  onClick={() => {
                    setPreviewDocument(dispatch, {
                      isImage: false,
                      documentIdentifier: item.number,
                      name: item.name,
                      dmsDocumentId: item.dmsDocumentId,
                    });
                  }}
                  sx={{
                    textAlign: "left",
                    padding: 2,
                    marginBottom: 2,
                    border:
                      item.number && item.dmsDocumentId ? "1px solid #1A73E8" : "1px solid #000000",
                  }}
                  disabled={!item.number || !item.dmsDocumentId}
                >
                  <MDBox sx={{ display: "flex", width: "100%" }}>
                    <MDBox
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <MDTypography
                        variant="button"
                        color={item.number && item.dmsDocumentId ? "info" : "black"}
                      >
                        {item.name}
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        color={item.number && item.dmsDocumentId ? "info" : "black"}
                      >
                        {item.number}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        paddingLeft: 1,
                        paddingRight: 1,
                      }}
                    >
                      <MDTypography variant="caption" color="black">
                        {moment.utc(item.createdDateTime).format(CommonFun.getDateFormat())}
                      </MDTypography>
                      <MDTypography variant="caption" color="black">
                        {CommonFun.displayCurrency(item.nativeTotal, item.nativeCurrencyIso)}
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ display: "flex", alignItems: "center", padding: 1 }}>
                      <MDTypography color="black">
                        <Icon>picture_as_pdf</Icon>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDButton>
              ))}
              {!documents.length && (
                <MDTypography
                  component="div"
                  variant="caption"
                  align="center"
                  color="black"
                  fontWeight="medium"
                >
                  {t("No data to show")}
                </MDTypography>
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(Log);
