import getSymbolFromCurrency from "currency-symbol-map";
import {
  REGION_US,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_FORMAT_US,
  DATE_TIME_FORMAT_US,
} from "environment";

const languages = [
  { language: "DE", country: "DE" },
  { language: "AT", country: "DE" },
  { language: "NL", country: "NL" },
  { language: "BE", country: "NL" },
  { language: "BE", country: "FR" },
  { language: "FR", country: "FR" },
  { language: "ES", country: "ES" },
  { language: "IT", country: "IT" },
  { language: "EN", country: "UK" },
  { language: "EN", country: "BIZ" },
  { language: "EN", country: "US" },
  { language: "SE", country: "SE" },
  { language: "PL", country: "PL" },
];
const CommonFun = {
  isAdmin() {
    const rolesJSON = localStorage.getItem("A24_ROLES");
    const roles = JSON.parse(rolesJSON);
    if (roles && roles.length && roles.includes("admin")) {
      return true;
    }
    return false;
  },
  getLanguageCountry() {
    return languages.map((item) => `${item.language}-${item.country}`);
  },
  isCompanyEmail(email) {
    return email && (email.includes("automation24.com") || email.includes("info@xejic.com"));
  },
  displayCurrency(currencyValue, currencyCode = "EUR") {
    return `${currencyCode ? getSymbolFromCurrency(currencyCode) : ""} ${
      currencyValue ? currencyValue.toFixed(2) : "0.00"
    }`;
  },
  displayPhone(phone) {
    return phone && phone.charAt(0) !== "+" ? `+${phone}` : phone;
  },
  serverPhone(phone) {
    return phone ? phone.replace("+", "") : phone;
  },
  truncateString(str, num = 30) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  },
  getDateFormat() {
    const region = parseInt(localStorage.getItem("A24_REGION"), 10);
    if (region === REGION_US) {
      return DATE_FORMAT_US;
    }
    return DATE_FORMAT;
  },
  getDateTimeFormat() {
    const region = parseInt(localStorage.getItem("A24_REGION"), 10);
    if (region === REGION_US) {
      return DATE_TIME_FORMAT_US;
    }
    return DATE_TIME_FORMAT;
  },
  async deleteCurrentTab() {
    const tabStr = sessionStorage.getItem("A24_ACTIVE_TAB");
    let tab = null;
    if (tabStr && tabStr !== "") {
      tab = JSON.parse(tabStr);
    }
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }

    const index = tabList.findIndex((x) => x.id === tab.id && x.deleted === tab.deleted);
    if (index !== -1) {
      let item = {
        id: "tickets",
        type: "TICKETS",
        label: "Tickets",
        icon: "task",
        disabled: false,
      };
      tabList.every((tabItem, tabIndex) => {
        if (!tabItem.deleted && tabIndex < index) {
          item = tabItem;
        }
        if (!tabItem.deleted && tabIndex > index) {
          item = tabItem;
          return false;
        }
        return true;
      });
      tabList[index].deleted = true;
      await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
      await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(item));
      window.dispatchEvent(new Event("storage"));
    }
  },
  async onClickCustomer(
    customerID,
    name,
    email = null,
    phone = null,
    mobile = null,
    ticketId = null
  ) {
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }

    const newTab = {
      id: customerID,
      ticketId,
      name,
      email,
      phone,
      mobile,
      type: "CUSTOMER",
      label: customerID.includes("new-") ? "#new" : ``,
    };
    const index = tabList.findIndex((e) => e.id === customerID && !e.deleted);
    if (index === -1) {
      if (customerID.includes("new-customer")) {
        const newIndex = tabList.findIndex((e) => e.id === customerID && e.deleted);
        if (newIndex === -1) {
          tabList.push(newTab);
          await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
          await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
        } else {
          tabList[newIndex].deleted = false;
          await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
          await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(tabList[newIndex]));
        }
      } else if (customerID.includes("new-")) {
        const index2 = tabList.findIndex(
          (e) =>
            !e.deleted &&
            ((email && e.email === email) ||
              (phone && e.phone === phone) ||
              (mobile && e.mobile === mobile))
        );
        if (index2 === -1) {
          tabList.push(newTab);
          await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
          await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
        } else {
          tabList[index2].ticketId = ticketId;
          await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
          await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(tabList[index2]));
        }
      } else {
        tabList.push(newTab);
        await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
        await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
      }
    } else {
      tabList[index].ticketId = ticketId;
      await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
      await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    }
    window.dispatchEvent(new Event("storage"));
  },
  async onClickOrder(documentIdentifier, tabIndex = 0) {
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }

    const newTab = {
      id: documentIdentifier,
      name: documentIdentifier,
      type: "ORDER",
      label: "Order",
      tab: tabIndex,
    };
    const index = tabList.findIndex((e) => e.id === documentIdentifier && !e.deleted);
    if (index === -1) {
      tabList.push(newTab);
      await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
    }
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    window.dispatchEvent(new Event("storage"));
  },
  async onClickItem(articleNumber, tabIndex = 0) {
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }

    const newTab = {
      id: articleNumber,
      name: articleNumber,
      type: "ITEM",
      label: "Item",
      tab: tabIndex,
    };
    const index = tabList.findIndex((e) => e.id === articleNumber && !e.deleted);
    if (index === -1) {
      tabList.push(newTab);
      await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
    }
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    window.dispatchEvent(new Event("storage"));
  },
  async onClickQuotation(documentIdentifier, tabIndex = 0) {
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }

    const newTab = {
      id: documentIdentifier,
      name: documentIdentifier,
      type: "QUOTATION",
      label: "Quotation",
      tab: tabIndex,
    };
    const index = tabList.findIndex((e) => e.id === documentIdentifier && !e.deleted);
    if (index === -1) {
      tabList.push(newTab);
      await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
    }
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    window.dispatchEvent(new Event("storage"));
  },
};
export default CommonFun;
