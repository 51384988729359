/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, useRef, memo } from "react";
import parse from "html-react-parser";
import juice from "juice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import useLocalStorage from "hooks/useLocalStorage";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import CKEditor from "components/Editors/CK";
import CkStyles from "components/Editors/CK/styles";
import MDButton from "components/MDButton";
import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";

import TicketsAPI from "services/tickets";
import UsersAPI from "services/users";
import TemplatesAPI from "services/templates";
import SignaturesAPI from "services/signatures";
import TasksAPI from "services/tasks";
import CommonFun from "services/common";
import { useMaterialUIController, setPreviewDocument } from "context";
import {
  TICKET_STATUS_OPEN_ID,
  TICKET_STATUS_CLOSE_ID,
  USER_ROLE_ADMIN,
  USER_ROLE_CRM,
  TICKET_INTERACTION_TYPE_EMAIL,
  TICKET_INTERACTION_TYPE_PHONE,
  TICKET_INTERACTION_TYPE_CHAT,
} from "environment";
import CreateTask from "view/components/CreateTask";

function Ticket({
  customerID,
  ticket,
  openTicketID,
  popupOpened,
  setPopupOpened,
  setIsDirty,
  isPopup,
  setOpenTicket,
  setParentLoading,
}) {
  const { t } = useTranslation("common");
  const [menu, setMenu] = useState(null);
  const [openAttention, setOpenAttention] = useState(false);
  const [templateMenu, setTemplateMenu] = useState(null);
  const [signatureMenu, setSignatureMenu] = useState(null);
  const [fromMenu, setFromMenu] = useState(null);
  const userId = parseInt(localStorage.getItem("A24_USER_ID"), 10);
  const [region] = useLocalStorage("A24_REGION", 0);
  const [currentTicket, setCurrentTicket] = useState(ticket);
  const [replyVia, setReplyVia] = useState(null);
  const [replyFrom, setReplyFrom] = useState(null);
  const [replyEmail, setReplyEmail] = useState("");
  const [ticketDetailId, setTicketDetailId] = useState(null);
  const inputFile = useRef(null);
  const [attentions, setAttentions] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [userData, setUserData] = useState({});
  const [deliveryStatistics, setDeliveryStatistics] = useState(null);
  const [, dispatch] = useMaterialUIController();
  const editorRef = useRef();
  const mailActions = {
    0: "Delivered",
    1: "Bounced",
    2: "Complaint",
  };

  useEffect(() => {
    function handleContentWidth() {
      const collapseWrapEle = document.getElementById("Tickets_Collapse_Wrap");
      if (collapseWrapEle) {
        document.documentElement.style.setProperty(
          "--a24-content-width",
          `${collapseWrapEle.clientWidth - 32}px`
        );
        document.documentElement.style.setProperty(
          "--a24-editor-width",
          `${collapseWrapEle.clientWidth - 64}px`
        );
        return;
      }
      document.documentElement.style.setProperty("--a24-content-width", "597px");
      document.documentElement.style.setProperty("--a24-editor-width", `565px`);
    }

    window.addEventListener("resize", handleContentWidth);

    handleContentWidth();

    return () => window.removeEventListener("resize", handleContentWidth);
  }, []);

  useEffect(async () => {
    const userStr = localStorage.getItem("A24_USER");
    let user = {};
    if (userStr && userStr !== "") {
      user = JSON.parse(userStr);
      setReplyFrom(user);
    }
  }, []);

  const getVerifiedUsers = async () => {
    const response = await UsersAPI.getVerifiedUsers();
    if (response && response.data) {
      const userList = response.data.filter(
        (item) =>
          item.region === region &&
          item.email_verified &&
          item.roles.length &&
          item.roles.filter((e) => e.name === USER_ROLE_ADMIN || e.name === USER_ROLE_CRM).length
      );
      userList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setUsers(userList);
    } else {
      setUsers([]);
    }
  };

  const closeTemplateMenu = () => setTemplateMenu(null);
  const closeSignatureMenu = () => setSignatureMenu(null);
  const closeFromMenu = () => setFromMenu(null);

  const getTemplates = async () => {
    const response = await TemplatesAPI.getAll();
    if (response) {
      setTemplates(response);
      if (!response.length) {
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "WARN",
              title: "Templates",
              content: "Templates not found",
            },
          })
        );
        closeTemplateMenu();
      }
    } else {
      setTemplates([]);
    }
  };

  const getSignatures = async () => {
    const response = await SignaturesAPI.getAllByUser();
    if (response) {
      if (!response.length) {
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "WARN",
              title: "Signatures",
              content: "Signatures not found",
            },
          })
        );
        closeSignatureMenu();
      } else {
        const result = [];
        response.forEach((item) => {
          item.signatures.forEach((item2) => {
            result.push({
              ...item2,
              default: item.default,
            });
          });
        });
        setSignatures(result);
      }
    } else {
      setSignatures([]);
    }
  };

  useEffect(async () => {
    if (currentTicket.id === openTicketID) {
      getSignatures();
    }
  }, []);

  const openTemplateMenu = (event, cell) => {
    getTemplates();
    setTemplateMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      cell,
    });
  };

  const openFromMenu = (event, cell) => {
    getVerifiedUsers();
    setFromMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      cell,
    });
  };

  const openSignatureMenu = (event, cell) => {
    setSignatureMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      cell,
    });
  };

  const openMenu = (event, cell) => {
    getVerifiedUsers();
    setMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      cell,
    });
  };

  const closeMenu = () => setMenu(null);

  const getAttentions = async (ticketID) => {
    const tasksResponse = await TasksAPI.getAllByTicket(ticketID);
    if (tasksResponse) {
      setAttentions(tasksResponse);
    } else {
      setAttentions([]);
    }
  };

  const handleDirty = (value) => {
    if (value) {
      localStorage.setItem(`CK_EDITOR_DIRTY_${customerID}`, value);
    } else {
      localStorage.removeItem(`CK_EDITOR_DIRTY_${customerID}`);
    }
    if (typeof setIsDirty === "function") {
      setIsDirty(value);
    }
  };

  const scrollIntoView = () => {
    if (openTicketID === currentTicket.id) {
      const ticketViewEle = document.getElementById(`TicketView_${currentTicket.id}`);
      if (ticketViewEle) {
        setTimeout(() => {
          ticketViewEle.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          setParentLoading(false);
        }, 500);
      } else {
        setParentLoading(false);
      }
    }
  };

  const getTicketDetails = async (ticketID) => {
    setParentLoading(true);
    const response = await TicketsAPI.getTicketDetails(ticketID);
    if (response) {
      response.ticket_details.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      const ticketDetails = response.ticket_details.filter(
        (e) =>
          e.interaction_type === TICKET_INTERACTION_TYPE_PHONE ||
          e.interaction_type === TICKET_INTERACTION_TYPE_CHAT ||
          e.message_id ||
          (!e.message_id && e.user_id !== userId)
      );
      const userDraft = response.ticket_details.filter(
        (e) =>
          !e.message_id &&
          e.user_id === userId &&
          (!e.interaction_type || e.interaction_type === TICKET_INTERACTION_TYPE_EMAIL)
      );
      if (userDraft.length) {
        ticketDetails.unshift(userDraft[0]);
        setReplyVia("DRAFT");
        setTicketDetailId(userDraft[0].id);
        setReplyFrom({
          name: userDraft[0].agent_name,
          email: userDraft[0].agent_email,
        });
        setReplyEmail(userDraft[0].content_html ? userDraft[0].content_html : userDraft[0].content);
        handleDirty(null);
        if (userDraft[0].attachments) {
          setFiles(JSON.parse(userDraft[0].attachments));
        }
      }
      ticketDetails.forEach((item) => {
        if (item.attachments) {
          const att = JSON.parse(item.attachments);
          item.inlineAttachments = att.filter((x) => x.contentDisposition === "inline");
          item.attachments = att.filter((x) => x.contentDisposition !== "inline");
        }
      });
      ticketDetails.forEach((item) => {
        if (item.inlineAttachments && item.inlineAttachments.length) {
          item.inlineAttachments.forEach((att) => {
            item.content_html_replace = item.content_html
              ? item.content_html.replaceAll(`cid:${att.cid}`, att.key)
              : item.content.replaceAll(`cid:${att.cid}`, att.key);
          });
        } else {
          item.content_html_replace = item.content_html ? item.content_html : item.content;
        }
      });
      currentTicket.ticket_details = ticketDetails;
      setCurrentTicket({ ...currentTicket });
      scrollIntoView();
    } else {
      setParentLoading(false);
    }
    await getAttentions(ticketID);
  };

  useEffect(async () => {
    if (replyVia) {
      setTimeout(() => {
        if (editorRef && editorRef.current) {
          editorRef.current.sourceElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          editorRef.current.editing.view.focus();
        }
      }, 500);
    }
  }, [replyVia]);

  useEffect(async () => {
    const getUserData = () => {
      const profileStr = localStorage.getItem("A24_USER");
      let profile = {};
      if (profileStr && profileStr !== "") {
        profile = JSON.parse(profileStr);
      }
      setUserData({
        name: profile.name || "",
        email: profile.email || "",
        title: profile.title || "",
        phone: profile.phone || "",
        mobile: profile.mobile || "",
      });
    };
    getUserData();
    window.addEventListener("user_storage", getUserData);
    return () => window.removeEventListener("user_storage", getUserData);
  }, []);

  useEffect(async () => {
    if (openTicketID && openTicketID === currentTicket.id && !currentTicket.ticket_details) {
      await getTicketDetails(currentTicket.id);
    } else if (openTicketID && openTicketID === currentTicket.id) {
      const ticketDetails = currentTicket.ticket_details.filter(
        (e) =>
          e.interaction_type === TICKET_INTERACTION_TYPE_PHONE ||
          e.interaction_type === TICKET_INTERACTION_TYPE_CHAT ||
          e.message_id ||
          (!e.message_id && e.user_id !== userId)
      );
      const userDraft = currentTicket.ticket_details.filter(
        (e) =>
          !e.message_id &&
          e.user_id === userId &&
          (!e.interaction_type || e.interaction_type === TICKET_INTERACTION_TYPE_EMAIL)
      );
      if (userDraft.length) {
        ticketDetails.unshift(userDraft[0]);
        setReplyVia("DRAFT");
        setTicketDetailId(userDraft[0].id);
        setReplyFrom({
          name: userDraft[0].agent_name,
          email: userDraft[0].agent_email,
        });
        setReplyEmail(userDraft[0].content_html ? userDraft[0].content_html : userDraft[0].content);
        handleDirty(null);
        if (userDraft[0].attachments) {
          setFiles(JSON.parse(userDraft[0].attachments));
        }
      }
      ticketDetails.forEach((item) => {
        if (item.attachments) {
          const att = JSON.parse(item.attachments);
          item.inlineAttachments = att.filter((x) => x.contentDisposition === "inline");
          item.attachments = att.filter((x) => x.contentDisposition !== "inline");
        }
      });
      ticketDetails.forEach((item) => {
        if (item.inlineAttachments && item.inlineAttachments.length) {
          item.inlineAttachments.forEach((att) => {
            item.content_html_replace = item.content_html
              ? item.content_html.replaceAll(`cid:${att.cid}`, att.key)
              : item.content.replaceAll(`cid:${att.cid}`, att.key);
          });
        } else {
          item.content_html_replace = item.content_html ? item.content_html : item.content;
        }
      });
      currentTicket.ticket_details = ticketDetails;
      setCurrentTicket({ ...currentTicket });
      scrollIntoView();
    }
  }, [openTicketID]);

  const setHistory = (value, type) => {
    const lastTicketDetail = currentTicket.ticket_details.filter((e) => e.message_id);
    if (lastTicketDetail.length) {
      const name = lastTicketDetail[0].outgoing
        ? lastTicketDetail[0].agent_name
        : lastTicketDetail[0].customer_name;
      const email = lastTicketDetail[0].outgoing
        ? lastTicketDetail[0].agent_email
        : lastTicketDetail[0].customer_email;
      if (type === "TEXT") {
        return `${value} \n On ${moment.utc(lastTicketDetail[0].created_at).format("lll")} ${
          name || ""
        } &lt;${email}&gt; wrote:\n${lastTicketDetail[0].content}`;
      }
      if (type === "HTML") {
        return `${value} <br> <p>On ${moment.utc(lastTicketDetail[0].created_at).format("lll")} ${
          name || ""
        } &lt;${email}&gt; wrote:</p><blockquote>${
          lastTicketDetail[0].content_html_replace
            ? lastTicketDetail[0].content_html_replace
            : lastTicketDetail[0].content_html
        }</blockquote>`;
      }
    }
    return value;
  };

  const htmlToText = (html) => {
    const temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent;
  };

  const handleReplyEmail = async () => {
    if (replyEmail) {
      setParentLoading(true);
      const content = juice.inlineContent(replyEmail, CkStyles);
      const replyEmailText = htmlToText(content);
      const formData = {
        interaction_type: TICKET_INTERACTION_TYPE_EMAIL,
        content: setHistory(replyEmailText, "TEXT"),
        content_html: setHistory(content, "HTML"),
        ticket_id: currentTicket.id,
        ticket_detail_id: ticketDetailId,
        from: replyFrom.name,
        from_email: replyFrom.email,
        customer_email: currentTicket.customer_email,
      };
      const response = await TicketsAPI.replyTicket(formData);
      if (response && response.data) {
        setReplyEmail("");
        setReplyVia(null);
        setTicketDetailId(null);
        setFiles([]);
        handleDirty(null);
        await getTicketDetails(currentTicket.id);
      } else {
        setParentLoading(false);
      }
    }
  };

  const handleSaveDraft = async (refresh = false) => {
    if (replyEmail) {
      setParentLoading(true);
      const content = juice.inlineContent(replyEmail, CkStyles);
      const replyEmailText = htmlToText(content);
      const formData = {
        interaction_type: null,
        content: replyEmailText,
        content_html: content,
        ticket_id: currentTicket.id,
        ticket_detail_id: ticketDetailId,
        from: replyFrom.name,
        from_email: replyFrom.email,
        customer_email: currentTicket.customer_email,
      };
      const response = await TicketsAPI.replyTicket(formData);
      if (response && response.data) {
        setReplyEmail("");
        handleDirty(null);
        if (!refresh) {
          await getTicketDetails(currentTicket.id);
        }
      } else {
        setParentLoading(false);
      }
    } else {
      handleDirty(null);
    }
  };

  useEffect(() => {
    const onSaveDraft = (event) => {
      const isDirtyLocal = parseInt(localStorage.getItem(`CK_EDITOR_DIRTY_${customerID}`), 10);
      if (event.detail.id === customerID && isDirtyLocal === currentTicket.id) {
        handleSaveDraft(true);
        window.dispatchEvent(
          new CustomEvent("updatesTickets", {
            detail: {
              id: customerID,
            },
          })
        );
      }
    };
    window.addEventListener("saveDraft", onSaveDraft);
    return () => window.removeEventListener("saveDraft", onSaveDraft);
  }, [handleSaveDraft, customerID]);

  const openAttachments = async (key) => {
    const response = await TicketsAPI.getAttachmentsURL(key);
    if (response && response.data) {
      const url = response.data;
      // eslint-disable-next-line no-useless-escape
      if (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim)) {
        setPreviewDocument(dispatch, {
          isImage: true,
          url,
        });
      } else {
        window.open(`${url}`, "_blank");
      }
    }
  };

  const handleAddFile = () => {
    inputFile.current.click();
  };

  const handelChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    let ticketDetailIdTemp = ticketDetailId;
    if (!ticketDetailIdTemp) {
      const replyEmailText = htmlToText(replyEmail);
      const formData = {
        interaction_type: null,
        content: replyEmailText,
        content_html: replyEmail,
        ticket_id: currentTicket.id,
        ticket_detail_id: ticketDetailId,
        customer_email: currentTicket.customer_email,
      };
      const response = await TicketsAPI.replyTicket(formData);
      if (response && response.data) {
        ticketDetailIdTemp = response.data.id;
        setTicketDetailId(response.data.id);
      }
    }
    for (const file of event.target.files) {
      const response = await TicketsAPI.addAttachment(ticketDetailIdTemp, file);
      if (response && response.data) {
        setFiles([...response.data]);
      }
    }
    event.target.value = "";
  };

  const handleFileDelete = async (fileName) => {
    const response = await TicketsAPI.deleteAttachment(ticketDetailId, fileName);
    if (response && response.data) {
      await getTicketDetails(currentTicket.id);
    }
  };

  const handleCloseTicket = async () => {
    setParentLoading(true);
    const response = await TicketsAPI.changeStatus({
      status_code: TICKET_STATUS_CLOSE_ID,
      ticket_id: currentTicket.id,
    });
    if (response) {
      window.dispatchEvent(new Event("tickets_status_change"));
    } else {
      setParentLoading(false);
    }
  };

  const handleReopenTicket = async () => {
    setParentLoading(true);
    const response = await TicketsAPI.changeStatus({
      status_code: TICKET_STATUS_OPEN_ID,
      ticket_id: currentTicket.id,
    });
    if (response) {
      window.dispatchEvent(new Event("tickets_status_change"));
    } else {
      setParentLoading(false);
    }
  };

  const handleDiscardDraft = async () => {
    if (ticketDetailId) {
      const response = await TicketsAPI.delateDraft(ticketDetailId);
      if (response && response.data) {
        if (currentTicket.ticket_details && currentTicket.ticket_details.length !== 1) {
          await getTicketDetails(currentTicket.id);
        } else if (isPopup) {
          window.dispatchEvent(new Event("tickets_status_change"));
          window.close();
        } else {
          setOpenTicket(null);
          window.dispatchEvent(new Event("tickets_status_change"));
        }
      }
    }
    setReplyVia(null);
    setTicketDetailId(null);
    setReplyEmail("");
    setFiles([]);
    handleDirty(null);
  };

  const handleReAssign = async (userID) => {
    setParentLoading(true);
    const response = await TicketsAPI.reassignTicket({
      user_id: userID,
      ticket_id: currentTicket.id,
    });
    if (response) {
      window.dispatchEvent(new Event("tickets_status_change"));
      closeMenu();
    } else {
      setParentLoading(false);
    }
  };

  const handleAttention = async (currentTask) => {
    setParentLoading(true);
    const response = await TasksAPI.createTask({
      ...currentTask,
      ticket_id: currentTicket.id,
      customer_id: null,
      erp_customer_id: null,
      task_type: 0,
    });
    if (response && response.data) {
      setOpenAttention(false);
      await getAttentions(currentTicket.id);
    }
    setParentLoading(false);
  };

  const handleAttentionDelete = async (taskID) => {
    setParentLoading(true);
    const response = await TasksAPI.deleteTask(taskID);
    if (response) {
      await getAttentions(currentTicket.id);
    }
    setParentLoading(false);
  };

  const handleNewReply = () => {
    setReplyVia("EMAIL");
    setTicketDetailId(null);
    setReplyEmail("");
    handleDirty(null);
    if (signatures && signatures.length) {
      const defaultSignatures = signatures.filter((x) => x.default);
      if (defaultSignatures.length) {
        const content = defaultSignatures[0].content.replace(/{(\w+)}/g, (_, k) => userData[k]);
        setReplyEmail(`<br/><br/><br/>${content}`);
      } else {
        setReplyEmail("");
      }
    } else {
      setReplyEmail("");
    }
  };

  const handleTemplate = (template) => {
    closeTemplateMenu();
    if (editorRef && editorRef.current) {
      const viewFragment = editorRef.current.data.processor.toView(template.content);
      const modelFragment = editorRef.current.data.toModel(viewFragment);
      editorRef.current.model.insertContent(
        modelFragment,
        editorRef.current.model.document.selection.getFirstPosition()
      );
      // editorRef.current.focus();
      editorRef.current.editing.view.focus();
    }
  };

  const handleSignature = (signature) => {
    closeSignatureMenu();
    if (editorRef && editorRef.current) {
      const content = signature.content.replace(/{(\w+)}/g, (_, k) => userData[k]);
      const viewFragment = editorRef.current.data.processor.toView(content);
      const modelFragment = editorRef.current.data.toModel(viewFragment);
      editorRef.current.model.insertContent(
        modelFragment,
        editorRef.current.model.document.selection.getFirstPosition()
      );
      // editorRef.current.focus();
      editorRef.current.editing.view.focus();
    }
  };

  const handleOpenMailInfo = async (messageId) => {
    setParentLoading(true);
    const response = await TicketsAPI.getMailInfo(messageId);
    if (response && response.data) {
      setDeliveryStatistics(response.data);
    }
    setParentLoading(false);
  };

  const handleDeleteTicket = async (ticketID) => {
    setParentLoading(true);
    const data = {
      ticket_id: ticketID,
      deleted: true,
    };
    const response = await TicketsAPI.softDeleteTicket(data);
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Delete",
            content: "Ticket deleted successfully",
          },
        })
      );
      setOpenTicket(null);
      window.dispatchEvent(new Event("tickets_status_change"));
      if (isPopup) {
        window.close();
      }
    } else {
      setParentLoading(false);
    }
  };

  const openNewWindow = (ticketID) => {
    const popupWindow = window.open(
      `${window.location.origin}/ticket/${ticketID}`,
      "_blank",
      "popup"
    );
    setPopupOpened(true);
    localStorage.setItem(`A24_POPUP_${ticketID}`, true);
    popupWindow.onload = () => {
      popupWindow.onbeforeunload = () => {
        setPopupOpened(false);
        localStorage.removeItem(`A24_POPUP_${ticketID}`);
      };
      popupWindow.addEventListener("tickets_status_change", () => {
        window.dispatchEvent(new Event("tickets_status_change"));
      });
    };
  };

  const isEditable = !popupOpened && currentTicket.status_code === TICKET_STATUS_OPEN_ID;
  const isDeletable =
    currentTicket.ticket_details &&
    currentTicket.ticket_details.length &&
    currentTicket.ticket_details.filter((x) => x.outgoing === 1).length === 0;

  return (
    <Collapse in={openTicketID === currentTicket.id} timeout="auto" unmountOnExit>
      <MDBox
        p={2}
        sx={{
          whiteSpace: "normal",
          overflow: "auto",
          width: "100%",
          maxWidth: "var(--a24-content-width)",
        }}
        id={`TicketView_${currentTicket.id}`}
      >
        <MDBox lineHeight={0} sx={{ display: "flex", justifyContent: "space-between" }} mb={2}>
          <MDTypography variant="h5" fontWeight="medium">
            {currentTicket.subject}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" sx={{ position: "relative" }}>
          {isEditable &&
          !replyVia &&
          currentTicket.assigned_to &&
          currentTicket.assigned_to === userId ? (
            <Tooltip title={t("Reply")}>
              <IconButton
                color="warning"
                disabled={!currentTicket.customer_email}
                sx={{ marginLeft: 1 }}
                onClick={() => {
                  handleNewReply();
                }}
              >
                <Icon>reply</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          {isEditable && currentTicket.status_code === TICKET_STATUS_OPEN_ID ? (
            <Tooltip title={currentTicket.assigned_to ? t("Re-assign") : t("Assign")}>
              <IconButton
                color="info"
                aria-describedby={`menu_${currentTicket.id}`}
                sx={{ marginLeft: 1 }}
                onClick={(event) => {
                  openMenu(event, currentTicket);
                }}
              >
                <Icon>assignment_ind</Icon>
              </IconButton>
            </Tooltip>
          ) : null}
          {isEditable && currentTicket.assigned_to && currentTicket.assigned_to === userId ? (
            <>
              <Tooltip title={t("Attention")}>
                <IconButton
                  color="primary"
                  aria-describedby={`menu_attention_${currentTicket.id}`}
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    setOpenAttention(true);
                  }}
                >
                  <Icon>notifications</Icon>
                </IconButton>
              </Tooltip>
              <CreateTask
                open={openAttention}
                onSave={handleAttention}
                onCancel={setOpenAttention}
              />
            </>
          ) : null}

          {!popupOpened ? (
            <>
              {currentTicket.assigned_to &&
              currentTicket.assigned_to === userId &&
              currentTicket.status_code === TICKET_STATUS_CLOSE_ID ? (
                <Tooltip title={t("Reopen")}>
                  <IconButton
                    color="success"
                    sx={{ marginLeft: 1 }}
                    onClick={() => {
                      handleReopenTicket();
                    }}
                  >
                    <Icon>lock_open</Icon>
                  </IconButton>
                </Tooltip>
              ) : null}

              {currentTicket.assigned_to &&
              currentTicket.assigned_to === userId &&
              currentTicket.status_code === TICKET_STATUS_OPEN_ID ? (
                <Tooltip title={t("Resolve")}>
                  <IconButton
                    color="success"
                    sx={{ marginLeft: 1 }}
                    onClick={() => {
                      handleCloseTicket();
                    }}
                  >
                    <Icon>check_circle</Icon>
                  </IconButton>
                </Tooltip>
              ) : null}
              {isDeletable ? (
                <Tooltip title={t("Delete")}>
                  <IconButton
                    color="error"
                    sx={{ marginLeft: 1 }}
                    onClick={() => {
                      handleDeleteTicket(currentTicket.id);
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Tooltip>
              ) : null}
              {!isPopup ? (
                <Tooltip title={t("In new window")}>
                  <IconButton
                    color="black"
                    onClick={(event) => {
                      event.stopPropagation();
                      openNewWindow(currentTicket.id);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Icon>open_in_new</Icon>
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          ) : null}
        </MDBox>
        {attentions && attentions.length ? (
          <MDBox mt={1} sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <MDTypography
              variant="button"
              fontWeight="medium"
              sx={{ minWidth: 40, marginBottom: 1 }}
            >
              {t("Attentions")}:
            </MDTypography>
            {attentions.map((item, key) => (
              <Chip
                key={key}
                label={item.task_user.name ? item.task_user.name : item.task_user.email}
                variant="outlined"
                sx={{ marginLeft: 1, marginBottom: 1, fontWeight: 400 }}
                onDelete={() => {
                  handleAttentionDelete(item.id);
                }}
              />
            ))}
          </MDBox>
        ) : null}
        {isEditable && replyVia === "EMAIL" ? (
          <>
            <MDBox
              lineHeight={0}
              sx={{
                display: "flex",
                minWidth: "100%",
                margin: "12px 0",
              }}
            >
              <MDBox display="flex" alignItems="center" lineHeight={1}>
                <MDTypography variant="button" fontWeight="regular" color="black">
                  {t("From")}:
                </MDTypography>
                <MDBox ml={2} lineHeight={1}>
                  <MDTypography display="block" variant="button" fontWeight="medium" color="black">
                    {replyFrom?.name ? replyFrom?.name : replyFrom?.email}
                  </MDTypography>
                  {replyFrom?.name ? (
                    <MDTypography
                      variant="caption"
                      className="sub-title-email"
                      fontWeight="regular"
                      color="black"
                    >
                      {replyFrom?.email}
                    </MDTypography>
                  ) : null}
                </MDBox>
              </MDBox>
              <MDBox display="flex" alignItems="center" lineHeight={1}>
                <IconButton
                  onClick={(event) => {
                    openFromMenu(event, currentTicket);
                  }}
                >
                  <Icon>arrow_drop_down</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
            <MDBox mt={1} mb={1} sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="black"
                sx={{ minWidth: 40 }}
              >
                {t("To")}:
              </MDTypography>
              <Chip
                label={currentTicket.customer_email}
                variant="outlined"
                sx={{ marginLeft: 1, fontWeight: 400 }}
              />
            </MDBox>
            <MDBox
              sx={{
                padding: "8px 16px 16px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                backgroundColor: "#fff",
              }}
            >
              <MDBox py={2}>
                <CKEditor
                  value={replyEmail || ""}
                  placeholder={t("Type here")}
                  onChange={(event, value) => {
                    setReplyEmail(value);
                    handleDirty(currentTicket.id);
                  }}
                  setRef={(ref) => {
                    editorRef.current = ref;
                  }}
                />
              </MDBox>
              {files.length ? (
                <MDBox display="flex" flexDirection="column">
                  {files.map((item, key) => (
                    <Chip
                      key={key}
                      label={`${item.filename} (${Math.round(item.size / 1024)} KB)`}
                      sx={{
                        marginBottom: 1,
                        fontWeight: 400,
                        justifyContent: "space-between",
                      }}
                      onDelete={() => {
                        handleFileDelete(item.filename);
                      }}
                    />
                  ))}
                </MDBox>
              ) : null}
              <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                    paddingTop: 2,
                  }}
                >
                  <Tooltip title={t("Attachments")}>
                    <IconButton
                      color="warning"
                      sx={{ marginRight: 1 }}
                      onClick={() => {
                        handleAddFile();
                      }}
                    >
                      <Icon>attachment</Icon>
                    </IconButton>
                  </Tooltip>
                  <input
                    multiple
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={handelChangeFile}
                  />
                  <Tooltip title={t("Templates")}>
                    <IconButton
                      color="dark"
                      sx={{ marginRight: 1 }}
                      aria-describedby={`menu_template_${currentTicket.id}`}
                      onClick={(event) => {
                        openTemplateMenu(event, currentTicket);
                      }}
                    >
                      <Icon>code_blocks</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Signature")}>
                    <IconButton
                      color="primary"
                      sx={{ marginRight: 1 }}
                      aria-describedby={`menu_signature_${currentTicket.id}`}
                      onClick={(event) => {
                        openSignatureMenu(event, currentTicket);
                      }}
                    >
                      <Icon baseClassName="material-symbols-outlined">signature</Icon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
                <MDBox
                  sx={{
                    paddingTop: 2,
                  }}
                >
                  <Tooltip title={t("Discard draft")}>
                    <IconButton
                      color="error"
                      sx={{ marginLeft: 1 }}
                      onClick={() => {
                        handleDiscardDraft();
                      }}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Save as draft")}>
                    <IconButton
                      color="info"
                      sx={{ marginLeft: 1 }}
                      disabled={!replyEmail}
                      onClick={() => {
                        handleSaveDraft();
                      }}
                    >
                      <Icon>save</Icon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Send")}>
                    <IconButton
                      color="success"
                      sx={{ marginLeft: 1 }}
                      disabled={!replyEmail}
                      onClick={() => {
                        handleReplyEmail();
                      }}
                    >
                      <Icon>send</Icon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
            </MDBox>
          </>
        ) : null}

        <Menu
          id={`menu_${currentTicket.id}`}
          anchorReference="anchorPosition"
          anchorPosition={
            menu !== null ? { top: menu.position.top, left: menu.position.left } : undefined
          }
          open={!!(menu && currentTicket.id === menu.cell.id)}
          onClose={closeMenu}
        >
          <MenuItem
            disabled
            sx={{
              opacity: "1 !important",
              fontWeight: "bold",
              backgroundColor: "#c3c3c3",
              borderRadius: 0,
              color: "#000",
              marginBottom: "8px",
            }}
          >
            {t("Assign to")} :
          </MenuItem>
          {currentTicket.assigned_to !== userId ? (
            <>
              {users
                .filter((item) => item.id === userId && !item.generic)
                .map((user, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleReAssign(user.id);
                    }}
                  >
                    {user.name}
                  </MenuItem>
                ))}
              <Divider />
            </>
          ) : null}

          {users
            .filter(
              (item) => item.id !== userId && !item.generic && item.id !== currentTicket.assigned_to
            )
            .map((user, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleReAssign(user.id);
                }}
              >
                {user.name}
              </MenuItem>
            ))}
        </Menu>
        <Menu
          id={`menu_from_${currentTicket.id}`}
          anchorReference="anchorPosition"
          anchorPosition={
            fromMenu !== null
              ? { top: fromMenu.position.top, left: fromMenu.position.left }
              : undefined
          }
          open={!!(fromMenu && currentTicket.id === fromMenu.cell.id)}
          onClose={closeFromMenu}
        >
          <>
            {users
              .filter((item) => item.id === userId)
              .map((user, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setReplyFrom(user);
                    closeFromMenu();
                  }}
                >
                  {user.name} ({user.email})
                </MenuItem>
              ))}
            <Divider />
          </>
          {users
            .filter((item) => item.generic && item.id !== userId)
            .map((user, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setReplyFrom(user);
                  closeFromMenu();
                }}
              >
                {user.name} ({user.email})
              </MenuItem>
            ))}
        </Menu>
        <Menu
          id={`menu_template_${currentTicket.id}`}
          anchorReference="anchorPosition"
          anchorPosition={
            templateMenu !== null
              ? {
                  top: templateMenu.position.top,
                  left: templateMenu.position.left,
                }
              : undefined
          }
          open={!!(templateMenu && currentTicket.id === templateMenu.cell.id)}
          onClose={closeTemplateMenu}
        >
          {templates.map((template, tempIndex) => (
            <MenuItem
              key={tempIndex}
              onClick={() => {
                handleTemplate(template);
              }}
            >
              {template.name} ({template.language})
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id={`menu_signature_${currentTicket.id}`}
          anchorReference="anchorPosition"
          anchorPosition={
            signatureMenu !== null
              ? {
                  top: signatureMenu.position.top,
                  left: signatureMenu.position.left,
                }
              : undefined
          }
          open={!!(signatureMenu && currentTicket.id === signatureMenu.cell.id)}
          onClose={closeSignatureMenu}
        >
          {signatures.map((signature, tempIndex) => (
            <MenuItem
              key={tempIndex}
              onClick={() => {
                handleSignature(signature);
              }}
            >
              {signature.name} ({signature.language})
            </MenuItem>
          ))}
        </Menu>
        {popupOpened && currentTicket.status_code === TICKET_STATUS_OPEN_ID ? (
          <MDBox p={2}>
            <Alert severity="warning">
              {t("You are currently editing your reply in a separate window.")}
            </Alert>
          </MDBox>
        ) : null}
        {currentTicket.ticket_details?.map((detail, index) => (
          <Accordion
            defaultExpanded={index === 0}
            disableGutters
            sx={{
              boxShadow: "none",
              margin: "0px !important",
              backgroundColor: "transparent",
            }}
            disabled={index === 0}
            key={index}
            className="ticket_details_accordion"
          >
            <AccordionSummary expandIcon={null} sx={{ padding: 0 }}>
              <MDBox
                lineHeight={0}
                sx={{ display: "flex", justifyContent: "space-between", minWidth: "100%" }}
              >
                {detail.interaction_type === TICKET_INTERACTION_TYPE_EMAIL ||
                !detail.interaction_type ? (
                  <MDBox display="flex" alignItems="center" lineHeight={1}>
                    <MDTypography variant="button" fontWeight="regular" color="black">
                      {t("From")}:
                    </MDTypography>
                    <MDBox ml={2} lineHeight={1}>
                      <MDTypography
                        display="block"
                        variant="button"
                        fontWeight="medium"
                        color="black"
                      >
                        {detail.outgoing
                          ? detail.agent_name
                            ? detail.agent_name
                            : detail.agent_email
                          : detail.customer_name
                          ? detail.customer_name
                          : detail.customer_email}
                      </MDTypography>
                      {detail.outgoing && detail.agent_name ? (
                        <MDTypography
                          variant="caption"
                          className="sub-title-email"
                          fontWeight="regular"
                          color="black"
                        >
                          {detail.agent_email}
                        </MDTypography>
                      ) : null}
                      {!detail.outgoing && detail.customer_name ? (
                        <MDTypography
                          variant="caption"
                          className="sub-title-email"
                          fontWeight="regular"
                          color="black"
                        >
                          {detail.customer_email}
                        </MDTypography>
                      ) : null}
                      <MDTypography
                        variant="caption"
                        className="sub-title"
                        color="black"
                        fontWeight="regular"
                      >
                        {detail.content && detail.content.replace(/<[^>]+>/g, "").substring(0, 50)}
                        {detail.content && detail.content.replace(/<[^>]+>/g, "").length > 50
                          ? "..."
                          : ""}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ) : null}
                {detail.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
                  <MDBox display="flex" alignItems="center" lineHeight={1}>
                    <MDTypography variant="button" fontWeight="regular" color="black">
                      {t("From")}:
                    </MDTypography>
                    <MDBox ml={2} lineHeight={1}>
                      <MDTypography display="block" variant="button" fontWeight="medium">
                        {detail.customer_name
                          ? detail.customer_name
                          : CommonFun.displayPhone(detail.from_phone)}
                      </MDTypography>
                      <MDTypography variant="caption">
                        {detail.customer_name ? CommonFun.displayPhone(detail.from_phone) : ""}
                      </MDTypography>
                    </MDBox>
                    <Icon color="info" sx={{ marginLeft: 1 }}>
                      phone_callback
                    </Icon>
                  </MDBox>
                ) : null}
                {detail.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
                  <MDBox display="flex" alignItems="center" lineHeight={1}>
                    <MDTypography variant="button" fontWeight="regular" color="black">
                      {t("From")}:
                    </MDTypography>
                    <MDBox ml={2} lineHeight={1}>
                      <MDTypography display="block" variant="button" fontWeight="medium">
                        {detail.customer_name ? detail.customer_name : "New Customer"}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ) : null}
                <MDBox
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column"
                  lineHeight={1}
                  sx={{ minWidth: "100px" }}
                >
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="regular"
                    className="sub-title"
                    style={{ lineHeight: "20px" }}
                  >
                    {moment.utc(detail.created_at).local().format(CommonFun.getDateTimeFormat())}
                  </MDTypography>
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="regular"
                    style={{ lineHeight: "20px" }}
                    className="sub-title-email"
                  >
                    {moment.utc(detail.created_at).local().format(CommonFun.getDateTimeFormat())}
                  </MDTypography>
                  {(!detail.interaction_type ||
                    detail.interaction_type === TICKET_INTERACTION_TYPE_EMAIL) &&
                  !detail.message_id ? (
                    <MDBadge badgeContent={t("Draft")} color="error" variant="gradient" size="sm" />
                  ) : null}
                </MDBox>
              </MDBox>
            </AccordionSummary>
            {!detail.interaction_type ||
            detail.interaction_type === TICKET_INTERACTION_TYPE_EMAIL ? (
              <AccordionDetails
                sx={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  backgroundColor: " #fff",
                  overflow: "auto",
                  width: "100%",
                  maxWidth: "var(--a24-content-width)",
                }}
              >
                <MDBox>
                  {detail.to_list ? (
                    <MDBox mt={1} sx={{ display: "flex", alignItems: "center" }}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="black"
                        sx={{ minWidth: 40 }}
                      >
                        {t("To")}:
                      </MDTypography>
                      {detail.to_list &&
                        detail.to_list
                          .split(",")
                          .map((item, key) => (
                            <Chip
                              key={key}
                              label={item}
                              variant="outlined"
                              sx={{ marginLeft: 1, fontWeight: 400 }}
                            />
                          ))}
                    </MDBox>
                  ) : (
                    <MDBox mt={1} sx={{ display: "flex", alignItems: "center" }}>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="black"
                        sx={{ minWidth: 40 }}
                      >
                        {t("To")}:
                      </MDTypography>
                      <Chip
                        label={detail.outgoing ? detail.customer_email : detail.agent_email}
                        variant="outlined"
                        sx={{ marginLeft: 1, fontWeight: 400 }}
                      />
                      {detail.outgoing && detail.message_id ? (
                        <Tooltip title={t("Delivery Statistics")}>
                          <IconButton
                            color="info"
                            size="small"
                            sx={{ marginLeft: 1 }}
                            onClick={() => {
                              handleOpenMailInfo(detail.message_id);
                            }}
                          >
                            <Icon>notes</Icon>
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </MDBox>
                  )}
                  {detail.cc_list ? (
                    <MDBox mt={1} sx={{ display: "flex", alignItems: "center" }}>
                      <MDTypography variant="button" fontWeight="regular" sx={{ minWidth: 40 }}>
                        {t("Cc")}:
                      </MDTypography>
                      {detail.cc_list &&
                        detail.cc_list
                          .split(",")
                          .map((item, key) => (
                            <Chip
                              key={key}
                              label={item}
                              variant="outlined"
                              sx={{ marginLeft: 1, fontWeight: 400 }}
                            />
                          ))}
                    </MDBox>
                  ) : null}
                </MDBox>
                {detail.message_id || detail.user_id !== userId || !isEditable ? (
                  <>
                    <MDBox
                      mt={3}
                      lineHeight={1}
                      sx={{
                        overflow: "auto",
                        width: "100%",
                        maxHeight: "400px",
                      }}
                    >
                      <MDTypography variant="button" color="black" fontWeight="regular">
                        <div className="MuiTypography-root MuiTypography-button">
                          {parse(
                            detail.content_html_replace
                              ? detail.content_html_replace
                              : detail.content_html
                              ? detail.content_html
                              : detail.content,
                            {
                              replace: ({ type }) => {
                                if (type === "style" || type === "script") {
                                  return <></>;
                                }
                                return null;
                              },
                            }
                          )}
                        </div>
                      </MDTypography>
                    </MDBox>
                    {detail.attachments &&
                    Array.isArray(detail.attachments) &&
                    detail.attachments.length ? (
                      <MDBox mt={2}>
                        <MDTypography variant="button" fontWeight="bold" color="black">
                          {t("Attachments")}:
                        </MDTypography>
                        <MDBox display="flex" p={2}>
                          {detail.attachments.map((attachment, key) => (
                            <MDBox display="flex" p={2} key={key}>
                              <Card
                                sx={{
                                  padding: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  border: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                                onClick={() => {
                                  openAttachments(attachment.key);
                                }}
                              >
                                <Icon>attachments</Icon>
                                <MDTypography
                                  mt={3}
                                  variant="button"
                                  fontWeight="regular"
                                  color="black"
                                >
                                  {attachment.filename}
                                </MDTypography>
                              </Card>
                            </MDBox>
                          ))}
                        </MDBox>
                      </MDBox>
                    ) : null}
                  </>
                ) : null}
                {index === 0 ? (
                  <>
                    {isEditable &&
                    !replyVia &&
                    currentTicket.assigned_to &&
                    currentTicket.assigned_to === userId ? (
                      <MDBox
                        py={4}
                        display="flex"
                        alignItems="center"
                        id={`Replay_Wrap_${currentTicket.id}`}
                      >
                        <MDTypography
                          variant="button"
                          color="black"
                          fontWeight="regular"
                          sx={{ marginRight: 1 }}
                        >
                          {t("Reply")}:
                        </MDTypography>
                        <Tooltip title={t("Reply")}>
                          <IconButton
                            color="warning"
                            sx={{ marginRight: 1 }}
                            disabled={!currentTicket.customer_email}
                            onClick={() => {
                              handleNewReply();
                            }}
                          >
                            <Icon>reply</Icon>
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    ) : null}
                    {isEditable && replyVia === "DRAFT" ? (
                      <>
                        <MDBox py={2}>
                          <CKEditor
                            value={replyEmail || ""}
                            placeholder={t("Type here")}
                            onChange={(event, value) => {
                              setReplyEmail(value);
                              handleDirty(currentTicket.id);
                            }}
                            setRef={(ref) => {
                              editorRef.current = ref;
                            }}
                          />
                        </MDBox>
                        {files.length ? (
                          <MDBox display="flex" flexDirection="column">
                            {files.map((item, key) => (
                              <Chip
                                key={key}
                                label={`${item.filename} (${Math.round(item.size / 1024)} KB)`}
                                sx={{
                                  marginBottom: 1,
                                  fontWeight: 400,
                                  justifyContent: "space-between",
                                }}
                                onDelete={() => {
                                  handleFileDelete(item.filename);
                                }}
                              />
                            ))}
                          </MDBox>
                        ) : null}
                        <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
                          <MDBox
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flex: 1,
                              paddingTop: 2,
                            }}
                          >
                            <Tooltip title={t("Attachments")}>
                              <IconButton
                                color="warning"
                                sx={{ marginRight: 1 }}
                                onClick={() => {
                                  handleAddFile();
                                }}
                              >
                                <Icon>attachment</Icon>
                              </IconButton>
                            </Tooltip>
                            <input
                              multiple
                              type="file"
                              id="file"
                              ref={inputFile}
                              style={{ display: "none" }}
                              onChange={handelChangeFile}
                            />
                            <Tooltip title={t("Templates")}>
                              <IconButton
                                color="dark"
                                sx={{ marginRight: 1 }}
                                aria-describedby={`menu_template_${currentTicket.id}`}
                                onClick={(event) => {
                                  openTemplateMenu(event, currentTicket);
                                }}
                              >
                                <Icon>code_blocks</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Signature")}>
                              <IconButton
                                color="primary"
                                sx={{ marginRight: 1 }}
                                aria-describedby={`menu_signature_${currentTicket.id}`}
                                onClick={(event) => {
                                  openSignatureMenu(event, currentTicket);
                                }}
                              >
                                <Icon baseClassName="material-symbols-outlined">signature</Icon>
                              </IconButton>
                            </Tooltip>
                          </MDBox>
                          <MDBox
                            sx={{
                              paddingTop: 2,
                            }}
                          >
                            <Tooltip title={t("Discard draft")}>
                              <IconButton
                                color="error"
                                sx={{ marginLeft: 1 }}
                                onClick={() => {
                                  handleDiscardDraft();
                                }}
                              >
                                <Icon>delete</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Save as draft")}>
                              <IconButton
                                color="info"
                                disabled={!replyEmail}
                                sx={{ marginLeft: 1 }}
                                onClick={() => {
                                  handleSaveDraft();
                                }}
                              >
                                <Icon>save</Icon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Send")}>
                              <IconButton
                                color="success"
                                disabled={!replyEmail}
                                sx={{ marginLeft: 1 }}
                                onClick={() => {
                                  handleReplyEmail();
                                }}
                              >
                                <Icon>send</Icon>
                              </IconButton>
                            </Tooltip>
                          </MDBox>
                        </MDBox>
                      </>
                    ) : null}
                    {popupOpened && currentTicket.status_code === TICKET_STATUS_OPEN_ID ? (
                      <MDBox p={2}>
                        <Alert severity="warning">
                          {t("You are currently editing your reply in a separate window.")}
                        </Alert>
                      </MDBox>
                    ) : null}
                  </>
                ) : null}
              </AccordionDetails>
            ) : (
              <AccordionDetails
                sx={{
                  overflow: "auto",
                  width: "100%",
                  maxWidth: "var(--a24-content-width)",
                }}
              >
                {index === 0 ? (
                  <>
                    {isEditable &&
                    !replyVia &&
                    currentTicket.assigned_to &&
                    currentTicket.assigned_to === userId ? (
                      <MDBox
                        py={4}
                        display="flex"
                        alignItems="center"
                        id={`Replay_Wrap_${currentTicket.id}`}
                      >
                        <MDTypography
                          variant="button"
                          color="black"
                          fontWeight="regular"
                          sx={{ marginRight: 1 }}
                        >
                          {t("Reply")}:
                        </MDTypography>
                        <Tooltip title={t("Reply")}>
                          <IconButton
                            color="warning"
                            sx={{ marginRight: 1 }}
                            disabled={!currentTicket.customer_email}
                            onClick={() => {
                              handleNewReply();
                            }}
                          >
                            <Icon>reply</Icon>
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    ) : null}
                    {popupOpened && currentTicket.status_code === TICKET_STATUS_OPEN_ID ? (
                      <MDBox p={2}>
                        <Alert severity="warning">
                          {t("You are currently editing your reply in a separate window.")}
                        </Alert>
                      </MDBox>
                    ) : null}
                  </>
                ) : null}
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </MDBox>
      <Dialog open={Boolean(deliveryStatistics)} fullWidth maxWidth="sm">
        <DialogTitle>{t("Delivery Statistics")}</DialogTitle>
        <DialogContent dividers>
          {deliveryStatistics?.map((item, key) => (
            <ProfileInfoCard
              key={key}
              info={{ mailAction: mailActions[item.mail_action], ...JSON.parse(item.details) }}
              shadow={false}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setDeliveryStatistics(null);
            }}
          >
            {t("Close")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </Collapse>
  );
}

export default memo(Ticket);
