/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Progress from "components/Progress";
import { InputText, InputDropdown } from "components/MDInputs";

import ServerSideTable from "components/Tables/ServerSideTable";
import ERPAPI from "services/erp";

function ContactPersons({ customerID }) {
  const { t } = useTranslation("common");
  const [contactPersons, setContactPersons] = useState([]);
  const [currentContactPerson, setCurrentContactPerson] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  // MASTER DATA
  const [salutations, setSalutations] = useState([]);

  const getMasterData = async () => {
    const ERPResponse = await ERPAPI.getSalutations();
    if (ERPResponse) {
      setSalutations(ERPResponse);
    }
  };

  const getCustomerContacts = async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerContacts(
        customerID.replace("erp-", ""),
        page,
        pageSize,
        orderBy,
        order
      );
      if (
        ERPResponse &&
        ERPResponse.data &&
        ERPResponse.data.items &&
        ERPResponse.data.items.length
      ) {
        setContactPersons([...ERPResponse.data.items]);
        setCount(ERPResponse.data.totalCount);
      } else {
        setContactPersons([]);
      }
      setLoading(false);
    } else {
      setContactPersons([]);
    }
  };

  useEffect(() => {
    getMasterData();
  }, []);

  useEffect(() => {
    getCustomerContacts();
  }, [customerID, page, pageSize, orderBy, order]);

  const columns = [
    {
      header: t("Salutation"),
      accessor: "salutation",
      align: "left",
      sort: true,
    },
    {
      header: t("Title"),
      accessor: "title",
      align: "left",
      sort: true,
    },
    {
      header: t("First Name"),
      accessor: "firstName",
      align: "left",
      sort: true,
    },
    {
      header: t("Last Name"),
      accessor: "lastName",
      align: "left",
      sort: true,
    },
    {
      header: t("Department"),
      accessor: "department",
      align: "left",
      sort: true,
    },
    {
      header: t("Telephone"),
      accessor: "phone",
      align: "left",
      sort: true,
    },
    {
      header: t("Mobile"),
      accessor: "mobile",
      align: "left",
      sort: true,
    },
    {
      header: t("Email"),
      accessor: "email",
      align: "left",
      sort: true,
    },
    {
      header: t("Fax"),
      accessor: "fax",
      align: "left",
      sort: true,
    },
    {
      header: t("Note"),
      accessor: "note",
      align: "center",
      sort: false,
      render: (row) => (
        <>
          {row.note ? (
            <IconButton color="success">
              <Icon>search</Icon>
            </IconButton>
          ) : null}
        </>
      ),
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onChange = (name, value) => {
    setCurrentContactPerson({
      ...currentContactPerson,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (customerID.includes("erp-")) {
      setSaveLoading(true);
      const ERPResponse = await ERPAPI.createCustomerContact(
        customerID.replace("erp-", ""),
        currentContactPerson
      );
      if (ERPResponse && ERPResponse.data) {
        getCustomerContacts();
        setCurrentContactPerson(null);
        setOpen(false);
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "OK",
              title: "Contact",
              content: "Contact add successfully",
            },
          })
        );
      }
      setSaveLoading(false);
    }
  };

  return (
    <Card sx={{ overflow: "hidden" }}>
      <Progress loading={loading} isFull />
      <MDBox px={3} py={1} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h5" fontWeight="medium">
          {t("Contact Person")}
        </MDTypography>
        <MDButton
          variant="gradient"
          color="success"
          size="small"
          onClick={() => {
            setCurrentContactPerson({});
            setOpen(true);
          }}
        >
          {t("Add")}
        </MDButton>
      </MDBox>
      <MDBox>
        <ServerSideTable
          rowsPerPage={pageSize}
          page={page}
          columns={columns}
          data={contactPersons}
          count={count}
          order={order}
          orderBy={orderBy}
          onRequestSort={(key) => {
            const isAsc = orderBy === key && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(key);
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          // eslint-disable-next-line no-unused-vars
          getRowProps={(row) => ({
            onClick: () => {
              // setCurrentContactPerson(row);
              // setOpen(true);
            },
            style: {
              cursor: "pointer",
            },
          })}
        />
      </MDBox>
      <Dialog open={open} fullWidth maxWidth="md">
        <Progress loading={saveLoading} />
        <DialogTitle>
          {currentContactPerson && currentContactPerson.id
            ? t("Edit Contact Person")
            : t("Add Contact Person")}
        </DialogTitle>
        <DialogContent dividers>
          {currentContactPerson ? (
            <MDBox py={2}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <InputDropdown
                        label={t("Salutation")}
                        options={salutations}
                        name="salutation"
                        onChange={onChange}
                        value={currentContactPerson.salutation}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <InputText
                        label={t("Title")}
                        name="title"
                        value={currentContactPerson.title}
                        onChange={onChange}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <InputText
                        label={t("Full Name")}
                        name="fullName"
                        value={currentContactPerson.fullName}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("First Name")}
                        name="firstName"
                        value={currentContactPerson.firstName}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Last Name")}
                        name="lastName"
                        value={currentContactPerson.lastName}
                        onChange={onChange}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <InputTextArea
                        label={t("Note")}
                        name="note"
                        value={currentContactPerson.note}
                        onChange={onChange}
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Phone")}
                        name="phone"
                        value={currentContactPerson.phone}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Mobile")}
                        name="mobile"
                        value={currentContactPerson.mobile}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Email")}
                        name="email"
                        value={currentContactPerson.email}
                        onChange={onChange}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <InputText
                        label={t("Fax")}
                        name="fax"
                        value={currentContactPerson.fax}
                        onChange={onChange}
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setCurrentContactPerson(null);
              setOpen(false);
            }}
          >
            {t("Cancel")}
          </MDButton>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              handleSave();
            }}
          >
            {currentContactPerson && currentContactPerson.id ? t("Update") : t("Save")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default memo(ContactPersons);
