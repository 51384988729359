import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

import CommonFun from "services/common";

export default function Item() {
  const navigate = useNavigate();
  const { articleNumber, tabId } = useParams();
  const tabs = ["basics", "shops"];

  useEffect(async () => {
    const tabIndex = tabs.findIndex((t) => t === tabId);
    CommonFun.onClickItem(articleNumber, tabIndex);
    navigate("/home");
  }, []);

  return (
    <MDBox>
      <Progress loading isFull />
    </MDBox>
  );
}
