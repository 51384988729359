/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import useLocalStorage from "hooks/useLocalStorage";
import MDBox from "components/MDBox";
import TicketCard from "components/Cards/StatisticsCards/TicketCard";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import ServerSideTable from "components/Tables/ServerSideTable";
import Progress from "components/Progress";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";

import TicketsAPI from "services/tickets";
import ERPAPI from "services/erp";
import UsersAPI from "services/users";
import CustomersAPI from "services/customers";
import CommonFun from "services/common";
import {
  TICKET_STATUS_OPEN_ID,
  TICKET_STATUS_CLOSE_ID,
  USER_ROLE_ADMIN,
  USER_ROLE_CRM,
  TICKET_INTERACTION_TYPE_EMAIL,
  TICKET_INTERACTION_TYPE_PHONE,
  TICKET_INTERACTION_TYPE_CHAT,
} from "environment";

function Tickets() {
  const { t } = useTranslation("common");
  const [type, setType] = useState("ALL");
  const [region] = useLocalStorage("A24_REGION", 0);
  const [tickets, setTickets] = useState([]);
  const [status, setStatus] = useState(TICKET_STATUS_OPEN_ID);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [unassignedCount, setUnassignedCount] = useState(0);
  const [mineCount, setMineCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchObject, setSearchObject] = useState({});
  const [searchResult, setSearchResult] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasNewTicket, setHasNewTicket] = useState(false);
  const [lastHit, setLastHit] = useState(null);
  const [lastRefreshAt, setLastRefreshAt] = useState(null);
  const [clearAll, setClearAll] = useState(false);
  const [selectedColumns] = useState([
    "created_at",
    "updated_at",
    "id",
    "email",
    "from_phone",
    "assigned_to",
    "interaction_type",
  ]);
  const searchableColumnsLabels = {
    id: t("Ticket ID"),
    email: t("Email"),
    created_at: t("Created At"),
    updated_at: t("Updated At"),
    created_at_start: `${t("Created At")} ${t("Start")}`,
    updated_at_start: `${t("Updated At")} ${t("Start")}`,
    created_at_end: `${t("Created At")} ${t("End")}`,
    updated_at_end: `${t("Updated At")} ${t("End")}`,
    subject: t("Title"),
    assigned_to: t("Assigned to"),
    from_phone: t("Phone"),
    interaction_type: t("Type"),
  };

  const getLocations = () => {
    const locationStr = localStorage.getItem("A24_LOCATION");
    let locations = [];
    if (locationStr && locationStr !== "") {
      locations = JSON.parse(locationStr);
      locations = locations.map((item) => item.code);
    }
    return locations;
  };

  const getTickets = async () => {
    setLoading(true);
    setOpen(false);
    let advancedSearchOn = false;
    const data = {};
    const locations = getLocations();
    if (locations.length) {
      data.locations = locations;
    }
    if (search) {
      for (const property in searchObject) {
        if (searchObject[property]) {
          data[property] = searchObject[property];
          advancedSearchOn = true;
        }
      }
    }
    if (type === "UNASSIGNED") {
      data.assigned_to = null;
    } else if (type === "MINE") {
      data.assigned_to = localStorage.getItem("A24_USER_ID");
      delete data.locations;
    } else if (data.assigned_to) {
      delete data.locations;
    }
    if (status) {
      data.status_code = status;
    }
    setSearchResult(advancedSearchOn);
    const response = await TicketsAPI.getTicketsByFilter(rowsPerPage, page, data);
    if (response && response.data) {
      setTickets(response.data);
      setCount(response.total);
      if (
        response.data.length &&
        type === "ALL" &&
        status === TICKET_STATUS_OPEN_ID &&
        page === 0 &&
        search === false
      ) {
        setLastHit(response.data[0].created_at);
        setHasNewTicket(null);
        setLastRefreshAt(moment());
      }
    } else {
      setTickets([]);
      setCount(0);
    }
    setLoading(false);
  };

  const getVerifiedUsers = async () => {
    const response = await UsersAPI.getVerifiedUsers();
    if (response && response.data) {
      const userList = response.data.filter(
        (item) =>
          item.region === region &&
          item.email_verified &&
          !item.generic &&
          item.roles.length &&
          item.roles.filter((e) => e.name === USER_ROLE_ADMIN || e.name === USER_ROLE_CRM).length
      );
      userList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setUsers(userList);
    } else {
      setUsers([]);
    }
  };

  const getTicketsCount = async () => {
    const data = { status_code: TICKET_STATUS_OPEN_ID };
    const locations = getLocations();
    if (locations.length) {
      data.locations = locations;
    }
    let response = await TicketsAPI.getTicketsByFilter(1, 0, data);
    if (response && response.data) {
      setAllCount(response.total);
    } else {
      setAllCount(0);
    }
    response = await TicketsAPI.getTicketsByFilter(1, 0, {
      ...data,
      assigned_to: null,
    });
    if (response && response.data) {
      setUnassignedCount(response.total);
    } else {
      setUnassignedCount(0);
    }
    delete data.locations;
    response = await TicketsAPI.getTicketsByFilter(1, 0, {
      ...data,
      assigned_to: localStorage.getItem("A24_USER_ID"),
    });
    if (response && response.data) {
      setMineCount(response.total);
    } else {
      setMineCount(0);
    }
  };

  const findNewTicket = async () => {
    const data = { status_code: TICKET_STATUS_OPEN_ID };
    const locations = getLocations();
    if (locations.length) {
      data.locations = locations;
    }
    if (lastHit) {
      data.created_at_start = moment.utc(lastHit).add(1, "s").format("YYYY-MM-DD HH:mm:ss");
      data.created_at_end = moment.utc().format("YYYY-MM-DD HH:mm:ss");
    }
    const response = await TicketsAPI.getTicketsByFilter(10, 0, data);
    if (response && response.data && response.data.length) {
      if (!lastHit) {
        setLastHit(response.data[0].created_at);
      } else {
        setHasNewTicket(response.total);
      }
    }
    setLastRefreshAt(moment());
  };

  useEffect(async () => {
    getTickets();
  }, [page, rowsPerPage, type, status, search, clearAll]);

  useEffect(() => {
    const onTabChange = (event) => {
      if (event.detail.type === "TICKETS") {
        getTicketsCount();
        if (searchResult) {
          getTickets();
        } else {
          setSearch(false);
        }
      }
    };
    window.addEventListener("tabChange", onTabChange);
    return () => window.removeEventListener("tabChange", onTabChange);
  }, [getTickets, getTicketsCount]);

  useEffect(() => {
    const onLocationsChange = () => {
      getTickets();
      getTicketsCount();
    };
    window.addEventListener("location_change", onLocationsChange);
    return () => window.removeEventListener("location_change", onLocationsChange);
  }, [getTickets, getTicketsCount]);

  useEffect(async () => {
    getTicketsCount();
    getVerifiedUsers();
  }, []);

  useEffect(() => {
    const id = setInterval(findNewTicket, 2.5 * 60 * 1000);
    return () => clearInterval(id);
  }, [findNewTicket]);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetTabView = async (customerID) => {
    const tabStateStr = localStorage.getItem("A24_CUSTOMER_TAB_STATE");
    let tabStateJson = {};
    if (tabStateStr && tabStateStr !== "") {
      tabStateJson = JSON.parse(tabStateStr);
    }
    tabStateJson[customerID] = 0;
    await localStorage.setItem("A24_CUSTOMER_TAB_STATE", JSON.stringify(tabStateJson));
  };

  const onClickTicket = async (row) => {
    setLoading(true);
    let erpData = {};
    let leadData = {};
    if (row.customer_email) {
      erpData = {
        email: row.customer_email,
      };
      leadData = {
        email: row.customer_email,
      };
    } else {
      erpData = {
        phone: CommonFun.serverPhone(row.from_phone),
      };
      leadData = {
        phone: CommonFun.serverPhone(row.from_phone),
      };
    }
    const erpResponse = await ERPAPI.findCustomer(erpData);
    const response = await CustomersAPI.searchCustomer(leadData);
    const searchList = [];
    if (
      erpResponse &&
      erpResponse.data &&
      erpResponse.data.items &&
      erpResponse.data.items.length
    ) {
      erpResponse.data.items.forEach((item) => {
        if (item.customerNumber) {
          searchList.push({
            id: item.customerNumber,
            name:
              item.shippingAddress && item.shippingAddress.name2
                ? item.shippingAddress.name2
                : item.customerNumber,
            email: item.email,
            ticketID: row.id,
            lead: false,
          });
        }
      });
    }
    if (response && response.length) {
      response.forEach((item) => {
        if (item.id) {
          searchList.push({
            id: item.id,
            name: `${item.first_name ? item.first_name : ""} ${
              item.last_name ? item.last_name : ""
            }`,
            email: item.email,
            ticketID: row.id,
            lead: true,
          });
        }
      });
    }
    if (searchList.length === 0) {
      resetTabView(`new-ticket-${row.id}`);
      CommonFun.onClickCustomer(
        `new-ticket-${row.id}`,
        row.customer_name,
        row.customer_email,
        row.from_phone,
        null,
        row.id
      );
    } else if (searchList.length === 1) {
      resetTabView(`${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`);
      CommonFun.onClickCustomer(
        `${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`,
        searchList[0].name,
        searchList[0].email,
        searchList[0].phone,
        searchList[0].mobile,
        searchList[0].ticketID
      );
    } else {
      resetTabView(`${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`);
      CommonFun.onClickCustomer(
        `${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`,
        searchList[0].name,
        searchList[0].email,
        searchList[0].phone,
        searchList[0].mobile,
        searchList[0].ticketID
      );
    }
    setLoading(false);
  };

  const handleCreateTicket = () => {
    resetTabView(`new-customer`);
    CommonFun.onClickCustomer(`new-customer`, null, null, null, null, null);
  };

  const columns = [
    {
      header: "",
      accessor: "interaction_type",
      align: "center",
      width: "72px",
      render: (row) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
            <MDTypography color="success" style={{ lineHeight: "20px" }}>
              <Icon>mail</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE && !row.missed_call_reason ? (
            <MDTypography color="success" style={{ lineHeight: "20px" }}>
              <Icon>phone_in_talk</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE && row.missed_call_reason ? (
            <MDTypography color="error" style={{ lineHeight: "20px" }}>
              <Icon>phone_missed</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
            <MDTypography color="primary" style={{ lineHeight: "20px" }}>
              <Icon>chat</Icon>
            </MDTypography>
          ) : null}
          {row.attachment ? (
            <MDTypography color="black" style={{ lineHeight: "20px" }}>
              <Icon>attach_file</Icon>
            </MDTypography>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Customer"),
      accessor: "customer_name",
      align: "left",
      render: (row) => (
        <MDBox>
          {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              {row.customer_name ? (
                <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
              ) : (
                <MDAvatar size="sm" bgColor="info">
                  <Icon>person</Icon>
                </MDAvatar>
              )}
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {row.customer_name
                    ? CommonFun.truncateString(row.customer_name)
                    : row.customer_email}
                </MDTypography>
                {row.customer_name ? (
                  <MDTypography variant="caption" fontWeight="regular">
                    {row.customer_email}
                  </MDTypography>
                ) : null}
              </MDBox>
            </MDBox>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              {row.customer_name ? (
                <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
              ) : (
                <MDAvatar size="sm" bgColor="info">
                  <Icon>person</Icon>
                </MDAvatar>
              )}
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {row.customer_name
                    ? CommonFun.truncateString(row.customer_name)
                    : CommonFun.displayPhone(row.from_phone)}
                </MDTypography>
                <MDTypography variant="caption">
                  {row.customer_name ? CommonFun.displayPhone(row.from_phone) : ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              {row.customer_name ? (
                <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
              ) : (
                <MDAvatar size="sm" bgColor="info">
                  <Icon>person</Icon>
                </MDAvatar>
              )}
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {row.customer_name ? CommonFun.truncateString(row.customer_name) : "New Customer"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Conversation"),
      accessor: "subject",
      align: "left",
      width: "50%",
      render: (row) => (
        <MDBox lineHeight={0}>
          <MDBox lineHeight={1} textAlign="left">
            {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="medium"
                mb={0.5}
              >
                {CommonFun.truncateString(row.subject, 100)}
              </MDTypography>
            ) : null}
            {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
              <MDBox
                display="flex"
                alignItems="center"
                borderRadius="sm"
                lineHeight={1}
                mb={0.5}
                sx={{ height: "15px" }}
              >
                <MDBox lineHeight={1} display="flex" alignItems="center">
                  <MDTypography
                    mr={1}
                    sx={{ fontSize: "16px" }}
                    variant="caption"
                    color="black"
                    fontWeight="medium"
                  >
                    <Icon>phone_forwarded</Icon>
                  </MDTypography>
                  <MDTypography variant="caption" color="black" fontWeight="medium">
                    {row.created_by ? row.created_by : null}{" "}
                    {row.to_phone ? `(${CommonFun.displayPhone(row.to_phone)})` : null}
                  </MDTypography>
                </MDBox>
              </MDBox>
            ) : null}
            {row.assigned_to ? (
              <>
                {row.status_code !== TICKET_STATUS_CLOSE_ID ? (
                  <MDBadge
                    badgeContent={t("Assigned")}
                    color="success"
                    variant="gradient"
                    size="sm"
                    sx={{ marginLeft: -1, marginRight: 1 }}
                  />
                ) : null}
                <MDBadge
                  className="assigned_to_name_badge"
                  badgeContent={row.assigned_to_name}
                  color="light"
                  variant="gradient"
                  size="sm"
                  sx={{ marginLeft: -1 }}
                />
              </>
            ) : (
              <MDBadge
                badgeContent={t("Unassigned")}
                color="primary"
                variant="gradient"
                size="sm"
                sx={{ marginLeft: -1 }}
              />
            )}
            {row.missed_call_reason ? (
              <MDBadge
                className="missed_call_reason_badge"
                badgeContent={row.missed_call_reason.replace(/_/g, " ")}
                color="light"
                variant="contained"
                border
                size="sm"
              />
            ) : null}
          </MDBox>
        </MDBox>
      ),
    },
    {
      header: "ID",
      accessor: "id",
      align: "center",
      width: 25,
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          #{row.id}
        </MDTypography>
      ),
    },
    {
      header: t("Status"),
      accessor: "status_code",
      align: "left",
      render: (row) => (
        <>
          {row.status_code === TICKET_STATUS_OPEN_ID ? (
            <MDBadge badgeContent={t("Open")} color="success" variant="gradient" size="sm" />
          ) : null}
          {row.status_code === TICKET_STATUS_CLOSE_ID ? (
            <MDBadge badgeContent={t("Closed")} color="error" variant="gradient" size="sm" />
          ) : null}
        </>
      ),
    },
    {
      header: t("Created At"),
      accessor: "created_at",
      align: "left",
      render: (row) =>
        row.created_at ? (
          <MDTypography variant="caption" color="black" fontWeight="medium">
            {moment.utc(row.created_at).local().format(CommonFun.getDateTimeFormat())}
          </MDTypography>
        ) : null,
    },
    {
      header: t("Updated At"),
      accessor: "updated_at",
      align: "left",
      render: (row) =>
        row.updated_at ? (
          <MDTypography variant="caption" color="black" fontWeight="medium">
            {moment.utc(row.updated_at).local().format(CommonFun.getDateTimeFormat())}
          </MDTypography>
        ) : null,
    },
  ];

  const handleAdvancedSearch = () => {
    if (page !== 0 || type !== "ALL") {
      setPage(0);
      setType("ALL");
      setSearch(true);
    } else if (!search) {
      setSearch(true);
    } else {
      getTickets();
    }
  };

  const refreshAll = () => {
    if (type !== "ALL" || status !== TICKET_STATUS_OPEN_ID || page !== 0 || search !== false) {
      setType("ALL");
      setStatus(TICKET_STATUS_OPEN_ID);
      setPage(0);
      setSearchObject({});
      setSearch(false);
      setHasNewTicket(false);
      setLastHit(null);
    } else {
      setType("ALL");
      setStatus(TICKET_STATUS_OPEN_ID);
      setPage(0);
      setSearchObject({});
      setSearch(false);
      setHasNewTicket(false);
      setLastHit(null);
      getTickets();
    }
    getTicketsCount();
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  }));

  const removeFilter = (key) => {
    delete searchObject[key];
    setSearchObject({
      ...searchObject,
    });
    setClearAll(!clearAll);
  };

  const searchValues = [];
  if (searchObject) {
    Object.keys(searchObject).forEach((key) => {
      if (searchObject[key]) {
        let formattedValue = searchObject[key];
        if (key === "assigned_to") {
          formattedValue = searchObject[key];
          const findUser = users.find((item) => item.id === searchObject[key]);
          if (findUser) {
            formattedValue = findUser.name;
          }
        } else if (key === "interaction_type") {
          if (searchObject[key] === TICKET_INTERACTION_TYPE_EMAIL) {
            formattedValue = t("Email");
          } else if (searchObject[key] === TICKET_INTERACTION_TYPE_PHONE) {
            formattedValue = t("Phone");
          }
        }
        searchValues.push({
          label: key,
          value: formattedValue,
        });
      }
    });
  }

  const rows = tickets;
  // eslint-disable-next-line no-console
  console.log("render tickets");

  return (
    <MDBox p={1} bgColor="light">
      {hasNewTicket ? (
        <Alert
          severity="warning"
          onClick={() => {
            refreshAll();
          }}
          sx={{
            cursor: "pointer",
            position: "fixed",
            top: "4px",
            left: "50%",
            zIndex: 2000,
            paddingTop: 0,
            paddingBottom: 0,
            transform: "translate(-50%, 0)",
          }}
        >
          New tickets available. Please click here to update
        </Alert>
      ) : null}
      <Progress loading={loading} isFull />

      <Grid container spacing={1}>
        <Grid item xs={4} md={4} lg={4}>
          <MDBox>
            <TicketCard
              color="warning"
              icon="star"
              title={t("All")}
              count={allCount}
              active={type === "ALL"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setStatus(TICKET_STATUS_OPEN_ID);
                setPage(0);
                setType("ALL");
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <MDBox>
            <TicketCard
              color="primary"
              icon="folder_open"
              title={t("Unassigned")}
              count={unassignedCount}
              active={type === "UNASSIGNED"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setStatus(TICKET_STATUS_OPEN_ID);
                setPage(0);
                setType("UNASSIGNED");
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <MDBox>
            <TicketCard
              color="success"
              icon="military_tech"
              title={t("Mine")}
              count={mineCount}
              active={type === "MINE"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setStatus(TICKET_STATUS_OPEN_ID);
                setPage(0);
                setType("MINE");
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <MDBox display="flex" flex="1" alignItems="center" justifyContent="flex-end">
                      {searchResult ? (
                        <MDBox px={1} display="flex" flex="1">
                          <Stack
                            direction="row"
                            spacing={1}
                            useFlexGap
                            flexWrap="wrap"
                            sx={{ paddingTop: 1 }}
                          >
                            {searchValues.map((item, key) => (
                              <Chip
                                key={key}
                                label={`${searchableColumnsLabels[item.label]}: ${item.value}`}
                                variant="outlined"
                                onDelete={() => {
                                  removeFilter(item.label);
                                }}
                              />
                            ))}
                            {searchResult ? (
                              <MDButton
                                variant="gradient"
                                color="error"
                                size="small"
                                sx={{ marginRight: 1 }}
                                onClick={() => {
                                  setSearchObject({});
                                  setSearchResult(false);
                                  setSearch(false);
                                }}
                              >
                                {t("Clear All")}
                              </MDButton>
                            ) : null}
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              onClick={() => {
                                handleAdvancedSearch();
                              }}
                            >
                              {t("Search")}
                            </MDButton>
                          </Stack>
                        </MDBox>
                      ) : null}
                      <MDBox display="flex" alignItems="center">
                        <MDTypography
                          variant="body2"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          {t("Status")}:
                        </MDTypography>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            labelId="status-label"
                            value={status || ""}
                            displayEmpty
                            onChange={(event) => {
                              setStatus(event.target.value);
                              setPage(0);
                            }}
                            sx={{ textTransform: "capitalize" }}
                          >
                            <MenuItem value="">{t("All")}</MenuItem>
                            <MenuItem value={TICKET_STATUS_OPEN_ID}>{t("Open")}</MenuItem>
                            <MenuItem value={TICKET_STATUS_CLOSE_ID}>{t("Closed")}</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>
                      <MDButton
                        variant="gradient"
                        color="success"
                        size="medium"
                        onClick={() => {
                          handleCreateTicket();
                        }}
                      >
                        {t("Create Ticket")}
                      </MDButton>
                      <MDButton
                        variant="gradient"
                        color="info"
                        size="medium"
                        iconOnly
                        sx={{ marginLeft: 1 }}
                        onClick={() => {
                          getTickets();
                        }}
                      >
                        <Icon>refresh</Icon>
                      </MDButton>

                      <IconButton
                        color="info"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <Icon>filter_alt</Icon>
                      </IconButton>
                    </MDBox>
                    <Drawer
                      sx={{
                        width: 300,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                          width: 300,
                          boxSizing: "border-box",
                        },
                      }}
                      variant="temporary"
                      anchor="right"
                      open={open}
                      onClose={() => {
                        setOpen(false);
                      }}
                    >
                      <DrawerHeader>
                        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                          {t("Advanced Search")}
                        </MDTypography>
                        <IconButton
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <Icon>close</Icon>
                        </IconButton>
                      </DrawerHeader>
                      <Divider sx={{ margin: 0 }} />
                      <MDBox p={2}>
                        <Grid container spacing={2}>
                          {selectedColumns.map((col, index) =>
                            col === "created_at" || col === "updated_at" ? (
                              <Grid item xs={12} key={index}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <DatePicker
                                      label={`${searchableColumnsLabels[col]} ${t("Start")}`}
                                      value={
                                        searchObject[`${col}_start`]
                                          ? moment(searchObject[`${col}_start`])
                                          : null
                                      }
                                      maxDate={
                                        searchObject[`${col}_end`]
                                          ? moment(searchObject[`${col}_end`])
                                          : null
                                      }
                                      disableFuture
                                      onChange={(value) => {
                                        setSearchObject({
                                          ...searchObject,
                                          [`${col}_start`]: value
                                            ? value.startOf("day").format("YYYY-MM-DD HH:mm:ss")
                                            : null,
                                        });
                                      }}
                                      slotProps={{
                                        field: {
                                          fullWidth: true,
                                          clearable: true,
                                          onClear: () => {
                                            delete searchObject[`${col}_start`];
                                            setSearchObject({
                                              ...searchObject,
                                            });
                                          },
                                          onKeyPress: (e) => {
                                            if (e.key === "Enter") {
                                              handleAdvancedSearch();
                                            }
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <DatePicker
                                      label={`${searchableColumnsLabels[col]} ${t("End")}`}
                                      value={
                                        searchObject[`${col}_end`]
                                          ? moment(searchObject[`${col}_end`])
                                          : null
                                      }
                                      minDate={
                                        searchObject[`${col}_start`]
                                          ? moment(searchObject[`${col}_start`])
                                          : null
                                      }
                                      disableFuture
                                      onChange={(value) => {
                                        setSearchObject({
                                          ...searchObject,
                                          [`${col}_end`]: value
                                            ? value.endOf("day").format("YYYY-MM-DD HH:mm:ss")
                                            : null,
                                        });
                                      }}
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          clearable: true,
                                          onClear: () => {
                                            delete searchObject[`${col}_end`];
                                            setSearchObject({
                                              ...searchObject,
                                            });
                                          },
                                          onKeyPress: (e) => {
                                            if (e.key === "Enter") {
                                              handleAdvancedSearch();
                                            }
                                          },
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : col === "assigned_to" ? (
                              <Grid item xs={12} key={index}>
                                <MDInput
                                  label={searchableColumnsLabels[col]}
                                  fullWidth
                                  select
                                  value={searchObject[col] || ""}
                                  onChange={(event) => {
                                    setSearchObject({
                                      ...searchObject,
                                      [col]: event.target.value,
                                    });
                                  }}
                                >
                                  <MenuItem>None</MenuItem>
                                  {users.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </MDInput>
                              </Grid>
                            ) : col === "interaction_type" ? (
                              <Grid item xs={12} key={index}>
                                <MDInput
                                  label={searchableColumnsLabels[col]}
                                  fullWidth
                                  select
                                  value={searchObject[col] || ""}
                                  onChange={(event) => {
                                    setSearchObject({
                                      ...searchObject,
                                      [col]: event.target.value,
                                    });
                                  }}
                                >
                                  <MenuItem>{t("All")}</MenuItem>
                                  <MenuItem value={TICKET_INTERACTION_TYPE_EMAIL}>
                                    {t("Email")}
                                  </MenuItem>
                                  <MenuItem value={TICKET_INTERACTION_TYPE_PHONE}>
                                    {t("Phone")}
                                  </MenuItem>
                                </MDInput>
                              </Grid>
                            ) : (
                              <Grid item xs={12} key={index}>
                                <MDInput
                                  label={searchableColumnsLabels[col]}
                                  fullWidth
                                  value={searchObject[col] || ""}
                                  onChange={(event) => {
                                    setSearchObject({
                                      ...searchObject,
                                      [col]: event.target.value,
                                    });
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      handleAdvancedSearch();
                                    }
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => {
                                            delete searchObject[col];
                                            setSearchObject({
                                              ...searchObject,
                                            });
                                          }}
                                          sx={{
                                            marginRight: "-12px",
                                          }}
                                        >
                                          {searchObject[col] ? <Icon>close</Icon> : null}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            )
                          )}
                          <Grid item xs={12}>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="medium"
                              sx={{ width: "100%" }}
                              onClick={() => {
                                handleAdvancedSearch();
                              }}
                            >
                              {t("Search")}
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Drawer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ServerSideTable
                  fixedTable
                  rowsPerPage={rowsPerPage}
                  page={page}
                  columns={columns}
                  data={rows}
                  count={count}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  getRowProps={(row) => ({
                    onClick: () => {
                      onClickTicket(row);
                    },
                    style: {
                      cursor: "pointer",
                    },
                  })}
                  message={
                    lastRefreshAt
                      ? `Last refresh at : ${lastRefreshAt.format(CommonFun.getDateTimeFormat())}`
                      : null
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Tickets;
