/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function MDStatus({ value }) {
  const { t } = useTranslation("common");
  const [shipmentsMenu, setShipmentsMenu] = useState(null);
  const getColor = (val) => {
    switch (val) {
      case "Payment-0":
        return "secondary";
      case "Payment-1":
        return "warning";
      case "Payment-2":
        return "success";
      case "Payment-3":
        return "error";

      case "Packing-0":
        return "secondary";
      case "Packing-1":
        return "info";
      case "Packing-2":
        return "warning";
      case "Packing-3":
        return "success";

      case "Invoicing-0":
        return "secondary";
      case "Invoicing-1":
        return "info";
      case "Invoicing-2":
        return "warning";
      case "Invoicing-3":
        return "success";

      case "Shipping-0":
        return "secondary";
      case "Shipping-1":
        return "warning";
      case "Shipping-3":
        return "info";
      case "Shipping-2":
        return "success";
      default:
        return "dark";
    }
  };

  const openShipmentsMenu = (event, documentIdentifier) => {
    setShipmentsMenu({
      anchorEl: event.currentTarget,
      documentIdentifier,
    });
  };

  const closeShipmentsMenu = () => setShipmentsMenu(null);

  const handelCopyClipboard = (id) => {
    const copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  };

  return (
    <>
      {MDStatus ? (
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title={t(`Payment-${value.payment}`)} placement="bottom">
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
              }}
              variant="gradient"
              bgColor={getColor(`Payment-${value.payment}`)}
            >
              <Icon>euro</Icon>
            </MDBox>
          </Tooltip>
          <Tooltip title={t(`Packing-${value.packing}`)} placement="bottom">
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
              }}
              variant="gradient"
              bgColor={getColor(`Packing-${value.packing}`)}
            >
              <Icon>inventory_2</Icon>
            </MDBox>
          </Tooltip>
          <Tooltip title={t(`Invoicing-${value.invoicing}`)} placement="bottom">
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
              }}
              variant="gradient"
              bgColor={getColor(`Invoicing-${value.invoicing}`)}
            >
              <Icon>description</Icon>
            </MDBox>
          </Tooltip>
          <Tooltip title={t(`Shipping-${value.shipping}`)} placement="bottom">
            <MDBox
              sx={{
                width: 24,
                height: 24,
                marginLeft: 0.25,
                marginRight: 0.25,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                borderRadius: 1,
                cursor:
                  value.shipments &&
                  value.shipments.length &&
                  value.shipments.filter((x) => x.trackingId).length
                    ? "pointer"
                    : "default",
              }}
              variant="gradient"
              bgColor={getColor(`Shipping-${value.shipping}`)}
              onClick={(event) => {
                if (
                  value.shipments &&
                  value.shipments.length &&
                  value.shipments.filter((x) => x.trackingId).length
                ) {
                  openShipmentsMenu(event, value.documentIdentifier);
                }
              }}
            >
              <Icon>local_shipping</Icon>
            </MDBox>
          </Tooltip>
          <Menu
            anchorEl={shipmentsMenu !== null ? shipmentsMenu.anchorEl : undefined}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            open={
              !!(shipmentsMenu && value.documentIdentifier === shipmentsMenu.documentIdentifier)
            }
            onClose={closeShipmentsMenu}
          >
            {value.shipments &&
              value.shipments
                .filter((x) => x.trackingId)
                .map((item, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      padding: 1,
                      marginBottom: 0.5,
                      border: "1px solid #1A73E8",
                      cursor: "default",
                    }}
                  >
                    <MDBox sx={{ display: "flex", width: "100%" }}>
                      <MDBox
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <input
                          type="text"
                          value={item.trackingId}
                          id={`input_${item.trackingId}`}
                          style={{ display: "none" }}
                          readOnly
                        />
                        <input
                          type="text"
                          value={item.trackingLink}
                          id={`input_${item.trackingId}_link`}
                          style={{ display: "none" }}
                          readOnly
                        />
                        <MDTypography variant="button" color="black">
                          {item.shippingService}
                        </MDTypography>
                        <MDBox>
                          <MDTypography variant="button" color="black">
                            {item.trackingId}
                          </MDTypography>
                          <Tooltip title={t("Copy to clipboard")}>
                            <IconButton
                              size="small"
                              color="black"
                              aria-label="share"
                              onClick={() => {
                                handelCopyClipboard(`input_${item.trackingId}`);
                              }}
                            >
                              <Icon>content_copy</Icon>
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                        <MDBox>
                          <Link
                            variant="button"
                            color="info"
                            href={item.trackingLink}
                            target="_black"
                          >
                            {t("Tracking Link")}
                          </Link>
                          <Tooltip title={t("Copy to clipboard")}>
                            <IconButton
                              size="small"
                              color="black"
                              aria-label="share"
                              onClick={() => {
                                handelCopyClipboard(`input_${item.trackingId}_link`);
                              }}
                            >
                              <Icon>content_copy</Icon>
                            </IconButton>
                          </Tooltip>{" "}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MenuItem>
                ))}
          </Menu>
        </MDBox>
      ) : null}
    </>
  );
}

export default MDStatus;
