/* eslint-disable no-eval */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import CommonFun from "services/common";

function DataCell({ column, value, row, getRowColor }) {
  if (column.amount) {
    return (
      <MDTypography
        variant="caption"
        color={getRowColor ? getRowColor(row) : "black"}
        fontWeight="medium"
      >
        {value ? value.toFixed(2) : "0.00"}
      </MDTypography>
    );
  }
  if (column.currency) {
    return (
      <MDTypography
        variant="caption"
        color={getRowColor ? getRowColor(row) : "black"}
        fontWeight="medium"
      >
        {CommonFun.displayCurrency(value, row[column.currency])}
      </MDTypography>
    );
  }
  if (column.dateFormat) {
    return (
      <MDTypography
        variant="caption"
        color={getRowColor ? getRowColor(row) : "black"}
        fontWeight="medium"
      >
        {moment.utc(value).format(column.dateFormat)}
      </MDTypography>
    );
  }
  return (
    <MDTypography
      variant="caption"
      color={getRowColor ? getRowColor(row) : "black"}
      fontWeight="medium"
    >
      {value}
    </MDTypography>
  );
}

function ReportTable({
  fixedTable,
  rowsPerPage,
  page,
  columns,
  data,
  count,
  onPageChange,
  onRowsPerPageChange,
  order,
  orderBy,
  onRequestSort,
  getRowProps,
  getRowColor,
  message,
}) {
  const { t } = useTranslation("common");
  const tableContainerRef = useRef(null);
  const tableStyle = fixedTable ? { boxShadow: "none", maxHeight: 390 } : { boxShadow: "none" };
  if (fixedTable) {
    setTimeout(() => {
      // const tableContainerEle = document.getElementById(`${name}_tableContainer`);
      if (tableContainerRef && tableContainerRef.current) {
        // console.log(tableContainerEle);
        const bound = tableContainerRef.current.getBoundingClientRect();
        tableContainerRef.current.style.maxHeight = `${window.innerHeight - bound.top - 60}px`;
      }
    }, 1000);
  }
  return (
    <MDBox bgColor="white">
      {/* <TablePagination
        labelRowsPerPage={t("Rows per page:")}
        getItemAriaLabel={(type) => t(`Go to ${type} page`)}
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      /> */}
      <TableContainer sx={tableStyle} ref={tableContainerRef}>
        <Table stickyHeader>
          <TableHead sx={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.accessor}
                  align={column.align}
                  sortDirection={orderBy === column.accessor ? order : false}
                >
                  {column.sort ? (
                    <TableSortLabel
                      active={orderBy === column.accessor}
                      direction={orderBy === column.accessor ? order : "asc"}
                      onClick={() => {
                        onRequestSort(column.accessor);
                      }}
                    >
                      <MDTypography
                        variant="caption"
                        color="black"
                        fontWeight="bold"
                        textTransform="uppercase"
                        sx={({ typography: { size } }) => ({
                          fontSize: size.xs,
                        })}
                      >
                        {column.header}
                      </MDTypography>
                    </TableSortLabel>
                  ) : (
                    <MDTypography
                      variant="caption"
                      color="black"
                      fontWeight="bold"
                      textTransform="uppercase"
                      sx={({ typography: { size } }) => ({
                        fontSize: size.xs,
                      })}
                    >
                      {column.header}
                    </MDTypography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((dataRow, index1) =>
              dataRow.positions && dataRow.positions.length ? (
                dataRow.positions.map((row, index) => (
                  <TableRow key={index}>
                    {index === 0 ? (
                      <>
                        {columns.map((column) => {
                          const value = eval(`row.${column.accessor}`);
                          return (
                            <TableCell
                              key={column.accessor}
                              align={column.align}
                              {...(column.getCellProps
                                ? column.getCellProps(row)
                                : getRowProps(row))}
                            >
                              {column.render ? (
                                column.render(row)
                              ) : (
                                <DataCell
                                  column={column}
                                  value={value}
                                  row={row}
                                  getRowColor={getRowColor}
                                />
                              )}
                            </TableCell>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <TableCell colSpan={6} />
                        {columns.slice(6).map((column) => {
                          const value = eval(`row.${column.accessor}`);
                          return (
                            <TableCell
                              key={column.accessor}
                              align={column.align}
                              {...(column.getCellProps
                                ? column.getCellProps(row)
                                : getRowProps(row))}
                            >
                              {column.render ? (
                                column.render(row)
                              ) : (
                                <DataCell
                                  column={column}
                                  value={value}
                                  row={row}
                                  getRowColor={getRowColor}
                                />
                              )}
                            </TableCell>
                          );
                        })}
                      </>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow key={index1}>
                  {columns.map((column) => {
                    const value = eval(`row.${column.accessor}`);
                    return (
                      <TableCell
                        key={column.accessor}
                        align={column.align}
                        {...(column.getCellProps
                          ? column.getCellProps(dataRow)
                          : getRowProps(dataRow))}
                      >
                        {column.render ? (
                          column.render(dataRow)
                        ) : (
                          <DataCell
                            column={column}
                            value={value}
                            row={dataRow}
                            getRowColor={getRowColor}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              )
            )}

            {!data.length && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <MDTypography variant="caption" color="black" fontWeight="medium">
                    {t("No data to show")}
                  </MDTypography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={1} alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center" flex={1} justifyContent="flex-end">
          <Typography variant="body1" sx={{ fontSize: "14px" }}>
            Go to
          </Typography>
          <TextField
            size="small"
            type="number"
            value={page + 1}
            InputProps={{ inputProps: { min: 1, max: Math.ceil(count / rowsPerPage) } }}
            disabled={!count}
            onChange={(event) => {
              let pageIndex = event.target.value ? Number(event.target.value) - 1 : 0;
              if (pageIndex < 0) {
                pageIndex = 0;
              } else if (pageIndex > Math.ceil(count / rowsPerPage)) {
                pageIndex = Math.ceil(count / rowsPerPage) - 1;
              }
              onPageChange(event, pageIndex);
            }}
            sx={{
              "& .MuiOutlinedInput-input": {
                py: 0.75,
                paddingLeft: 0.5,
                paddingRight: 0,
                width: 52,
                textAlign: "center",
              },
            }}
          />
        </Stack>
        <TablePagination
          labelRowsPerPage={t("Rows per page:")}
          getItemAriaLabel={(type) => t(`Go to ${type} page`)}
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Stack>
      {message ? (
        <MDBox sx={{ position: "absolute", left: "8px", bottom: "16px" }}>
          <MDTypography variant="caption">{message}</MDTypography>
        </MDBox>
      ) : null}
    </MDBox>
  );
}

// Setting default values for the props of DataTable
ReportTable.defaultProps = {
  rowsPerPage: 10,
  page: 0,
  columns: [],
  data: [],
  count: 0,
  onPageChange: () => ({}),
  onRowsPerPageChange: () => ({}),
  getRowProps: () => ({}),
};

// Typechecking props for the DataTable
ReportTable.propTypes = {
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  getRowProps: PropTypes.func,
};

export default memo(ReportTable);
