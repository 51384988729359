/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Progress from "components/Progress";
import ServerSideTable from "components/Tables/ServerSideTable";
import { InputText } from "components/MDInputs";
import MDBadge from "components/MDBadge";

import UsersAPI from "services/users";
import CommonFun from "services/common";

function Users() {
  const { t } = useTranslation("common");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const getUsers = async () => {
    setLoading(true);
    const response = await UsersAPI.getAll(rowsPerPage, page);
    if (response && response.data) {
      setUsers(response.data);
      setCount(response.total);
    } else {
      setUsers([]);
      setCount(0);
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (CommonFun.isAdmin()) {
      getUsers();
    }
  }, [page, rowsPerPage]);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onEmailVerify = async (row) => {
    setLoading(true);
    const response = await UsersAPI.verifyEmail(row.email);
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Email Verify",
            content: "Verification email sent successfully",
          },
        })
      );
      getUsers();
    } else {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "ERR",
            title: "Email Verify",
            content: response.message,
          },
        })
      );
    }
    setLoading(false);
  };

  const onChange = (name, value) => {
    setCurrentUser({
      ...currentUser,
      [name]: value,
    });
    if (name === "email") {
      if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  };

  const handleSave = async () => {
    setSubmitted(true);
    if (currentUser.name && currentUser.email && currentUser.password && !emailError) {
      const response = await UsersAPI.createUser(currentUser);
      if (response && response.data) {
        setLoading(true);
        setCurrentUser(null);
        setOpen(false);
        setSubmitted(false);
        setEmailError(false);
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "OK",
              title: "User",
              content: "Saved Successfully",
            },
          })
        );
        getUsers();
      } else {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      header: t("Number"),
      accessor: "id",
      align: "left",
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          #{row.id}
        </MDTypography>
      ),
    },
    {
      header: t("Name"),
      accessor: "name",
      align: "left",
    },
    {
      header: t("Email"),
      accessor: "email",
      align: "left",
    },
    {
      header: t("Type"),
      accessor: "system",
      align: "center",
      render: (row) => (
        <>
          {row.system ? (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              System
            </MDTypography>
          ) : (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              AT
            </MDTypography>
          )}
        </>
      ),
    },
    {
      header: t("Region"),
      accessor: "region",
      align: "center",
      render: (row) => (
        <>
          {row.region ? (
            <MDBadge badgeContent={t("US")} color="warning" variant="gradient" size="sm" />
          ) : (
            <MDBadge badgeContent={t("EU")} color="secondary" variant="gradient" size="sm" />
          )}
        </>
      ),
    },
    {
      header: t("Generic Support Email"),
      accessor: "generic",
      align: "center",
      render: (row) => (
        <>
          {row.generic ? (
            <MDBadge badgeContent={t("Yes")} color="success" variant="gradient" size="sm" />
          ) : (
            <MDBadge badgeContent={t("No")} color="error" variant="gradient" size="sm" />
          )}
        </>
      ),
    },
    {
      header: t("Verified"),
      accessor: "email_verified",
      align: "left",
      render: (row) => (
        <MDBox>
          {row.email_verified ? (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              <Icon color="success" sx={{ marginRight: 1 }}>
                check_circle
              </Icon>{" "}
              {t("Verified")}
            </MDTypography>
          ) : (
            <MDButton
              color="info"
              variant="gradient"
              size="small"
              onClick={() => {
                onEmailVerify(row);
              }}
            >
              {t("Verify")}
            </MDButton>
          )}
        </MDBox>
      ),
    },
    {
      header: t("Verified At"),
      accessor: "email_verified_at",
      align: "left",
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {row.email_verified_at
            ? moment.utc(row.email_verified_at).format(CommonFun.getDateTimeFormat())
            : null}
        </MDTypography>
      ),
    },
    {
      header: t("Created At"),
      accessor: "created_at",
      align: "left",
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.created_at).format(CommonFun.getDateTimeFormat())}
        </MDTypography>
      ),
    },
  ];

  const rows = users;

  return (
    <MDBox>
      <Progress loading={loading} isFull />
      {/* <MDBox p={1} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h5" fontWeight="medium" />
        <MDButton
          variant="gradient"
          color="success"
          size="small"
          onClick={() => {
            setCurrentUser({});
            setSubmitted(false);
            setEmailError(false);
            setOpen(true);
          }}
        >
          {t("Add")}
        </MDButton>
      </MDBox> */}
      <MDBox pt={1}>
        <ServerSideTable
          rowsPerPage={rowsPerPage}
          page={page}
          columns={columns}
          data={rows}
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </MDBox>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>{currentUser && currentUser.id ? t("Edit User") : t("Add User")}</DialogTitle>
        <DialogContent dividers>
          {currentUser ? (
            <MDBox py={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputText
                    label={t("Name")}
                    name="name"
                    required
                    error={submitted && !currentUser.name}
                    value={currentUser.name}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputText
                    type="email"
                    label={t("Email")}
                    name="email"
                    required
                    error={submitted && (!currentUser.email || emailError)}
                    value={currentUser.email}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputText
                    type="phone"
                    label={t("Phone")}
                    name="phone"
                    value={currentUser.phone}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputText
                    type="mobile"
                    label={t("Mobile")}
                    name="mobile"
                    value={currentUser.mobile}
                    onChange={onChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputText
                    type="password"
                    label={t("Password")}
                    name="password"
                    required
                    error={submitted && !currentUser.password}
                    value={currentUser.password}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setCurrentUser(null);
              setOpen(false);
            }}
          >
            {t("Cancel")}
          </MDButton>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              handleSave();
            }}
          >
            {currentUser && currentUser.id ? t("Update") : t("Save")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default memo(Users);
