import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

function ProfileInfoCard({ info, shadow, dense }) {
  const { t } = useTranslation("common");
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    // if (el.match(/[A-Z\s]+/)) {
    //   const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
    //   const newElement = el.replace(uppercaseLetter, `${uppercaseLetter.toLowerCase()}`);

    //   labels.push(newElement);
    // } else {
    labels.push(el);
    // }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={dense ? 0.25 : 1}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {t(label)}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="black">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox p={dense ? 0 : 2}>
        <MDBox>{renderItems}</MDBox>
      </MDBox>
    </Card>
  );
}

ProfileInfoCard.defaultProps = {
  shadow: true,
  dense: false,
};

ProfileInfoCard.propTypes = {
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  shadow: PropTypes.bool,
  dense: PropTypes.bool,
};

export default ProfileInfoCard;
