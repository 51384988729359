import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MicrosoftLogin from "react-microsoft-login";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { AZURE_CLIENT_ID, AZURE_TENANT_ID, BRAND_LOGO, REGION_US } from "environment";

import AuthAPI from "services/auth";
import ERPAPI from "services/erp";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation("common");

  useEffect(async () => {
    const auth = localStorage.getItem("A24_AUTH");
    if (auth) {
      navigate("/home");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    setSubmitted(true);
    if (email && password) {
      setLoading(true);
      const response = await AuthAPI.login(email, password);
      if (response && response.data) {
        if (response.data.region === REGION_US) {
          localStorage.setItem(
            "A24_LOCATION",
            JSON.stringify([{ code: "us", text: "United States" }])
          );
        } else {
          localStorage.setItem("A24_LOCATION", JSON.stringify([]));
        }
        navigate("/home");
        ERPAPI.getMasterData();
      } else {
        setErrorMessage(response && response.message ? response.message : "Something Went Wrong");
      }
      setLoading(false);
    }
  };

  const authHandler = async (authErr, authData) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    if (authData && authData.accessToken) {
      setLoading(true);
      const response = await AuthAPI.loginAD(authData.accessToken);
      if (response && response.data) {
        if (response.data.email_verified) {
          if (response.data.region === REGION_US) {
            localStorage.setItem(
              "A24_LOCATION",
              JSON.stringify([{ code: "us", text: "United States" }])
            );
          } else {
            localStorage.setItem("A24_LOCATION", JSON.stringify([]));
          }

          navigate("/home");
        } else {
          setSuccessMessage(response.message);
        }
      } else {
        setErrorMessage(response && response.message ? response.message : "Something Went Wrong");
      }
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="none"
          borderRadius="lg"
          coloredShadow="light"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            <MDBox component="img" src={BRAND_LOGO} alt="Brand" />
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit} noValidate>
            <MDBox mb={2}>
              <MDInput
                required
                error={submitted && !email}
                type="email"
                label={t("Email")}
                fullWidth
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                required
                error={submitted && !password}
                type="password"
                label={t("Password")}
                fullWidth
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </MDBox>
            <MDBox
              sx={{
                minHeight: 32.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {errorMessage ? (
                <MDTypography variant="button" fontWeight="medium" color="error">
                  {errorMessage}
                </MDTypography>
              ) : null}
              {successMessage ? (
                <MDTypography variant="button" fontWeight="medium" color="success">
                  {successMessage}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mt={2} mb={1} display="flex" justifyContent="center">
              {loading ? (
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled
                  sx={{ width: "215px" }}
                >
                  {t("Loading")}...
                </MDButton>
              ) : (
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  sx={{ width: "215px" }}
                >
                  {t("Sign in")}
                </MDButton>
              )}
            </MDBox>
            <MDBox mt={4} mb={1} display="flex" justifyContent="center">
              <MicrosoftLogin
                className="Ad_login_button"
                clientId={AZURE_CLIENT_ID}
                authCallback={authHandler}
                tenantUrl={`https://login.microsoftonline.com/${AZURE_TENANT_ID}`}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
