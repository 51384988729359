const styles = `
<styles>
    code{
        background-color:hsla(0,0%,78%,.3);
        border-radius:2px;
        padding:.15em 
    }
    blockquote{
        border-left:5px solid #ccc;
        font-style:italic;
        margin-left:0;
        margin-right:0;
        overflow:hidden;
        padding-left:1.5em;
        padding-right:1.5em 
    }
    [dir=rtl] blockquote{
        border-left:0;
        border-right:5px solid #ccc 
    }
    .text-tiny{
        font-size:.7em 
    }
    .text-small{
        font-size:.85em 
    }
    .text-big{
        font-size:1.4em 
    }
    .text-huge{
        font-size:1.8em 
    }
    .image{
        clear:both;
        display:table;
        margin:.9em auto;
        min-width:50px;
        text-align:center 
    }
    .image img{
        display:block;
        height:auto;
        margin:0 auto;
        max-width:100%;
        min-width:100% 
    }
    .image-inline{
        align-items:flex-start;
        display:inline-flex;
        max-width:100% 
    }
    .image-inline picture{
        display:flex 
    }
    .image-inline img, .image-inline picture{
        flex-grow:1;
        flex-shrink:1;
        max-width:100% 
    }
    :root{
        --ck-color-image-caption-background:#f7f7f7;
        --ck-color-image-caption-text:#333;
    }
    .image>figcaption{
        background-color:var(--ck-color-image-caption-background);
        caption-side:bottom;
        color:var(--ck-color-image-caption-text);
        display:table-caption;
        font-size:.75em;
        outline-offset:-1px;
        padding:.6em;
        word-break:break-word 
    }
    img.image_resized{
        height:auto 
    }
    .image.image_resized{
        box-sizing:border-box;
        display:block;
        max-width:100% 
    }
    .image.image_resized img{
        width:100% 
    }
    .image.image_resized>figcaption{
        display:block 
    }
    :root{
        --ck-image-style-spacing:1.5em;
        --ck-inline-image-style-spacing:calc(var(--ck-image-style-spacing)/2) 
    }
    .image-style-block-align-left, .image-style-block-align-right{
        max-width:calc(100% - var(--ck-image-style-spacing)) 
    }
    .image-style-align-left, .image-style-align-right{
        clear:none 
    }
    .image-style-side{
        float:right;
        margin-left:var(--ck-image-style-spacing);
        max-width:50% 
    }
    .image-style-align-left{
        float:left;
        margin-right:var(--ck-image-style-spacing) 
    }
    .image-style-align-center{
        margin-left:auto;
        margin-right:auto 
    }
    .image-style-align-right{
        float:right;
        margin-left:var(--ck-image-style-spacing) 
    }
    .image-style-block-align-right{
        margin-left:auto;
        margin-right:0 
    }
    .image-style-block-align-left{
        margin-left:0;
        margin-right:auto 
    }
    p+.image-style-align-left, p+.image-style-align-right, p+.image-style-side{
        margin-top:0 
    }
    .image-inline.image-style-align-left, .image-inline.image-style-align-right{
        margin-bottom:var(--ck-inline-image-style-spacing);
        margin-top:var(--ck-inline-image-style-spacing) 
    }
    .image-inline.image-style-align-left{
        margin-right:var(--ck-inline-image-style-spacing) 
    }
    .image-inline.image-style-align-right{
        margin-left:var(--ck-inline-image-style-spacing) 
    }
    ol{
        list-style-type:decimal 
    }
    ol ol{
        list-style-type:lower-latin 
    }
    ol ol ol{
        list-style-type:lower-roman 
    }
    ol ol ol ol{
        list-style-type:upper-latin 
    }
    ol ol ol ol ol{
        list-style-type:upper-roman 
    }
    ul{
        list-style-type:disc 
    }
    ul ul{
        list-style-type:circle 
    }
    ul ul ul, ul ul ul ul{
        list-style-type:square 
    }
    :root{
        --ck-todo-list-checkmark-size:16px 
    }
    .todo-list{
        list-style:none 
    }
    .todo-list li{
        margin-bottom:5px;
        position:relative 
    }
    .todo-list li .todo-list{
        margin-top:5px 
    }
    .todo-list .todo-list__label>input{
        -webkit-appearance:none;
        border:0;
        display:inline-block;
        height:var(--ck-todo-list-checkmark-size);
        left:-25px;
        margin-left:0;
        margin-right:-15px;
        position:relative;
        right:0;
        vertical-align:middle;
        width:var(--ck-todo-list-checkmark-size) 
    }
    [dir=rtl] .todo-list .todo-list__label>input{
        left:0;
        margin-left:-15px;
        margin-right:0;
        right:-25px 
    }
    .todo-list .todo-list__label>input:before{
        border:1px solid #333;
        border-radius:2px;
        box-sizing:border-box;
        content:"";
        display:block;
        height:100%;
        position:absolute;
        transition:box-shadow .25s ease-in-out;
        width:100% 
    }
    .todo-list .todo-list__label>input:after{
        border-color:transparent;
        border-style:solid;
        border-width:0 calc(var(--ck-todo-list-checkmark-size)/8) calc(var(--ck-todo-list-checkmark-size)/8) 0;
        box-sizing:content-box;
        content:"";
        display:block;
        height:calc(var(--ck-todo-list-checkmark-size)/2.6);
        left:calc(var(--ck-todo-list-checkmark-size)/3);
        pointer-events:none;
        position:absolute;
        top:calc(var(--ck-todo-list-checkmark-size)/5.3);
        transform:rotate(45deg);
        width:calc(var(--ck-todo-list-checkmark-size)/5.3) 
    }
    .todo-list .todo-list__label>input[checked]:before{
        background:#26ab33;
        border-color:#26ab33 
    }
    .todo-list .todo-list__label>input[checked]:after{
        border-color:#fff 
    }
    .todo-list .todo-list__label .todo-list__label__description{
        vertical-align:middle 
    }
    .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox]{
        position:absolute 
    }
    .media{
        clear:both;
        display:block;
        margin:.9em 0;
        min-width:15em 
    }
    .table{
        display:table;
        margin:.9em auto 
    }
    .table table{
        border:1px double #b3b3b3;
        border-collapse:collapse;
        border-spacing:0;
        height:100%;
        width:100% 
    }
    .table table td, .table table th{
        border:1px solid #bfbfbf;
        min-width:2em;
        padding:.4em 
    }
    .table table th{
        background:rgba(0,0,0,.05);
        font-weight:700 
    }
    [dir=rtl] .table th{
        text-align:right 
    }
    [dir=ltr] .table th{
        text-align:left 
    }
    :root{
        --ck-color-selector-caption-background:#f7f7f7;
        --ck-color-selector-caption-text:#333;
        --ck-color-selector-caption-highlighted-background:#fd0 
    }
    .table>figcaption{
        background-color:var(--ck-color-selector-caption-background);
        caption-side:top;
        color:var(--ck-color-selector-caption-text);
        display:table-caption;
        font-size:.75em;
        outline-offset:-1px;
        padding:.6em;
        text-align:center;
        word-break:break-word 
    }
    :root{
        --ck-color-base-active:#2977ff;
    }
    :root{
        --ck-color-selector-column-resizer-hover:var(--ck-color-base-active);
        --ck-table-column-resizer-width:7px;
        --ck-table-column-resizer-position-offset:calc(var(--ck-table-column-resizer-width)*-0.5 - 0.5px) 
    }
    .table .ck-table-resized{
        table-layout:fixed 
    }
    .table table{
        overflow:hidden 
    }
    .table td, .table th{
        overflow-wrap:break-word;
        position:relative 
    }

</styles>
`;

export default styles;
