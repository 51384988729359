/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CKEditor from "components/Editors/CK";

export function InputText({
  label,
  name,
  value,
  onChange,
  error = false,
  required = false,
  type = "text",
  disabled = false,
}) {
  return (
    <MDBox px={2} mb={2} lineHeight={1.25}>
      <MDInput
        type={type}
        label={label}
        name={name}
        fullWidth
        error={error}
        required={required}
        value={value !== null && value !== undefined ? value : ""}
        onChange={(event) => {
          if (typeof onChange === "function") {
            onChange(name, event.target.value);
          }
        }}
        disabled={disabled}
      />
    </MDBox>
  );
}

export function InputTextArea({ rows = 5, label, name, value, onChange, disabled = false }) {
  return (
    <MDBox px={2} mb={2} lineHeight={1.25}>
      <MDInput
        label={label}
        name={name}
        multiline
        rows={rows}
        fullWidth
        value={value || ""}
        onChange={(event) => {
          if (typeof onChange === "function") {
            onChange(name, event.target.value);
          }
        }}
        disabled={disabled}
      />
    </MDBox>
  );
}

export function InputAutocomplete({ label, name, value, onChange, options }) {
  return (
    <MDBox px={2} mb={2} lineHeight={1.25}>
      <Autocomplete
        disableClearable
        disablePortal
        fullWidth
        options={options}
        name={name}
        onChange={(event, newValue) => {
          if (typeof onChange === "function") {
            onChange(name, newValue);
          }
        }}
        value={value || ""}
        renderInput={(params) => <MDInput {...params} label={label} />}
      />
    </MDBox>
  );
}

export function InputDropdown({
  label,
  name,
  value,
  onChange,
  error = false,
  required = false,
  disabled = false,
  options,
}) {
  return (
    <MDBox px={2} mb={2} lineHeight={1.25}>
      <MDInput
        select
        label={label}
        name={name}
        fullWidth
        error={error}
        required={required}
        value={value || ""}
        onChange={(event) => {
          if (typeof onChange === "function") {
            onChange(name, event.target.value);
          }
        }}
        disabled={disabled}
      >
        {options.map((option, index) =>
          option && (option.id || option.label) ? (
            <MenuItem key={index} value={option.id}>
              {option.label}
            </MenuItem>
          ) : (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          )
        )}
      </MDInput>
    </MDBox>
  );
}

export function InputCheckbox({
  label,
  name,
  value,
  onChange,
  valueInt = false,
  disabled = false,
}) {
  return (
    <MDBox display="flex" alignItems="center" mb={2} px={2} py={0.125}>
      <MDBox width="80%" mr="auto" ml={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="black">
          {label}
        </MDTypography>
      </MDBox>
      <MDBox mt={0.5}>
        <Checkbox
          disabled={disabled}
          color="success"
          checked={!!value}
          onChange={(event) => {
            if (typeof onChange === "function") {
              if (valueInt) {
                onChange(name, event.target.checked ? -1 : 0);
              } else {
                onChange(name, event.target.checked);
              }
            }
          }}
        />
      </MDBox>
    </MDBox>
  );
}

export function InputEditor({ label, placeholder, name, value, onChange }) {
  return (
    <MDBox display="flex" flexDirection="column" mb={2} px={2}>
      <MDBox>
        <MDTypography variant="button" fontWeight="regular" color="black">
          {label}
        </MDTypography>
      </MDBox>
      <MDBox mt={0.5}>
        <CKEditor
          value={value || ""}
          placeholder={placeholder}
          onChange={(event, htmlContent) => {
            if (typeof onChange === "function") {
              onChange(name, htmlContent);
            }
          }}
        />
      </MDBox>
    </MDBox>
  );
}

export function InputDate({ label, name, value, onChange }) {
  return (
    <MDBox px={2} mb={2} lineHeight={1.25}>
      <DatePicker
        label={label}
        value={value ? moment(value) : null}
        disableFuture
        onChange={(val) => {
          if (typeof onChange === "function") {
            onChange(name, val ? val.startOf("day").format("YYYY-MM-DD HH:mm:ss") : null);
          }
        }}
        slotProps={{
          field: {
            fullWidth: true,
            clearable: true,
            onClear: () => {
              onChange(name, null);
            },
          },
        }}
      />
    </MDBox>
  );
}
