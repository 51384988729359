import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

// import MyTasks from "./components/MyTasks";
import Calendar from "./components/Calendar";

function Dashboard() {
  // eslint-disable-next-line no-console
  console.log("render dashboard");
  return (
    <MDBox p={1} bgColor="light">
      <MDBox>
        <Grid container spacing={3}>
          {/* <Grid item xs={6} md={6} lg={6}>
            <MyTasks />
          </Grid> */}
          <Grid item xs={12} md={6} lg={6}>
            <Calendar />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Dashboard;
