import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Progress from "components/Progress";
import { InputText, InputCheckbox } from "components/MDInputs";

import UsersAPI from "services/users";
import CommonFun from "services/common";

function Profile() {
  const { t } = useTranslation("common");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(async () => {
    const profileStr = localStorage.getItem("A24_USER");
    let profile = {};
    if (profileStr && profileStr !== "") {
      profile = JSON.parse(profileStr);
    }
    setFormData({
      name: profile.name,
      email: profile.email,
      title: profile.title,
      phone: profile.phone,
      mobile: profile.mobile,
      extension: profile.extension,
      email_verified: profile.email_verified,
      email_verified_at: profile.email_verified_at,
    });
  }, []);

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    setSubmitted(true);
    if (formData.name && formData.email && formData.title) {
      setLoading(true);
      const response = await UsersAPI.saveProfile({
        name: formData.name,
        title: formData.title,
        phone: formData.phone,
        mobile: formData.mobile,
        extension: formData.extension,
      });
      if (response) {
        await localStorage.setItem("A24_USER_EMAIL", response.email);
        await localStorage.setItem("A24_USER", JSON.stringify(response));
        window.dispatchEvent(new Event("user_storage"));
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "OK",
              title: "Profile",
              content: "Saved successfully",
            },
          })
        );
      }
      setLoading(false);
    }
    setSubmitted(false);
  };

  return (
    <MDBox py={1}>
      <Progress loading={loading} isFull />
      <Card sx={{ boxShadow: "none" }}>
        <MDBox py={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <InputText
                required
                fullWidth
                label={t("Name")}
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                error={submitted && !formData.name}
              />
              <InputText
                required
                fullWidth
                label={t("Title")}
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                error={submitted && !formData.title}
              />
              <InputText
                fullWidth
                label={t("Phone")}
                type="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
              <InputText
                fullWidth
                label={t("Mobile")}
                type="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
              />
              <InputText
                fullWidth
                label={t("Extension")}
                name="extension"
                value={formData.extension}
                onChange={handleInputChange}
              />
              <InputText
                required
                fullWidth
                disabled
                label={t("Email")}
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                error={submitted && !formData.email}
              />
              <InputCheckbox
                disabled
                label={t("Email Verified")}
                name="email_verified"
                value={!!formData.email_verified}
              />
              <InputText
                disabled
                fullWidth
                label={t("Email Verified At")}
                name="email_verified_at"
                value={
                  formData.email_verified_at
                    ? moment.utc(formData.email_verified_at).format(CommonFun.getDateTimeFormat())
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MDBox display="flex" justifyContent="flex-end" px={2}>
                <MDButton variant="gradient" color="info" onClick={handleSave}>
                  {t("Save")}
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default memo(Profile);
