import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

import CommonFun from "services/common";

export default function Quotation() {
  const navigate = useNavigate();
  const { documentIdentifier, tabId } = useParams();
  const tabs = ["items", "customer", "information", "log", "items"];

  useEffect(async () => {
    const tabIndex = tabs.findIndex((t) => t === tabId);
    CommonFun.onClickQuotation(documentIdentifier, tabIndex);
    navigate("/home");
  }, []);

  return (
    <MDBox>
      <Progress loading isFull />
    </MDBox>
  );
}
