/* eslint-disable react/prop-types */
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { API_BASE_URL } from "environment";

const editorConfiguration = (token) => ({
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },
  // plugins: [],
  // extraPlugins: [],
  // removePlugins: [],
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "link",
      "insertTable",
      "uploadImage",
      "|",
      "alignment",
      "bulletedList",
      "numberedList",
      "outdent",
      "indent",
      "|",
    ],
    shouldNotGroupWhenFull: true,
  },
  simpleUpload: {
    uploadUrl: `${API_BASE_URL}api/ticketdetail/publicattachment`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
  image: {
    insert: {
      type: "inline",
    },
    toolbar: [
      "imageTextAlternative",
      "toggleImageCaption",
      "imageStyle:inline",
      "imageStyle:alignLeft",
      "imageStyle:alignRight",
      "imageStyle:alignCenter",
      "imageStyle:alignBlockLeft",
      "imageStyle:alignBlockRight",
      "imageStyle:side",
    ],
    upload: {
      types: ["jpeg", "png"],
    },
  },
});

function CK({ value, placeholder, onChange, onBlur, setRef }) {
  const i18nLocal = useTranslation();
  const language = i18nLocal.i18n.language.substring(0, 2);
  let CKeditor = null;
  const authToken = localStorage.getItem("A24_TOKEN");

  return (
    <CKEditor
      editor={Editor}
      data={value}
      config={{
        ...editorConfiguration(authToken),
        language,
        placeholder,
      }}
      onReady={(editor) => {
        editor.focus();
        // console.log(Array.from(editor.ui.componentFactory.names()));
        editor.ui
          .getEditableElement()
          .parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
          );
        CKeditor = editor;
        if (typeof setRef === "function") {
          setRef(CKeditor);
        }
        editor.ui.focusTracker.on("change:isFocused", (evt, name, isFocused) => {
          if (!isFocused) {
            const data = editor.getData();
            if (typeof onBlur === "function") {
              onBlur(evt, data);
            }
          }
        });
      }}
      onError={(error, { willEditorRestart }) => {
        if (willEditorRestart) {
          CKeditor.ui.view.toolbar.element.remove();
        }
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        if (typeof onChange === "function") {
          onChange(event, data);
        }
      }}
    />
  );
}

export default memo(CK);
