/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Progress from "components/Progress";
import {
  InputText,
  InputDropdown,
  InputCheckbox,
  InputDate,
  InputAutocomplete,
} from "components/MDInputs";
import ERPAPI from "services/erp";

function Customer({ documentIdentifier, setHeader }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [customerInformation, setCustomerInformation] = useState({});
  const [documentInformation, setDocumentInformation] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [differentDeliveryAddress, setDifferentDeliveryAddress] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({});
  // const [invoiceAddress, setInvoiceAddress] = useState(customer?.invoiceAddress);
  // MASTER DATA
  const [salutations, setSalutations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([]);
  const taxations = [];
  const priceList = [];

  const getMasterData = async () => {
    let ERPResponse = await ERPAPI.getSalutations();
    if (ERPResponse) {
      setSalutations(ERPResponse);
    }
    ERPResponse = await ERPAPI.getCountries();
    if (ERPResponse) {
      setCountries(ERPResponse);
    }
    ERPResponse = await ERPAPI.getCustomerGroups();
    if (ERPResponse) {
      setCustomerGroups(ERPResponse);
    }
  };

  const getQuotationCustomer = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getQuotationCustomer(documentIdentifier);
    if (ERPResponse && ERPResponse.data) {
      const customerInfo = ERPResponse.data?.customerInformation;
      const currentDeliveryAddress = ERPResponse.data?.deliveryAddress;
      setCustomerInformation({ ...customerInfo });
      setDocumentInformation({ ...ERPResponse.data?.documentInformation });
      setDocumentDetails({ ...ERPResponse.data?.documentDetails });
      setDeliveryAddress({ ...currentDeliveryAddress });
      const address = [
        customerInfo.street,
        customerInfo.city,
        customerInfo.country,
        customerInfo.zipCode,
      ]
        .filter((x) => typeof x === "string" && x.length > 0)
        .join(", ");
      setHeader({
        customerNumber: customerInfo.customerNumber,
        name1: customerInfo.name1,
        name2: customerInfo.name2,
        address,
      });
      if (
        customerInfo.name1 !== currentDeliveryAddress.name1 ||
        customerInfo.name2 !== currentDeliveryAddress.name2 ||
        customerInfo.street !== currentDeliveryAddress.street ||
        customerInfo.city !== currentDeliveryAddress.city ||
        customerInfo.country !== currentDeliveryAddress.country ||
        customerInfo.zipCode !== currentDeliveryAddress.zipCode
      ) {
        setDifferentDeliveryAddress(true);
      } else {
        setDifferentDeliveryAddress(false);
      }
    } else {
      setCustomerInformation({});
      setDocumentInformation({});
      setDocumentDetails({});
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (documentIdentifier) {
      getQuotationCustomer();
    }
    getMasterData();
  }, [documentIdentifier]);

  // eslint-disable-next-line no-unused-vars
  const handleChangeCustomerInformation = (name, value) => {
    setCustomerInformation({
      ...customerInformation,
      // [name]: value,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeDocumentInformation = (name, value) => {
    setDocumentInformation({
      ...documentInformation,
      // [name]: value,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeDocumentDetails = (name, value) => {
    setDocumentDetails({
      ...documentDetails,
      // [name]: value,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeDeliveryAddress = (name, value) => {
    setDeliveryAddress({
      ...deliveryAddress,
      // [name]: value,
    });
  };

  return (
    <MDBox position="relative">
      <Progress loading={loading} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <Card sx={{ boxShadow: "none" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Customer")}
              </MDTypography>
            </MDBox>
            <InputText
              label={t("Customer Number")}
              name="customerNumber"
              value={customerInformation?.customerNumber}
              onChange={handleChangeCustomerInformation}
              disabled
            />
            <InputDropdown
              label={t("Salutation")}
              options={salutations}
              name="title"
              value={customerInformation.title}
              onChange={handleChangeCustomerInformation}
            />
            <InputText
              label={t("Addition")}
              name="addition"
              value={customerInformation?.addition}
              onChange={handleChangeCustomerInformation}
            />
            <InputText
              label={t("Name 1")}
              name="name1"
              value={customerInformation?.name1}
              onChange={handleChangeCustomerInformation}
            />
            <InputText
              label={t("Name 2")}
              name="name2"
              value={customerInformation?.name2}
              onChange={handleChangeCustomerInformation}
            />
            <InputText
              label={t("Street, no.")}
              name="street"
              value={customerInformation?.street}
              onChange={handleChangeCustomerInformation}
            />
            <MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={6}>
                  <InputText
                    label={t("Zipcode")}
                    name="zipCode"
                    value={customerInformation?.zipCode}
                    onChange={handleChangeCustomerInformation}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputText
                    label={t("City")}
                    name="city"
                    value={customerInformation?.city}
                    onChange={handleChangeCustomerInformation}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <InputDropdown
              label={t("Country")}
              options={countries}
              name="country"
              value={customerInformation?.country}
              onChange={handleChangeCustomerInformation}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} xl={4} style={{ display: "flex", position: "relative" }}>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: "4px",
              m: 0,
              display: { xs: "none", md: "block" },
            }}
          />
          <Card sx={{ boxShadow: "none", width: "100%" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Delivery Address")}
              </MDTypography>
            </MDBox>
            <InputCheckbox
              label={t("Different delivery address")}
              name="different_delivery_address"
              value={differentDeliveryAddress}
              onChange={(name, value) => {
                setDifferentDeliveryAddress(value);
              }}
            />
            <InputText
              label={t("Name 1")}
              name="name1"
              value={deliveryAddress?.name1}
              onChange={handleChangeDeliveryAddress}
            />
            <InputText
              label={t("Name 2")}
              name="name2"
              value={deliveryAddress?.name2}
              onChange={handleChangeDeliveryAddress}
            />
            <InputText
              label={t("Street, no.")}
              name="street"
              value={deliveryAddress?.street}
              onChange={handleChangeDeliveryAddress}
            />
            <MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={6}>
                  <InputText
                    label={t("Zipcode")}
                    name="zipCode"
                    value={deliveryAddress?.zipCode}
                    onChange={handleChangeDeliveryAddress}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputText
                    label={t("City")}
                    name="city"
                    value={deliveryAddress?.city}
                    onChange={handleChangeDeliveryAddress}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <InputDropdown
              label={t("Country")}
              options={countries}
              name="country"
              value={deliveryAddress?.country}
              onChange={handleChangeDeliveryAddress}
            />
          </Card>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              m: 0,
              right: "-4px",
              display: { xs: "none", xl: "block" },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4} style={{ display: "flex", position: "relative" }}>
          <Card sx={{ boxShadow: "none", width: "100%" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Contact details")}
              </MDTypography>
            </MDBox>
            <MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={6}>
                  <InputText
                    label={t("Phone")}
                    name="telephone"
                    value={customerInformation?.telephone}
                    onChange={handleChangeCustomerInformation}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputText
                    label={t("Mobile")}
                    name="mobile"
                    value={customerInformation?.mobile}
                    onChange={handleChangeCustomerInformation}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <InputText
              label={t("Email")}
              name="email"
              value={customerInformation?.email}
              onChange={handleChangeCustomerInformation}
            />
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Internal Information")}
              </MDTypography>
            </MDBox>
            <MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={6}>
                  <InputDate
                    label={t("Document Date")}
                    name="documentDate"
                    value={documentInformation?.documentDate}
                    onChange={handleChangeDocumentInformation}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputDate
                    label={t("Delivery Date")}
                    name="deliveryDate"
                    value={documentInformation?.deliveryDate}
                    onChange={handleChangeDocumentInformation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputDate
                    label={t("Validity Date")}
                    name="validUntil"
                    value={documentDetails?.validUntil}
                    onChange={handleChangeDocumentDetails}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputDropdown
                    label={t("Customer Group")}
                    options={customerGroups}
                    name="customerGroup"
                    value={customerInformation?.customerGroup}
                    onChange={handleChangeCustomerInformation}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputCheckbox
                    valueInt
                    label={t("Gross Prices")}
                    name="grossPrices"
                    value={documentDetails?.priceType}
                    onChange={handleChangeDocumentDetails}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputAutocomplete
                    label={t("Price List")}
                    options={priceList}
                    name="priceList"
                    value={customerInformation?.priceList}
                    onChange={handleChangeCustomerInformation}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  <InputAutocomplete
                    label={t("Taxation")}
                    options={taxations}
                    name="taxationType"
                    value={customerInformation?.taxationType}
                    onChange={handleChangeCustomerInformation}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <InputDropdown
              label={t("EU Country")}
              options={countries}
              name="euCountry"
              value={customerInformation?.euCountry}
              onChange={handleChangeCustomerInformation}
            />
            <InputText
              label={t("VAT ID")}
              name="vatID"
              value={customerInformation?.vatID}
              onChange={handleChangeCustomerInformation}
            />
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(Customer);
