import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

export default function Tickets() {
  const navigate = useNavigate();

  useEffect(async () => {
    const newTab = {
      id: "tickets",
      type: "TICKETS",
      label: "Tickets",
    };
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    navigate("/home");
  }, []);

  return (
    <MDBox>
      <Progress loading isFull />
    </MDBox>
  );
}
