/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import ServerSideTable from "components/Tables/ServerSideTable";
import MDBadge from "components/MDBadge";
import Progress from "components/Progress";
import MDButton from "components/MDButton";

import TicketsAPI from "services/tickets";
import CommonFun from "services/common";
import {
  TICKET_STATUS_OPEN_ID,
  TICKET_STATUS_CLOSE_ID,
  TICKET_INTERACTION_TYPE_EMAIL,
  TICKET_INTERACTION_TYPE_PHONE,
  TICKET_INTERACTION_TYPE_CHAT,
} from "environment";

function Trash({ type }) {
  const { t } = useTranslation("common");
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const getTickets = async () => {
    setLoading(true);
    const data = {
      deleted: true,
    };
    const response = await TicketsAPI.getTicketsByFilter(rowsPerPage, page, data);
    if (response && response.data) {
      setTickets(response.data);
      setCount(response.total);
    } else {
      setTickets([]);
      setCount(0);
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (type === "TRASH") {
      getTickets();
    }
  }, [page, rowsPerPage]);

  const handleRestoreTicket = async (ticketID) => {
    setLoading(true);
    const data = {
      ticket_id: ticketID,
      deleted: false,
    };
    const response = await TicketsAPI.softDeleteTicket(data);
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Restore",
            content: "Ticket restore successfully",
          },
        })
      );
      getTickets();
    }
    setLoading(false);
  };

  const handleDeleteTicket = async (ticketID) => {
    setLoading(true);
    const response = await TicketsAPI.hardDeleteTicket(ticketID);
    if (response) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Delete",
            content: "Ticket delete permanently",
          },
        })
      );
      getTickets();
    }
    setLoading(false);
  };

  const columns = [
    {
      header: "",
      accessor: "interaction_type",
      align: "center",
      width: 25,
      render: (row) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
            <MDTypography color="success" style={{ lineHeight: "20px" }}>
              <Icon>mail</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
            <MDTypography color="secondary" style={{ lineHeight: "20px" }}>
              <Icon>phone_in_talk</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
            <MDTypography color="primary" style={{ lineHeight: "20px" }}>
              <Icon>chat</Icon>
            </MDTypography>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Customer"),
      accessor: "customer_email",
      align: "left",
      render: (row) => (
        <MDBox>
          {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              {row.customer_name ? (
                <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
              ) : (
                <MDAvatar size="sm" bgColor="info">
                  <Icon>person</Icon>
                </MDAvatar>
              )}
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {row.customer_name ? row.customer_name : row.customer_email}
                </MDTypography>
                {row.customer_name ? (
                  <MDTypography variant="caption" fontWeight="regular">
                    {row.customer_email}
                  </MDTypography>
                ) : null}
              </MDBox>
            </MDBox>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              {row.customer_name ? (
                <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
              ) : (
                <MDAvatar size="sm" bgColor="info">
                  <Icon>person</Icon>
                </MDAvatar>
              )}
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {row.customer_name ? row.customer_name : row.from_phone}
                </MDTypography>
                <MDTypography variant="caption">
                  {row.customer_name ? row.from_phone : ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              {row.customer_name ? (
                <MDAvatar name={row.customer_name} size="sm" bgColor="info" />
              ) : (
                <MDAvatar size="sm" bgColor="info">
                  <Icon>person</Icon>
                </MDAvatar>
              )}
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {row.customer_name ? row.customer_name : "New Customer"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Conversation"),
      accessor: "subject",
      align: "left",
      width: "50%",
      render: (row) => (
        <MDBox lineHeight={0}>
          <MDBox lineHeight={1} textAlign="left">
            {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="medium"
                mb={0.5}
              >
                {row.subject && row.subject.substring(0, 100)}
                {row.subject && row.subject.length > 100 ? "..." : ""}
              </MDTypography>
            ) : null}
            {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
              <MDBox
                display="flex"
                alignItems="center"
                borderRadius="sm"
                lineHeight={1}
                mb={0.5}
                sx={{ height: "15px" }}
              >
                <MDBox
                  variant="gradient"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgColor="info"
                  sx={{ width: "15px", height: "15px" }}
                >
                  <MDTypography color="light" sx={{ fontSize: "12px", lineHeight: 0.5 }}>
                    <Icon>call_end</Icon>
                  </MDTypography>
                </MDBox>
                <MDBox ml={1} mr={2} lineHeight={1}>
                  <MDTypography display="block" variant="caption" color="black" fontWeight="medium">
                    {row.from_phone}
                  </MDTypography>
                  <MDTypography variant="caption" />
                </MDBox>
              </MDBox>
            ) : null}
            {row.assigned_to ? (
              <>
                {row.status_code !== TICKET_STATUS_CLOSE_ID ? (
                  <MDBadge
                    badgeContent={t("Assigned")}
                    color="success"
                    variant="gradient"
                    size="sm"
                    sx={{ marginLeft: -1, marginRight: 1 }}
                  />
                ) : null}
                <MDBadge
                  className="assigned_to_name_badge"
                  badgeContent={row.assigned_to_name}
                  color="light"
                  variant="gradient"
                  size="sm"
                  sx={{ marginLeft: -1 }}
                />
              </>
            ) : (
              <MDBadge
                badgeContent={t("Unassigned")}
                color="primary"
                variant="gradient"
                size="sm"
                sx={{ marginLeft: -1 }}
              />
            )}
          </MDBox>
        </MDBox>
      ),
    },
    {
      header: t("Status"),
      accessor: "status_code",
      align: "left",
      render: (row) => (
        <>
          {row.status_code === TICKET_STATUS_OPEN_ID ? (
            <MDBadge badgeContent={t("Open")} color="success" variant="gradient" size="sm" />
          ) : null}
          {row.status_code === TICKET_STATUS_CLOSE_ID ? (
            <MDBadge badgeContent={t("Closed")} color="error" variant="gradient" size="sm" />
          ) : null}
        </>
      ),
    },
    {
      header: t("Created At"),
      accessor: "created_at",
      align: "left",
      render: (row) =>
        row.created_at ? (
          <MDTypography variant="caption" color="black" fontWeight="medium">
            {moment.utc(row.created_at).local().format(CommonFun.getDateTimeFormat())}
          </MDTypography>
        ) : null,
    },
    {
      header: t("Updated At"),
      accessor: "updated_at",
      align: "left",
      render: (row) =>
        row.updated_at ? (
          <MDTypography variant="caption" color="black" fontWeight="medium">
            {moment.utc(row.updated_at).local().format(CommonFun.getDateTimeFormat())}
          </MDTypography>
        ) : null,
    },
    {
      header: t("Action"),
      accessor: "action",
      align: "left",
      render: (row) => (
        <>
          <Tooltip title={t("Restore")}>
            <IconButton
              size="small"
              color="success"
              onClick={() => {
                handleRestoreTicket(row.id);
              }}
            >
              <Icon>restore_from_trash</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Delete Forever")}>
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                handleDeleteTicket(row.id);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </Tooltip>
        </>
      ),
      getCellProps: () => ({
        onClick: () => {},
      }),
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (type === "TRASH") {
    // eslint-disable-next-line no-console
    console.log("render trash");
    return (
      <MDBox p={1} bgColor="light" position="relative">
        <Progress loading={loading} isFull />
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MDBox pt={1} px={2} display="flex" alignItems="center" justifyContent="flex-end">
                <MDButton
                  variant="gradient"
                  color="info"
                  size="medium"
                  iconOnly
                  onClick={() => {
                    getTickets();
                  }}
                >
                  <Icon>refresh</Icon>
                </MDButton>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <ServerSideTable
                fixedTable
                rowsPerPage={rowsPerPage}
                page={page}
                columns={columns}
                data={tickets}
                count={count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                getRowProps={() => ({
                  onClick: () => {
                    // onClickTicket(row);
                  },
                })}
              />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
    );
  }
  return false;
}

export default memo(Trash);
