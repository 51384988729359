/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import { InputText, InputDropdown, InputCheckbox, InputAutocomplete } from "components/MDInputs";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Basic({ customerID, customer }) {
  const { t } = useTranslation("common");
  const [currentCustomer, setCurrentCustomer] = useState(customer);
  const [open, setOpen] = useState(false);
  const [vatInfo, setVatInfo] = useState({});
  // MASTER DATA
  const [salutations, setSalutations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [countries, setCountries] = useState([]);
  const taxations = [];
  const priceList = [];
  const currencyList = [];
  const paymentConditions = [];
  const deliveryConditions = [];
  const typeOfDispatchList = [];
  const invoiceTypes = [];
  const b2bList = [];
  const industryList = [];
  const printMarketingList = [];
  const editingList = [];
  const requirementList = [];

  const getMasterData = async () => {
    let ERPResponse = await ERPAPI.getSalutations();
    if (ERPResponse) {
      setSalutations(ERPResponse);
    }
    ERPResponse = await ERPAPI.getLanguages();
    if (ERPResponse) {
      setLanguages(ERPResponse);
    }
    ERPResponse = await ERPAPI.getCustomerGroups();
    if (ERPResponse) {
      setCustomerGroups(ERPResponse);
    }
    ERPResponse = await ERPAPI.getCountries();
    if (ERPResponse) {
      setCountries(ERPResponse);
    }
  };

  useEffect(async () => {
    if (customerID.includes("erp-")) {
      const ERPResponse = await ERPAPI.getCustomerBasic(customerID.replace("erp-", ""));
      if (ERPResponse && ERPResponse.data) {
        setCurrentCustomer({
          ...ERPResponse.data,
        });
      }
    } else {
      setCurrentCustomer({ ...customer });
    }
    getMasterData();
  }, [customer]);

  // eslint-disable-next-line no-unused-vars
  const handleChange = (name, value) => {
    setCurrentCustomer({
      ...currentCustomer,
      // [name]: value,
    });
  };

  const getVATResult = (vatValidationResult) => {
    switch (vatValidationResult) {
      case "0":
        return (
          <IconButton sx={{ cursor: "default" }}>
            <Icon color="success">check_circle</Icon>
          </IconButton>
        );
      case "1":
        return (
          <IconButton sx={{ cursor: "default" }}>
            <Icon color="error">cancel</Icon>
          </IconButton>
        );
      case "2":
        return (
          <IconButton sx={{ cursor: "default" }}>
            <Icon color="warning">help</Icon>
          </IconButton>
        );
      default:
        return (
          <IconButton sx={{ cursor: "default" }}>
            <Icon color="info">help</Icon>
          </IconButton>
        );
    }
  };

  const onClickVATInfo = async () => {
    if (customerID.includes("erp-")) {
      const ERPResponse = await ERPAPI.getCustomerVatInfo(customerID.replace("erp-", ""));
      if (ERPResponse) {
        setVatInfo(ERPResponse.data);
        setOpen(true);
      }
    }
  };

  // eslint-disable-next-line no-console
  console.log("render customer", customer.id, "Basic");

  return (
    <MDBox position="relative">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <Card sx={{ boxShadow: "none" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Address")}
              </MDTypography>
            </MDBox>
            <InputText
              label={t("Customer Number")}
              name="customerNumber"
              value={currentCustomer.customerNumber}
              onChange={handleChange}
              disabled
            />
            <InputText
              label={t("Shop ID")}
              name="shopId"
              value={currentCustomer.shopId}
              onChange={handleChange}
              disabled
            />
            <InputText
              label={t("Match Code")}
              name="matchcode"
              value={currentCustomer.matchcode}
              onChange={handleChange}
            />
            <InputDropdown
              label={t("Salutation")}
              options={salutations}
              name="title"
              onChange={handleChange}
              value={currentCustomer.title}
            />
            <InputText
              label={t("Name 1")}
              name="name1"
              value={currentCustomer.name1}
              onChange={handleChange}
            />
            <InputText
              label={t("Name 2")}
              name="name2"
              value={currentCustomer.name2}
              onChange={handleChange}
            />
            <InputText
              label={t("Addition")}
              name="addition"
              value={currentCustomer.addition}
              onChange={handleChange}
            />
            <InputText
              label={t("Street, no.")}
              name="street"
              value={currentCustomer.street}
              onChange={handleChange}
            />
            <InputText
              label={t("Zipcode")}
              name="zipCode"
              value={currentCustomer.zipCode}
              onChange={handleChange}
            />
            <InputText
              label={t("City")}
              name="city"
              value={currentCustomer.city}
              onChange={handleChange}
            />
            <InputText
              label={t("County")}
              name="county"
              value={currentCustomer.county}
              onChange={handleChange}
            />
            <InputDropdown
              label={t("Country")}
              options={countries}
              name="country"
              onChange={handleChange}
              value={currentCustomer.country}
            />
            <InputDropdown
              label={t("Language")}
              options={languages}
              name="language"
              onChange={handleChange}
              value={currentCustomer.language}
            />
            <InputText
              label={t("WebSite")}
              name="homePage"
              value={currentCustomer.homePage}
              onChange={handleChange}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} xl={4} style={{ display: "flex", position: "relative" }}>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: "4px",
              m: 0,
              display: { xs: "none", md: "block" },
            }}
          />
          <Card sx={{ boxShadow: "none", width: "100%" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Communication")}
              </MDTypography>
            </MDBox>
            <InputText
              label={t("Internal Contact")}
              name="internalContact"
              value={currentCustomer.internalContact}
              onChange={handleChange}
            />
            <InputText
              label={t("Email")}
              name="email"
              value={currentCustomer.email}
              onChange={handleChange}
            />
            <InputText
              label={t("Mobile")}
              name="mobile"
              value={currentCustomer.mobile}
              onChange={handleChange}
            />
            <InputText
              label={t("Telephone")}
              name="telephone"
              value={currentCustomer.telephone}
              onChange={handleChange}
            />
            <InputText
              label={t("Fax")}
              name="fax"
              value={currentCustomer.fax}
              onChange={handleChange}
            />
            <InputText
              label={t("Invoice Mail")}
              name="secondInvoiceEmail"
              onChange={handleChange}
              value={currentCustomer.secondInvoiceEmail}
            />
            <InputAutocomplete
              label={t("Invoice Delivery")}
              options={invoiceTypes}
              name="invoiceDelivery"
              onChange={handleChange}
              value={currentCustomer.invoiceDelivery}
            />
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Taxation & Orders")}
              </MDTypography>
            </MDBox>
            <InputDropdown
              label={t("Customer Group")}
              options={customerGroups}
              name="customerGroup"
              onChange={handleChange}
              value={currentCustomer.customerGroup}
            />
            <InputAutocomplete
              label={t("Taxation")}
              options={taxations}
              name="taxationType"
              onChange={handleChange}
              value={currentCustomer.taxationType}
            />
            <MDBox display="flex" alignItems="center">
              <MDBox flex={1}>
                <InputText
                  label={t("VAT ID")}
                  name="vatID"
                  onChange={handleChange}
                  value={currentCustomer.vatID}
                />
              </MDBox>
              <MDBox flex={1}>
                <InputText
                  label={t("Status")}
                  name="vatVerificationResult"
                  value={currentCustomer.vatVerificationResult}
                  onChange={handleChange}
                />
              </MDBox>
              <MDBox mb={2} pr={2}>
                {getVATResult(currentCustomer.vatValidationResult)}
              </MDBox>
              <MDBox mb={2} pr={2}>
                <IconButton
                  onClick={() => {
                    onClickVATInfo();
                  }}
                >
                  <Icon>info</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
            <InputText
              label={t("Local Tax Number")}
              name="localTaxNumber"
              onChange={handleChange}
              value={currentCustomer.localTaxNumber}
            />
            <InputDropdown
              label={t("EU Country")}
              options={countries}
              name="euCountry"
              onChange={handleChange}
              value={currentCustomer.euCountry}
            />
            <InputAutocomplete
              label={t("Price List")}
              options={priceList}
              name="priceList"
              onChange={handleChange}
              value={currentCustomer.priceList}
            />
            <InputAutocomplete
              label={t("Currency")}
              options={currencyList}
              name="currencyIso"
              onChange={handleChange}
              value={currentCustomer.currencyIso}
            />
          </Card>
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              m: 0,
              right: "-4px",
              display: { xs: "none", xl: "block" },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={4} style={{ display: "flex", position: "relative" }}>
          <Card sx={{ boxShadow: "none", width: "100%" }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Settings & Marketing")}
              </MDTypography>
            </MDBox>
            <InputCheckbox
              valueInt
              label={t("Delivery Block")}
              name="deliveryBlock"
              onChange={handleChange}
              value={currentCustomer.deliveryBlock}
            />
            <InputCheckbox
              valueInt
              label={t("Partial Delivery")}
              name="paritalDelivery"
              onChange={handleChange}
              value={currentCustomer.paritalDelivery}
            />
            <InputCheckbox
              valueInt
              label={t("Prohibit Invoice Purchase")}
              name="prohibitInvoicePurchase"
              onChange={handleChange}
              value={currentCustomer.prohibitInvoicePurchase}
            />
            <InputCheckbox
              valueInt
              label={t("Customer Active")}
              name="active"
              onChange={handleChange}
              value={currentCustomer.active}
            />
            <InputCheckbox
              valueInt
              label={t("UStID Checked")}
              name="vatIdChecked"
              onChange={handleChange}
              value={currentCustomer.vatIdChecked}
            />
            <InputAutocomplete
              label={t("B2B / B2C")}
              options={b2bList}
              name="userB2bB2c"
              onChange={handleChange}
              value={currentCustomer.userB2bB2c}
            />
            <InputAutocomplete
              label={t("Industry")}
              options={industryList}
              name="industry"
              onChange={handleChange}
              value={currentCustomer.industry}
            />
            <InputText
              label={t("Customer Type")}
              name="customerType"
              value={currentCustomer.customerType}
              onChange={handleChange}
            />
            <InputAutocomplete
              label={t("Print Marketing")}
              options={printMarketingList}
              name="printMarketing"
              onChange={handleChange}
              value={currentCustomer.printMarketing}
            />
            <InputAutocomplete
              label={t("Editing")}
              options={editingList}
              name="ads"
              onChange={handleChange}
              value={currentCustomer.ads}
            />
            <InputAutocomplete
              label={t("Requirement")}
              options={requirementList}
              name="need"
              onChange={handleChange}
              value={currentCustomer.need}
            />
            <InputAutocomplete
              label={t("Payment Condition")}
              options={paymentConditions}
              name="paymentCondition"
              onChange={handleChange}
              value={currentCustomer.paymentCondition}
            />
            <InputAutocomplete
              label={t("Delivery Condition")}
              options={deliveryConditions}
              name="termsOfDelivery"
              onChange={handleChange}
              value={currentCustomer.termsOfDelivery}
            />
            <InputAutocomplete
              label={t("Type of Dispatch")}
              options={typeOfDispatchList}
              name="modeOfDispatch"
              onChange={handleChange}
              value={currentCustomer.modeOfDispatch}
            />
          </Card>
        </Grid>
      </Grid>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <MDBox px={2}>
            <MDButton variant="gradient" color="error" size="medium">
              {t("Cancel")}
            </MDButton>
          </MDBox>
          <MDBox px={2}>
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={() => {
                window.dispatchEvent(
                  new CustomEvent("snackbar", {
                    detail: {
                      type: "OK",
                      title: "Customer",
                      content: "Saved successfully",
                    },
                  })
                );
              }}
            >
              {t("Save")}
            </MDButton>
          </MDBox>
        </Grid>
      </Grid> */}
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>
          <MDBox display="flex" alignItems="center" justifyContent="space-between">
            {t("VAT ID no Verification")}
            <IconButton
              size="medium"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </MDBox>
        </DialogTitle>
        <DialogContent dividers>
          <MDBox py={2}>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MDTypography variant="body" fontWeight="medium">
                      {t("Individual VAT ID no.")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.ownVatId}
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body" fontWeight="medium">
                      {t("Current account")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.customerNumber}
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body" fontWeight="medium">
                      {t("Requested VAT ID no.")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.requestedVatId}
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body" fontWeight="medium">
                      {t("Name and legal form")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatLastVerifiedName}
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body" fontWeight="medium">
                      {t("Street")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatLastVerifiedStreet}
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body" fontWeight="medium">
                      {t("Postcode")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatLastVerifiedZipCode}
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <MDTypography variant="body" fontWeight="medium">
                      {t("City")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatLastVerifiedLocation}
                    </MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MDTypography variant="body" fontWeight="medium" textTransform="capitalize">
                      {t("Verification Results")}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body"
                      color="text"
                      sx={{ display: "block", paddingTop: "26px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="body" color="text">
                      {!vatInfo.vatValidationResult ? "Not checked" : null}
                      {vatInfo.vatValidationResult === "0" ? "Valid" : null}
                      {vatInfo.vatValidationResult === "1" ? "Invalid" : null}
                      {vatInfo.vatValidationResult === "2" ? "No response" : null}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatValidationName === "A" ? "Exact Match" : null}
                      {vatInfo.vatValidationName === "B" ? "Does not Match" : null}
                      {vatInfo.vatValidationName === "Z" ? "No Result" : null}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatValidationStreet === "A" ? "Exact Match" : null}
                      {vatInfo.vatValidationStreet === "B" ? "Does not Match" : null}
                      {vatInfo.vatValidationStreet === "Z" ? "No Result" : null}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatValidationZipCode === "A" ? "Exact Match" : null}
                      {vatInfo.vatValidationZipCode === "B" ? "Does not Match" : null}
                      {vatInfo.vatValidationZipCode === "Z" ? "No Result" : null}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="body" color="text">
                      {vatInfo.vatValidationLocation === "A" ? "Exact Match" : null}
                      {vatInfo.vatValidationLocation === "B" ? "Does not Match" : null}
                      {vatInfo.vatValidationLocation === "Z" ? "No Result" : null}
                    </MDTypography>
                  </Grid>
                </Grid>
              </Grid>
              {vatInfo.vatValidationDate ? (
                <Grid item xs={12}>
                  <MDTypography variant="body" fontWeight="medium">
                    {t("Verified on")}{" "}
                    {moment.utc(vatInfo.vatValidationDate).format(CommonFun.getDateTimeFormat())}
                  </MDTypography>
                </Grid>
              ) : null}
            </Grid>
          </MDBox>
        </DialogContent>
      </Dialog>
    </MDBox>
  );
}

export default memo(Basic);
