import Fetch from "./fetch";

const LogAPI = {
  async getAllByFilter(filter, perPage = 50, page = 0, orderBy, order) {
    const params = [];
    params.push(`page=${page + 1}`);
    params.push(`per_page=${perPage}`);
    params.push(`sort_by=${orderBy}`);
    params.push(`sort_order=${order}`);

    const response = await Fetch.post(`api/audit/filter?${params.join("&")}`, filter);
    return response?.data;
  },
};
export default LogAPI;
