import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

export default function Dashboard() {
  const navigate = useNavigate();

  useEffect(async () => {
    const newTab = {
      id: "dashboard",
      type: "DASHBOARD",
      label: "Dashboard",
    };
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    navigate("/home");
  }, []);

  return (
    <MDBox>
      <Progress loading isFull />
    </MDBox>
  );
}
