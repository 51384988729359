/* eslint-disable no-eval */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import MDTypography from "components/MDTypography";
import CommonFun from "services/common";

function DataCell({ column, value, row }) {
  if (column.amount) {
    return (
      <MDTypography variant="caption" color="black" fontWeight="medium">
        {value ? value.toFixed(2) : "0.00"}
      </MDTypography>
    );
  }
  if (column.currency) {
    return (
      <MDTypography variant="caption" color="black" fontWeight="medium">
        {CommonFun.displayCurrency(
          value,
          row[column.currency] ? row[column.currency] : column.currency
        )}
      </MDTypography>
    );
  }
  if (column.dateFormat) {
    return (
      <MDTypography variant="caption" color="black" fontWeight="medium">
        {moment.utc(value).format(column.dateFormat)}
      </MDTypography>
    );
  }
  return (
    <MDTypography variant="caption" color="black" fontWeight="medium">
      {value}
    </MDTypography>
  );
}

function StatisticsTable({
  columns,
  rows,
  getRowProps,
  order,
  orderBy,
  onRequestSort,
  tableHighLight = false,
  stickyHeader,
}) {
  const { t } = useTranslation("common");
  let style = {};
  if (tableHighLight && stickyHeader) {
    style = { maxHeight: stickyHeader };
  } else if (!tableHighLight && stickyHeader) {
    style = { maxHeight: stickyHeader, boxShadow: "none" };
  } else if (stickyHeader) {
    style = { maxHeight: stickyHeader };
  } else if (!tableHighLight) {
    style = { boxShadow: "none" };
  }
  return (
    <TableContainer sx={style}>
      <Table stickyHeader={!!stickyHeader}>
        <TableHead sx={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.accessor}
                align={column.align}
                sortDirection={orderBy === column.accessor ? order : false}
                style={{
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  whiteSpace: "nowrap",
                }}
              >
                {column.sort ? (
                  <TableSortLabel
                    active={orderBy === column.accessor}
                    direction={orderBy === column.accessor ? order : "asc"}
                    onClick={() => {
                      onRequestSort(column.accessor);
                    }}
                  >
                    <MDTypography
                      variant="caption"
                      color="black"
                      fontWeight="bold"
                      textTransform="uppercase"
                      sx={({ typography: { size } }) => ({
                        fontSize: size.xs,
                      })}
                    >
                      {column.header}
                    </MDTypography>
                  </TableSortLabel>
                ) : (
                  <MDTypography
                    variant="caption"
                    color="black"
                    fontWeight="bold"
                    textTransform="uppercase"
                    sx={({ typography: { size } }) => ({
                      fontSize: size.xs,
                    })}
                  >
                    {column.header}
                  </MDTypography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column) => {
                const value = eval(`row.${column.accessor}`);
                return (
                  <TableCell
                    key={column.accessor}
                    align={column.align}
                    style={{
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "24px",
                      paddingRight: "24px",
                      whiteSpace: "nowrap",
                    }}
                    {...(column.getCellProps ? column.getCellProps(row) : getRowProps(row))}
                  >
                    {column.render ? (
                      column.render(row)
                    ) : (
                      <DataCell column={column} value={value} row={row} />
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {!rows.length && (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                align="center"
                style={{
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  whiteSpace: "nowrap",
                }}
              >
                <MDTypography variant="caption" color="black" fontWeight="medium">
                  {t("No data to show")}
                </MDTypography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(StatisticsTable);
