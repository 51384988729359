module.exports = {
  PRODUCTION: process.env.REACT_APP_PRODUCTION === "true",
  BRAND_NAME: process.env.REACT_APP_BRAND_NAME || "Automation24",
  BRAND_LOGO: process.env.REACT_APP_BRAND_LOGO || "/logo.png",
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || "",
  AZURE_CLIENT_ID: process.env.REACT_APP_AZURE_CLIENT_ID || "",
  AZURE_TENANT_ID: process.env.REACT_APP_AZURE_TENANT_ID || "",
  USER_ROLE_ADMIN: "admin",
  USER_ROLE_CRM: "crmuser",
  TICKET_STATUS_OPEN_ID: process.env.REACT_APP_TICKET_STATUS_OPEN_ID
    ? parseInt(process.env.REACT_APP_TICKET_STATUS_OPEN_ID, 10)
    : 1,
  TICKET_STATUS_CLOSE_ID: process.env.REACT_APP_TICKET_STATUS_CLOSE_ID
    ? parseInt(process.env.REACT_APP_TICKET_STATUS_CLOSE_ID, 10)
    : 5,
  TICKET_INTERACTION_TYPE_EMAIL: 1,
  TICKET_INTERACTION_TYPE_PHONE: 2,
  TICKET_INTERACTION_TYPE_CHAT: 3,
  // Master Data Types
  MDT_LANGUAGE: "2",
  MDT_SALUTATION: "4",
  MDT_COUNTRIES: "6",
  MDT_CUSTOMER_GROUP: "11",
  // address group: 10, 12, 13, 14, 16,
  REGION_US: 1,
  DATE_FORMAT: "DD/MM/YYYY",
  DATE_TIME_FORMAT: "DD/MM/YYYY, hh:mm:ss A",
  DATE_FORMAT_US: "MM/DD/YYYY",
  DATE_TIME_FORMAT_US: "MM/DD/YYYY, hh:mm:ss A",
};
