import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import CustomerAPI from "services/customers";
import TicketsAPI from "services/tickets";
import ERPAPI from "services/erp";

export default function customer() {
  const navigate = useNavigate();
  const { customerId, tabId } = useParams();
  const tabs = [
    "overview",
    "basic",
    "contact",
    "orders",
    "items",
    "quotations",
    "collective-folder",
    "dms-documents",
    "history",
  ];

  useEffect(async () => {
    if (customerId) {
      const tabsStr = localStorage.getItem("A24_TABS");
      let tabList = [];
      if (tabsStr && tabsStr !== "") {
        tabList = JSON.parse(tabsStr);
      }
      const newTab = {
        id: customerId,
        name: "",
        type: "CUSTOMER",
        label: customerId.includes("new-") ? "#new" : ``,
      };
      const index = tabList.findIndex((e) => e.id === customerId && !e.deleted);
      if (index === -1) {
        let name = "unknown";
        if (customerId.includes("new-ticket-")) {
          const response = await TicketsAPI.getTicketDetails(customerId.replace("new-ticket-", ""));
          if (response) {
            name = response.customer_name;
          }
        } else if (customerId.includes("erp-")) {
          const ERPResponse = await ERPAPI.getCustomerDetails(customerId.replace("erp-", ""));
          if (ERPResponse && ERPResponse.data) {
            name =
              ERPResponse.data.shippingAddress && ERPResponse.data.shippingAddress.name2
                ? ERPResponse.data.shippingAddress.name2
                : ERPResponse.data.customerNumber;
          }
        } else if (customerId.includes("lead-")) {
          const response = await CustomerAPI.getCustomerDetails(customerId.replace("lead-", ""));
          if (response) {
            name = response.first_name;
          }
        }
        tabList.push({ ...newTab, name });
        await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
      }
      await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
      if (tabId) {
        const tabIndex = tabs.findIndex((t) => t === tabId);
        const tabStateStr = localStorage.getItem("A24_CUSTOMER_TAB_STATE");
        let tabStateJson = {};
        if (tabStateStr && tabStateStr !== "") {
          tabStateJson = JSON.parse(tabStateStr);
        }
        tabStateJson[customerId] = tabIndex !== -1 ? tabIndex : 0;
        await localStorage.setItem("A24_CUSTOMER_TAB_STATE", JSON.stringify(tabStateJson));
      }
    }
    navigate("/home");
  }, []);

  return (
    <MDBox>
      <Progress loading isFull />
    </MDBox>
  );
}
