import PropTypes from "prop-types";
import Container from "@mui/material/Container";

import MDBox from "components/MDBox";

import typography from "assets/theme/base/typography";
import { BRAND_NAME } from "environment";

function Footer({ light }) {
  const { size } = typography;

  return (
    <MDBox position="absolute" width="100%" bottom={0} py={4}>
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "white" : "black"}
            fontSize={size.sm}
          >
            {BRAND_NAME} | {process.env.REACT_APP_VERSION} | Copyright &copy;{" "}
            {new Date().getFullYear()}
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

Footer.defaultProps = {
  light: false,
};

Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
