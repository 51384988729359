import Fetch from "./fetch";

const customerAPI = {
  async getAll(perPage = 50, page = 1) {
    const response = await Fetch.get(`api/customer?page=${page + 1}&per_page=${perPage}`);
    return response?.data;
  },
  async searchCustomer(data) {
    const response = await Fetch.post(`api/customers/search`, data);
    return response?.data;
  },
  async getCustomerDetails(customerID) {
    const response = await Fetch.get(`api/customer/${customerID}`);
    return response?.data;
  },
  async createCustomer(data) {
    const response = await Fetch.post(`api/customer`, data);
    return response?.data;
  },
  async updateCustomer(customerId, data) {
    const response = await Fetch.post(`api/customer/${customerId}`, data);
    return response?.data;
  },
};
export default customerAPI;
