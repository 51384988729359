/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import StatisticsTable from "components/Tables/StatisticsTable";
import MDTypography from "components/MDTypography";
import { InputCheckbox } from "components/MDInputs";
import Progress from "components/Progress";

import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Shops({ articleNumber }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const [accessories, setAccessories] = useState([]);

  const getItemStatus = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getItemStatus(articleNumber);
    if (ERPResponse && ERPResponse.data) {
      setStatus([...ERPResponse.data.status]);
    } else {
      setStatus([]);
    }
    setLoading(false);
  };

  const getItemAccessories = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getItemAccessories(articleNumber);
    if (ERPResponse && ERPResponse.data) {
      setAccessories([...ERPResponse.data]);
    } else {
      setAccessories([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getItemAccessories();
    getItemStatus();
  }, [articleNumber]);

  // const handleChange = (name, value) => {
  //   setCurrentItem({
  //     ...currentItem,
  //     [name]: value,
  //   });
  // };

  const columns = [
    {
      header: t("Article Number"),
      accessor: "articleNumber",
      align: "left",
      sort: false,
      render: (row) => (
        <MDTypography variant="caption" color="info" fontWeight="medium">
          {row.articleNumber}
        </MDTypography>
      ),
      getCellProps: (row) => ({
        onClick: () => {
          CommonFun.onClickItem(row.articleNumber);
        },
        style: {
          cursor: "pointer",
        },
      }),
    },
    {
      header: t("Article Group"),
      accessor: "articleGroup",
      align: "left",
      sort: false,
    },
    {
      header: t("Match Code"),
      accessor: "matchcode",
      align: "left",
      sort: false,
    },
    {
      header: t("Available Stock"),
      accessor: "availableStock",
      align: "left",
      sort: false,
    },
  ];

  return (
    <Grid container spacing={1}>
      <Progress loading={loading} />
      <Grid item xs={12} md={8}>
        <Card sx={{ boxShadow: "none" }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {t("Accessories")}
            </MDTypography>
          </MDBox>
          <MDBox px={2}>
            <StatisticsTable
              tableHighLight
              columns={columns}
              rows={accessories}
              getRowProps={() => ({})}
            />
          </MDBox>
        </Card>
      </Grid>
      <Grid item xs={12} md={2}>
        <Card sx={{ boxShadow: "none" }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {t("Shop Active")}
            </MDTypography>
          </MDBox>
          {status.map((item, key) => (
            <InputCheckbox
              valueInt
              key={key}
              label={item.shopId}
              name={item.shopId}
              value={item.isActive}
            />
          ))}
        </Card>
      </Grid>
      <Grid item xs={12} md={2} sx={{ display: "flex", position: "relative" }}>
        <Divider
          orientation="vertical"
          sx={{ ml: -1, mr: 0, display: { xs: "none", md: "block" } }}
        />
        <Card sx={{ boxShadow: "none", width: "100%" }}>
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {t("Shop created")}
            </MDTypography>
          </MDBox>
          {status.map((item, key) => (
            <InputCheckbox
              valueInt
              key={key}
              label={item.shopId}
              name={item.shopId}
              value={item.isActive}
            />
          ))}
        </Card>
      </Grid>
    </Grid>
  );
}

export default memo(Shops);
