/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import TasksAPI from "services/tasks";
import ERPAPI from "services/erp";
import CustomersAPI from "services/customers";
import CommonFun from "services/common";

import CreateTask from "view/components/CreateTask";

function Reminders({ customerID, setRemindersCount }) {
  const isNew = customerID.includes("new-");
  const isERP = customerID.includes("erp-");
  const isLead = customerID.includes("lead-");

  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [open, setOpen] = useState(false);

  const getTasks = async () => {
    setLoading(true);
    const data = {};
    if (isERP) {
      data.erp_customer_id = customerID.replace("erp-", "");
    } else if (isLead) {
      data.customer_id = customerID.replace("lead-", "");
    }
    if (data.erp_customer_id || data.customer_id) {
      const response = await TasksAPI.getTaskByFilter(1000, 0, data);
      if (response && response.data) {
        setTasks([...response.data]);
        setRemindersCount(response.data.length);
      } else {
        setTasks([]);
        setRemindersCount(0);
      }
    }
    setLoading(false);
  };

  useEffect(async () => {
    getTasks();
  }, []);

  const handleSave = async (currentTask) => {
    // setLoading(true);
    const response = await TasksAPI.createTask({
      ...currentTask,
      ticket_id: null,
      customer_id: isLead ? customerID.replace("lead-", "") : null,
      erp_customer_id: isERP ? customerID.replace("erp-", "") : null,
      task_type: 0,
    });
    if (response && response.data) {
      setOpen(false);
      getTasks();
    }
    // setLoading(false);
  };

  const handleTicket = async (row) => {
    let erpData = {};
    let leadData = {};
    if (row.ticket.customer_email) {
      erpData = {
        email: row.ticket.customer_email,
      };
      leadData = {
        email: row.ticket.customer_email,
      };
    } else {
      erpData = {
        phone: CommonFun.serverPhone(row.ticket.from_phone),
      };
      leadData = {
        phone: CommonFun.serverPhone(row.ticket.from_phone),
      };
    }
    const erpResponse = await ERPAPI.findCustomer(erpData);
    const response = await CustomersAPI.searchCustomer(leadData);
    const searchList = [];
    if (
      erpResponse &&
      erpResponse.data &&
      erpResponse.data.items &&
      erpResponse.data.items.length
    ) {
      erpResponse.data.items.forEach((item) => {
        searchList.push({
          id: item.customerNumber,
          name:
            item.shippingAddress && item.shippingAddress.name2
              ? item.shippingAddress.name2
              : item.customerNumber,
          email: item.email,
          phone: item.phone,
          mobile: item.mobile,
          ticketID: row.ticket.id,
          lead: false,
        });
      });
    }
    if (response && response.length) {
      response.forEach((item) => {
        searchList.push({
          id: item.id,
          name: `${item.first_name ? item.first_name : ""} ${item.last_name ? item.last_name : ""}`,
          email: item.email,
          phone: item.phone,
          mobile: item.mobile,
          ticketID: row.ticket.id,
          lead: true,
        });
      });
    }
    if (searchList.length === 0) {
      CommonFun.onClickCustomer(
        `new-ticket-${row.ticket.id}`,
        row.ticket.customer_name,
        row.ticket.customer_email,
        row.ticket.from_phone,
        null,
        row.ticket.id
      );
    } else if (searchList.length === 1) {
      CommonFun.onClickCustomer(
        `${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`,
        searchList[0].name,
        searchList[0].email,
        searchList[0].phone,
        searchList[0].mobile,
        searchList[0].ticketID
      );
    } else {
      CommonFun.onClickCustomer(
        `${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`,
        searchList[0].name,
        searchList[0].email,
        searchList[0].phone,
        searchList[0].mobile,
        searchList[0].ticketID
      );
    }
  };

  const handleDelete = async (taskID) => {
    setLoading(true);
    const response = await TasksAPI.deleteTask(taskID);
    if (response) {
      await getTasks();
    }
    setLoading(false);
  };

  return (
    <MDBox p={1} sx={{ backgroundColor: "#f0f2f5" }}>
      <Progress loading={loading} isFull />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MDBox sx={{ display: "flex", justifyContent: "flex-start" }}>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              disabled={isNew}
              onClick={() => {
                setOpen(true);
              }}
            >
              {t("Add")}
            </MDButton>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          {tasks.map((item, index) => (
            <Card key={index} sx={{ marginBottom: 1, borderRadius: 0, flexDirection: "row" }}>
              <CardContent sx={{ padding: "0.5rem 1rem", flex: 1 }}>
                <MDBox display="flex" flexDirection="column">
                  <MDTypography variant="button" fontWeight="medium" color="black">
                    {item.title || "Unknown"}
                  </MDTypography>
                  <MDTypography mt={0.5} variant="button" color="text">
                    {moment.utc(item.task_date).format(CommonFun.getDateTimeFormat())}
                  </MDTypography>
                  <MDBox display="flex" mt={0.5} alignItems="center">
                    <Icon color="info">assignment_ind</Icon>
                    <MDTypography ml={0.5} variant="button" color="text">
                      {item.task_user.name}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </CardContent>
              <CardActions sx={{ padding: "0.5rem" }}>
                {item.ticket_id && item.ticket ? (
                  <Tooltip title={t("Go to Ticket")}>
                    <IconButton
                      color="info"
                      sx={{ marginRight: 1 }}
                      onClick={() => {
                        handleTicket(item);
                      }}
                    >
                      <Icon>task</Icon>
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip title={t("Delete")}>
                  <IconButton
                    color="error"
                    sx={{ marginRight: 1 }}
                    onClick={() => {
                      handleDelete(item.id);
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          ))}
        </Grid>
      </Grid>
      <CreateTask open={open} onSave={handleSave} onCancel={setOpen} />
    </MDBox>
  );
}

export default memo(Reminders);
