/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import FilterInput from "./filterInput";

function TableSearch({ columns, onSearch }) {
  const { t } = useTranslation("common");
  const [reportType, setReportType] = useState(3);
  const [orderCondition, setOrderCondition] = useState(null);
  const [isBlocked, setIsBlocked] = useState(null);
  const [searchObject, setSearchObject] = useState({ ReportType: [3] });
  const [searchResult, setSearchResult] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [clearAll, setClearAll] = useState(false);

  const reportList = [
    {
      id: 1,
      label: "Abholung",
    },
    {
      id: 2,
      label: "Abrechenbar, Adresse fehlt",
    },
    {
      id: 3,
      label: "Abrechenbare Aufträge",
    },
    {
      id: 4,
      label: "AmazonMCFAufträge",
    },
    {
      id: 5,
      label: "StucklisteUnvollständig",
    },
    {
      id: 6,
      label: "VorauskasseNichtBezahlt",
    },
    {
      id: 7,
      label: "VorauskasseUberbezahlt",
    },
    {
      id: 8,
      label: "VorauskasseUnterbezahlt",
    },
  ];

  const searchableColumnsLabels = {
    DateFrom: t("Date From"),
    DateTo: t("Date To"),
    CountryCodes: t("Country Codes"),
    DocumentNumbers: t("Document Numbers"),
    CustomerNumbers: t("Customer Numbers"),
    ArticleNumbers: t("Article Numbers"),
  };

  const addFilter = async (key, value) => {
    let filterList = searchObject;
    if (!filterList) {
      filterList = {};
    }
    if (!filterList[key]) {
      filterList[key] = [];
    }
    const findIndex = filterList[key].findIndex((x) => x === value);
    if (findIndex === -1) {
      filterList[key].push(value);
    }
    await setSearchObject({ ...filterList });
    setFilterChanged(true);
  };

  const removeFilter = async (key, value) => {
    const filterList = searchObject;
    if (filterList[key].length === 1) {
      delete filterList[key];
    } else {
      const findIndex = filterList[key].findIndex((x) => x === value);
      if (findIndex !== -1) {
        filterList[key].splice(findIndex, 1);
      }
    }
    if (Object.keys(filterList).length === 0) {
      await setSearchObject(null);
    } else {
      await setSearchObject({ ...filterList });
    }
    setFilterChanged(true);
  };

  const handleAdvancedSearch = () => {
    setOpen(false);
    const data = {};
    let searchOn = false;
    if (reportType) {
      data.ReportType = [reportType];
    }
    if (orderCondition) {
      data.OrderCondition = [orderCondition];
    }
    if (isBlocked) {
      data.IsBlocked = [isBlocked];
    }
    if (searchObject) {
      for (const property in searchObject) {
        if (searchObject[property]) {
          data[property] = searchObject[property];
          searchOn = true;
        }
      }
    }
    setSearchResult(searchOn);
    onSearch(data);
    setFilterChanged(false);
    setClearAll(false);
  };

  useEffect(async () => {
    handleAdvancedSearch();
  }, [reportType, orderCondition, isBlocked, clearAll]);

  const searchValues = [];
  if (searchObject) {
    Object.keys(searchObject).forEach((key) => {
      const type = columns.find((e) => e.name === key)?.type;
      const select = columns.find((e) => e.name === key)?.select;
      if (select) {
        searchObject[key].forEach((value) => {
          let formatValue = value;
          if (type === "checkbox") {
            formatValue = value ? "Yes" : "No";
          }
          searchValues.push({
            label: key,
            type,
            formatValue,
            value,
          });
        });
      }
    });
  }

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  }));

  return (
    <Grid item xs={12}>
      <MDBox pt={3} px={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox display="flex">
              <MDBox flex="1">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                    <MDInput
                      select
                      label={t("Report Type")}
                      name="reportType"
                      fullWidth
                      value={reportType}
                      onChange={(event) => {
                        setReportType(event.target.value);
                      }}
                    >
                      {reportList.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MDInput>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={9}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <FormControl>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ position: "absolute", top: "-15px" }}
                          >
                            {t("Order Condition")}
                          </MDTypography>
                          <RadioGroup
                            row
                            name="OrderCondition"
                            value={orderCondition}
                            onChange={(event) => {
                              setOrderCondition(event.target.value ? event.target.value : null);
                            }}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio size="small" />}
                              label={
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  {t("Deliverable")}
                                </MDTypography>
                              }
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio size="small" />}
                              label={
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  {t("Partial")}
                                </MDTypography>
                              }
                            />
                            <FormControlLabel
                              value="3"
                              control={<Radio size="small" />}
                              label={
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  {t("Out of Stock")}
                                </MDTypography>
                              }
                            />
                            <FormControlLabel
                              value={null}
                              control={<Radio size="small" />}
                              label={
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  {t("No Selection")}
                                </MDTypography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ position: "absolute", top: "-15px" }}
                          >
                            {t("Blocked Status")}
                          </MDTypography>
                          <RadioGroup
                            row
                            name="IsBlocked"
                            value={isBlocked}
                            onChange={(event) => {
                              setIsBlocked(event.target.value ? event.target.value : null);
                            }}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio size="small" />}
                              label={
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  {t("True")}
                                </MDTypography>
                              }
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio size="small" />}
                              label={
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  {t("False")}
                                </MDTypography>
                              }
                            />{" "}
                            <FormControlLabel
                              value={null}
                              control={<Radio size="small" />}
                              label={
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  {t("No Selection")}
                                </MDTypography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox>
                <IconButton
                  color="info"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Icon>filter_alt</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          </Grid>
          {searchObject && searchValues && searchValues.length ? (
            <Grid item xs={12}>
              <MDBox pt={0}>
                {/* <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {t("Selected Search")}
                </MDTypography> */}
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  flexWrap="wrap"
                  sx={{ paddingTop: 1 }}
                >
                  {searchValues.map((item, key) => (
                    <Chip
                      key={key}
                      label={`${searchableColumnsLabels[item.label]}: ${item.formatValue}`}
                      variant="outlined"
                      onDelete={() => {
                        removeFilter(item.label, item.value);
                      }}
                    />
                  ))}
                  {searchResult ? (
                    <MDButton
                      variant="gradient"
                      color="error"
                      size="small"
                      sx={{ marginRight: 1 }}
                      onClick={() => {
                        setSearchObject(null);
                        setSearchResult(false);
                        setFilterChanged(true);
                        setOrderCondition(null);
                        setIsBlocked(null);
                        setClearAll(true);
                      }}
                    >
                      {t("Clear All")}
                    </MDButton>
                  ) : null}
                  {filterChanged ? (
                    <MDButton
                      className={filterChanged ? "MUIButton-pulse" : ""}
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => {
                        handleAdvancedSearch();
                      }}
                    >
                      {t("Search")}
                    </MDButton>
                  ) : null}
                </Stack>
              </MDBox>
            </Grid>
          ) : null}
          <Drawer
            sx={{
              width: 300,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: 300,
                boxSizing: "border-box",
              },
            }}
            variant="temporary"
            anchor="right"
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <DrawerHeader>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                {t("Advanced Search")}
              </MDTypography>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </DrawerHeader>
            <Divider sx={{ margin: 0 }} />
            <MDBox p={2}>
              <Grid container spacing={2}>
                {columns
                  .filter((e) => e.select)
                  .map((col, index) => (
                    <FilterInput
                      col={col}
                      key={index}
                      addFilter={addFilter}
                      labels={searchableColumnsLabels}
                      disabled={
                        !col.multiple &&
                        searchObject &&
                        searchObject[col.name] &&
                        searchObject[col.name].length
                      }
                    />
                  ))}
                <Grid item xs={12}>
                  <MDButton
                    className={filterChanged ? "MUIButton-pulse" : ""}
                    variant="gradient"
                    color="info"
                    size="medium"
                    sx={{ width: "100%" }}
                    onClick={() => {
                      handleAdvancedSearch();
                    }}
                  >
                    {t("Search")}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Drawer>
        </Grid>
      </MDBox>
    </Grid>
  );
}

export default memo(TableSearch);
