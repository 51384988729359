import { forwardRef } from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import CalendarRoot from "components/MDCalendar/CalendarRoot";

const MDInput = forwardRef(({ ...rest }, ref) => (
  <CalendarRoot>
    <FullCalendar {...rest} ref={ref} plugins={[dayGridPlugin]} initialView="dayGridMonth" />
  </CalendarRoot>
));

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDInput;
