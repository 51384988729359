/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Progress from "components/Progress";
import MDButton from "components/MDButton";
import StatisticsTable from "components/Tables/StatisticsTable";

import SignaturesAPI from "services/signatures";
import CommonFun from "services/common";

function MySignatures() {
  const { t } = useTranslation("common");
  const [signatures, setSignatures] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSignatures = async () => {
    setLoading(true);
    const response = await SignaturesAPI.getAllByUser();
    if (response && response.length) {
      const result = [];
      response.forEach((item) => {
        item.signatures.forEach((item2) => {
          result.push({
            ...item2,
            default: item.default,
            created_at: item.created_at,
            updated_at: item.updated_at,
          });
        });
      });
      setSignatures(result);
    } else {
      setSignatures([]);
    }
    setLoading(false);
  };

  useEffect(async () => {
    getSignatures();
  }, []);

  const handelChangeDefault = async (row) => {
    setLoading(true);
    const response = await SignaturesAPI.setDefaultSignature(row.id);
    if (response && response.data && response.data.length) {
      const result = [];
      response.data.forEach((item) => {
        item.signatures.forEach((item2) => {
          result.push({
            ...item2,
            default: item.default,
            created_at: item.created_at,
            updated_at: item.updated_at,
          });
        });
      });
      setSignatures(result);
    } else {
      setSignatures([]);
    }
    setLoading(false);
  };

  const columns = [
    {
      header: t("Name"),
      accessor: "name",
      align: "left",
    },
    {
      header: t("Language"),
      accessor: "language",
      align: "left",
      sort: false,
    },
    {
      header: t("Created At"),
      accessor: "created_at",
      align: "left",
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.created_at).format(CommonFun.getDateTimeFormat())}
        </MDTypography>
      ),
    },
    {
      header: t("Updated At"),
      accessor: "updated_at",
      align: "left",
      sort: false,
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.updated_at).format(CommonFun.getDateTimeFormat())}
        </MDTypography>
      ),
    },
    {
      header: t("Default"),
      accessor: "default",
      align: "center",
      sort: false,
      render: (row) => (
        <>
          {row.default ? (
            <IconButton color="success">
              <Icon>check_circle</Icon>
            </IconButton>
          ) : (
            <MDButton
              color="info"
              size="small"
              onClick={() => {
                handelChangeDefault(row);
              }}
            >
              {t("Set as default")}
            </MDButton>
          )}
        </>
      ),
      getCellProps: () => ({
        // onClick: () => {}
        style: {
          cursor: "default",
        },
      }),
    },
  ];

  const rows = signatures;

  return (
    <MDBox>
      <Progress loading={loading} isFull />
      <MDBox pt={1}>
        <StatisticsTable
          columns={columns}
          rows={rows}
          getRowProps={() => ({
            onClick: () => {},
          })}
        />
      </MDBox>
    </MDBox>
  );
}

export default memo(MySignatures);
