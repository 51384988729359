import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

export default function Orders() {
  const navigate = useNavigate();

  useEffect(async () => {
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }
    let lastNewID = 0;
    tabList.forEach((item) => {
      if (item.id.includes("orders-")) {
        const idNo = parseInt(item.id.replace(/[^0-9]/g, ""), 10);
        if (lastNewID < idNo) {
          lastNewID = idNo;
        }
      }
    });
    const newTab = {
      id: `orders-${lastNewID + 1}`,
      type: "ORDERS",
      label: "#Orders",
    };
    tabList.push(newTab);
    await localStorage.setItem("A24_TABS", JSON.stringify(tabList));
    await sessionStorage.setItem("A24_ACTIVE_TAB", JSON.stringify(newTab));
    navigate("/home");
  }, []);

  return (
    <MDBox>
      <Progress loading isFull />
    </MDBox>
  );
}
