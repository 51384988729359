import MDBox from "components/MDBox";
import { styled } from "@mui/material/styles";

export default styled(MDBox)(() => {
  const Styles = () => ({
    height: "100%",
    padding: "0px 24px",

    ".fc-media-screen": {
      height: "100%",
      color: "#344767",
    },

    ".fc-theme-standard .fc-scrollgrid": {
      border: "none",
    },

    ".fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover": {
      background: "none",
    },

    ".fc-theme-standard thead tr th": {
      borderLeft: "none",
      borderRight: "none",
    },

    ".fc-theme-standard td, .fc-theme-standard th": {
      borderColor: "#f0f2f5",
    },

    ".fc .fc-view-harness": {
      height: "100% !important",
    },

    ".fc th": {
      textAlign: "center",
    },

    ".fc .fc-col-header-cell-cushion": {
      fontSize: "0.875rem",
      fontWeight: 600,
      color: "#adb5bd",
    },

    " .fc .fc-daygrid-day-number": {
      color: "#495057",
      fontSize: "0.875rem",
      fontWeight: 300,
      width: "100%",
      textAlign: " center",
    },

    ".fc-scrollgrid-section.fc-scrollgrid-section-header>td": {
      border: "none",
    },

    ".fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid>td": {
      border: "none",
    },

    ".fc-scrollgrid-sync-table": {
      height: "auto !important",
    },

    ".fc .fc-view-harness-active>.fc-view": {
      position: "static",
      height: "100%",
    },

    ".fc .fc-scroller-liquid-absolute": {
      position: "static",
    },

    ".fc-daygrid-event": {
      margin: "0.03125rem 0.125rem",
      border: "none",
      borderRadius: "0.35rem",
      fontSize: "0.875rem",
      fontWeight: 600,
    },

    ".fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events": {
      minHeight: "2rem",
    },

    ".fc-event-title ": {
      fontSize: "0.75rem !important",
      fontWeight: " 400 !important",
      padding: "0.125rem 0.3rem 0.09375rem !important",
    },

    ".fc-button, .fc-today-button, .fc-button:disabled": {
      backgroundColor: "#1A73E8 !important",
      borderColor: "#1A73E8 !important",
      fontSize: "0.875rem !important",
      boxShadow:
        "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06) !important",
      opacity: "1 !important",
      transition: "all 150ms ease-in",
    },

    ".fc-button:hover, .fc-today-button:hover, .fc-button:disabled:hover, .fc-button:focus, .fc-today-button:focus, .fc-button:disabled:focus, .fc-button:active, .fc-today-button:active, .fc-button:disabled:active":
      {
        transform: "scale(1.02)",
        boxShadow:
          "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06) !important",
        backgroundColor: "#1A73E8 !important",
        borderColor: "#1A73E8 !important",
      },

    ".fc .fc-button .fc-icon": {
      fontSize: "0.875rem",
    },

    ".fc-toolbar-title": {
      fontSize: "1.125rem !important",
      fontWeight: "700 !important",
      color: "#344767",
    },

    ".event-primary": {
      backgroundImage: "linear-gradient(195deg, #EC407A, #D81B60)",
    },

    ".event-primary * ": {
      color: " #ffffff",
    },

    ".event-secondary ": {
      backgroundImage: "linear-gradient(195deg, #747b8a, #495361)",
    },

    ".event-secondary *": {
      color: "#ffffff",
    },

    ".event-info ": {
      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
    },

    ".event-info *": {
      color: "#ffffff",
    },

    ".event-success": {
      backgroundImage: "linear-gradient(195deg, #66BB6A, #43A047)",
    },

    ".event-success * ": {
      color: "#ffffff",
    },

    ".event-warning ": {
      backgroundImage: "linear-gradient(195deg, #FFA726, #FB8C00)",
    },

    ".event-warning *": {
      color: "#ffffff",
    },

    ".event-error": {
      backgroundImage: "linear-gradient(195deg, #EF5350, #E53935)",
    },

    ".event-error *": {
      color: "#ffffff",
    },

    ".event-light ": {
      backgroundImage: "linear-gradient(195deg, #EBEFF4, #CED4DA)",
    },

    ".event-light *": {
      color: "#344767",
    },

    ".event-dark": {
      backgroundImage: "linear-gradient(195deg, #42424a, #191919)",
    },

    ".event-dark * ": {
      color: "#ffffff",
    },
  });

  return {
    ...Styles(),
  };
});
