/* eslint-disable react/prop-types */
import { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useLocalStorage from "hooks/useLocalStorage";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { USER_ROLE_ADMIN, USER_ROLE_CRM } from "environment";
import UsersAPI from "services/users";

function CreateTask({ open, onSave, onCancel }) {
  const { t } = useTranslation("common");
  const [currentTask, setCurrentTask] = useState({});
  const [users, setUsers] = useState([]);
  const [region] = useLocalStorage("A24_REGION", 0);

  const getVerifiedUsers = async () => {
    const response = await UsersAPI.getVerifiedUsers();
    if (response && response.data) {
      const userList = response.data.filter(
        (item) =>
          item.region === region &&
          item.email_verified &&
          !item.generic &&
          item.roles.length &&
          item.roles.filter((e) => e.name === USER_ROLE_ADMIN || e.name === USER_ROLE_CRM).length
      );
      userList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setUsers(userList);
    } else {
      setUsers([]);
    }
  };

  useEffect(async () => {
    if (open && !users.length) {
      getVerifiedUsers();
    }
    setCurrentTask({});
  }, [open]);

  const handleChange = (name, value) => {
    setCurrentTask({
      ...currentTask,
      [name]: value,
    });
  };

  const handleSave = async () => {
    if (currentTask.title && currentTask.user_id && currentTask.task_date) {
      onSave(currentTask);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>{t("Create Task")}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              required
              label={t("Title")}
              name="title"
              value={currentTask.title}
              onChange={(event) => {
                handleChange("title", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label={t("Description")}
              name="note"
              multiline
              rows={5}
              value={currentTask.note}
              onChange={(event) => {
                handleChange("note", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <MDInput
              required
              fullWidth
              label={t("Assign To")}
              name="user_id"
              select
              value={currentTask.user_id}
              onChange={(event) => {
                handleChange("user_id", event.target.value);
              }}
            >
              {users.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </MDInput>
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              required
              fullWidth
              label={t("Due Date")}
              value={currentTask.task_date ? moment(currentTask.task_date) : null}
              onChange={(value) => {
                handleChange(
                  "task_date",
                  value ? value.startOf("day").format("YYYY-MM-DD HH:mm:ss") : null
                );
              }}
              slotProps={{
                field: {
                  fullWidth: true,
                  required: true,
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          color="error"
          size="small"
          style={{ marginRight: 16 }}
          onClick={() => {
            onCancel(false);
          }}
        >
          {t("Cancel")}
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          size="small"
          onClick={() => {
            handleSave();
          }}
          disabled={!currentTask.title || !currentTask.user_id || !currentTask.task_date}
        >
          {t("Save")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default memo(CreateTask);
