/* eslint-disable react/no-array-index-key */
import { useEffect, useState, memo } from "react";
import juice from "juice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Progress from "components/Progress";
import StatisticsTable from "components/Tables/StatisticsTable";
import { InputText, InputEditor, InputDropdown } from "components/MDInputs";
import CkStyles from "components/Editors/CK/styles";

import TemplatesAPI from "services/templates";
import CommonFun from "services/common";
import UsersAPI from "services/users";
import { USER_ROLE_ADMIN, USER_ROLE_CRM } from "environment";

function Templates() {
  const { t } = useTranslation("common");
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [menu, setMenu] = useState(null);

  const getTemplates = async () => {
    setLoading(true);
    const response = await TemplatesAPI.getAll();
    if (response) {
      setTemplates(response);
    } else {
      setTemplates([]);
    }
    setLoading(false);
  };

  const getVerifiedUsers = async () => {
    const response = await UsersAPI.getVerifiedUsers();
    if (response && response.data) {
      const userList = response.data.filter(
        (item) =>
          item.email_verified &&
          !item.generic &&
          item.roles.length &&
          item.roles.filter((e) => e.name === USER_ROLE_ADMIN || e.name === USER_ROLE_CRM).length
      );
      userList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setUsers(userList);
    } else {
      setUsers([]);
    }
  };

  useEffect(async () => {
    getTemplates();
    setLanguages(CommonFun.getLanguageCountry());
  }, []);

  const openMenu = (event, cell) => {
    getVerifiedUsers();
    setMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      cell,
    });
  };

  const closeMenu = () => setMenu(null);

  const onDeleteTemplate = async (row) => {
    setLoading(true);
    const response = await TemplatesAPI.delete(row.id);
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Delete Template",
            content: "Template deleted successfully",
          },
        })
      );
      getTemplates();
    }
    setLoading(false);
  };

  const onCopyTemplate = async (templateId, userId) => {
    setLoading(true);
    const response = await TemplatesAPI.copyTemplate(templateId, userId);
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Template",
            content: "Template shared successfully",
          },
        })
      );
      closeMenu();
    }
    setLoading(false);
  };

  const onChange = (name, value) => {
    setCurrentTemplate({
      ...currentTemplate,
      [name]: value,
    });
  };

  const handleSave = async () => {
    setSubmitted(true);
    if (currentTemplate.name && currentTemplate.language && currentTemplate.content) {
      const response = await TemplatesAPI.create({
        ...currentTemplate,
        content: juice.inlineContent(currentTemplate.content, CkStyles),
      });
      if (response && response.data) {
        setLoading(true);
        setCurrentTemplate(null);
        setOpen(false);
        setSubmitted(false);
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "OK",
              title: "Template",
              content: "Saved Successfully",
            },
          })
        );
        getTemplates();
      } else {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      header: t("Name"),
      accessor: "name",
      align: "left",
      sort: false,
    },
    {
      header: t("Language"),
      accessor: "language",
      align: "left",
      sort: false,
    },
    {
      header: t("Content"),
      accessor: "content",
      align: "left",
      sort: false,
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {row.content && row.content.replace(/<[^>]+>/g, "").substring(0, 50)}
          {row.content && row.content.replace(/<[^>]+>/g, "").length > 50 ? "..." : ""}
        </MDTypography>
      ),
    },
    {
      header: t("Created At"),
      accessor: "created_at",
      align: "left",
      sort: false,
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.created_at).format(CommonFun.getDateTimeFormat())}
        </MDTypography>
      ),
    },
    {
      header: t("Updated At"),
      accessor: "updated_at",
      align: "left",
      sort: false,
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.updated_at).format(CommonFun.getDateTimeFormat())}
        </MDTypography>
      ),
    },
    {
      header: t("Action"),
      accessor: "delete",
      align: "center",
      sort: false,
      render: (row) => (
        <>
          <IconButton
            color="info"
            onClick={() => {
              setCurrentTemplate(row);
              setSubmitted(false);
              setOpen(true);
            }}
          >
            <Icon>edit</Icon>
          </IconButton>
          <IconButton
            color="success"
            onClick={(event) => {
              openMenu(event, row);
            }}
          >
            <Icon>share</Icon>
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              onDeleteTemplate(row);
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
          <Menu
            anchorReference="anchorPosition"
            anchorPosition={
              menu !== null ? { top: menu.position.top, left: menu.position.left } : undefined
            }
            open={!!(menu && row.id === menu.cell.id)}
            onClose={closeMenu}
          >
            <MenuItem
              disabled
              sx={{
                opacity: "1 !important",
                fontWeight: "bold",
                backgroundColor: "#c3c3c3",
                borderRadius: 0,
                color: "#000",
                marginBottom: "8px",
              }}
            >
              {t("Share to")} :
            </MenuItem>

            {users.map((user, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  onCopyTemplate(menu.cell.id, user.id);
                }}
              >
                {user.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      ),
      getCellProps: () => ({
        // onClick: () => {}
        style: {
          cursor: "default",
        },
      }),
    },
  ];

  const rows = templates;

  return (
    <MDBox>
      <Progress loading={loading} isFull />
      <MDBox py={1} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h5" fontWeight="medium" />
        <MDButton
          variant="gradient"
          color="success"
          size="small"
          onClick={() => {
            setCurrentTemplate({ language: "EN" });
            setSubmitted(false);
            setOpen(true);
          }}
        >
          {t("Add")}
        </MDButton>
      </MDBox>
      <MDBox>
        <StatisticsTable
          columns={columns}
          rows={rows}
          getRowProps={(row) => ({
            onClick: () => {
              setCurrentTemplate(row);
              setSubmitted(false);
              setOpen(true);
            },
            style: {
              cursor: "pointer",
            },
          })}
        />
      </MDBox>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          {currentTemplate && currentTemplate.id ? t("Edit Template") : t("Add Template")}
        </DialogTitle>
        <DialogContent dividers>
          {currentTemplate ? (
            <MDBox py={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputText
                    label={t("Name")}
                    name="name"
                    required
                    error={submitted && !currentTemplate.name}
                    value={currentTemplate.name}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputDropdown
                    label={t("Language")}
                    options={languages}
                    name="language"
                    onChange={onChange}
                    value={currentTemplate.language}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputEditor
                    label={t("Content")}
                    name="content"
                    placeholder={t("Type here")}
                    onChange={onChange}
                    value={currentTemplate.content}
                  />
                </Grid>
              </Grid>
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setCurrentTemplate(null);
              setOpen(false);
            }}
          >
            {t("Cancel")}
          </MDButton>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              handleSave();
            }}
          >
            {currentTemplate && currentTemplate.id ? t("Update") : t("Save")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default memo(Templates);
