/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";

function MDConfirm({ data }) {
  const { t } = useTranslation("common");
  return (
    <>
      {data ? (
        <Dialog
          open={data.open || false}
          onClose={data.handleDisagree}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{data.title}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">{data.contentText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={data.handleAgree} autoFocus>
              {data.agreeText || t("confirm")}
            </MDButton>
            <MDButton onClick={data.handleDisagree}>{data.disagreeText || t("cancel")}</MDButton>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
}

export default MDConfirm;
