import Fetch from "./fetch";

const UsersAPI = {
  async getAll(perPage = 50, page = 0) {
    const response = await Fetch.get(`api/users?page=${page + 1}&per_page=${perPage}`);
    return response?.data;
  },
  async verifyEmail(email) {
    const response = await Fetch.post(`api/admin/verifyemail`, { email });
    return response;
  },
  async createUser(data) {
    const response = await Fetch.post(`api/admin/user`, data);
    return response;
  },
  async getVerifiedUsers() {
    const response = await Fetch.get(`api/users?page=1&per_page=10000`);
    return response?.data;
  },
  async saveProfile(data) {
    const response = await Fetch.post(`api/user/profile`, data);
    return response?.data;
  },
};
export default UsersAPI;
