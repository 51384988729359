import { API_BASE_URL } from "environment";

const HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const ERROR_HANDLING = async (status, auth, response) => {
  if (status !== 200 && response && response.message) {
    window.dispatchEvent(
      new CustomEvent("snackbar", {
        detail: {
          type: "ERR",
          title: "API Error",
          content: response.message,
        },
      })
    );
  }
  if (auth && status === 401) {
    await localStorage.removeItem("A24_AUTH");
    await localStorage.removeItem("A24_USER");
    await localStorage.removeItem("A24_USER_EMAIL");
    await localStorage.removeItem("A24_TOKEN");
    window.location = "/";
  } else if (auth && status === 500 && response && response.message === "Unauthenticated.") {
    await localStorage.removeItem("A24_AUTH");
    await localStorage.removeItem("A24_USER");
    await localStorage.removeItem("A24_USER_EMAIL");
    await localStorage.removeItem("A24_TOKEN");
    window.location = "/";
  }
};

const Fetch = {
  async get(URL, auth = true) {
    let response;
    let responseJson;
    try {
      response = await fetch(`${API_BASE_URL}${URL}`, {
        method: "GET",
        headers: auth
          ? { Authorization: `Bearer ${localStorage.getItem("A24_TOKEN")}`, ...HEADERS }
          : HEADERS,
      });
      responseJson = await response.json();
    } catch (error) {
      await ERROR_HANDLING(500, auth, error);
    }

    if (response?.ok) {
      return responseJson;
    }
    await ERROR_HANDLING(response?.status, auth, responseJson);

    return responseJson;
  },
  async delete(URL, auth = true) {
    let response;
    let responseJson;
    try {
      response = await fetch(`${API_BASE_URL}${URL}`, {
        method: "DELETE",
        headers: auth
          ? { Authorization: `Bearer ${localStorage.getItem("A24_TOKEN")}`, ...HEADERS }
          : HEADERS,
      });
      responseJson = await response.json();
    } catch (error) {
      await ERROR_HANDLING(500, auth, error);
    }

    if (response?.ok) {
      return responseJson;
    }
    await ERROR_HANDLING(response?.status, auth, responseJson);

    return responseJson;
  },
  async post(URL, body, auth = true) {
    let response;
    let responseJson;
    try {
      response = await fetch(`${API_BASE_URL}${URL}`, {
        method: "POST",
        headers: auth
          ? { Authorization: `Bearer ${localStorage.getItem("A24_TOKEN")}`, ...HEADERS }
          : HEADERS,
        body: JSON.stringify(body),
      });
      responseJson = await response.json();
    } catch (error) {
      await ERROR_HANDLING(500, auth, error);
    }

    if (response?.ok) {
      return responseJson;
    }
    await ERROR_HANDLING(response?.status, auth, responseJson);

    return responseJson;
  },
  async postFormData(URL, formData, auth = true) {
    let response;
    let responseJson;
    try {
      response = await fetch(`${API_BASE_URL}${URL}`, {
        method: "POST",
        headers: auth ? { Authorization: `Bearer ${localStorage.getItem("A24_TOKEN")}` } : null,
        body: formData,
      });
      responseJson = await response.json();
    } catch (error) {
      await ERROR_HANDLING(500, auth, error);
    }

    if (response?.ok) {
      return responseJson;
    }
    await ERROR_HANDLING(response?.status, auth, responseJson);

    return responseJson;
  },
};
export default Fetch;
