/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import UsersAPI from "services/users";

import FilterInput from "./filterInput";

function TableSearch({ columns, onSearch, page }) {
  const { t } = useTranslation("common");

  const [searchableColumns, setSearchableColumns] = useState(columns);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchObject, setSearchObject] = useState(null);
  const [searchResult, setSearchResult] = useState(false);
  const [advancedSearchMenu, setAdvancedSearchMenu] = useState(null);
  const [filterChanged, setFilterChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [clearAll, setClearAll] = useState(false);

  const searchableColumnsLabels = {
    // Customers
    CustomerNumber: t("Customer Number"),
    Matchcode: t("Match Code"),
    Name1: t("Company Name"),
    Name2: t("Customer Name"),
    Street: t("Street"),
    Postcode: t("Zipcode"),
    City: t("City"),
    Country: t("Country"),
    CustomerGroup: t("Customer Group"),
    Active: t("Active"),
    Note: t("Note"),
    Phone: t("Phone"),
    Mobile: t("Mobile"),
    Email: t("Email"),
    CountryCode: t("Country Code"),
    VATId: t("VAT ID"),
    Homepage: t("Home Page"),
    Memo: t("Memo"),
    ContactEmail: t("Contact Email"),
    ContactPhone: t("Contact Phone"),
    LocalTaxNumber: t("Local Tax Number"),

    // Orders
    ProcessId: t("Process Id"),
    DocumentId: t("Order Id"),
    DocumentYear: t("Order Year"),
    DocumentNumber: t("Order Number"),
    DocumentTypeId: t("Order Type Id"),
    DocumentType: t("Order Type"),
    NativeCurrencyIso: t("Native Currency Iso"),
    CustomerName: t("Customer Name"),
    NativeNetFrom: t("Native Net From"),
    NativeNetTo: t("Native Net To"),
    NativeNetEqualTo: t("Native Net Equal To"),
    NativeTotalFrom: t("Native Total From"),
    NativeTotalTo: t("Native Total To"),
    NativeTotalEqualTo: t("Native Total Equal To"),
    ConvertedNetFrom: t("Converted Net From"),
    ConvertedNetTo: t("Converted Net To"),
    ConvertedNetEqualTo: t("Converted Net Equal To"),
    ConvertedTotalFrom: t("Converted Total From"),
    ConvertedTotalTo: t("Converted Total To"),
    ConvertedTotalEqualTo: t("Converted Total Equal To"),
    DocumentDateFrom: t("Order Date From"),
    DocumentDateTo: t("Order Date To"),
    DocumentDateEqualTo: t("Order Date Equal To"),
    PaymentCondition: t("Payment Condition"),
    CustomerEmail: t("Customer Email"),
    DocumentYearDocumentNumber: t("Order Year Order Number"),

    // Items
    ArticleNumber: t("Article Number"),
    Manufacturer: t("Manufacturer"),
    ManufacturerArticleNumber: t("Manufacturer Number"),
    ArticleGroup: t("Article Group"),
  };

  useEffect(async () => {
    const userStr = localStorage.getItem("A24_USER");
    let userData = {};
    if (userStr && userStr !== "") {
      userData = JSON.parse(userStr);
    }
    let selectedKeys = [];
    if (page === "CUSTOMERS" && userData.customer_search && userData.customer_search !== "") {
      selectedKeys = JSON.parse(userData.customer_search);
    } else if (page === "ORDERS" && userData.order_search && userData.order_search !== "") {
      selectedKeys = JSON.parse(userData.order_search);
    } else if (page === "ITEMS" && userData.item_search && userData.item_search !== "") {
      selectedKeys = JSON.parse(userData.item_search);
    } else if (page === "QUOTATIONS" && userData.quote_search && userData.quote_search !== "") {
      selectedKeys = JSON.parse(userData.quote_search);
    } else if (page === "DELIVERY" && userData.delivery_search && userData.delivery_search !== "") {
      selectedKeys = JSON.parse(userData.delivery_search);
    }
    if (selectedKeys && selectedKeys.length) {
      selectedKeys.forEach((item) => {
        const findIndex = searchableColumns.findIndex((x) => x.name === item);
        if (findIndex !== -1) {
          searchableColumns[findIndex].select = true;
        }
      });
      setSearchableColumns([...searchableColumns]);
    }
  }, []);

  const closeAdvancedSearchMenu = () => setAdvancedSearchMenu(null);
  const openAdvancedSearchMenu = (event) => {
    setAdvancedSearchMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
    });
  };

  const addFilter = async (key, value) => {
    let filterList = searchObject;
    if (!filterList) {
      filterList = {};
    }
    if (!filterList[key]) {
      filterList[key] = [];
    }
    const findIndex = filterList[key].findIndex((x) => x === value);
    if (findIndex === -1) {
      filterList[key].push(value);
    }
    await setSearchObject({ ...filterList });
    setFilterChanged(true);
  };

  const removeFilter = async (key, value) => {
    const filterList = searchObject;
    if (filterList[key].length === 1) {
      delete filterList[key];
    } else {
      const findIndex = filterList[key].findIndex((x) => x === value);
      if (findIndex !== -1) {
        filterList[key].splice(findIndex, 1);
      }
    }
    if (Object.keys(filterList).length === 0) {
      await setSearchObject(null);
    } else {
      await setSearchObject({ ...filterList });
    }
    setFilterChanged(true);
  };

  const handleSelectColumn = (col) => {
    const findIndex = searchableColumns.findIndex((e) => e.name === col.name);
    if (findIndex !== -1) {
      if (searchableColumns[findIndex].select) {
        if (searchObject) {
          delete searchObject[col];
          setSearchObject({ ...searchObject });
        }
      }
      searchableColumns[findIndex].select = !searchableColumns[findIndex].select;
    }
    setSearchableColumns([...searchableColumns]);
  };

  const handleAdvancedSearch = () => {
    setOpen(false);
    const data = {};
    let searchOn = false;
    if (searchTerm) {
      data.SearchTerm = [searchTerm];
    }
    if (searchObject) {
      for (const property in searchObject) {
        if (searchObject[property]) {
          data[property] = searchObject[property];
          searchOn = true;
        }
      }
    }
    setSearchResult(searchOn);
    onSearch(data);
    setFilterChanged(false);
    setClearAll(false);
  };

  useEffect(async () => {
    if (clearAll) {
      handleAdvancedSearch();
    }
  }, [clearAll]);

  const handleSave = async () => {
    const data = {};
    const selectedKeys = columns.filter((x) => x.select).map((x) => x.name);
    if (page === "CUSTOMERS") {
      data.customer_search = JSON.stringify(selectedKeys);
    } else if (page === "ORDERS") {
      data.order_search = JSON.stringify(selectedKeys);
    } else if (page === "ITEMS") {
      data.item_search = JSON.stringify(selectedKeys);
    } else if (page === "QUOTATIONS") {
      data.quote_search = JSON.stringify(selectedKeys);
    } else if (page === "DELIVERY") {
      data.delivery_search = JSON.stringify(selectedKeys);
    }
    const response = await UsersAPI.saveProfile(data);
    if (response) {
      await localStorage.setItem("A24_USER", JSON.stringify(response));
      window.dispatchEvent(new Event("user_storage"));
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Search",
            content: "Saved successfully",
          },
        })
      );
    }
  };

  const searchValues = [];
  if (searchObject) {
    Object.keys(searchObject).forEach((key) => {
      const type = searchableColumns.find((e) => e.name === key)?.type;
      searchObject[key].forEach((value) => {
        let formatValue = value;
        if (type === "checkbox") {
          formatValue = value ? t("Yes") : t("No");
        }
        searchValues.push({
          label: key,
          type,
          formatValue,
          value,
        });
      });
    });
  }

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  }));

  return (
    <>
      <Grid item xs={12}>
        <MDBox display="flex" sx={{ width: "100%" }}>
          <MDBox pt={2} px={3} flex="1">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                <MDInput
                  label={t("Search")}
                  name="search"
                  fullWidth
                  value={searchTerm || ""}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                    setFilterChanged(true);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleAdvancedSearch();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setSearchTerm("");
                            setFilterChanged(true);
                          }}
                          sx={{
                            marginRight: "-12px",
                          }}
                        >
                          {searchTerm ? <Icon>close</Icon> : null}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                {!searchObject || !filterChanged ? (
                  <MDButton
                    className={filterChanged ? "MUIButton-pulse" : ""}
                    variant="gradient"
                    color="info"
                    size="medium"
                    onClick={() => {
                      handleAdvancedSearch();
                    }}
                  >
                    {t("Search")}
                  </MDButton>
                ) : null}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={2} px={3}>
            <IconButton
              color="info"
              onClick={() => {
                setOpen(true);
              }}
            >
              <Icon>filter_alt</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </Grid>
      {searchObject ? (
        <Grid item xs={12}>
          <MDBox pt={0} px={3}>
            {/* <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {t("Selected Search")}
            </MDTypography> */}
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" sx={{ paddingTop: 1 }}>
              {searchValues.map((item, key) => (
                <Chip
                  key={key}
                  label={`${searchableColumnsLabels[item.label]}: ${item.formatValue}`}
                  variant="outlined"
                  onDelete={() => {
                    removeFilter(item.label, item.value);
                  }}
                />
              ))}
              {searchResult ? (
                <MDButton
                  variant="gradient"
                  color="error"
                  size="small"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    setSearchObject(null);
                    setSearchTerm("");
                    setSearchResult(false);
                    setFilterChanged(true);
                    setClearAll(true);
                  }}
                >
                  {t("Clear All")}
                </MDButton>
              ) : null}
              {filterChanged ? (
                <MDButton
                  className={filterChanged ? "MUIButton-pulse" : ""}
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => {
                    handleAdvancedSearch();
                  }}
                >
                  {t("Search")}
                </MDButton>
              ) : null}
            </Stack>
          </MDBox>
        </Grid>
      ) : null}

      <Drawer
        sx={{
          width: 300,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DrawerHeader>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {t("Advanced Search")}
          </MDTypography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ margin: 0 }} />
        <MDBox p={2}>
          <Grid container spacing={2}>
            {searchableColumns
              .filter((e) => e.select)
              .map((col, index) => (
                <FilterInput
                  col={col}
                  key={index}
                  addFilter={addFilter}
                  labels={searchableColumnsLabels}
                  disabled={
                    !col.multiple &&
                    searchObject &&
                    searchObject[col.name] &&
                    searchObject[col.name].length
                  }
                />
              ))}
            <Grid item xs={12}>
              <MDBox display="flex">
                <MDButton
                  variant="gradient"
                  color="success"
                  size="medium"
                  sx={{ flex: 1 }}
                  onClick={() => {
                    handleSave();
                  }}
                >
                  {t("Save as default")}
                </MDButton>
                <IconButton
                  onClick={(event) => {
                    openAdvancedSearchMenu(event);
                  }}
                >
                  <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                  id="menu_advanced_search"
                  anchorReference="anchorPosition"
                  anchorPosition={
                    advancedSearchMenu !== null
                      ? {
                          top: advancedSearchMenu.position.top,
                          left: advancedSearchMenu.position.left,
                        }
                      : undefined
                  }
                  open={!!advancedSearchMenu}
                  onClose={closeAdvancedSearchMenu}
                >
                  {searchableColumns.map((col, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleSelectColumn(col);
                      }}
                    >
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flex="1"
                      >
                        <span>{searchableColumnsLabels[col.name]}</span>
                        {col.select ? (
                          <Icon color="success" sx={{ marginLeft: 1 }}>
                            check_circle
                          </Icon>
                        ) : null}
                      </MDBox>
                    </MenuItem>
                  ))}
                </Menu>
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <MDButton
                className={filterChanged ? "MUIButton-pulse" : ""}
                variant="gradient"
                color="info"
                size="medium"
                sx={{ width: "100%" }}
                onClick={() => {
                  handleAdvancedSearch();
                }}
              >
                {t("Search")}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Drawer>
    </>
  );
}

export default memo(TableSearch);
