import { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import MDBox from "components/MDBox";
import Progress from "components/Progress";

import { useMaterialUIController, setPreviewDocument } from "context";

import ERPAPI from "services/erp";

function DocumentPreview() {
  const [controller, dispatch] = useMaterialUIController();
  const { previewDocument } = controller;
  const [loading, setLoading] = useState(true);
  const [base64STR, setBase64STR] = useState(null);

  useEffect(async () => {
    setBase64STR(null);
    if (previewDocument && !previewDocument.isImage) {
      setLoading(true);
      const response = await ERPAPI.getDSM(
        previewDocument.documentIdentifier,
        previewDocument.dmsDocumentId
      );
      if (response && response.data) {
        setBase64STR(response.data);
      } else {
        setBase64STR(null);
      }
    }
    setLoading(false);
  }, [previewDocument]);

  const handelClose = () => {
    setPreviewDocument(dispatch, null);
  };

  return (
    <>
      <Progress loading={loading} />
      <Dialog
        open={Boolean(previewDocument)}
        onClose={() => {
          handelClose();
        }}
        fullScreen
        PaperProps={{
          sx: {
            background: "rgba(0, 0, 0, .5)",
          },
        }}
      >
        <DialogTitle>
          <MDBox display="flex">
            <MDBox flex="1" />
            {previewDocument && previewDocument.isImage ? (
              <IconButton
                color="white"
                onClick={() => {
                  window.open(`${previewDocument.url}`, "_blank");
                }}
                aria-label="close"
              >
                <Icon>download</Icon>
              </IconButton>
            ) : null}
            <IconButton
              color="white"
              onClick={() => {
                handelClose();
              }}
              aria-label="close"
            >
              <Icon>close</Icon>
            </IconButton>
          </MDBox>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {base64STR ? (
            <embed
              src={`data:${base64STR.mimeType};base64,${base64STR.base64DocumentString}`}
              style={{ width: "100%", height: "100%" }}
            />
          ) : null}
          {previewDocument && previewDocument.isImage && previewDocument.url ? (
            <img src={previewDocument.url} alt="" style={{ maxWidth: "100%" }} />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DocumentPreview;
