/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import Ticket from "view/components/Ticket";
import TicketsAPI from "services/tickets";
import { TICKET_STATUS_OPEN_ID } from "environment";

export default function TicketRoute() {
  const { ticketId } = useParams();
  const [currentTicket, setCurrentTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openTicket, setOpenTicket] = useState(null);

  const getTicket = async () => {
    setLoading(true);
    const response = await TicketsAPI.getTicketDetails(ticketId);
    if (response) {
      if (response.status_code === TICKET_STATUS_OPEN_ID) {
        response.ticket_details = null;
      } else {
        response.ticket_details.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
      setOpenTicket(response.id);
      setCurrentTicket({ ...response });
    } else {
      setLoading(false);
    }
  };

  useEffect(async () => {
    getTicket();
  }, []);

  useEffect(async () => {
    const handleListener = () => {
      setCurrentTicket(null);
      getTicket();
    };
    window.addEventListener("tickets_status_change", handleListener);
    return () => window.removeEventListener("tickets_status_change", handleListener);
  }, []);

  useEffect(async () => {
    const handleListener = () => {
      localStorage.removeItem(`A24_POPUP_${ticketId}`);
    };
    window.addEventListener("beforeunload", handleListener);
    return () => window.removeEventListener("beforeunload", handleListener);
  }, []);

  return (
    <MDBox id="Tickets_Collapse_Wrap">
      <Progress loading={loading} isFull />
      {currentTicket ? (
        <Ticket
          customerID={`ticket-${currentTicket.id}`}
          ticket={currentTicket}
          openTicketID={openTicket}
          isPopup
          collapseOpen
          setOpenTicket={setOpenTicket}
          setParentLoading={setLoading}
        />
      ) : null}
    </MDBox>
  );
}
