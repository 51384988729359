/* eslint-disable react/prop-types */
import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Icon from "@mui/material/Icon";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";
import {
  TICKET_INTERACTION_TYPE_EMAIL,
  TICKET_INTERACTION_TYPE_PHONE,
  TICKET_INTERACTION_TYPE_CHAT,
} from "environment";
import ServerSideTable from "components/Tables/ServerSideTable";
import LogAPI from "services/log";
import CommonFun from "services/common";

function History({ customerID, customer }) {
  const { t } = useTranslation("common");
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");
  const [popover, setPopover] = useState(null);

  const getLogs = async () => {
    if (
      customer &&
      (customer.customerNumber ||
        (customer.emailList && customer.emailList.length) ||
        (customer.phoneList && customer.phoneList.length))
    ) {
      setLoading(true);
      const filter = {};
      if (customer.customerNumber) {
        filter.erp_customer_id = customer.customerNumber;
      }
      if (customer.emailList.length) {
        filter.email_list = customer.emailList;
      }
      if (customer.phoneList.length) {
        filter.phone = customer.phoneList;
      }
      const response = await LogAPI.getAllByFilter(filter, rowsPerPage, page, orderBy, order);
      if (response && response.data) {
        setLogs(response.data);
        setCount(response.total);
      } else {
        setLogs([]);
        setCount(0);
      }
      setLoading(false);
    }
  };

  useEffect(async () => {
    getLogs();
  }, [customer, page, rowsPerPage, order, orderBy]);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getActionText = (action) => {
    switch (action) {
      case 0:
        return t("Ticket Created");
      case 1:
        return t("Ticket Assigned");
      case 2:
        return t("Ticket Status Changed");
      case 3:
        return t("Ticket Reassigned");
      case 4:
        return t("Email Reply");
      case 5:
        return t("Incoming Call");
      case 6:
        return t("Outgoing Call");
      default:
        return "";
    }
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };

  const handlePopoverOpen = (event, row) => {
    setPopover({
      id: row.id,
      anchorEl: event.currentTarget,
    });
  };

  const columns = [
    {
      header: t("Type"),
      accessor: "interaction_type",
      align: "center",
      sort: false,
      render: (row) => (
        <MDBox display="flex" alignItems="center" justifyContent="center" lineHeight={1}>
          {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL || !row.interaction_type ? (
            <MDTypography color="success" style={{ lineHeight: "20px" }}>
              <Icon>mail</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE && !row.missed_call_reason ? (
            <MDTypography color="success" style={{ lineHeight: "20px" }}>
              <Icon>phone_in_talk</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE && row.missed_call_reason ? (
            <MDTypography color="error" style={{ lineHeight: "20px" }}>
              <Icon>phone_missed</Icon>
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_CHAT ? (
            <MDTypography color="primary" style={{ lineHeight: "20px" }}>
              <Icon>chat</Icon>
            </MDTypography>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Information"),
      accessor: "action",
      align: "left",
      sort: false,
      render: (row) => (
        <MDBox lineHeight={1}>
          <MDTypography display="block" variant="caption" color="black" fontWeight="medium">
            {getActionText(row.action)}
          </MDTypography>
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE && row.missed_call_reason ? (
            <MDBox mt={0.5}>
              <MDBadge
                className="missed_call_reason_badge"
                badgeContent={row.missed_call_reason.replace(/_/g, " ")}
                color="light"
                variant="contained"
                size="sm"
                sx={{ marginLeft: -1 }}
                border
              />
            </MDBox>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Date"),
      accessor: "created_at",
      align: "left",
      sort: false,
      render: (row) =>
        row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
          <MDTypography variant="caption" color="black" fontWeight="medium">
            {row.started_at
              ? moment.utc(row.started_at).local().format(CommonFun.getDateTimeFormat())
              : null}
          </MDTypography>
        ) : (
          <MDTypography variant="caption" color="black" fontWeight="medium">
            {moment.utc(row.created_at).local().format(CommonFun.getDateTimeFormat())}
          </MDTypography>
        ),
    },
    {
      header: t("Duration"),
      accessor: "duration ",
      align: "center",
      sort: false,
    },
    {
      header: t("Customer"),
      accessor: "customer ",
      align: "left",
      sort: false,
      render: (row) => (
        <MDBox lineHeight={1}>
          {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL ? (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              {row.customer_email}
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              {CommonFun.displayPhone(row.customer_phone)}
            </MDTypography>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Agent"),
      accessor: "agent ",
      align: "left",
      sort: false,
      render: (row) => (
        <MDBox lineHeight={1}>
          {row.interaction_type === TICKET_INTERACTION_TYPE_EMAIL ? (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              {row.agent_email}
            </MDTypography>
          ) : null}
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              {CommonFun.displayPhone(row.agent_phone)}
            </MDTypography>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("Ticket"),
      accessor: "ticket_id",
      align: "left",
      sort: true,
      render: (row) => (
        <MDBox lineHeight={1}>
          {row.ticket_id ? (
            <MDTypography variant="caption" color="black" fontWeight="medium">
              #{row.ticket_id}
            </MDTypography>
          ) : null}
        </MDBox>
      ),
    },
    {
      header: t("User"),
      accessor: "created_by",
      align: "left",
      sort: false,
      render: (row) => (
        <>
          <MDTypography variant="caption" color="black" fontWeight="medium">
            {row.created_by ? row.created_by : "System"}
          </MDTypography>
          {row.interaction_type === TICKET_INTERACTION_TYPE_PHONE ? (
            <IconButton
              aria-label="info"
              onClick={(event) => {
                handlePopoverOpen(event, row);
              }}
            >
              <Icon>info</Icon>
            </IconButton>
          ) : null}
          <Popover
            open={Boolean(popover && popover.id === row.id)}
            anchorEl={popover ? popover.anchorEl : null}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ padding: 0 }}
          >
            <ProfileInfoCard
              info={{
                "Call ID": row.call_id ? row.call_id : "",
                "Started At": row.started_at
                  ? moment.utc(row.started_at).local().format(CommonFun.getDateTimeFormat())
                  : null,
                "Answered At": row.answered_at
                  ? moment.utc(row.answered_at).local().format(CommonFun.getDateTimeFormat())
                  : null,
              }}
              shadow={false}
            />
          </Popover>
        </>
      ),
    },
  ];

  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "History");

  return (
    <MDBox>
      <Progress loading={loading} />
      <ServerSideTable
        fixedTable
        rowsPerPage={rowsPerPage}
        page={page}
        columns={columns}
        data={logs}
        count={count}
        order={order}
        orderBy={orderBy}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRequestSort={(key) => {
          const isAsc = orderBy === key && order === "asc";
          setOrder(isAsc ? "desc" : "asc");
          setOrderBy(key);
          setPage(0);
        }}
        getRowProps={() => ({
          onClick: () => {},
          style: {
            cursor: "default",
          },
        })}
      />
    </MDBox>
  );
}

export default memo(History);
