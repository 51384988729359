import Fetch from "./fetch";

const AuthAPI = {
  async login(email, password) {
    const response = await Fetch.post(`api/auth/userlogin`, { email, password }, false);
    if (response && response.data) {
      await localStorage.setItem("A24_AUTH", true);
      await localStorage.setItem("A24_USER_ID", response.data.user_id);
      await localStorage.setItem("A24_REGION", response.data.region);
      await localStorage.setItem("A24_USER_EMAIL", response.data.email);
      await localStorage.setItem("A24_USER", JSON.stringify(response.data));
      await localStorage.setItem("A24_TOKEN", response.data.token);
      await localStorage.setItem("A24_ROLES", JSON.stringify(response.data.roles));

      await localStorage.removeItem("A24_TABS");
      await sessionStorage.removeItem("A24_ACTIVE_TAB");
      await localStorage.removeItem("A24_FAVOURITE");
      await localStorage.removeItem("A24_RECENTLY_VIEW");
      await localStorage.removeItem("A24_CUSTOMER_TAB_STATE");
      await localStorage.removeItem("A24_LEAD_SAVED");
      await localStorage.removeItem("A24_CUSTOMERS_SEARCH_TAB");
      await localStorage.removeItem("A24_ITEMS_SEARCH_TAB");
      await localStorage.removeItem("A24_ORDERS_SEARCH_TAB");
    }
    return response;
  },
  async loginAD(accessToken) {
    const response = await Fetch.post(`api/ad/token`, { access_token: accessToken }, false);
    if (response && response.data) {
      if (response.data.email_verified) {
        await localStorage.setItem("A24_AUTH", true);
        await localStorage.setItem("A24_USER_ID", response.data.user_id);
        await localStorage.setItem("A24_REGION", response.data.region);
        await localStorage.setItem("A24_USER_EMAIL", response.data.email);
        await localStorage.setItem("A24_USER", JSON.stringify(response.data));
        await localStorage.setItem("A24_TOKEN", response.data.token);
        await localStorage.setItem("A24_ROLES", JSON.stringify(response.data.roles));

        await localStorage.removeItem("A24_TABS");
        await sessionStorage.removeItem("A24_ACTIVE_TAB");
        await localStorage.removeItem("A24_FAVOURITE");
        await localStorage.removeItem("A24_RECENTLY_VIEW");
        await localStorage.removeItem("A24_CUSTOMER_TAB_STATE");
        await localStorage.removeItem("A24_LEAD_SAVED");
        await localStorage.removeItem("A24_CUSTOMERS_SEARCH_TAB");
        await localStorage.removeItem("A24_ITEMS_SEARCH_TAB");
        await localStorage.removeItem("A24_ORDERS_SEARCH_TAB");
      } else {
        response.message =
          "Thank you for logging in. Your email is not yet verified. Kindly ask the admin to verify your email address before you can start to use the system";
      }
    }
    return response;
  },
  async getUser() {
    const response = await Fetch.get(`api/user`);
    return response;
  },
  async logout() {
    await localStorage.removeItem("A24_AUTH");
    await localStorage.removeItem("A24_USER");
    await localStorage.removeItem("A24_USER_EMAIL");
    await localStorage.removeItem("A24_USER_ID");
    await localStorage.removeItem("A24_REGION");
    await localStorage.removeItem("A24_LOCATION");
    await localStorage.removeItem("A24_TOKEN");
    await localStorage.removeItem("A24_ROLES");

    await localStorage.removeItem("A24_TABS");
    await sessionStorage.removeItem("A24_ACTIVE_TAB");
    await localStorage.removeItem("A24_FAVOURITE");
    await localStorage.removeItem("A24_RECENTLY_VIEW");
    await localStorage.removeItem("A24_CUSTOMER_TAB_STATE");
    await localStorage.removeItem("A24_LEAD_SAVED");
    await localStorage.removeItem("A24_CUSTOMERS_SEARCH_TAB");
    await localStorage.removeItem("A24_ITEMS_SEARCH_TAB");
    await localStorage.removeItem("A24_ORDERS_SEARCH_TAB");
  },
};
export default AuthAPI;
