/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDStatus from "components/MDStatus";
// import MDButton from "components/MDButton";
import Progress from "components/Progress";
import { useMaterialUIController, setPreviewDocument } from "context";

import ServerSideTable from "components/Tables/ServerSideTable";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Orders({ customerID }) {
  const { t } = useTranslation("common");
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useMaterialUIController();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [orderBy, setOrderBy] = useState("documentId");
  const [order, setOrder] = useState("desc");
  const [documentsMenu, setDocumentsMenu] = useState(null);

  const getOrders = async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerOrders(
        customerID.replace("erp-", ""),
        page,
        pageSize,
        orderBy,
        order
      );
      if (
        ERPResponse &&
        ERPResponse.data &&
        ERPResponse.data.items &&
        ERPResponse.data.items.length
      ) {
        setOrders(ERPResponse.data.items);
        setCount(ERPResponse.data.totalCount);
      } else {
        setOrders([]);
      }
      setLoading(false);
    } else {
      setOrders([]);
    }
  };

  useEffect(async () => {
    getOrders();
  }, [customerID, page, pageSize, orderBy, order]);

  const openDocumentsMenu = (event, row) => {
    setDocumentsMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      row,
    });
  };

  const closeDocumentsMenu = () => setDocumentsMenu(null);

  const columns = [
    {
      header: t("Order Number"),
      accessor: "documentIdentifier",
      align: "left",
      sort: true,
    },
    {
      header: t("Order Date"),
      accessor: "documentDate",
      align: "left",
      sort: true,
      dateFormat: CommonFun.getDateFormat(),
    },
    {
      header: t("Line Items"),
      accessor: "numberOfLineItems",
      align: "left",
      sort: true,
    },
    {
      header: t("Net Total"),
      accessor: "nativeNetValue",
      align: "left",
      sort: true,
      currency: "nativeCurrencyIso",
    },
    {
      header: t("Total"),
      accessor: "nativeTotalValue",
      align: "left",
      sort: true,
      currency: "nativeCurrencyIso",
    },
    {
      header: t("Payment Condition"),
      accessor: "documentPaymentCondition.paymentCondition",
      align: "left",
      sort: true,
    },
    {
      header: t("Delivery Condition"),
      accessor: "deliveryCondition",
      align: "left",
      sort: true,
    },
    {
      header: t("Status"),
      accessor: "status",
      align: "left",
      sort: false,
      render: (row) => (
        <MDStatus
          value={{
            documentIdentifier: row.documentIdentifier,
            payment: row.paidStatus,
            packing: row.fulfillmentStatus,
            invoicing: row.invoicingStatus,
            shipping: row.shipmentStatus,
            shipments: row.shipments,
          }}
        />
      ),
      getCellProps: () => ({
        style: {
          cursor: "default",
        },
      }),
    },
    {
      header: t("Documents"),
      accessor: "documents",
      align: "center",
      sort: false,
      render: (row) => (
        <MDBox sx={{ position: "relative" }}>
          <IconButton
            color="black"
            aria-describedby={`menu_${row.documentId}`}
            onClick={(event) => {
              openDocumentsMenu(event, row);
            }}
            size="small"
            disabled={!row.orderDocuments || !row.orderDocuments.length}
          >
            <Icon>picture_as_pdf</Icon>
          </IconButton>
          <Menu
            id={`menu_documents_${row.documentId}`}
            anchorEl={documentsMenu !== null ? documentsMenu.anchorEl : undefined}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            open={!!(documentsMenu && row.documentId === documentsMenu.row.documentId)}
            onClose={closeDocumentsMenu}
          >
            {row.orderDocuments &&
              row.orderDocuments.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setPreviewDocument(dispatch, {
                      isImage: false,
                      documentIdentifier: item.documentIdentifier,
                      name: item.documentType,
                      dmsDocumentId: item.dmsDocumentId,
                    });
                  }}
                  sx={{
                    padding: 1,
                    marginBottom: 0.5,
                    border:
                      item.documentIdentifier && item.dmsDocumentId
                        ? "1px solid #1A73E8"
                        : "1px solid #000000",
                  }}
                  disabled={!item.documentIdentifier || !item.dmsDocumentId}
                >
                  <MDBox sx={{ display: "flex", width: "100%" }}>
                    <MDBox
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <MDTypography
                        variant="button"
                        color={item.documentIdentifier && item.dmsDocumentId ? "info" : "black"}
                      >
                        {item.documentType}
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        color={item.documentIdentifier && item.dmsDocumentId ? "info" : "black"}
                      >
                        {item.documentIdentifier}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        paddingLeft: 1,
                        paddingRight: 1,
                      }}
                    >
                      <MDTypography variant="caption" color="black">
                        {moment.utc(item.documentDate).format(CommonFun.getDateFormat())}
                      </MDTypography>
                      <MDTypography variant="caption" color="black">
                        {CommonFun.displayCurrency(item.nativeTotalValue, item.nativeCurrencyIso)}
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ display: "flex", alignItems: "center", padding: 1 }}>
                      <MDTypography color="black">
                        <Icon>picture_as_pdf</Icon>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MenuItem>
              ))}
          </Menu>
        </MDBox>
      ),
      getCellProps: () => ({
        // onClick: () => {}
        style: {
          cursor: "default",
        },
      }),
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "Orders");

  return (
    <MDBox bgColor="light">
      <Progress loading={loading} isFull />
      <ServerSideTable
        fixedTable
        rowsPerPage={pageSize}
        page={page}
        columns={columns}
        data={orders}
        count={count}
        order={order}
        orderBy={orderBy}
        onRequestSort={(key) => {
          const isAsc = orderBy === key && order === "asc";
          setOrder(isAsc ? "desc" : "asc");
          setOrderBy(key);
        }}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        getRowProps={(row) => ({
          onClick: () => {
            CommonFun.onClickOrder(row.documentIdentifier);
          },
          style: {
            cursor: "pointer",
          },
        })}
      />
    </MDBox>
  );
}

export default memo(Orders);
