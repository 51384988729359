/* eslint-disable react/prop-types */
import { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

import StatisticsTable from "components/Tables/StatisticsTable";
import MDBox from "components/MDBox";
import Progress from "components/Progress";
import { InputText } from "components/MDInputs";

import ERPAPI from "services/erp";
import CommonFun from "services/common";

function Shops({ article, articleNumber }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [orderBy, setOrderBy] = useState("shippingDate");
  const [order, setOrder] = useState("asc");
  const [stockOnShelf, setStockOnShelf] = useState(article?.stock);
  const [orderedByCustomers, setOrderedByCustomers] = useState(0);
  const [incommingFromSuppliers, setIncommingFromSuppliers] = useState(0);

  const getStockInformation = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getStockInformation(articleNumber);
    if (ERPResponse && ERPResponse.data) {
      const array = ERPResponse.data.sort(
        (a, b) => new Date(a.shippingDate) - new Date(b.shippingDate)
      );
      setStocks([...array]);

      let OrderedByCustomers = 0;
      let IncommingFromSuppliers = 0;
      array.forEach((item) => {
        if (item.type === 1) {
          IncommingFromSuppliers += item.quantity;
        } else {
          OrderedByCustomers += item.quantity;
        }
      });
      setOrderedByCustomers(OrderedByCustomers);
      setIncommingFromSuppliers(IncommingFromSuppliers);
    } else {
      setStocks([]);
      // setStockOnShelf(0);
      setOrderedByCustomers(0);
      setIncommingFromSuppliers(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStockInformation();
  }, [articleNumber]);

  useEffect(() => {
    setStockOnShelf(article?.stock);
  }, [article]);

  const columns = [
    {
      header: t("Shipping Date"),
      accessor: "shippingDate",
      align: "left",
      sort: true,
      dateFormat: CommonFun.getDateFormat(),
    },
    {
      header: t("Document Date"),
      accessor: "documentDate",
      align: "left",
      sort: true,
      dateFormat: CommonFun.getDateFormat(),
    },
    {
      header: t("Document Number"),
      accessor: "documentNumber",
      align: "left",
      sort: false,
    },
    {
      header: t("Customer / Vendor Id"),
      accessor: "customerVendorId",
      align: "left",
      sort: false,
    },
    {
      header: t("Match Code"),
      accessor: "matchCode",
      align: "left",
      sort: false,
    },
    {
      header: t("Quantity"),
      accessor: "quantity",
      align: "right",
      sort: false,
    },
    {
      header: t("Type"),
      accessor: "type",
      align: "center",
      sort: false,
      render: (row) => (
        <MDBox
          component="span"
          lineHeight={1}
          bgColor={row.type === 1 ? "success" : "error"}
          sx={{ width: "24px", height: "24px", display: "inline-block" }}
        />
      ),
    },
  ];

  return (
    <MDBox position="relative">
      <Progress loading={loading} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <InputText label={t("Stock On Shelf")} value={stockOnShelf} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputText label={t("Ordered By Customers")} value={orderedByCustomers} />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputText label={t("Incomming From Suppliers")} value={incommingFromSuppliers} />
        </Grid>
        <Grid item xs={12} md={12}>
          <StatisticsTable
            columns={columns}
            rows={stocks}
            order={order}
            orderBy={orderBy}
            onRequestSort={(key) => {
              const isAsc = orderBy === key && order === "asc";
              setOrder(isAsc ? "desc" : "asc");
              setOrderBy(key);
              const array = stocks.sort((a, b) =>
                isAsc ? new Date(b[key]) - new Date(a[key]) : new Date(a[key]) - new Date(b[key])
              );
              setStocks([...array]);
            }}
            getRowProps={() => ({
              onClick: () => {},
              style: {},
            })}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(Shops);
