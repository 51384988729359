import Fetch from "../fetch";

const Customer = {
  async searchCustomer(filter, page = 0, pageSize = 10, orderBy = null, order = null) {
    const params = [];
    if (filter) {
      Object.keys(filter).forEach((key) => {
        if (filter[key].length === 1) {
          params.push(`${key}=${filter[key][0]}`);
        } else {
          filter[key].forEach((value) => {
            params.push(`${key}[]=${value}`);
          });
        }
      });
    }
    params.push(`page=${page + 1}`);
    params.push(`pageSize=${pageSize}`);
    if (orderBy) {
      params.push(`order=${orderBy} ${order}`);
    }
    const response = await Fetch.get(`api/erp/customer?${params.join("&")}`);
    return response?.data;
  },
  async findCustomer(filter) {
    const params = [];
    if (filter) {
      Object.keys(filter).forEach((key) => {
        params.push(`${key}=${filter[key]}`);
      });
    }
    const response = await Fetch.get(`api/erp/customer/find?${params.join("&")}`);
    return response?.data;
  },
  async getCustomerDetails(customerID) {
    const response = await Fetch.get(`api/erp/customer/${customerID}`);
    return response?.data;
  },
  async getCustomerBasic(customerID) {
    const response = await Fetch.get(`api/erp/customer/${customerID}/basic`);
    return response?.data;
  },
  async getCustomerOrders(customerID, page = 0, pageSize = 10, orderBy, order) {
    const response = await Fetch.get(
      `api/erp/customer/${customerID}/orders?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderBy} ${order}`
    );
    return response?.data;
  },
  async getCustomerRecentOrders(customerID, numberOfRecentOrders = 3) {
    const response = await Fetch.get(
      `api/erp/customer/${customerID}/orders/recent/${numberOfRecentOrders}`
    );
    return response?.data;
  },
  async getCustomerStats(customerID) {
    const response = await Fetch.get(`api/erp/customer/${customerID}/stats`);
    return response?.data;
  },
  async getCustomerContacts(customerID, page = 0, pageSize = 10, orderBy, order) {
    const response = await Fetch.get(
      `api/erp/customer/${customerID}/contacts?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderBy} ${order}`
    );
    return response?.data;
  },
  async getCustomerAddresses(customerID, page = 0, pageSize = 10, orderBy, order) {
    const response = await Fetch.get(
      `api/erp/customer/${customerID}/addresses?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderBy} ${order}`
    );
    return response?.data;
  },
  async getCustomerItems(customerID, page = 0, pageSize = 10, orderBy, order) {
    const response = await Fetch.get(
      `api/erp/customer/${customerID}/items?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderBy} ${order}`
    );
    return response?.data;
  },
  async getCustomerQuotations(customerID, page = 0, pageSize = 10, orderBy, order) {
    const response = await Fetch.get(
      `api/erp/customer/${customerID}/quotes?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderBy} ${order}`
    );
    return response?.data;
  },
  async getCustomerVatInfo(customerID) {
    const response = await Fetch.get(`api/erp/customer/${customerID}/vatinfo`);
    return response?.data;
  },
  async getCustomerDocumentsSage(customerID, page = 0, pageSize = 10, orderBy, order) {
    const response = await Fetch.get(
      `api/erp/customer/${customerID}/documents/sage?page=${
        page + 1
      }&pageSize=${pageSize}&order=${orderBy} ${order}`
    );
    return response?.data;
  },
  async getCustomerDocumentsExternal(customerID) {
    const response = await Fetch.get(`api/erp/customer/${customerID}/documents/external`);
    return response?.data;
  },
  async getCustomerCreditInformation(customerID) {
    const response = await Fetch.get(`api/erp/customer/${customerID}/creditinformation`);
    return response?.data;
  },
  // POST
  async createCustomerContact(customerID, data) {
    const response = await Fetch.post(`api/erp/customer/${customerID}/contact`, data);
    return response;
  },
};
export default Customer;
