/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, memo as reactMemo } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useTranslation } from "react-i18next";

function MemoField({ customer = {} }) {
  const { t } = useTranslation("common");
  const [currentCustomer, setCurrentCustomer] = useState(customer);

  const [currentMemo, setCurrentMemo] = useState(null);
  const [open, setOpen] = useState(false);

  const [translateFrom, setTranslateFrom] = useState(null);
  const [translateTo, setTranslateTo] = useState(null);
  const [translateResult, setTranslateResult] = useState(null);

  useEffect(() => {
    setCurrentCustomer({ ...customer });
  }, [customer]);

  const handleMemoSave = () => {
    if (currentMemo && currentMemo.text) {
      let { memo } = currentCustomer;
      if (!memo) {
        memo = [];
      }
      if (currentMemo.id) {
        const index = memo.findIndex((item) => item.id === currentMemo.id);
        memo[index].text = currentMemo.text;
      } else {
        memo.unshift({
          id: memo.length > 0 ? memo.length - 1 : 1,
          text: currentMemo.text,
          author: "support",
          dateTime: new Date().toLocaleString(),
        });
      }
      setCurrentCustomer({
        ...currentCustomer,
        memo,
      });
      setCurrentMemo(null);
      setOpen(false);
    }
  };

  return (
    <>
      <MDBox position="relative">
        <Grid container spacing={1}>
          {/* <Grid item xs={12}>
            <MDBox p={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("Add")}
              </MDButton> 
            </MDBox>
          </Grid> */}
          <Grid item xs={12}>
            <MDBox p={2} sx={{ maxHeight: 390, overflow: "auto" }}>
              {currentCustomer && currentCustomer.memo ? (
                <MDTypography variant="body2" color="black" sx={{ whiteSpace: " pre-line" }}>
                  {currentCustomer.memo}
                </MDTypography>
              ) : null}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>{currentMemo && currentMemo.id ? t("Edit Memo") : t("Add Memo")}</DialogTitle>
        <DialogContent dividers>
          <MDBox p={2}>
            <MDInput
              label={t("Type here")}
              multiline
              rows={5}
              fullWidth
              value={currentMemo && currentMemo.text ? currentMemo && currentMemo.text : ""}
              onChange={(event) => {
                setCurrentMemo({
                  ...currentMemo,
                  text: event.target.value,
                });
              }}
            />
          </MDBox>
          {currentMemo && currentMemo.translate ? (
            <>
              <MDBox p={2} display="flex" alignItems="center">
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    fullWidth
                    options={[
                      { label: "English", code: "en" },
                      { label: "French", code: "fr" },
                      { label: "German", code: "de" },
                    ]}
                    onChange={(event, newValue) => {
                      setTranslateFrom(newValue);
                    }}
                    value={translateFrom || ""}
                    renderInput={(params) => <MDInput {...params} label={t("from")} />}
                  />
                </div>

                <MDBox px={2} />
                <div style={{ flex: 1 }}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    fullWidth
                    options={[
                      { label: "English", code: "en" },
                      { label: "French", code: "fr" },
                      { label: "German", code: "de" },
                    ]}
                    onChange={(event, newValue) => {
                      setTranslateTo(newValue);
                    }}
                    value={translateTo || ""}
                    renderInput={(params) => <MDInput {...params} label={t("to")} />}
                  />
                </div>
                <MDBox px={2} />
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => {
                    if (translateFrom && translateTo) {
                      setTranslateResult(currentMemo.translate[translateTo.code]);
                    }
                  }}
                >
                  {t("Translate")}
                </MDButton>
              </MDBox>
              {translateResult && (
                <MDBox p={2} lineHeight={0}>
                  <MDTypography variant="button" color="black">
                    {translateResult}
                  </MDTypography>
                </MDBox>
              )}
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setCurrentMemo(null);
              setOpen(false);
            }}
          >
            {t("Cancel")}
          </MDButton>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              handleMemoSave();
            }}
          >
            {currentMemo && currentMemo.id ? t("Update") : t("Save")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default reactMemo(MemoField);
