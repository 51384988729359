/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ProfileInfoCard from "components/Cards/InfoCards/ProfileInfoCard";
import ServerSideTable from "components/Tables/ServerSideTable";
import Progress from "components/Progress";

import CommonFun from "services/common";
import customerAPI from "services/customers";
import ERPAPI from "services/erp";

function BusinessCard({
  customerID,
  customer = {},
  setLeadSaved,
  openNewTicket,
  setOpenNewTicket,
  setParentLoading,
}) {
  const { t } = useTranslation("common");
  const [currentCustomer, setCurrentCustomer] = useState(customer);
  const [openSearch, setOpenSearch] = useState(false);
  const isNew = customerID.includes("new-");
  const isERP = customerID.includes("erp-");
  const [profile, setProfile] = useState(isNew ? customer : null);
  const [searchData, setSearchData] = useState({
    CustomerNumber: "",
    Name1: "",
    Name2: "",
    Email: "",
  });
  const [submittedFilter, setSubmittedFilter] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("customerNumber");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setCurrentCustomer({ ...customer });
    setProfile(isNew ? { ...customer } : null);
  }, [customer]);

  const getCustomers = async () => {
    setLoading(true);
    const data = {
      CustomerNumber: [searchData.CustomerNumber],
      Name1: [searchData.Name1],
      Name2: [searchData.Name2],
      Email: [searchData.Email],
    };
    const ERPResponse = await ERPAPI.searchCustomer(data, page, pageSize, orderBy, order);
    if (
      ERPResponse &&
      ERPResponse.data &&
      ERPResponse.data.items &&
      ERPResponse.data.items.length
    ) {
      setCustomers(ERPResponse.data.items);
      setCount(ERPResponse.data.totalCount);
    } else {
      setCustomers([]);
    }
    setLoading(false);
    setSubmittedFilter(false);
  };

  useEffect(async () => {
    if (submittedFilter) {
      getCustomers();
    }
  }, [submittedFilter]);

  const handleProfileChange = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  const loadCustomer = (cID, cName, afterConfirm = false) => {
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }
    const oldTabIndex = tabList.findIndex((e) => e.id === cID && !e.deleted);
    if (oldTabIndex === -1 || afterConfirm) {
      const tabStateStr = localStorage.getItem("A24_CUSTOMER_TAB_STATE");
      let tabStateJson = {};
      if (tabStateStr && tabStateStr !== "") {
        tabStateJson = JSON.parse(tabStateStr);
      }
      tabStateJson[cID] = 0;
      localStorage.setItem("A24_CUSTOMER_TAB_STATE", JSON.stringify(tabStateJson));

      const newTabIndex = tabList.findIndex((e) => e.id === customerID);
      tabList[newTabIndex].deleted = true;
      localStorage.setItem("A24_TABS", JSON.stringify(tabList));
      CommonFun.onClickCustomer(cID, cName);
    }
  };

  const saveCustomer = async () => {
    const formData = {
      ...profile,
      erp_customer_id: profile.customerNumber,
      erp_email: profile.erpEmail,
      first_name: profile.name2,
      last_name: profile.name1,
      address_line_1: profile.street,
      zip: profile.postcode,
    };
    if (isNew) {
      const response = await customerAPI.createCustomer(formData);
      if (response) {
        setCurrentCustomer({
          ...currentCustomer,
          ...response,
          customerNumber: response.erp_customer_id,
          erpEmail: response.erp_email,
          name2: response.first_name,
          name1: response.last_name,
          street: response.address_line_1,
          postcode: response.zip,
        });
        setProfile(null);
        setLeadSaved(true);
        loadCustomer(`lead-${response.id}`, response.first_name, false);
      }
    } else {
      const response = await customerAPI.updateCustomer(customerID.replace("lead-", ""), formData);
      if (response) {
        setCurrentCustomer({
          ...currentCustomer,
          ...response,
          customerNumber: response.erp_customer_id,
          erpEmail: response.erp_email,
          name2: response.first_name,
          name1: response.last_name,
          street: response.address_line_1,
          postcode: response.zip,
        });
        setProfile(null);
      }
    }
  };

  const columns = [
    {
      header: t("Action"),
      accessor: "action",
      align: "center",
      sort: false,
      render: (row) => (
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={() => {
            setProfile({
              ...profile,
              customerNumber: row.customerNumber,
              erpEmail: row.email,
            });
            setOpenSearch(false);
          }}
        >
          {t("Select")}
        </MDButton>
      ),
    },
    {
      header: t("Customer Number"),
      accessor: "customerNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Company Name"),
      accessor: "shippingAddress.name1",
      align: "left",
      sort: true,
    },
    {
      header: t("Customer Name"),
      accessor: "shippingAddress.name2",
      align: "left",
      sort: true,
    },
    {
      header: t("Email"),
      accessor: "email",
      align: "left",
      sort: true,
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    setSubmittedFilter(true);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    setSubmittedFilter(true);
  };

  const handleRemoveERP = () => {
    setProfile({
      ...profile,
      customerNumber: null,
      erpEmail: null,
    });
  };

  const handleOpenSearch = () => {
    setOpenSearch(true);
    setSearchData({
      CustomerNumber: "",
      Name1: "",
      Name2: "",
      Email: "",
    });
    setSubmittedFilter(false);
    setCustomers([]);
    setLoading(false);
    setOrderBy("customerNumber");
    setOrder("asc");
    setPage(0);
    setCount(0);
    setPageSize(20);
  };

  return (
    <MDBox position="relative">
      <Grid container spacing={1} alignItems="center">
        {!profile ? (
          <Grid item xs={12}>
            {isERP ? (
              <ProfileInfoCard
                info={{
                  "ERP Customer Number": currentCustomer.customerNumber
                    ? currentCustomer.customerNumber
                    : "-",
                  "ERP Email": currentCustomer.erpEmail ? currentCustomer.erpEmail : "-",
                  "Customer Name": currentCustomer.name2,
                  "Company Name": currentCustomer.name1,
                  "Street / No": currentCustomer.street,
                  Zipcode: currentCustomer.postcode,
                  City: currentCustomer.city,
                  Country: currentCustomer.country,
                  Phone: currentCustomer.phone,
                }}
                dense
                shadow={false}
              />
            ) : (
              <ProfileInfoCard
                info={{
                  "ERP Customer Number": currentCustomer.customerNumber
                    ? currentCustomer.customerNumber
                    : "-",
                  "ERP Email": currentCustomer.erpEmail ? currentCustomer.erpEmail : "-",
                  "Customer Name": currentCustomer.name2,
                  "Company Name": currentCustomer.name1,
                  "Street / No": currentCustomer.street,
                  Zipcode: currentCustomer.postcode,
                  City: currentCustomer.city,
                  Country: currentCustomer.country,
                  Phone: currentCustomer.phone,
                  Email: currentCustomer.email,
                }}
                dense
                shadow={false}
              />
            )}
          </Grid>
        ) : null}
        {profile ? (
          <Grid item xs={12}>
            <MDBox mb={2}>
              <MDBox display="flex">
                <MDBox flex="1">
                  <MDInput
                    label={t("ERP Customer Number")}
                    name="customerNumber"
                    fullWidth
                    value={profile.customerNumber ? profile.customerNumber : ""}
                    onChange={handleProfileChange}
                    disabled
                  />
                </MDBox>
                {currentCustomer.id ? (
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    sx={{ marginLeft: 1 }}
                    onClick={() => {
                      handleOpenSearch();
                    }}
                  >
                    {t("Search")}
                  </MDButton>
                ) : null}
                {profile.customerNumber ? (
                  <MDButton
                    variant="gradient"
                    color="error"
                    size="small"
                    sx={{ marginLeft: 1 }}
                    onClick={() => {
                      handleRemoveERP();
                    }}
                  >
                    {t("Remove")}
                  </MDButton>
                ) : null}
              </MDBox>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("ERP Email")}
                name="erpEmail"
                fullWidth
                disabled
                value={profile.erpEmail ? profile.erpEmail : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("Customer Name")}
                name="name2"
                fullWidth
                value={profile.name2 ? profile.name2 : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("Company Name")}
                name="name1"
                fullWidth
                value={profile.name1 ? profile.name1 : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("Street / No")}
                name="street"
                fullWidth
                value={profile.street_no ? profile.street : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("Zipcode")}
                name="postcode"
                fullWidth
                value={profile.postcode ? profile.postcode : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("City")}
                name="city"
                fullWidth
                value={profile.city ? profile.city : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("Country")}
                name="country"
                fullWidth
                value={profile.country ? profile.country : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("Phone")}
                name="phone"
                fullWidth
                value={profile.phone ? profile.phone : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                label={t("Email")}
                name="email"
                fullWidth
                value={profile.email ? profile.email : ""}
                onChange={handleProfileChange}
              />
            </MDBox>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <MDBox p={0} display="flex" alignItems="center" justifyContent="flex-end">
            {!profile && (currentCustomer.erpEmail || currentCustomer.email) ? (
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => {
                  if (!openNewTicket) {
                    setParentLoading(true);
                    setOpenNewTicket(true);
                  } else {
                    const ticketViewEle = document.getElementById(`create_ticket_wrap`);
                    if (ticketViewEle) {
                      setTimeout(() => {
                        ticketViewEle.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "nearest",
                        });
                      }, 500);
                    }
                  }
                }}
              >
                {t("Create Ticket")}
              </MDButton>
            ) : null}
            {!isERP && !profile ? (
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                sx={{
                  marginLeft: 1.5,
                }}
                onClick={() => {
                  setProfile(currentCustomer);
                }}
              >
                {t("Edit Lead")}
              </MDButton>
            ) : null}
            {profile ? (
              <>
                <MDButton
                  variant="gradient"
                  color="success"
                  size="small"
                  onClick={() => {
                    saveCustomer();
                  }}
                >
                  {isNew ? t("Save as Lead") : t("Update Lead")}
                </MDButton>
                {!isNew ? (
                  <MDButton
                    variant="gradient"
                    color="error"
                    size="small"
                    sx={{
                      marginLeft: 1.5,
                    }}
                    onClick={() => {
                      setProfile(null);
                    }}
                  >
                    {t("Cancel")}
                  </MDButton>
                ) : null}
              </>
            ) : null}
          </MDBox>
        </Grid>
      </Grid>
      <Dialog open={openSearch} fullWidth maxWidth="lg">
        <DialogTitle>{t("Customer Search")}</DialogTitle>
        <DialogContent dividers>
          <Progress loading={loading} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <MDInput
                    type="text"
                    label={t("Customer Number")}
                    name="CustomerNumber"
                    fullWidth
                    value={searchData.CustomerNumber || ""}
                    onChange={(event) => {
                      setSearchData({
                        ...searchData,
                        CustomerNumber: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <MDInput
                    type="text"
                    label={t("Company Name")}
                    name="Name1"
                    fullWidth
                    value={searchData.Name1 || ""}
                    onChange={(event) => {
                      setSearchData({
                        ...searchData,
                        Name1: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <MDInput
                    type="text"
                    label={t("Customer Name")}
                    name="Name2"
                    fullWidth
                    value={searchData.Name2 || ""}
                    onChange={(event) => {
                      setSearchData({
                        ...searchData,
                        Name2: event.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <MDInput
                    type="text"
                    label={t("Email")}
                    name="Email"
                    fullWidth
                    value={searchData.Email || ""}
                    onChange={(event) => {
                      setSearchData({
                        ...searchData,
                        Email: event.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDButton
                variant="gradient"
                color="info"
                size="medium"
                sx={{ marginRight: 1 }}
                onClick={() => {
                  setSubmittedFilter(true);
                }}
              >
                {t("Search")}
              </MDButton>
            </Grid>
            <Grid item xs={12}>
              <MDBox pt={3}>
                <ServerSideTable
                  rowsPerPage={pageSize}
                  page={page}
                  columns={columns}
                  data={customers}
                  count={count}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(key) => {
                    const isAsc = orderBy === key && order === "asc";
                    setOrder(isAsc ? "desc" : "asc");
                    setOrderBy(key);
                    setPage(0);
                    setSubmittedFilter(true);
                  }}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  getRowProps={(row) => ({
                    onClick: () => {
                      setProfile({
                        ...profile,
                        customerNumber: row.customerNumber,
                        erpEmail: row.email,
                      });
                      setOpenSearch(false);
                    },
                  })}
                />
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setOpenSearch(false);
            }}
          >
            {t("Close")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default memo(BusinessCard);
