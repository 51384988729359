/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";

const { info, black } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  body: {
    minHeight: "100vh",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "ol, ul": {
    paddingInlineStart: "40px",
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${black.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  ".conversations-accordion:before": {
    display: "none",
  },
  ".conversations-accordion .MuiAccordionSummary-expandIconWrapper": {
    marginTop: 8,
  },
  ".conversations-accordion .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    marginTop: 16,
  },
  ".conversations-accordion:last-child .MuiAccordionSummary-expandIconWrapper": {
    visibility: "hidden",
  },
  ".ql-editor": {
    minHeight: "150px",
  },
  ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
    fontSize: "0.875rem !important",
    color: "#000000 !important",
    fontWeight: "400 !important",
  },
  ".MuiAccordion-root.ticket_details_accordion": {
    backgroundColor: "transparent !important",
  },
  ".MuiAccordion-root.ticket_details_accordion:not(:last-child)": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  ".MuiAccordion-root.ticket_details_accordion.Mui-expanded .sub-title": {
    display: "none",
  },
  ".MuiAccordion-root.ticket_details_accordion .sub-title-email": {
    display: "none",
  },
  ".MuiAccordion-root.ticket_details_accordion.Mui-expanded .sub-title-email": {
    display: "block",
  },
  ".MuiAccordion-root.ticket_details_accordion.Mui-expanded:before": {
    opacity: "1 !important",
  },
  ".MuiAccordion-root.ticket_details_accordion:before": {
    display: "none !important",
  },
  ".MuiAccordion-root.ticket_details_accordion .MuiButtonBase-root.Mui-disabled": {
    opacity: "1 !important",
  },
  ".MuiAccordion-root.overview_accordion .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "48px",
  },
  ".MuiAccordion-root.overview_accordion .MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(26, 115, 232, 0.2)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(26, 115, 232, 0.2)",
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(26, 115, 232, 0.2)",
    },
  },
  ".MuiButton-root.MUIButton-pulse": {
    transform: "scale(1)",
    animation: "pulse 2s infinite",
  },
  ".ticket_details_accordion blockquote": {
    margin: "0px 0px 0px 8px",
    borderLeft: "1px solid rgb(204,204,204)",
    paddingLeft: "8px",
  },
  ".assigned_to_name_badge .MuiBadge-standard ": {
    textTransform: "none !important",
  },
  ".missed_call_reason_badge .MuiBadge-standard ": {
    textTransform: "capitalize !important",
    borderColor: "#000 !important",
    borderWidth: "1px !important",
  },
  ":root": {
    "--ck-z-modal": "calc(var(--ck-z-default) + 1300)",
  },
  ".ck.ck-editor__editable_inline": {
    background: "var(--ck-color-toolbar-background)",
    border: " 1px solid var(--ck-color-toolbar-border)",
    minHeight: "200px",
    maxHeight: "400px",
    // maxWidth: "var(--a24-editor-width)",
  },
  ".ck-powered-by": {
    display: "none !important",
  },
};

export default globals;
