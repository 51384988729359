import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import theme from "assets/theme";

import moment from "moment";
import { useTranslation } from "react-i18next";

import SignIn from "view/sign-in";
import Home from "view/home";
import Dashboard from "view/route/dashboard";
import Customers from "view/route/customers";
import Tickets from "view/route/tickets";
import Ticket from "view/route/ticket";
import Orders from "view/route/orders";
import Order from "view/route/order";
import Items from "view/route/items";
import Item from "view/route/item";
import Customer from "view/route/customer";
import Quotations from "view/route/quotations";
import Quotation from "view/route/quotation";
import { BRAND_NAME } from "environment";

export default function App() {
  const { i18n } = useTranslation("common");
  moment.locale(i18n.language);

  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.title = BRAND_NAME;
  }, [pathname]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route exact path="/home" element={<Home />} key="home" />
          <Route exact path="/dashboard" element={<Dashboard />} key="home" />
          <Route exact path="/customers" element={<Customers />} key="home" />
          <Route exact path="/tickets" element={<Tickets />} key="home" />
          <Route exact path="/ticket/:ticketId" element={<Ticket />} key="home" />
          <Route exact path="/items" element={<Items />} key="home" />
          <Route exact path="/item/:articleNumber/:tabId" element={<Item />} key="home" />
          <Route exact path="/orders" element={<Orders />} key="home" />
          <Route exact path="/order/:documentIdentifier/:tabId" element={<Order />} key="home" />
          <Route exact path="/quotations" element={<Quotations />} key="home" />
          <Route
            exact
            path="/quotation/:documentIdentifier/:tabId"
            element={<Quotation />}
            key="home"
          />
          <Route exact path="/customer/:customerId" element={<Customer />} key="home" />
          <Route exact path="/customer/:customerId/:tabId" element={<Customer />} key="home" />
          <Route exact path="/sign-in" element={<SignIn />} key="sign-in" />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
