/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import MDConfirm from "components/MDConfirm";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ServerSideTable from "components/Tables/ServerSideTable";
import Progress from "components/Progress";
import { InputText, InputDropdown, InputCheckbox, InputAutocomplete } from "components/MDInputs";
import ERPAPI from "services/erp";

function Address({ customerID }) {
  const { t } = useTranslation("common");
  const [addresses, setAddresses] = useState([]);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("customerAddressId");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [confirmData, setConfirmData] = useState(null);
  // MASTER DATA
  const [salutations, setSalutations] = useState([]);
  const [countries, setCountries] = useState([]);
  const addressTypes = [];
  const states = [];

  const getMasterData = async () => {
    let ERPResponse = await ERPAPI.getSalutations();
    if (ERPResponse) {
      setSalutations(ERPResponse);
    }
    ERPResponse = await ERPAPI.getCountries();
    if (ERPResponse) {
      setCountries(ERPResponse);
    }
  };

  const getCustomerAddresses = async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerAddresses(
        customerID.replace("erp-", ""),
        page,
        pageSize,
        orderBy,
        order
      );
      if (
        ERPResponse &&
        ERPResponse.data &&
        ERPResponse.data.items &&
        ERPResponse.data.items.length
      ) {
        setAddresses([...ERPResponse.data.items]);
        setCount(ERPResponse.data.totalCount);
      } else {
        setAddresses([]);
      }
      setLoading(false);
    } else {
      setAddresses([]);
    }
  };

  useEffect(() => {
    getMasterData();
  }, []);

  useEffect(() => {
    getCustomerAddresses();
  }, [customerID, page, pageSize, orderBy, order]);

  const handelChangeAddress = (row, key) => {
    const addressID = row.id;
    setConfirmData({
      open: true,
      title: t("Change Address"),
      contentText: t("OK to change the default address?"),
      handleDisagree: () => {
        setConfirmData({ open: false });
      },
      handleAgree: () => {
        setConfirmData({ open: false });
        for (const item of addresses) {
          if (item.id === addressID) {
            item[key] = true;
          } else {
            item[key] = false;
          }
        }
      },
    });
  };

  const columns = [
    {
      header: t("Salutation"),
      accessor: "title",
      align: "left",
      sort: true,
    },
    {
      header: t("First Name"),
      accessor: "firstName",
      align: "left",
      sort: true,
    },
    {
      header: t("Last Name"),
      accessor: "lastName",
      align: "left",
      sort: true,
    },
    {
      header: t("Addition"),
      accessor: "addition",
      align: "left",
      sort: true,
    },
    {
      header: t("Street, no."),
      accessor: "street",
      align: "left",
      sort: true,
    },
    {
      header: t("Zipcode"),
      accessor: "postalCode",
      align: "left",
      sort: true,
    },
    {
      header: t("City"),
      accessor: "city",
      align: "left",
      sort: true,
    },
    {
      header: t("Country"),
      accessor: "countryCode",
      align: "left",
      sort: true,
    },
    {
      header: t("Default Invoice Address"),
      accessor: "isPrimaryBillingAddress",
      align: "center",
      sort: true,
      render: (row) => (
        <>
          {row.isPrimaryBillingAddress ? (
            <IconButton color="success">
              <Icon>check_circle</Icon>
            </IconButton>
          ) : (
            <IconButton
              color="error"
              disabled
              onClick={() => {
                handelChangeAddress(row, "isPrimaryBillingAddress");
              }}
            >
              <Icon>cancel</Icon>
            </IconButton>
          )}
        </>
      ),
    },
    {
      header: t("Default Delivery Address"),
      accessor: "isPrimaryShippingAddress",
      align: "center",
      sort: true,
      render: (row) => (
        <>
          {row.isPrimaryShippingAddress ? (
            <IconButton color="success">
              <Icon>check_circle</Icon>
            </IconButton>
          ) : (
            <IconButton
              color="error"
              disabled
              onClick={() => {
                handelChangeAddress(row, "isPrimaryShippingAddress");
              }}
            >
              <Icon>cancel</Icon>
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line no-unused-vars
  const onChange = (name, value) => {
    setCurrentAddress({
      ...currentAddress,
      // [name]: value,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleSave = () => {
    setCurrentAddress(null);
    setOpen(false);
  };

  return (
    <>
      <Card sx={{ overflow: "hidden" }}>
        <Progress loading={loading} isFull />
        <MDBox px={3} py={1} display="flex" alignItems="center" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            {t("Address")}
          </MDTypography>
          {/* <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              setCurrentAddress({});
              setOpen(true);
            }}
          >
            {t("Add")}
          </MDButton> */}
        </MDBox>
        <MDBox>
          <ServerSideTable
            rowsPerPage={pageSize}
            page={page}
            columns={columns}
            data={addresses}
            count={count}
            order={order}
            orderBy={orderBy}
            onRequestSort={(key) => {
              const isAsc = orderBy === key && order === "asc";
              setOrder(isAsc ? "desc" : "asc");
              setOrderBy(key);
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            getRowProps={(row) => ({
              onClick: () => {
                setCurrentAddress(row);
                setOpen(true);
              },
              style: {
                cursor: "pointer",
              },
            })}
          />
        </MDBox>
      </Card>

      <MDConfirm data={confirmData} />
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>
          {currentAddress && currentAddress.id ? t("Edit Address") : t("Add Address")}
        </DialogTitle>
        <DialogContent dividers>
          {currentAddress ? (
            <MDBox py={2}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <InputAutocomplete
                        label={t("Address Type")}
                        options={addressTypes}
                        name="addressTypeId"
                        onChange={onChange}
                        value={currentAddress.addressTypeId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputCheckbox
                        valueInt
                        label={t("Bill Address")}
                        name="isPrimaryBillingAddress"
                        onChange={onChange}
                        value={currentAddress.isPrimaryBillingAddress}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputCheckbox
                        valueInt
                        label={t("Delivery Address")}
                        name="isPrimaryShippingAddress"
                        onChange={onChange}
                        value={currentAddress.isPrimaryShippingAddress}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputDropdown
                        label={t("Salutation")}
                        options={salutations}
                        name="title"
                        onChange={onChange}
                        value={currentAddress.title}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("First Name")}
                        name="firstName"
                        value={currentAddress.firstName}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Last Name")}
                        name="lastName"
                        value={currentAddress.lastName}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Business")}
                        name="business"
                        value={currentAddress.business}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Street")}
                        name="street"
                        value={currentAddress.street}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("House Number")}
                        name="houseNumber"
                        value={currentAddress.houseNumber}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Address Supplement")}
                        name="address_supplement"
                        value={currentAddress.address_supplement}
                        onChange={onChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <InputText
                        label={t("P.O. Box")}
                        name="postNumber"
                        value={currentAddress.postNumber}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Packing Station")}
                        name="packstationNumber"
                        value={currentAddress.packstationNumber}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Postcode")}
                        name="postalCode"
                        value={currentAddress.postalCode}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Location")}
                        name="location"
                        value={currentAddress.location}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputDropdown
                        label={t("Country")}
                        options={countries}
                        name="countryCode"
                        onChange={onChange}
                        value={currentAddress.countryCode}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputAutocomplete
                        label={t("State")}
                        options={states}
                        name="stateCode"
                        onChange={onChange}
                        value={currentAddress.stateCode}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Phone")}
                        name="phone"
                        value={currentAddress.phone}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Mobile")}
                        name="mobilePhone"
                        value={currentAddress.mobilePhone}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputText
                        label={t("Fax")}
                        name="fax"
                        value={currentAddress.fax}
                        onChange={onChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setCurrentAddress(null);
              setOpen(false);
            }}
          >
            {t("Cancel")}
          </MDButton>
          {/* <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              handleSave();
            }}
          >
            {currentAddress && currentAddress.id ? t("Update") : t("Save")}
          </MDButton> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(Address);
