import { MDT_SALUTATION, MDT_LANGUAGE, MDT_CUSTOMER_GROUP, MDT_COUNTRIES } from "environment";
import Fetch from "../fetch";

const master = {
  async getMasterData() {
    const response = await Fetch.get(
      `api/erp/groupinformation/master?types[]=${MDT_LANGUAGE}&types[]=${MDT_SALUTATION}&types[]=${MDT_COUNTRIES}&types[]=${MDT_CUSTOMER_GROUP}`
    );
    if (response && response.data && response.data.data && response.data.data.length) {
      response.data.data.forEach((ele) => {
        if (ele.type === MDT_LANGUAGE) {
          const languages = ele.items.map((item) => ({
            id: item.code,
            label: item.description,
          }));
          localStorage.setItem("A24_MDT_LANGUAGE", JSON.stringify(languages));
        } else if (ele.type === MDT_SALUTATION) {
          const salutations = ele.items.map((item) => ({
            id: item.description,
            label: item.description,
          }));
          localStorage.setItem("A24_MDT_SALUTATION", JSON.stringify(salutations));
        } else if (ele.type === MDT_CUSTOMER_GROUP) {
          const customerGroups = ele.items.map((item) => ({
            id: item.code,
            label: item.description,
          }));
          localStorage.setItem("A24_MDT_CUSTOMER_GROUP", JSON.stringify(customerGroups));
        } else if (ele.type === MDT_COUNTRIES) {
          const countries = ele.items.map((item) => ({
            id: item.code,
            label: item.description,
          }));
          localStorage.setItem("A24_MDT_COUNTRIES", JSON.stringify(countries));
        }
      });
    }
    return [];
  },

  async getLanguages() {
    const storageStr = localStorage.getItem("A24_MDT_LANGUAGE");
    if (storageStr && storageStr !== "") {
      return JSON.parse(storageStr);
    }
    const response = await Fetch.get(`api/erp/groupinformation/master?types=${MDT_LANGUAGE}`);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.length &&
      response.data.data[0].items &&
      response.data.data[0].items.length
    ) {
      const languages = response.data.data[0].items.map((item) => ({
        id: item.code,
        label: item.description,
      }));
      localStorage.setItem("A24_MDT_LANGUAGE", JSON.stringify(languages));
      return languages;
    }
    return [];
  },
  async getSalutations() {
    const storageStr = localStorage.getItem("A24_MDT_SALUTATION");
    if (storageStr && storageStr !== "") {
      return JSON.parse(storageStr);
    }
    const response = await Fetch.get(`api/erp/groupinformation/master?types=${MDT_SALUTATION}`);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.length &&
      response.data.data[0].items &&
      response.data.data[0].items.length
    ) {
      const salutations = response.data.data[0].items.map((item) => ({
        id: item.description,
        label: item.description,
      }));
      localStorage.setItem("A24_MDT_SALUTATION", JSON.stringify(salutations));
      return salutations;
    }
    return [];
  },
  async getCustomerGroups() {
    const storageStr = localStorage.getItem("A24_MDT_CUSTOMER_GROUP");
    if (storageStr && storageStr !== "") {
      return JSON.parse(storageStr);
    }
    const response = await Fetch.get(`api/erp/groupinformation/master?types=${MDT_CUSTOMER_GROUP}`);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.length &&
      response.data.data[0].items &&
      response.data.data[0].items.length
    ) {
      const customerGroups = response.data.data[0].items.map((item) => ({
        id: item.code,
        label: item.description,
      }));
      localStorage.setItem("A24_MDT_CUSTOMER_GROUP", JSON.stringify(customerGroups));
      return customerGroups;
    }
    return [];
  },
  async getCountries() {
    const storageStr = localStorage.getItem("A24_MDT_COUNTRIES");
    if (storageStr && storageStr !== "") {
      return JSON.parse(storageStr);
    }
    const response = await Fetch.get(`api/erp/groupinformation/master?types=${MDT_COUNTRIES}`);
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.length &&
      response.data.data[0].items &&
      response.data.data[0].items.length
    ) {
      const countries = response.data.data[0].items.map((item) => ({
        id: item.code,
        label: item.description,
      }));

      localStorage.setItem("A24_MDT_COUNTRIES", JSON.stringify(countries));
      return countries;
    }
    return [];
  },
};
export default master;
