/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, useRef, memo } from "react";
import juice from "juice";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import useLocalStorage from "hooks/useLocalStorage";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import CKEditor from "components/Editors/CK";
import CkStyles from "components/Editors/CK/styles";

import TemplatesAPI from "services/templates";
import SignaturesAPI from "services/signatures";
import UsersAPI from "services/users";
import TicketsAPI from "services/tickets";
import { TICKET_INTERACTION_TYPE_EMAIL, USER_ROLE_ADMIN, USER_ROLE_CRM } from "environment";

function NewTicket({ open, customer, setOpenNewTicket, setOpenTicket, setParentLoading }) {
  const { t } = useTranslation("common");
  const [currentTicket, setCurrentTicket] = useState({
    subject: "",
    content: "",
    content_html: "",
    to_list: [],
    from_email: "",
    from: "",
    customer_email: customer.email,
  });
  const userId = parseInt(localStorage.getItem("A24_USER_ID"), 10);
  const [region] = useLocalStorage("A24_REGION", 0);
  const [userData, setUserData] = useState({});
  const [templateMenu, setTemplateMenu] = useState(null);
  const [signatureMenu, setSignatureMenu] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [signatures, setSignatures] = useState([]);
  const [users, setUsers] = useState([]);
  const editorRef = useRef();

  const getVerifiedUsers = async () => {
    const response = await UsersAPI.getVerifiedUsers();
    if (response && response.data) {
      const userList = response.data.filter(
        (item) =>
          item.region === region &&
          item.email_verified &&
          item.roles.length &&
          (item.id === userId || item.generic) &&
          item.roles.filter((e) => e.name === USER_ROLE_ADMIN || e.name === USER_ROLE_CRM).length
      );
      userList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setUsers(userList);
    } else {
      setUsers([]);
    }
  };

  useEffect(async () => {
    const getUserData = () => {
      const profileStr = localStorage.getItem("A24_USER");
      let profile = {};
      if (profileStr && profileStr !== "") {
        profile = JSON.parse(profileStr);
      }
      setUserData({
        name: profile.name || "",
        email: profile.email || "",
        title: profile.title || "",
        phone: profile.phone || "",
        mobile: profile.mobile || "",
      });
      setCurrentTicket({
        ...currentTicket,
        from_email: profile.email,
        from: profile.name,
      });
    };
    getUserData();
    window.addEventListener("user_storage", getUserData);
    return () => window.removeEventListener("user_storage", getUserData);
  }, []);

  useEffect(() => {
    getVerifiedUsers();
  }, []);

  const closeTemplateMenu = () => setTemplateMenu(null);
  const closeSignatureMenu = () => setSignatureMenu(null);

  const getTemplates = async () => {
    const response = await TemplatesAPI.getAll();
    if (response) {
      setTemplates(response);
      if (!response.length) {
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "WARN",
              title: "Templates",
              content: "Templates not found",
            },
          })
        );
        closeTemplateMenu();
      }
    } else {
      setTemplates([]);
    }
  };

  const getSignatures = async () => {
    const response = await SignaturesAPI.getAllByUser();
    if (response) {
      if (!response.length) {
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "WARN",
              title: "Signatures",
              content: "Signatures not found",
            },
          })
        );
        closeSignatureMenu();
      } else {
        const result = [];
        response.forEach((item) => {
          item.signatures.forEach((item2) => {
            result.push({
              ...item2,
              default: item.default,
            });
          });
        });
        setSignatures(result);
        if (result && result.length) {
          const defaultSignatures = result.filter((x) => x.default);
          if (defaultSignatures.length) {
            const content = defaultSignatures[0].content.replace(/{(\w+)}/g, (_, k) => userData[k]);
            setCurrentTicket({
              ...currentTicket,
              content_html: `<br/><br/><br/>${content}`,
            });
          }
        }
      }
    } else {
      setSignatures([]);
    }
  };

  const handleChange = (name, value) => {
    setCurrentTicket({
      ...currentTicket,
      [name]: value,
    });
  };

  useEffect(async () => {
    getSignatures();
  }, [userData]);

  useEffect(async () => {
    const from = users.filter((x) => x.email === currentTicket.from_email);
    if (from.length) {
      handleChange("from", from[0].name);
    }
  }, [currentTicket.from_email]);

  useEffect(async () => {
    if (open) {
      const ticketViewEle = document.getElementById(`create_ticket_wrap`);
      if (ticketViewEle) {
        setTimeout(() => {
          ticketViewEle.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          setParentLoading(false);
        }, 500);
      } else {
        setParentLoading(false);
      }
    }
  }, [open]);

  const htmlToText = (html) => {
    const temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent;
  };

  const handleDiscardDraft = () => {
    setOpenNewTicket(false);
  };

  const handleCreate = async () => {
    setParentLoading(true);
    const content = juice.inlineContent(currentTicket.content_html, CkStyles);
    const replyEmailText = htmlToText(content);
    const formData = {
      interaction_type: TICKET_INTERACTION_TYPE_EMAIL,
      subject: currentTicket.subject,
      from: currentTicket.from,
      from_email: currentTicket.from_email,
      customer_email: currentTicket.customer_email,
      to_list: currentTicket.to_list.length ? currentTicket.to_list.join() : "",
      content: replyEmailText,
      content_html: content,
    };
    const response = await TicketsAPI.createTicket(formData);
    if (response && response.data) {
      setOpenNewTicket(false);
      setOpenTicket(response.data.ticket_id);
      window.dispatchEvent(new Event("tickets_status_change"));
    } else {
      setParentLoading(false);
    }
  };

  const handleSaveDraft = async () => {
    setParentLoading(true);
    const content = juice.inlineContent(currentTicket.content_html, CkStyles);
    const replyEmailText = htmlToText(content);
    const formData = {
      interaction_type: null,
      subject: currentTicket.subject,
      from: currentTicket.from,
      from_email: currentTicket.from_email,
      customer_email: currentTicket.customer_email,
      to_list: currentTicket.to_list.length ? currentTicket.to_list.join() : "",
      content: replyEmailText,
      content_html: content,
    };
    const response = await TicketsAPI.createTicket(formData);
    if (response && response.data) {
      setOpenNewTicket(false);
      setOpenTicket(response.data.ticket_id);
      window.dispatchEvent(new Event("tickets_status_change"));
    } else {
      setParentLoading(false);
    }
  };

  const openTemplateMenu = (event, cell) => {
    getTemplates();
    setTemplateMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      cell,
    });
  };

  const openSignatureMenu = (event, cell) => {
    setSignatureMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
      cell,
    });
  };

  const handleTemplate = (template) => {
    closeTemplateMenu();
    if (editorRef && editorRef.current) {
      const viewFragment = editorRef.current.data.processor.toView(template.content);
      const modelFragment = editorRef.current.data.toModel(viewFragment);
      editorRef.current.model.insertContent(
        modelFragment,
        editorRef.current.model.document.selection.getFirstPosition()
      );
      editorRef.current.editing.view.focus();
    }
  };

  const handleSignature = (signature) => {
    closeSignatureMenu();
    if (editorRef && editorRef.current) {
      const content = signature.content.replace(/{(\w+)}/g, (_, k) => userData[k]);
      const viewFragment = editorRef.current.data.processor.toView(content);
      const modelFragment = editorRef.current.data.toModel(viewFragment);
      editorRef.current.model.insertContent(
        modelFragment,
        editorRef.current.model.document.selection.getFirstPosition()
      );
      editorRef.current.editing.view.focus();
    }
  };

  return (
    <MDBox position="relative" p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MDInput
            fullWidth
            required
            label={t("Customer")}
            name="customer_email"
            value={currentTicket.customer_email}
            disabled
            onChange={(event) => {
              handleChange("customer_email", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            select
            label={t("From")}
            name="from_email"
            fullWidth
            value={currentTicket.from_email}
            onChange={(event) => {
              handleChange("from_email", event.target.value);
            }}
          >
            {users.map((user, index) => (
              <MenuItem key={index} value={user.email}>
                {user.name} ({user.email})
              </MenuItem>
            ))}
          </MDInput>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={[]}
            value={currentTicket.to_list}
            onChange={(e, newVal) => {
              handleChange("to_list", newVal);
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                type="email"
                label={t("CC")}
                placeholder={t("Enter Email")}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.target.value) {
                    if (e.target.validity.valid) {
                      handleChange("to_list", currentTicket.to_list.concat(e.target.value));
                    }
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <MDInput
            fullWidth
            required
            label={t("Subject")}
            name="subject"
            value={currentTicket.subject}
            onChange={(event) => {
              handleChange("subject", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CKEditor
            value={currentTicket.content_html || ""}
            placeholder={t("Type here")}
            onChange={(event, value) => {
              handleChange("content_html", value);
            }}
            setRef={(ref) => {
              editorRef.current = ref;
            }}
          />
          <MDBox sx={{ display: "flex", flexWrap: "wrap" }}>
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                paddingTop: 2,
              }}
            >
              {/* <Tooltip title={t("Attachments")}>
                  <IconButton
                    color="warning"
                    sx={{ marginRight: 1 }}
                    onClick={() => {
                      handleAddFile();
                    }}
                  >
                    <Icon>attachment</Icon>
                  </IconButton>
                </Tooltip> */}
              {/* <input
                  multiple
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={handelChangeFile}
                /> */}
              <Tooltip title={t("Templates")}>
                <IconButton
                  color="dark"
                  sx={{ marginRight: 1 }}
                  aria-describedby={`menu_template_${currentTicket.id}`}
                  onClick={(event) => {
                    openTemplateMenu(event, currentTicket);
                  }}
                >
                  <Icon>code_blocks</Icon>
                </IconButton>
              </Tooltip>
              <Menu
                id={`menu_template_${currentTicket.id}`}
                anchorReference="anchorPosition"
                anchorPosition={
                  templateMenu !== null
                    ? {
                        top: templateMenu.position.top,
                        left: templateMenu.position.left,
                      }
                    : undefined
                }
                open={!!(templateMenu && currentTicket.id === templateMenu.cell.id)}
                onClose={closeTemplateMenu}
              >
                {templates.map((template, tempIndex) => (
                  <MenuItem
                    key={tempIndex}
                    onClick={() => {
                      handleTemplate(template);
                    }}
                  >
                    {template.name} ({template.language})
                  </MenuItem>
                ))}
              </Menu>

              <Tooltip title={t("Signature")}>
                <IconButton
                  color="primary"
                  sx={{ marginRight: 1 }}
                  aria-describedby={`menu_signature_${currentTicket.id}`}
                  onClick={(event) => {
                    openSignatureMenu(event, currentTicket);
                  }}
                >
                  <Icon baseClassName="material-symbols-outlined">signature</Icon>
                </IconButton>
              </Tooltip>
              <Menu
                id={`menu_signature_${currentTicket.id}`}
                anchorReference="anchorPosition"
                anchorPosition={
                  signatureMenu !== null
                    ? {
                        top: signatureMenu.position.top,
                        left: signatureMenu.position.left,
                      }
                    : undefined
                }
                open={!!(signatureMenu && currentTicket.id === signatureMenu.cell.id)}
                onClose={closeSignatureMenu}
              >
                {signatures.map((signature, tempIndex) => (
                  <MenuItem
                    key={tempIndex}
                    onClick={() => {
                      handleSignature(signature);
                    }}
                  >
                    {signature.name} ({signature.language})
                  </MenuItem>
                ))}
              </Menu>
            </MDBox>
            <MDBox
              sx={{
                paddingTop: 2,
              }}
            >
              <Tooltip title={t("Discard draft")}>
                <IconButton
                  color="error"
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    handleDiscardDraft();
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Save as draft")}>
                <IconButton
                  color="info"
                  sx={{ marginLeft: 1 }}
                  disabled={!currentTicket.subject || !currentTicket.content_html}
                  onClick={() => {
                    handleSaveDraft();
                  }}
                >
                  <Icon>save</Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Send")}>
                <IconButton
                  color="success"
                  sx={{ marginLeft: 1 }}
                  disabled={!currentTicket.subject || !currentTicket.content_html}
                  onClick={() => {
                    handleCreate();
                  }}
                >
                  <Icon>send</Icon>
                </IconButton>
              </Tooltip>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(NewTicket);
