import Fetch from "./fetch";

const TasksAPI = {
  async getAllByUser(perPage = 50, page = 0) {
    const response = await Fetch.get(`api/tasks/user?page=${page + 1}&per_page=${perPage}`);
    return response?.data;
  },
  async getTaskByFilter(perPage = 50, page = 0, data) {
    const response = await Fetch.post(`api/task/filter?page=${page + 1}&per_page=${perPage}`, data);
    return response?.data;
  },
  async getAllByTicket(ticketId) {
    const response = await Fetch.get(`api/tasks/ticket/${ticketId} `);
    return response?.data;
  },
  async getTask(taskId) {
    const response = await Fetch.get(`api/task/${taskId}`);
    return response?.data;
  },
  async deleteTask(taskId) {
    const response = await Fetch.delete(`api/task/${taskId}`);
    return response;
  },
  async createTask(data) {
    const response = await Fetch.post(`api/task`, data);
    return response;
  },
};
export default TasksAPI;
