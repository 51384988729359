import customer from "./customer";
import order from "./order";
import item from "./item";
import quote from "./quote";
import master from "./master";
import delivery from "./delivery";
import dsm from "./dsm";

const ERPAPI = {
  ...customer,
  ...order,
  ...item,
  ...quote,
  ...master,
  ...delivery,
  ...dsm,
};

export default ERPAPI;
