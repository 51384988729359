import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController, setLayout, setOpenSnackbar } from "context";

function DashboardLayout({ children }) {
  const { t } = useTranslation("common");
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, snackbar } = controller;
  const { pathname } = useLocation();
  const handleCloseSnackbar = () => setOpenSnackbar(dispatch, null);

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  useEffect(() => {
    const handleSnackbar = (event) => {
      setOpenSnackbar(dispatch, event.detail);
    };
    window.addEventListener("snackbar", handleSnackbar);
    return () => window.removeEventListener("snackbar", handleSnackbar);
  }, []);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        px: 0,
        pb: 0,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(0) : pxToRem(0),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
      <MDSnackbar
        type="error"
        color="error"
        icon="priority_high"
        bgWhite
        title={snackbar && snackbar.title ? t(snackbar.title) : ""}
        content={snackbar && snackbar.content ? t(snackbar.content) : ""}
        dateTime={snackbar && snackbar.dateTime ? snackbar.dateTime : ""}
        duration={5000}
        open={Boolean(snackbar) && snackbar.type === "ERR"}
        onClose={() => handleCloseSnackbar()}
        close={() => handleCloseSnackbar()}
      />
      <MDSnackbar
        type="warning"
        color="warning"
        icon="priority_high"
        bgWhite
        title={snackbar && snackbar.title ? t(snackbar.title) : ""}
        content={snackbar && snackbar.content ? t(snackbar.content) : ""}
        dateTime={snackbar && snackbar.dateTime ? snackbar.dateTime : ""}
        duration={5000}
        open={Boolean(snackbar) && snackbar.type === "WARN"}
        onClose={() => handleCloseSnackbar()}
        close={() => handleCloseSnackbar()}
      />
      <MDSnackbar
        type="success"
        color="success"
        icon="check"
        bgWhite
        title={snackbar && snackbar.title ? t(snackbar.title) : ""}
        content={snackbar && snackbar.content ? t(snackbar.content) : ""}
        dateTime={snackbar && snackbar.dateTime ? snackbar.dateTime : ""}
        open={Boolean(snackbar) && snackbar.type === "OK"}
        onClose={() => handleCloseSnackbar()}
        close={() => handleCloseSnackbar()}
      />
    </MDBox>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
