/* eslint-disable react/prop-types */
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";

function Progress({ loading, isFull }) {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? "800ms" : "0ms",
      }}
      unmountOnExit
    >
      <MDBox
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.36)",
          zIndex: 1400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          minHeight: isFull ? "calc(100vh - 100px)" : 0,
        }}
      >
        <CircularProgress color="white" />
      </MDBox>
    </Fade>
  );
}

export default Progress;
