/* eslint-disable react/prop-types */
import { memo, useState, useEffect } from "react";
import MDBox from "components/MDBox";
import StatisticsTable from "components/Tables/StatisticsTable";
import Progress from "components/Progress";

import { useTranslation } from "react-i18next";
import CommonFun from "services/common";
import ERPAPI from "services/erp";

function Items({ documentIdentifier, nativeCurrencyIso }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const getQuotationItems = async () => {
    setLoading(true);
    const ERPResponse = await ERPAPI.getQuotationPositions(documentIdentifier);
    if (ERPResponse && ERPResponse.data) {
      setItems([...ERPResponse.data]);
    } else {
      setItems([]);
    }
    setLoading(false);
  };

  useEffect(async () => {
    if (documentIdentifier) {
      getQuotationItems();
    }
  }, [documentIdentifier]);

  const columns = [
    {
      header: t("Items"),
      accessor: "position",
      align: "left",
      sort: false,
    },
    {
      header: t("Article no."),
      accessor: "articleNumber",
      align: "left",
      sort: false,
    },
    {
      header: t("Name"),
      accessor: "name1",
      align: "left",
      sort: false,
    },
    {
      header: t("Quantity"),
      accessor: "quantity",
      align: "right",
      sort: false,
    },
    {
      header: t("Unit Price"),
      accessor: "nativeUnitPrice",
      align: "right",
      sort: false,
      currency: nativeCurrencyIso,
    },
    {
      header: t("Discount %"),
      accessor: "discountPercent",
      align: "right",
      sort: false,
    },
    {
      header: t("Total Price"),
      accessor: "nativeTotalPrice",
      align: "right",
      sort: false,
      currency: nativeCurrencyIso,
    },
    {
      header: t("Available Stock"),
      accessor: "availableStock",
      align: "right",
      sort: false,
    },
  ];

  return (
    <MDBox position="relative">
      <Progress loading={loading} />
      <StatisticsTable
        columns={columns}
        rows={items}
        getRowProps={(row) => ({
          onClick: () => {
            CommonFun.onClickItem(row.articleNumber);
          },
          style: {
            cursor: "pointer",
          },
        })}
      />
    </MDBox>
  );
}

export default memo(Items);
