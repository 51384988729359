import Fetch from "../fetch";

const quote = {
  async searchQuotations(filter, page = 0, pageSize = 10, orderBy, order) {
    const params = [];
    if (filter) {
      Object.keys(filter).forEach((key) => {
        if (filter[key].length === 1) {
          params.push(`${key}=${filter[key][0]}`);
        } else {
          filter[key].forEach((value) => {
            params.push(`${key}[]=${value}`);
          });
        }
      });
    }
    params.push(`page=${page + 1}`);
    params.push(`pageSize=${pageSize}`);
    params.push(`order=${orderBy} ${order}`);
    const response = await Fetch.get(`api/erp/quote?${params.join("&")}`);
    return response?.data;
  },
  async getQuotation(documentIdentifier) {
    const response = await Fetch.get(`api/erp/quote/${documentIdentifier}/information`);
    return response?.data;
  },
  async getQuotationPositions(documentIdentifier) {
    const response = await Fetch.get(`api/erp/quote/${documentIdentifier}/positions`);
    return response?.data;
  },
  async getQuotationCustomer(documentIdentifier) {
    const response = await Fetch.get(`api/erp/quote/${documentIdentifier}/customer`);
    return response?.data;
  },
  async getQuotationLogs(documentIdentifier) {
    const response = await Fetch.get(`api/erp/quote/${documentIdentifier}/logs`);
    return response?.data;
  },
};
export default quote;
