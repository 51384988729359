import Fetch from "./fetch";

const TemplatesAPI = {
  async getAll() {
    const response = await Fetch.get(`api/templates`);
    return response?.data;
  },
  async delete(templateId) {
    const response = await Fetch.delete(`api/template/${templateId}`);
    return response;
  },
  async create(data) {
    const response = await Fetch.post(`api/template`, data);
    return response;
  },
  async copyTemplate(templateId, userId) {
    const response = await Fetch.post(`api/copytemplate`, {
      template_id: templateId,
      user_id: userId,
    });
    return response;
  },
};
export default TemplatesAPI;
