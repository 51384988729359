/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState, memo } from "react";
import juice from "juice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// import CodeMirror from "@uiw/react-codemirror";
// import { basicLight } from "@uiw/codemirror-theme-basic";
// import { html } from "@codemirror/lang-html";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Progress from "components/Progress";
import StatisticsTable from "components/Tables/StatisticsTable";
import { InputText, InputDropdown, InputEditor } from "components/MDInputs";
import CkStyles from "components/Editors/CK/styles";

import SignaturesAPI from "services/signatures";
import UsersAPI from "services/users";
import CommonFun from "services/common";

function Signatures() {
  const { t } = useTranslation("common");
  const [signatures, setSignatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSignature, setCurrentSignature] = useState(null);
  const [open, setOpen] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [userData, setUserData] = useState({});
  const [users, setUsers] = useState([]);
  const [signature, setSignature] = useState(null);
  const [userMenu, setUserMenu] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [languages, setLanguages] = useState([]);

  const getSignatures = async () => {
    setLoading(true);
    const response = await SignaturesAPI.getAll();
    if (response && response.data) {
      setSignatures(response.data);
    } else {
      setSignatures([]);
    }
    setLoading(false);
  };

  const getSignature = async (id) => {
    setLoading(true);
    const response = await SignaturesAPI.get(id);
    if (response && response.length) {
      setSignature({
        ...response[0],
        users: response[1],
      });
    } else {
      setSignature(null);
    }
    setLoading(false);
  };

  useEffect(async () => {
    const getUserData = () => {
      const profileStr = localStorage.getItem("A24_USER");
      let profile = {};
      if (profileStr && profileStr !== "") {
        profile = JSON.parse(profileStr);
      }
      setUserData({
        name: profile.name || "",
        email: profile.email || "",
        title: profile.title || "",
        phone: profile.phone || "",
        mobile: profile.mobile || "",
      });
    };
    getUserData();
    window.addEventListener("user_storage", getUserData);
    return () => window.removeEventListener("user_storage", getUserData);
  }, []);

  useEffect(async () => {
    if (CommonFun.isAdmin()) {
      getSignatures();
    }
    setLanguages(CommonFun.getLanguageCountry());
  }, []);

  const onChange = (name, value) => {
    setCurrentSignature({
      ...currentSignature,
      [name]: value,
    });
  };

  const onDeleteSignature = async (row) => {
    setLoading(true);
    const response = await SignaturesAPI.delete(row.id);
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Delete Signature",
            content: "Signature deleted successfully",
          },
        })
      );
      getSignatures();
    }
    setLoading(false);
  };

  const getVerifiedUsers = async () => {
    const response = await UsersAPI.getVerifiedUsers();
    if (response && response.data) {
      const userList = response.data.filter((item) => item.email_verified);
      userList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setUsers(userList);
    } else {
      setUsers([]);
    }
  };

  const openUserSignatureMap = async (row) => {
    getSignature(row.id);
    getVerifiedUsers();
    setMapOpen(true);
  };

  const openUserMenu = (event) => {
    setUserMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
    });
  };

  const closeUserMenu = () => setUserMenu(null);

  const handleAddUserSignatureMap = async (userId) => {
    setLoading(true);
    const response = await SignaturesAPI.addUserSignatureMap({
      user_id: userId,
      signature_id: signature.id,
    });
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Add User Signature Map",
            content: "User add successfully",
          },
        })
      );
      getSignature(signature.id);
    }
    setLoading(false);
    closeUserMenu();
  };

  const handleUserSignatureMapDelete = async (userSignatureMapId) => {
    setLoading(true);
    const response = await SignaturesAPI.deleteUserSignatureMap(userSignatureMapId);
    if (response && response.data) {
      window.dispatchEvent(
        new CustomEvent("snackbar", {
          detail: {
            type: "OK",
            title: "Delete User Signature Map",
            content: "User deleted successfully",
          },
        })
      );
      getSignature(signature.id);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    setSubmitted(true);
    if (currentSignature.name && currentSignature.language && currentSignature.content) {
      const response = await SignaturesAPI.create({
        ...currentSignature,
        content: juice.inlineContent(currentSignature.content, CkStyles),
      });
      if (response && response.data) {
        setLoading(true);
        setCurrentSignature(null);
        setOpen(false);
        setSubmitted(false);
        window.dispatchEvent(
          new CustomEvent("snackbar", {
            detail: {
              type: "OK",
              title: "Signature",
              content: "Saved Successfully",
            },
          })
        );
        getSignatures();
      } else {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      header: t("Name"),
      accessor: "name",
      align: "left",
    },
    {
      header: t("Language"),
      accessor: "language",
      align: "left",
      sort: false,
    },
    {
      header: t("Created At"),
      accessor: "created_at",
      align: "left",
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.created_at).format(CommonFun.getDateTimeFormat())}
        </MDTypography>
      ),
    },
    {
      header: t("Updated At"),
      accessor: "updated_at",
      align: "left",
      sort: false,
      render: (row) => (
        <MDTypography variant="caption" color="black" fontWeight="medium">
          {moment.utc(row.updated_at).format(CommonFun.getDateTimeFormat())}
        </MDTypography>
      ),
    },
    {
      header: t("Action"),
      accessor: "delete",
      align: "center",
      sort: false,
      render: (row) => (
        <>
          <IconButton
            color="info"
            onClick={() => {
              setCurrentSignature(row);
              setSubmitted(false);
              setOpen(true);
            }}
          >
            <Icon>edit</Icon>
          </IconButton>
          <IconButton
            color="info"
            onClick={() => {
              openUserSignatureMap(row);
            }}
          >
            <Icon>group_add</Icon>
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              onDeleteSignature(row);
            }}
          >
            <Icon>delete</Icon>
          </IconButton>
        </>
      ),
      getCellProps: () => ({
        // onClick: () => {}
        style: {
          cursor: "default",
        },
      }),
    },
  ];

  const getPreviewDate = (content) => {
    if (content) {
      return juice.inlineContent(
        content.replace(/{(\w+)}/g, (_, k) => userData[k]),
        CkStyles
      );
    }
    return "";
  };

  const rows = signatures;

  return (
    <MDBox>
      <Progress loading={loading} isFull />
      <MDBox py={1} display="flex" alignItems="center" justifyContent="space-between">
        <MDTypography variant="h5" fontWeight="medium" />
        <MDButton
          variant="gradient"
          color="success"
          size="small"
          onClick={() => {
            setCurrentSignature({ language: "EN" });
            setSubmitted(false);
            setOpen(true);
          }}
        >
          {t("Add")}
        </MDButton>
      </MDBox>
      <MDBox>
        <StatisticsTable
          columns={columns}
          rows={rows}
          getRowProps={(row) => ({
            onClick: () => {
              setCurrentSignature(row);
              setSubmitted(false);
              setOpen(true);
            },
            style: {
              cursor: "pointer",
            },
          })}
        />
      </MDBox>
      <Dialog open={open} fullWidth fullScreen={fullScreen} maxWidth="lg" disableEnforceFocus>
        <DialogTitle>
          {currentSignature && currentSignature.id ? t("Edit Signature") : t("Add Signature")}
        </DialogTitle>
        <IconButton
          onClick={() => {
            setFullScreen(!fullScreen);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon>{fullScreen ? "close_fullscreen" : "open_in_full"}</Icon>
        </IconButton>
        <DialogContent dividers>
          {currentSignature ? (
            <MDBox py={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <InputText
                        label={t("Name")}
                        name="name"
                        required
                        error={submitted && !currentSignature.name}
                        value={currentSignature.name}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputDropdown
                        label={t("Language")}
                        options={languages}
                        name="language"
                        onChange={onChange}
                        value={currentSignature.language}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <InputEditor
                        label={t("Content")}
                        name="content"
                        placeholder={t("Type here")}
                        onChange={onChange}
                        value={currentSignature.content}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox
                        mb={2}
                        px={2}
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          paddingBottom: "16px",
                        }}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="black"
                          sx={{ marginBottom: "4px" }}
                        >
                          {t("Preview")}
                        </MDTypography>
                        <MDBox
                          sx={{
                            padding: "10px",
                            border: "1px solid #d2d6da",
                            borderRadius: "6px",
                            overflow: "auto",
                            flex: 1,
                          }}
                        >
                          <div
                            className="MuiTypography-root MuiTypography-button"
                            dangerouslySetInnerHTML={{
                              __html: getPreviewDate(currentSignature.content),
                            }}
                          />
                          <div
                            className="MuiTypography-root MuiTypography-button"
                            dangerouslySetInnerHTML={{
                              __html: getPreviewDate(currentSignature.content_inline),
                            }}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setCurrentSignature(null);
              setOpen(false);
            }}
          >
            {t("Cancel")}
          </MDButton>
          <MDButton
            variant="gradient"
            color="success"
            size="small"
            onClick={() => {
              handleSave();
            }}
          >
            {currentSignature && currentSignature.id ? t("Update") : t("Save")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={mapOpen} fullWidth maxWidth="sm">
        <DialogTitle>
          {t("User Map")} | {signature?.name}
        </DialogTitle>
        <DialogContent dividers>
          <MDBox sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {signature?.users.map((item, key) => (
              <Chip
                key={key}
                label={item.user_name ? item.user_name : item.user_email}
                variant="outlined"
                sx={{ marginLeft: 1, marginBottom: 1, fontWeight: 400 }}
                onDelete={() => {
                  handleUserSignatureMapDelete(item.user_signature_map_id);
                }}
              />
            ))}
            <IconButton
              color="info"
              onClick={(event) => {
                openUserMenu(event);
              }}
            >
              <Icon>add</Icon>
            </IconButton>
            <Menu
              id="menu_user"
              anchorReference="anchorPosition"
              anchorPosition={
                userMenu !== null
                  ? { top: userMenu.position.top, left: userMenu.position.left }
                  : undefined
              }
              open={!!userMenu}
              onClose={closeUserMenu}
            >
              <MenuItem
                disabled
                sx={{
                  opacity: "1 !important",
                  fontWeight: "bold",
                  backgroundColor: "#c3c3c3",
                  borderRadius: 0,
                  color: "#000",
                  marginBottom: "8px",
                }}
              >
                {t("Users")} :
              </MenuItem>
              {users
                .filter(
                  (item) => signature?.users.findIndex((u) => u.user_email === item.email) === -1
                )
                .map((user, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleAddUserSignatureMap(user.id);
                    }}
                  >
                    {user.name}
                  </MenuItem>
                ))}
            </Menu>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => {
              setSignature(null);
              setMapOpen(false);
            }}
          >
            {t("Close")}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default memo(Signatures);
