import Fetch from "../fetch";

const orderAPI = {
  async searchOrders(filter, page = 0, pageSize = 10, orderBy, order) {
    const params = [];
    if (filter) {
      Object.keys(filter).forEach((key) => {
        if (filter[key].length === 1) {
          params.push(`${key}=${filter[key][0]}`);
        } else {
          filter[key].forEach((value) => {
            params.push(`${key}[]=${value}`);
          });
        }
      });
    }
    params.push(`page=${page + 1}`);
    params.push(`pageSize=${pageSize}`);
    params.push(`order=${orderBy} ${order}`);
    const response = await Fetch.get(`api/erp/order?${params.join("&")}`);
    return response?.data;
  },
  async getOrder(documentIdentifier) {
    const response = await Fetch.get(`api/erp/order/${documentIdentifier}`);
    return response?.data;
  },
  async getOrderPositions(documentIdentifier) {
    const response = await Fetch.get(`api/erp/order/${documentIdentifier}/positions`);
    return response?.data;
  },
  async getOrderLogs(documentIdentifier) {
    const response = await Fetch.get(`api/erp/order/${documentIdentifier}/logs`);
    return response?.data;
  },
  async getOrderCustomer(documentIdentifier) {
    const response = await Fetch.get(`api/erp/order/${documentIdentifier}/customer`);
    return response?.data;
  },
};
export default orderAPI;
