/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */

import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import useLocalStorage from "hooks/useLocalStorage";
import useSessionStorage from "hooks/useSessionStorage";
import usePrevious from "hooks/usePrevious";

import DocumentPreview from "view/components/DocumentPreview";
import Customers from "./components/customers";
import Tickets from "./components/tickets";
import Dashboard from "./components/dashboard";
import Delivery from "./components/delivery";
import Customer from "./components/customer";
import Orders from "./components/orders";
import Order from "./components/order";
import Items from "./components/items";
import Item from "./components/item";
import Quotations from "./components/quotations";
import Quotation from "./components/quotation";
import Settings from "./components/settings";
import Trash from "./components/trash";

function Home() {
  const { t } = useTranslation("common");
  const staticTab = [
    {
      id: "dashboard",
      type: "DASHBOARD",
      label: "Dashboard",
      icon: "dashboard",
      disabled: false,
    },
    {
      id: "tickets",
      type: "TICKETS",
      label: "Tickets",
      icon: "task",
      disabled: false,
    },
  ];
  const [tabs, setTabs] = useLocalStorage("A24_TABS", [
    { id: "delivery", type: "DELIVERY", label: "Delivery Inventory", deleted: true },
  ]);
  const [tab, setTab] = useSessionStorage("A24_ACTIVE_TAB", staticTab[1]);
  const [menu, setMenu] = useState(null);
  const prevState = usePrevious({ tab });

  const init = () => {
    const tabsStr = localStorage.getItem("A24_TABS");
    let tabList = [];
    if (tabsStr && tabsStr !== "") {
      tabList = JSON.parse(tabsStr);
    }
    const activeTabStr = sessionStorage.getItem("A24_ACTIVE_TAB");
    let activeTab = staticTab[1];
    if (activeTabStr && activeTabStr !== "") {
      activeTab = JSON.parse(activeTabStr);
    }
    setTabs(tabList);
    if (activeTab.type === "CUSTOMER") {
      const index = tabList.findIndex((e) => e.id === activeTab.id && !e.deleted);
      setTab(index === -1 ? { ...staticTab[1] } : { ...activeTab });
    } else {
      setTab({ ...activeTab });
    }
  };

  useEffect(async () => {
    window.addEventListener("storage", init);
    return () => window.removeEventListener("storage", init);
  }, []);

  useEffect(() => {
    const element = document.getElementById("PARENT_ACTIVE_TAB");
    if (element) {
      element.scrollIntoView({ block: "end" });
    }
    if (tab && prevState && (prevState.tab?.type !== tab?.type || prevState.tab?.id !== tab?.id)) {
      window.dispatchEvent(
        new CustomEvent("tabChange", {
          detail: {
            type: tab.type,
            tab,
          },
        })
      );
    }
  }, [tab]);

  const openMenu = (event) => {
    setMenu({
      position: {
        top: event.clientY,
        left: event.clientX,
      },
      anchorEl: event.currentTarget,
    });
  };

  const closeMenu = () => setMenu(null);

  const addCustomers = () => {
    let lastNewID = 0;
    tabs.forEach((item) => {
      if (item.id.includes("customers-")) {
        const idNo = parseInt(item.id.replace(/[^0-9]/g, ""), 10);
        if (lastNewID < idNo) {
          lastNewID = idNo;
        }
      }
    });
    const newTab = {
      id: `customers-${lastNewID + 1}`,
      type: "CUSTOMERS",
      label: "Customers",
    };

    tabs.push(newTab);
    setTabs([...tabs]);
    setTab({ ...newTab });
  };

  const addOrders = () => {
    let lastNewID = 0;
    tabs.forEach((item) => {
      if (item.id.includes("orders-")) {
        const idNo = parseInt(item.id.replace(/[^0-9]/g, ""), 10);
        if (lastNewID < idNo) {
          lastNewID = idNo;
        }
      }
    });
    const newTab = {
      id: `orders-${lastNewID + 1}`,
      type: "ORDERS",
      label: "Orders",
    };

    tabs.push(newTab);
    setTabs([...tabs]);
    setTab({ ...newTab });
  };

  const addItems = () => {
    let lastNewID = 0;
    tabs.forEach((item) => {
      if (item.id.includes("items-")) {
        const idNo = parseInt(item.id.replace(/[^0-9]/g, ""), 10);
        if (lastNewID < idNo) {
          lastNewID = idNo;
        }
      }
    });
    const newTab = {
      id: `items-${lastNewID + 1}`,
      type: "ITEMS",
      label: "Items",
    };

    tabs.push(newTab);
    setTabs([...tabs]);
    setTab({ ...newTab });
  };

  const addQuotations = () => {
    let lastNewID = 0;
    tabs.forEach((item) => {
      if (item.id.includes("quotations-")) {
        const idNo = parseInt(item.id.replace(/[^0-9]/g, ""), 10);
        if (lastNewID < idNo) {
          lastNewID = idNo;
        }
      }
    });
    const newTab = {
      id: `quotations-${lastNewID + 1}`,
      type: "QUOTATIONS",
      label: "Quotations",
    };

    tabs.push(newTab);
    setTabs([...tabs]);
    setTab({ ...newTab });
  };

  const addDelivery = () => {
    const index = tabs.findIndex((x) => x.id === "delivery");
    if (index === -1) {
      const newTab = {
        id: `delivery`,
        type: "DELIVERY",
        label: "Delivery Inventory",
      };
      tabs.unshift(newTab);
      setTabs([...tabs]);
      setTab({ ...newTab });
    } else {
      tabs[index].deleted = false;
      setTabs([...tabs]);
      setTab({ ...tabs[index] });
    }
  };

  const handleSetTab = (item) => {
    setTab({ ...item });
  };

  const handleTabDelete = (index) => {
    let item = staticTab[staticTab.length - 1];
    tabs.every((tabItem, tabIndex) => {
      if (!tabItem.deleted && tabIndex < index) {
        item = tabItem;
      }
      if (!tabItem.deleted && tabIndex > index) {
        item = tabItem;
        return false;
      }
      return true;
    });
    tabs[index].deleted = true;
    setTabs([...tabs]);
    setTab({ ...item });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        py={0}
        position="relative"
        sx={{
          borderTop: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <MDBox
          bgColor="white"
          display="flex"
          id="PARENT_TABS"
          sx={{ overflow: "auto", whiteSpace: "nowrap" }}
        >
          {staticTab.map((item, index) => (
            <MDBox
              display="flex"
              alignItems="center"
              key={index}
              bgColor={tab?.id !== item.id ? "white" : "light"}
              id={tab?.id === item.id ? "PARENT_ACTIVE_TAB" : null}
              sx={{
                borderRight: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${tab?.id !== item.id ? borderWidth[1] : borderWidth[0]} solid ${borderColor}`,
                cursor: !item.disabled ? "pointer" : "default",
              }}
            >
              <MDBox
                display="flex"
                alignItems="center"
                p={2}
                onClick={() => {
                  if (!item.disabled) {
                    handleSetTab(item);
                  }
                }}
              >
                <MDBox display="flex" alignItems="center" mr={2}>
                  <Icon sx={{ fontWeight: "bold" }} fontSize="small">
                    {item.icon}
                  </Icon>
                </MDBox>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {t(item.label)}
                </MDTypography>
              </MDBox>
            </MDBox>
          ))}
          {tabs.map((item, index) => (
            <MDBox display="flex" key={index}>
              {!item.deleted ? (
                <MDBox
                  display="flex"
                  alignItems="center"
                  bgColor={tab?.id !== item.id ? "white" : "light"}
                  id={tab?.id === item.id ? "PARENT_ACTIVE_TAB" : null}
                  sx={{
                    borderRight: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${
                        tab?.id !== item.id ? borderWidth[1] : borderWidth[0]
                      } solid ${borderColor}`,
                    cursor: "pointer",
                  }}
                >
                  <MDBox
                    display="flex"
                    alignItems="center"
                    py={0}
                    px={2}
                    onClick={() => {
                      handleSetTab(item);
                    }}
                  >
                    <MDBox display="flex" flexDirection="column">
                      <MDTypography display="block" variant="button" fontWeight="medium">
                        {t(item.label)}
                      </MDTypography>
                      {item.name ? (
                        <MDTypography display="block" variant="button" fontWeight="medium">
                          {item.name?.length < 20 ? item.name : `${item.name?.substring(0, 20)}...`}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    mr={2}
                    onClick={() => {
                      handleTabDelete(index);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }} fontSize="small">
                      close
                    </Icon>
                  </MDBox>
                </MDBox>
              ) : null}
            </MDBox>
          ))}
          <MDBox
            display="flex"
            alignItems="center"
            bgColor="white"
            sx={{
              borderRight: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
              cursor: "pointer",
            }}
          >
            <MDBox
              display="flex"
              alignItems="center"
              p={2}
              onClick={openMenu}
              aria-describedby="menu_add"
            >
              <MDBox display="flex" flexDirection="column">
                <Icon>add</Icon>
              </MDBox>
            </MDBox>
            <Menu
              id="menu_add"
              anchorReference="anchorPosition"
              anchorPosition={
                menu !== null ? { top: menu.position.top, left: menu.position.left } : undefined
              }
              open={Boolean(menu)}
              onClose={closeMenu}
            >
              <MenuItem
                onClick={() => {
                  addCustomers();
                  closeMenu();
                }}
              >
                {t("Customers")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addOrders();
                  closeMenu();
                }}
              >
                {t("Orders")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addItems();
                  closeMenu();
                }}
              >
                {t("Items")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addQuotations();
                  closeMenu();
                }}
              >
                {t("Quotations")}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  addDelivery();
                  closeMenu();
                }}
              >
                {t("Delivery Inventory")}
              </MenuItem>
            </Menu>
          </MDBox>
        </MDBox>
        <MDBox display={tab?.type === "DASHBOARD" ? "" : "none"}>
          {useMemo(
            () => (
              <Dashboard />
            ),
            []
          )}
        </MDBox>
        <MDBox display={tab?.type === "TICKETS" ? "" : "none"}>
          {useMemo(
            () => (
              <Tickets />
            ),
            []
          )}
        </MDBox>
        {tabs.map((item, index) => (
          <MDBox key={index}>
            {!item.deleted ? (
              <MDBox>
                <MDBox display={tab?.type === "DELIVERY" && item.id === tab?.id ? "" : "none"}>
                  <Delivery tabIndex={index} type={item.type} />
                </MDBox>
                <MDBox display={tab?.type === "CUSTOMERS" && item.id === tab?.id ? "" : "none"}>
                  <Customers tabIndex={index} type={item.type} />
                </MDBox>
                <MDBox display={tab?.type === "CUSTOMER" && item.id === tab?.id ? "" : "none"}>
                  <Customer customerID={item?.id} ticketID={item?.ticketId} type={item.type} />
                </MDBox>
                <MDBox display={tab?.type === "ORDERS" && item.id === tab?.id ? "" : "none"}>
                  <Orders tabIndex={index} type={item.type} />
                </MDBox>
                <MDBox display={tab?.type === "ORDER" && item.id === tab?.id ? "" : "none"}>
                  <Order documentIdentifier={item?.id} type={item.type} tab={item?.tab} />
                </MDBox>
                <MDBox display={tab?.type === "ITEMS" && item.id === tab?.id ? "" : "none"}>
                  <Items tabIndex={index} type={item.type} />
                </MDBox>
                <MDBox display={tab?.type === "ITEM" && item.id === tab?.id ? "" : "none"}>
                  <Item articleNumber={item?.id} type={item.type} tab={item?.tab} />
                </MDBox>
                <MDBox display={tab?.type === "QUOTATIONS" && item.id === tab?.id ? "" : "none"}>
                  <Quotations tabIndex={index} type={item.type} />
                </MDBox>
                <MDBox display={tab?.type === "QUOTATION" && item.id === tab?.id ? "" : "none"}>
                  <Quotation documentIdentifier={item?.id} type={item.type} tab={item?.tab} />
                </MDBox>
                <MDBox display={tab?.type === "SETTINGS" && item.id === tab?.id ? "" : "none"}>
                  <Settings tabIndex={index} type={item.type} />
                </MDBox>
                <MDBox display={tab?.type === "TRASH" && item.id === tab?.id ? "" : "none"}>
                  <Trash tabIndex={index} type={item.type} />
                </MDBox>
              </MDBox>
            ) : null}
          </MDBox>
        ))}
      </MDBox>
      <DocumentPreview />
    </DashboardLayout>
  );
}

export default Home;
