import Fetch from "./fetch";

const TicketsAPI = {
  async getAll(perPage = 50, page = 0) {
    const response = await Fetch.get(`api/ticket?page=${page + 1}&per_page=${perPage}`);
    return response?.data;
  },
  async getTicketsByFilter(perPage = 50, page = 0, data) {
    const response = await Fetch.post(
      `api/tickets/filter?page=${page + 1}&per_page=${perPage}`,
      data
    );
    return response?.data;
  },
  async getTicketsByCustomerFilter(perPage = 50, page = 0, data) {
    const response = await Fetch.post(
      `api/tickets/customerfilter?page=${page + 1}&per_page=${perPage}`,
      data
    );
    return response?.data;
  },
  async getStatuses() {
    const response = await Fetch.get(`api/ticketstatus/`);
    return response;
  },
  async changeStatus(data) {
    const response = await Fetch.post(`api/ticket/status`, data);
    return response?.data;
  },
  async reassignTicket(data) {
    const response = await Fetch.post(`api/reassignticket`, data);
    return response?.data;
  },
  async getTicketsSearchByEmail(email) {
    const response = await Fetch.post(`api/tickets/search`, { email });
    return response?.data;
  },
  async getTicketDetails(ticketID) {
    const response = await Fetch.get(`api/ticket/${ticketID}`);
    return response?.data;
  },
  async replyTicket(data) {
    const response = await Fetch.post(`api/ticketdetail`, data);
    return response;
  },
  async createTicket(data) {
    const response = await Fetch.post(`api/ticket`, data);
    return response;
  },
  async getAttachmentsURL(key) {
    const response = await Fetch.post(`api/ticketattachment`, { key });
    return response;
  },
  async addAttachment(ticketDetailId, file) {
    const formData = new FormData();
    formData.append("file_path", file);
    formData.append("ticket_detail_id", ticketDetailId);
    const response = await Fetch.postFormData(`api/ticketdetail/attachment`, formData);
    return response;
  },
  async deleteAttachment(ticketDetailId, fileName) {
    const response = await Fetch.post(`api/ticketdetail/deleteattachment`, {
      ticket_detail_id: ticketDetailId,
      file_name: fileName,
    });
    return response;
  },
  async delateDraft(ticketDetailId) {
    const response = await Fetch.post(`api/ticketdetail/deletedraft`, {
      ticket_detail_id: ticketDetailId,
    });
    return response;
  },
  async getMailInfo(messageId) {
    const response = await Fetch.post(`api/email/mailinfo`, {
      message_id: messageId,
    });
    return response;
  },
  async softDeleteTicket(data) {
    const response = await Fetch.post(`api/ticket/softdelete`, data);
    return response;
  },
  async hardDeleteTicket(ticketID) {
    const response = await Fetch.delete(`api/ticket/harddelete/${ticketID}`);
    return response;
  },
};
export default TicketsAPI;
