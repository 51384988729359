/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import { useState, useEffect, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";

import MDBox from "components/MDBox";
import MDCalendar from "components/MDCalendar";
import Progress from "components/Progress";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import TasksAPI from "services/tasks";
import ERPAPI from "services/erp";
import CustomersAPI from "services/customers";
import CommonFun from "services/common";

function Calendar() {
  const { t, i18n } = useTranslation("common");
  const calendarRef = useRef();
  const [viewType, setViewType] = useState("CALENDAR");
  const [tasks, setTasks] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTasks = async () => {
    setLoading(true);
    const response = await TasksAPI.getAllByUser(100000, 0);
    if (response && response.data) {
      setTasks(response.data);
      const eventArray = [];
      response.data.forEach((item) => {
        const key = moment.utc(item.task_date).format();
        const findIndex = eventArray.findIndex((e) => e.start === key);
        if (findIndex === -1) {
          eventArray.push({
            id: key,
            start: key,
            list: [item],
          });
        } else {
          eventArray[findIndex].list.push(item);
        }
      });
      setEvents(eventArray);
    } else {
      setTasks([]);
      setEvents([]);
    }
    setLoading(false);
  };

  useEffect(async () => {
    getTasks();
  }, []);

  useEffect(() => {
    const onTabChange = (event) => {
      if (event.detail.type === "DASHBOARD") {
        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.updateSize();
        }
      }
    };
    window.addEventListener("tabChange", onTabChange);
    return () => window.removeEventListener("tabChange", onTabChange);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, data) => {
    setAnchorEl({
      id: data.event.id,
      target: event.currentTarget,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTicket = async (row) => {
    let erpData = {};
    let leadData = {};
    if (row.ticket.customer_email) {
      erpData = {
        email: row.ticket.customer_email,
      };
      leadData = {
        email: row.ticket.customer_email,
      };
    } else {
      erpData = {
        phone: CommonFun.serverPhone(row.ticket.from_phone),
      };
      leadData = {
        phone: CommonFun.serverPhone(row.ticket.from_phone),
      };
    }
    const erpResponse = await ERPAPI.findCustomer(erpData);
    const response = await CustomersAPI.searchCustomer(leadData);
    const searchList = [];
    if (
      erpResponse &&
      erpResponse.data &&
      erpResponse.data.items &&
      erpResponse.data.items.length
    ) {
      erpResponse.data.items.forEach((item) => {
        searchList.push({
          id: item.customerNumber,
          name:
            item.shippingAddress && item.shippingAddress.name2
              ? item.shippingAddress.name2
              : item.customerNumber,
          email: item.email,
          phone: item.phone,
          mobile: item.mobile,
          ticketID: row.ticket.id,
          lead: false,
        });
      });
    }
    if (response && response.length) {
      response.forEach((item) => {
        searchList.push({
          id: item.id,
          name: `${item.first_name ? item.first_name : ""} ${item.last_name ? item.last_name : ""}`,
          email: item.email,
          phone: item.phone,
          mobile: item.mobile,
          ticketID: row.ticket.id,
          lead: true,
        });
      });
    }
    if (searchList.length === 0) {
      CommonFun.onClickCustomer(
        `new-ticket-${row.ticket.id}`,
        row.ticket.customer_name,
        row.ticket.customer_email,
        row.ticket.from_phone,
        null,
        row.ticket.id
      );
    } else if (searchList.length === 1) {
      CommonFun.onClickCustomer(
        `${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`,
        searchList[0].name,
        searchList[0].email,
        searchList[0].phone,
        searchList[0].mobile,
        searchList[0].ticketID
      );
    } else {
      CommonFun.onClickCustomer(
        `${searchList[0].lead ? "lead" : "erp"}-${searchList[0].id}`,
        searchList[0].name,
        searchList[0].email,
        searchList[0].phone,
        searchList[0].mobile,
        searchList[0].ticketID
      );
    }
  };

  const handleCustomer = async (row) => {
    if (row && row.customer_id) {
      const response = await CustomersAPI.getCustomerDetails(row.customer_id);
      if (response) {
        const name = response.first_name;
        CommonFun.onClickCustomer(`lead-${row.customer_id}`, name);
      } else {
        CommonFun.onClickCustomer(`lead-${row.customer_id}`, "unknown");
      }
    } else if (row && row.erp_customer_id) {
      const ERPResponse = await ERPAPI.getCustomerDetails(row.erp_customer_id);
      if (ERPResponse && ERPResponse.data) {
        const name =
          ERPResponse.data.shippingAddress && ERPResponse.data.shippingAddress.name2
            ? ERPResponse.data.shippingAddress.name2
            : ERPResponse.data.customerNumber;
        CommonFun.onClickCustomer(`erp-${row.erp_customer_id}`, name);
      } else {
        CommonFun.onClickCustomer(`erp-${row.erp_customer_id}`, "unknown");
      }
    }
  };

  const renderEventContent = (eventInfo) => (
    <>
      <IconButton
        onClick={(event) => {
          handleClick(event, eventInfo);
        }}
        size="small"
        aria-controls={
          anchorEl?.id === eventInfo.event.id ? `calendar-menu-${eventInfo.event.id}` : undefined
        }
        sx={{ color: "#66BB6A" }}
        aria-haspopup="true"
        aria-expanded={anchorEl?.id === eventInfo.event.id ? "true" : undefined}
      >
        <Badge badgeContent={eventInfo.event.extendedProps.list.length} color="primary">
          <Icon sx={{ fontSize: "24px !important" }}>notifications</Icon>
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl?.target}
        id={`calendar-menu-${eventInfo.event.id}`}
        open={Boolean(anchorEl && anchorEl?.id === eventInfo.event.id)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "#f0f2f5 !important",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            padding: "0.5rem",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              backgroundColor: "#f0f2f5 !important",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {eventInfo.event.extendedProps.list.map((item, index) => (
          <MenuItem key={index} sx={{ padding: "0.5rem" }}>
            <Card
              key={index}
              sx={{ cursor: "pointer", borderRadius: 0 }}
              onClick={() => {
                if (item.ticket_id && item.ticket) {
                  handleTicket(item);
                } else if (item.customer_id || item.erp_customer_id) {
                  handleCustomer(item);
                }
              }}
            >
              <CardContent sx={{ padding: "0.5rem !important" }}>
                <MDBox display="flex" flexDirection="column">
                  <MDTypography variant="button" fontWeight="medium" color="dark">
                    {item.title || "Unknown"}
                  </MDTypography>
                  <MDBox display="flex" alignItems="center">
                    <MDBox mr={1} lineHeight={1}>
                      {item.ticket_id && item.ticket ? <Icon color="info">task</Icon> : null}
                      {item.customer_id || item.erp_customer_id ? (
                        <Icon color="success">person</Icon>
                      ) : null}
                    </MDBox>
                    <MDTypography variant="caption" color="text">
                      {moment.utc(item.task_date).format(CommonFun.getDateTimeFormat())}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </CardContent>
            </Card>
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  return (
    <Card sx={{ minHeight: "100%" }}>
      <Progress loading={loading} />
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            {t("Calendar")}
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            iconOnly
            onClick={() => {
              getTasks();
            }}
            sx={{ marginLeft: 2 }}
          >
            <Icon>refresh</Icon>
          </MDButton>
          <MDButton
            variant={viewType === "CALENDAR" ? "gradient" : "outlined"}
            color="secondary"
            iconOnly
            onClick={() => {
              setViewType("CALENDAR");
            }}
            sx={{ marginLeft: 2 }}
          >
            <Icon>event</Icon>
          </MDButton>
          <MDButton
            variant={viewType === "LIST" ? "gradient" : "outlined"}
            color="secondary"
            iconOnly
            onClick={() => {
              setViewType("LIST");
            }}
            sx={{ marginLeft: 1 }}
          >
            <Icon>view_list</Icon>
          </MDButton>
        </MDBox>
      </MDBox>
      {viewType === "CALENDAR" ? (
        <MDCalendar
          weekends
          locale={i18n.language}
          events={events}
          eventContent={renderEventContent}
          ref={calendarRef}
        />
      ) : (
        <MDBox p={2} sx={{ maxHeight: 400, overflow: "auto", backgroundColor: "#f0f2f5" }}>
          {tasks.map((item, index) => (
            <Card
              key={index}
              sx={{ marginBottom: 1, cursor: "pointer", borderRadius: 0 }}
              onClick={() => {
                if (item.ticket_id && item.ticket) {
                  handleTicket(item);
                } else if (item.customer_id || item.erp_customer_id) {
                  handleCustomer(item);
                }
              }}
            >
              <CardContent sx={{ padding: "0.5rem 1rem !important" }}>
                <MDBox display="flex" flexDirection="column">
                  <MDTypography variant="button" fontWeight="medium" color="dark">
                    {item.title || "Unknown"}
                  </MDTypography>
                  <MDBox display="flex" alignItems="center">
                    <MDBox mr={1} lineHeight={1}>
                      {item.ticket_id && item.ticket ? <Icon color="info">task</Icon> : null}
                      {item.customer_id || item.erp_customer_id ? (
                        <Icon color="success">person</Icon>
                      ) : null}
                    </MDBox>
                    <MDTypography variant="caption" color="text">
                      {moment.utc(item.task_date).format(CommonFun.getDateTimeFormat())}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </CardContent>
            </Card>
          ))}
        </MDBox>
      )}
    </Card>
  );
}

export default memo(Calendar);
