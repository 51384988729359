import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Collapse from "@mui/material/Collapse";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDSnackbarIconRoot from "components/MDSnackbar/MDSnackbarIconRoot";

import { useMaterialUIController } from "context";

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

function MDSnackbar({
  type,
  color,
  icon,
  title,
  dateTime,
  content,
  duration,
  close,
  bgWhite,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [collapseIn, setCollapseIn] = useState(true);

  let titleColor;
  let dateTimeColor;
  let dividerColor;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = "dark";
    dividerColor = false;
  } else if (color === "light") {
    titleColor = darkMode ? "inherit" : "dark";
    dateTimeColor = darkMode ? "inherit" : "black";
    dividerColor = false;
  } else {
    titleColor = "white";
    dateTimeColor = "white";
    dividerColor = true;
  }

  useEffect(async () => {
    setCollapseIn(type !== "error");
  }, [type, title, content]);

  return (
    <Snackbar
      TransitionComponent={SlideTransition}
      autoHideDuration={duration}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...rest}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
          <Icon fontSize="small">close</Icon>
        </IconButton>
      }
    >
      <MDBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        maxWidth="21.875rem"
        shadow="md"
        borderRadius="md"
        p={1}
        sx={{
          backgroundColor: ({ palette }) =>
            darkMode ? palette.background.card : palette.white.main,
          border: bgWhite ? "1px solid" : "none",
          borderColor: ({ palette }) => (bgWhite ? palette[color].main : null),
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="black"
          p={1.5}
        >
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <MDSnackbarIconRoot fontSize="small" ownerState={{ color, bgWhite }}>
              {icon}
            </MDSnackbarIconRoot>
            <MDTypography
              variant="button"
              fontWeight="medium"
              color={titleColor}
              textGradient={bgWhite}
            >
              {title}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <MDTypography variant="caption" color={dateTimeColor}>
              {dateTime}
            </MDTypography>
            <Icon
              sx={{
                color: ({ palette: { dark, white } }) =>
                  (bgWhite && !darkMode) || color === "light" ? dark.main : white.main,
                fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                cursor: "pointer",
                marginLeft: 2,
                transform: "translateY(-1px)",
              }}
              onClick={() => {
                setCollapseIn(!collapseIn);
              }}
            >
              {collapseIn ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </Icon>
            <Icon
              sx={{
                color: ({ palette: { dark, white } }) =>
                  (bgWhite && !darkMode) || color === "light" ? dark.main : white.main,
                fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                cursor: "pointer",
                marginLeft: 2,
                transform: "translateY(-1px)",
              }}
              onClick={close}
            >
              close
            </Icon>
          </MDBox>
        </MDBox>
        <Collapse in={collapseIn} sx={{ display: !collapseIn ? "none" : null }}>
          <Divider sx={{ margin: 0 }} light={dividerColor} />
          <MDBox
            p={1.5}
            sx={{
              fontSize: ({ typography: { size } }) => size.sm,
              color: ({ palette: { white, text } }) => {
                let colorValue = bgWhite || color === "light" ? text.main : white.main;

                if (darkMode) {
                  colorValue = color === "light" ? "inherit" : white.main;
                }

                return colorValue;
              },
              wordBreak: "break-word",
            }}
          >
            {content}
          </MDBox>
        </Collapse>
      </MDBox>
    </Snackbar>
  );
}

MDSnackbar.defaultProps = {
  type: "success",
  bgWhite: false,
  color: "info",
  dateTime: "",
  duration: 5000,
};

MDSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  type: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  content: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  bgWhite: PropTypes.bool,
  duration: PropTypes.number,
};

export default MDSnackbar;
