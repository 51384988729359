/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import Progress from "components/Progress";
import { useMaterialUIController, setPreviewDocument } from "context";

import ServerSideTable from "components/Tables/ServerSideTable";
import ERPAPI from "services/erp";
import CommonFun from "services/common";

function CollectiveFolder({ customerID }) {
  const { t } = useTranslation("common");
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, dispatch] = useMaterialUIController();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [orderBy, setOrderBy] = useState("documentId");
  const [order, setOrder] = useState("desc");

  const getCustomerDocumentsSage = async () => {
    if (customerID.includes("erp-")) {
      setLoading(true);
      const ERPResponse = await ERPAPI.getCustomerDocumentsSage(
        customerID.replace("erp-", ""),
        page,
        pageSize,
        orderBy,
        order
      );
      if (
        ERPResponse &&
        ERPResponse.data &&
        ERPResponse.data.items &&
        ERPResponse.data.items.length
      ) {
        setFolders(ERPResponse.data.items);
        setCount(ERPResponse.data.totalCount);
      } else {
        setFolders([]);
      }
      setLoading(false);
    } else {
      setFolders([]);
    }
  };

  useEffect(async () => {
    getCustomerDocumentsSage();
  }, [customerID, page, pageSize, orderBy, order]);

  const columns = [
    {
      header: t("Date"),
      accessor: "documentDate",
      align: "left",
      sort: true,
      dateFormat: CommonFun.getDateFormat(),
    },
    {
      header: t("Year"),
      accessor: "documentYear",
      align: "left",
      sort: true,
    },
    {
      header: t("Document No."),
      accessor: "documentNumber",
      align: "left",
      sort: true,
    },
    {
      header: t("Document Type"),
      accessor: "documentType",
      align: "left",
      sort: true,
    },
    {
      header: t("Process"),
      accessor: "processId",
      align: "left",
      sort: true,
    },
    {
      header: t("Country Delivery Address"),
      accessor: "countryDeliveryAddress",
      align: "left",
      sort: true,
    },
    {
      header: t("EU VAT Id No."),
      accessor: "euVATID",
      align: "left",
      sort: true,
    },
    {
      header: t("Gross Amount Document Currency"),
      accessor: "nativeTotalValue",
      align: "left",
      sort: true,
      currency: "nativeCurrencyIso",
    },
    {
      header: t("Tax Amount Document Currency"),
      accessor: "nativeTaxAmount",
      align: "left",
      sort: true,
      currency: "nativeCurrencyIso",
    },
    {
      header: t("Net Amount Document Currency"),
      accessor: "nativeNetValue",
      align: "left",
      sort: true,
      currency: "nativeCurrencyIso",
    },
    {
      header: t("UstID Valid"),
      accessor: "validUntil",
      align: "left",
      sort: true,
    },
    {
      header: t("UstID Checked"),
      accessor: "validUntil",
      align: "left",
      sort: true,
    },
    {
      header: t("Documents"),
      accessor: "documents",
      align: "center",
      sort: false,
      render: (row) => (
        <MDBox sx={{ position: "relative" }}>
          <IconButton
            color="black"
            size="small"
            onClick={() => {
              setPreviewDocument(dispatch, {
                isImage: false,
                documentIdentifier: row.documentIdentifier,
                name: row.documentType,
                dmsDocumentId: row.dmsDocumentId,
              });
            }}
            disabled={!row.documentIdentifier || !row.dmsDocumentId}
          >
            <Icon>picture_as_pdf</Icon>
          </IconButton>
        </MDBox>
      ),
      getCellProps: () => ({
        // onClick: () => {}
        style: {
          cursor: "default",
        },
      }),
    },
  ];

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line no-console
  console.log("render customer", customerID, "Collective Folder");

  return (
    <MDBox bgColor="light">
      <Progress loading={loading} isFull />
      <ServerSideTable
        fixedTable
        rowsPerPage={pageSize}
        page={page}
        columns={columns}
        data={folders}
        count={count}
        order={order}
        orderBy={orderBy}
        onRequestSort={(key) => {
          const isAsc = orderBy === key && order === "asc";
          setOrder(isAsc ? "desc" : "asc");
          setOrderBy(key);
        }}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        getRowProps={() => ({
          // onClick: () => {
          // },
          // style: {
          //   cursor: "pointer",
          // },
        })}
      />
    </MDBox>
  );
}

export default memo(CollectiveFolder);
